import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    flex: '1 1 100%',
    marginBottom: 8,
  },
}));

export default useStyles;
