import React from 'react';
import * as VisitorAnalyticsSelectors from 'redux/reducers/visitorAnalytics_reducer/selectors';
import { DaysRangeEnum } from 'config';
import reactLogger from 'utils/logger';

interface ILayoutElementWrapperProps {
  Component: React.FC;
  categoryTypes: DaysRangeEnum[];
}

const LayoutElementWrapper = React.memo((props: ILayoutElementWrapperProps) => {
  const selectedRangeType = VisitorAnalyticsSelectors.useGetDateRangeType();
  reactLogger.renderComponent('LayoutElementWrapper');

  const { Component, categoryTypes } = props;
  return (
    <React.Fragment>
      {categoryTypes.includes(selectedRangeType) && <Component />}
    </React.Fragment>
  );
});

export default LayoutElementWrapper;
