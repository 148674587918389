import {
  IGridConf,
  ComponentTypeEnum,
} from 'components/compounds/dashboard_builder/configuration';
import { ChartOptions, ChartTooltipItem } from 'chart.js';
import { ChartTypesEnum } from 'components/molecules/custom_chart';
import * as StoreService from 'services/api/stores';
import * as DateUtils from 'utils/dates';
import { Theme } from '@material-ui/core';

export const chartOptionsDoughnut = (): ChartOptions => ({
  cutoutPercentage: 80,
  title: {
    display: false,
    position: 'top',
    fontStyle: 'bold',
    fontSize: 0,
    fullWidth: false,
    padding: 0,
  },
  maintainAspectRatio: true,
  legend: {
    display: false,
    position: 'top',
    fullWidth: false,
  },
  responsive: true,
  // tooltips: {
  //   enabled: true,
  //   callbacks: {
  //     label: (tooltipItem: ChartTooltipItem, data: any) => {
  //       return `${data.labels[tooltipItem.index || 0]}: ${0}%`;
  //     },
  //   },
  // },
  tooltips: {
    enabled: true,
    callbacks: {
      label: (tooltipItem: ChartTooltipItem, data: any) => {
        return `${
          data.datasets[tooltipItem.datasetIndex || 0].data[
            tooltipItem.index || 0
          ]
        } Persons`;
      },
    },
  },
  plugins: {
    datalabels: {
      display: false,
    },
    centerText: {
      display: true,
      formatText: (data: any) => {
        // console.log(data.labels)
        if (data.labels.length > 0) {
          let current = data.datasets[0].data[data.labels.indexOf('CURRENT')];
          let total =
            data.datasets[0].data[data.labels.indexOf('TOTAL')] + current;
          let percentage = ((current * 100) / total).toFixed(0);
          return `${percentage}%`;
        }
      },
      // color: CHART_TEXT_COLOR(theme),
    },
  },
});

export const DashboardConfig = (storeid: string): IGridConf[] => [
  // {
  //   width: 3,
  //   type: ComponentTypeEnum.statistic,
  //   props: {
  //     title: 'In',
  //     description: '',
  //     backgroundColor: 'rgba(240, 30, 2, .5)',
  //     titleColor: 'white',
  //     descriptionColor: 'white',
  //     containerStyle: {
  //       alignItems: 'center',
  //       justifyContent: 'center',
  //     },
  //     descriptionStyle: {
  //       fontSize: '2rem',
  //       marginTop: '0.2rem',
  //     },
  //   },
  //   fetch: async () => {
  //     try {
  //       const result = await StoreService.getStoreStatisticData(storeid);
  //       return {
  //         title: 'In',
  //         description: result.data.occupancy?.in?.toString(),
  //       };
  //     } catch (error) {
  //       return null;
  //     }
  //   },
  // },
  // {
  //   width: 3,
  //   type: ComponentTypeEnum.statistic,
  //   props: {
  //     title: 'Out',
  //     description: '',
  //     backgroundColor: 'rgba(66, 135, 245, .5)',
  //     titleColor: 'white',
  //     descriptionColor: 'white',
  //     containerStyle: {
  //       alignItems: 'center',
  //       justifyContent: 'center',
  //     },
  //     descriptionStyle: {
  //       fontSize: '2rem',
  //       marginTop: '0.2rem',
  //     },
  //   },
  //   fetch: async () => {
  //     try {
  //       const result = await StoreService.getStoreStatisticData(storeid);
  //       return {
  //         title: 'Out',
  //         description: result.data.occupancy?.out?.toString(),
  //       };
  //     } catch (error) {
  //       return null;
  //     }
  //   },
  // },
  {
    width: 12,
    type: ComponentTypeEnum.statistic,
    props: {
      title: 'Current',
      description: '',
      backgroundColor: 'rgba(8, 199, 30, .5)',
      titleColor: 'white',
      descriptionColor: 'white',
      containerStyle: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: 0,
        margin: 0,
      },
      descriptionStyle: {
        fontSize: '2rem',
        marginTop: '0.2rem',
      },
    },
    fetch: async () => {
      try {
        const result = await StoreService.getStoreStatisticData(storeid);
        return {
          title: 'Current',
          description: result.data.occupancy?.current?.toString(),
        };
      } catch (error) {
        return null;
      }
    },
  },
  {
    width: 12,
    type: ComponentTypeEnum.statistic,
    props: {
      title: 'Last Updated',
      description: '',
      backgroundColor: 'rgba(12, 15, 89, .5)',
      titleColor: 'white',
      descriptionColor: 'white',
      containerStyle: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: 0,
        margin: 0,
      },
      descriptionStyle: {
        fontSize: '2rem',
        marginTop: '0.2rem',
      },
    },
    fetch: async () => {
      try {
        const result = await StoreService.getStoreStatisticData(storeid);
        return {
          title: 'Last Updated',
          description: result.data.lastUpdated
            ? DateUtils.formatDate(result.data.lastUpdated, true) || ''
            : 'Not Updated Yet',
        };
      } catch (error) {
        return null;
      }
    },
  },
];

export const DashboardConfig1 = (storeid: string): IGridConf[] => [
  {
    width: 12,
    type: ComponentTypeEnum.chart,
    props: {
      height: 'auto',

      data: [
        {
          data: [100, 0],
          label: '',
          format: {
            label: 'Dataset 1',
            fill: false,
            borderColor: ['#DD2A42', '#D0D0D0'],
            lineTension: 0.1, // make the lines without radius
            backgroundColor: ['#DD2A42', '#D0D0D0'],
            pointBorderColor: ['#DD2A42', '#D0D0D0'],
            pointBackgroundColor: ['#DD2A42', '#D0D0D0'],
            pointHoverBackgroundColor: ['#DD2A42', '#D0D0D0'],
            pointHoverBorderColor: ['#DD2A42', '#D0D0D0'],
          },
        },
      ],
      labels: ['TOTAL', 'CURRENT'],
      chartType: ChartTypesEnum.DOUGHNUT,
      options: chartOptionsDoughnut(),
    },
    fetch: async () => {
      try {
        const result = await StoreService.getStoreStatisticData(storeid);

        // console.log(
        //   'TEST::',
        //   result.data.occupancy.current / result.data.cappacity,
        //   1 - result.data.occupancy.current / result.data.cappacity
        // );
        return {
          data: [
            [
              1 - result.data.occupancy.current / result.data.cappacity,
              result.data.occupancy.current / result.data.cappacity,
            ],
          ],
          labels: ['TOTAL', 'CURRENT'],
        };
      } catch (error) {
        console.log('ERR' + error);
        return null;
      }
    },
  },
];

export const StoreInOutOptionsChart = (): any => ({
  legend: {
    display: true,
    align: 'start',
    labels: {
      usePointStyle: true,
    },
  },
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      display: false,
    },
  },
  responsive: true,
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: true,
        },
      },
    ],
  },
  tooltips: {
    enabled: true,
  },
});
