import GridLayout from 'components/molecules/grid_layout';
import React, { ReactElement } from 'react';
import SelectForm from '../select_form';
import reactLogger from 'utils/logger';
import Store1VsStore2PerDay from '../store_1_vs_store_2_per_day';
import { MiniStoreDashboard1, MiniStoreDashboard2 } from '../store_dashboard';

export default function GraphsLayout(): ReactElement {
  reactLogger.renderComponent('GraphsLayout');
  return (
    <React.Fragment>
      <GridLayout
        spacing={1}
        elements={[
          {
            size: 12,
            element: <SelectForm />,
            id: '1',
          },
          {
            size: 12,
            element: <Store1VsStore2PerDay />,
            id: '2',
          },
          {
            size: 6,
            element: <MiniStoreDashboard1 />,
            id: '3',
          },
          {
            size: 6,
            element: <MiniStoreDashboard2 />,
            id: '4',
          },
        ]}
      />
    </React.Fragment>
  );
}
