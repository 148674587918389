import {
  IAction,
  IActionMethods,
  StateStatus,
  KEEP_THE_SAME,
} from '../../utils/common';
import * as Types from './types';
import * as NotificationService from 'services/api/notification';
import { Dispatch } from 'redux';
import reactLogger from 'utils/logger';
import _ from 'lodash';
import * as UserService from 'services/api/user';

/** Fetch Stores  */
class FetchNotifications implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_NOTIFICATION_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_NOTIFICATION_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_NOTIFICATION_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(input: NotificationService.IFetchNotificationsInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await NotificationService.fetchNotificationList(input);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        reactLogger.error('FetchNotifications Error:', error.message);
        dispatch(this.onFailed());
      }
    };
  }
}

/** Add Notification */
interface IAddNotification extends NotificationService.IAddNotificationInput {
  onFinish: () => void;
}

class AddNotification implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.ADD_NOTIFICATION,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.ADD_NOTIFICATION,
      status: StateStatus.Success,
      data: null,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.ADD_NOTIFICATION,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(input: IAddNotification): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        const { onFinish, ...data } = input;
        await NotificationService.addNotification(data);
        dispatch(this.onSuccess());
        onFinish();
      } catch (error) {
        reactLogger.error('AddNotification Error:', error.message);
        dispatch(this.onFailed());
      }
    };
  }
}

/** Read unread notification */
interface ReadUnReadNotificationInput {
  read: boolean;
  notificationid: string;
  onFinish: () => void;
}

class ReadUnReadNotification implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.ADD_NOTIFICATION,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.ADD_NOTIFICATION,
      status: StateStatus.Success,
      data: null,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.ADD_NOTIFICATION,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(input: ReadUnReadNotificationInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        await NotificationService.editNotification(
          {
            read: input.read,
          },
          input.notificationid
        );
        input.onFinish();
        dispatch(this.onSuccess());
      } catch (error) {
        reactLogger.error('ReadUnReadNotification Error:', error.message);
        dispatch(this.onFailed());
      }
    };
  }
}

// /** Fetch Users With Details  */

// class FetchUsersWithDetails implements IActionMethods {
//   onPending(): IAction {
//     return {
//       type: Types.SET_USERS_LIST,
//       status: StateStatus.Pending,
//       data: KEEP_THE_SAME,
//     };
//   }

//   onSuccess(data: UserService.IUserDataWithDetailsData): IAction {
//     return {
//       type: Types.SET_USERS_LIST,
//       status: StateStatus.Success,
//       data,
//     };
//   }

//   onFailed(): IAction {
//     return {
//       type: Types.SET_USERS_LIST,
//       status: StateStatus.Failed,
//       data: {},
//     };
//   }

//   action(data: UserService.IUserDetailsInput): any {
//     return async (dispatch: Dispatch<any>) => {
//       try {
//         dispatch(this.onPending());
//         const result = await UserService.getUserListDetails(data);
//         dispatch(
//           this.onSuccess(result.data as UserService.IUserDataWithDetailsData)
//         );
//       } catch (error) {
//         reactLogger.error('FetchUsers Error:', error.message);
//         dispatch(this.onFailed());
//       }
//     };
//   }
// }

class FetchUsers implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_USERS_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }

  onSuccess(data: UserService.IUserData): IAction {
    return {
      type: Types.SET_USERS_LIST,
      status: StateStatus.Success,
      data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_USERS_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        const result = await UserService.getUserList();
        dispatch(this.onSuccess(result.data));
      } catch (error) {
        reactLogger.error('FetchUsers Error:', error.message);
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  fetchNotificationsAction: (
    payload: NotificationService.IFetchNotificationsInput
  ) => new FetchNotifications().action(payload),
  addNotificationAction: (payload: IAddNotification) =>
    new AddNotification().action(payload),
  readUnReadNotificationAction: (payload: ReadUnReadNotificationInput) =>
    new ReadUnReadNotification().action(payload),
  fetchUsersAction: () => new FetchUsers().action(),
};
