import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useForm } from 'react-hook-form';
import Typography from '@material-ui/core/Typography';
import useStyles from './styles';
import { useHistory } from 'react-router';
import TranslatedText from '../../../../components/atoms/translated_text';
import { Translations } from '../../configuration';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import ResetPasswordActions from '../../../../redux/reducers/resetPassword_reducer/actions';
import { useSelectForgotPassworddStatus } from '../../../../redux/reducers/resetPassword_reducer/selectors';
import { StateStatus } from '../../../../redux/utils/common';

export default function ForgotForm() {
  const classes = useStyles();
  const { register, watch, errors, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  let watchUsername = watch('email');
  let forgotPasswordStatus = useSelectForgotPassworddStatus();

  const errorSelector = (fieldName: string) => {
    if (fieldName in errors) {
      if (errors[fieldName].type === 'required') {
        return <TranslatedText textMap={Translations.form_error_required} />;
      }
    } else {
      return '';
    }
  };

  const onSubmit = (data: any) => {
    dispatch(ResetPasswordActions.forgotPasswordAction(data.email, history));
  };

  return (
    <div className={clsx(classes.layout)}>
      <Typography
        variant="h5"
        className={clsx(classes.title, 'text-center md:w-full mb-48')}
      >
        <TranslatedText textMap={Translations.recover_title} />
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label={<TranslatedText textMap={Translations.form_field_email} />}
          name="email"
          autoComplete="email"
          error={'email' in errors}
          helperText={errorSelector('email')}
          inputRef={register({ required: true })}
          autoFocus
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={
            !watchUsername || forgotPasswordStatus == StateStatus.Pending
          }
          className={classes.submit}
        >
          <TranslatedText textMap={Translations.form_button_recover} />
        </Button>
      </form>
    </div>
  );
}
