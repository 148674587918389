import React, { ReactElement } from 'react';
import { CustomChart } from 'components/molecules/custom_chart';
import {
  ChartTypesEnum,
} from 'components/molecules/custom_chart/configuration';
import reactLogger from 'utils/logger';
import GridLayout from 'components/molecules/grid_layout';
import TranslatedText from 'components/atoms/translated_text';
import CurrentVisitorsWeekCompareGraph from '../current_visitors_week_compare_graph';
import DailyVisitorsOfWeekGraph from '../daily_viistors_of_week_graph';
import ImportanceOfWeekDayGraph from '../importance_of_weekday_graph';
import PeekHourPerWeekGraph from '../peek_hour_per_week_graph';
import VisitorsByTimegroupGraph from '../visitors_by_timegroup_graph';
import SelectForm from '../select_form';
import StatisticCardsLayout from '../statistic_cards_layout';

export default function GraphsLayout(): ReactElement {
  reactLogger.renderComponent('GraphsLayout');
  return (
    <React.Fragment>
      <GridLayout
        spacing={1}
        elements={[
          {
            size: 12,
            element: <SelectForm />,
            id: '1',
          },
          {
            size: 12,
            element: <StatisticCardsLayout />,
            id: '2',
          },
          {
            size: 6,
            element: <CurrentVisitorsWeekCompareGraph />,
            id: '3',
          },
          {
            size: 6,
            element: <ImportanceOfWeekDayGraph />,
            id: '4',
          },
          {
            size: 6,
            element: <DailyVisitorsOfWeekGraph />,
            id: '5',
          },
          {
            size: 3,
            element: <PeekHourPerWeekGraph />,
            id: '6',
          },
          {
            size: 3,
            element: <VisitorsByTimegroupGraph />,
            id: '7',
          },
        ]}
      />
    </React.Fragment>
  );
}
