import { colors, Theme } from '@material-ui/core';

export const DummyLabels = [
  '27/11/2020',
  '28/11/2020',
  '29/11/2020',
  '30/11/2020',
  '01/12/2020',
  '02/12/2020',
];

const Colors = [
  colors.blue[300],
  colors.green[300],
  colors.lightGreen[300],
  colors.red[300],
  colors.lightBlue[300],
  colors.amber[300],
  colors.purple[300],
  colors.teal[300],
  colors.deepPurple[300],
  colors.teal[300],
];

// {
//   // backgroundColor: colors.grey[200],
//   data: dataset1.data,
//   label: 'Last year',
//   format: {
//     label: dataset1.label,
//     fill: false,
//     borderColor: '#0094E8',
//     backgroundColor: '#0094E8',
//     pointBorderColor: '#0094E8',
//     pointBackgroundColor: '#0094E8',
//     pointHoverBackgroundColor: '#0094E8',
//     pointHoverBorderColor: '#0094E8',
//   },
// },
// {
//   // backgroundColor: colors.indigo[500],
//   data: dataset2.data,
//   label: 'This year',
//   format: {
//     label: dataset2.label,
//     fill: false,
//     borderColor: '#4668C2',
//     backgroundColor: '#4668C2',
//     pointBorderColor: '#4668C2',
//     pointBackgroundColor: '#4668C2',
//     pointHoverBackgroundColor: '#4668C2',
//     pointHoverBorderColor: '#4668C2',
//   },
// },

export const DummyDataset = (
  datasets: Array<{ name: string; list: number[] }>
) =>
  datasets.map(({ name, list }, index) => ({
    data: list,
    label: 'This year',
    format: {
      label: name,
      fill: false,
      borderColor: Colors[index % Colors.length],
      backgroundColor: Colors[index % Colors.length],
      pointBorderColor: Colors[index % Colors.length],
      pointBackgroundColor: Colors[index % Colors.length],
      pointHoverBackgroundColor: Colors[index % Colors.length],
      pointHoverBorderColor: Colors[index % Colors.length],
    },
  }));

export const DummyChartOptions = (theme: Theme) : any => ({
  legend: {
    display: true,
    align: 'start',
    labels: {
      usePointStyle: true,
    },
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
  maintainAspectRatio: false,
  responsive: true,
  scales: {
    xAxes: [
      {
        stacked: true,
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
          // min: 0,
          // max: 100,
          // callback: function (label: any, index: any, labels: any) {
          //   return `${label} %`;
          // },
        },
      },
    ],
  },
  tooltips: {
    enabled: true,
  },
});
