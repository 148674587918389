import { DaysEnum, MonthsEnum } from 'config';
import { addDays } from 'date-fns';

const dateData = (
  date: Date,
  type: 'minutes' | 'hours' | 'year' | 'month' | 'date',
  utcFormat = false
) => {
  switch (type) {
    case 'minutes':
      return utcFormat ? date.getUTCMinutes() : date.getMinutes();
    case 'hours':
      return utcFormat ? date.getUTCHours() : date.getHours();
    case 'year':
      return utcFormat ? date.getUTCFullYear() : date.getFullYear();
    case 'month':
      return utcFormat ? date.getUTCMonth() : date.getMonth();
    case 'date':
      return utcFormat ? date.getUTCDate() : date.getDate();
  }
};

export const formatDate = (
  datetime: string | Date,
  utcDate = false
): string | null => {
  try {
    const date = new Date(datetime);
    const daysTemp = dateData(date, 'date', utcDate);
    const monthTemp = dateData(date, 'month', utcDate) + 1;

    const minutes =
      dateData(date, 'minutes', utcDate) < 10
        ? `0${dateData(date, 'minutes', utcDate)}`
        : dateData(date, 'minutes', utcDate);
    const hours =
      dateData(date, 'hours', utcDate) < 10
        ? `0${dateData(date, 'hours', utcDate)}`
        : dateData(date, 'hours', utcDate);
    const day = daysTemp < 10 ? `0${daysTemp}` : daysTemp;
    const month = monthTemp < 10 ? `0${monthTemp}` : monthTemp;

    return `${day}-${month}-${dateData(
      date,
      'year',
      utcDate
    )} ${hours}:${minutes}`;
  } catch (error) {
    return null;
  }
};

export const datetimeToString = (
  datetime: string | Date,
  includeDate: boolean = true,
  includeTime: boolean = true
): string | null => {
  try {
    const date = new Date(datetime);
    let result = '';

    // DATE
    var datePart = `${date.getDate()}`;
    if (Number.parseInt(datePart) < 10) datePart = `0${datePart}`;
    var monthPart = `${date.getMonth() + 1}`;
    if (Number.parseInt(monthPart) < 10) monthPart = `0${monthPart}`;
    var yearPart = `${date.getFullYear()}`;
    if (includeDate) {
      result += `${datePart}/${monthPart}/${yearPart}`;
    }

    // TIME
    var hoursPart = `${date.getHours()}`;
    if (Number.parseInt(hoursPart) < 10) hoursPart = `0${hoursPart}`;
    var minutesPart = `${date.getMinutes()}`;
    if (Number.parseInt(minutesPart) < 10) minutesPart = `0${minutesPart}`;

    if (includeDate && includeTime) {
      result += ' ';
    }

    if (includeTime) {
      result += `${hoursPart}:${minutesPart}`;
    }

    return result;
  } catch (error) {
    return null;
  }
};

/** Change Hours of date to zero */
export const dateToStartDate = (date: Date) =>
  new Date(new Date(date).setHours(0, 0, 0, 0));

/** Change Hours of date to :59:59:99 */
export const dateToEndDate = (date: Date) =>
  new Date(new Date(date).setHours(23, 59, 59, 999));

export const getStartEndDateOfMonth = (month: MonthsEnum, year: number) => {
  // let date = new Date()
  // date.setFullYear(year, month)
  let firstDay = dateToStartDate(new Date(year, parseInt(month)));
  let lastDay = dateToEndDate(new Date(year, parseInt(month) + 1, 0));
  return { firstDay, lastDay };
};

export const getWeekNumberByDate = (date: Date) => {
  let _date: any = new Date(date);
  // let d: Date = new Date(
  //   Date.UTC(_date.getFullYear(), _date.getMonth(), _date.getDate())
  // );
  // let dayNum = d.getUTCDay() || 7;
  // d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  // let yearStart: Date = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  // return Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);

  const firstDayOfYear: any = new Date(_date.getFullYear(), 0, 1);
  const pastDaysOfYear = (_date - firstDayOfYear) / 86400000;
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
};

export const getWeeksOfMonth = (monthNumber: MonthsEnum, year: number) => {
  const { firstDay, lastDay } = getStartEndDateOfMonth(monthNumber, year);
  let firstWeek = getWeekNumberByDate(firstDay);
  let lastWeek = getWeekNumberByDate(lastDay);

  let weeksArray = [];
  let currentStartDate = firstDay;
  let currentEndDate = addDays(currentStartDate, 7 - currentStartDate.getDay());
  if (currentEndDate > lastDay) {
    currentEndDate = lastDay;
  }
  for (let i = firstWeek; i <= lastWeek; i++) {
    weeksArray.push(
      `${datetimeToString(currentStartDate, true, false)} - ${datetimeToString(
        currentEndDate,
        true,
        false
      )} (Week ${i})`
    );
    currentStartDate = addDays(currentEndDate, 1);
    currentEndDate = addDays(currentStartDate, 7 - currentStartDate.getDay());
    if (currentEndDate > lastDay) {
      currentEndDate = lastDay;
    }
  }
  return weeksArray;
};

export const numberToHourFormat = (number: number) => {
  let result;
  if (number >= 1 && number <= 12) {
    result = 'AM';
  } else {
    result = 'PM';
    number -= 12;
  }
  return number > 9 ? `${number} ${result}` : `0${number} ${result}`;
};

export function getLastSunday(d: Date) {
  var t = new Date(d);
  t.setDate(t.getDate() - t.getDay());
  return t;
}
