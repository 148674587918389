import React from 'react';
import Datatable from 'components/molecules/datatable';
import CardLabelTitle from 'components/app_common/card_label_title';
import useStyles from './styles';
import { MAP_TABLE_COL_NAMES, DummyColumns } from './configuration';
import { Paper, Typography } from '@material-ui/core';
import TranslatedText from 'components/atoms/translated_text';
import * as VisitorAnalyticsSelectors from 'redux/reducers/visitorAnalytics_reducer/selectors';
import _ from 'lodash';

interface IProps {}

const VisitorsPercChangeTable = (props: IProps) => {
  const classes = useStyles();
  const sortField = React.useRef<string>(DummyColumns[0].id);
  const sortType = React.useRef<'asc' | 'desc'>('asc');
  const visitorsPerStoreData = VisitorAnalyticsSelectors.useGetVisitorsPerStoreTableData();

  const Columns = React.useMemo(() => {
    const lastElementIndex = (visitorsPerStoreData?.tableArr?.length || 0) - 1;
    console.log('lastElementIndex:', lastElementIndex);
    let arr =
      visitorsPerStoreData?.columnsOrder?.map((columnName, index) => ({
        label: (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'space-between',
            }}
          >
            <div style={{ margin: 'auto' }}>
              <TranslatedText
                defaultText={_.get(
                  MAP_TABLE_COL_NAMES,
                  `${visitorsPerStoreData.type}.${columnName}`
                )}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
              }}
            >
              <div style={{ minWidth: 0 }}>Visitors</div>
              <div style={{ minWidth: 0 }}>%Change</div>
            </div>
          </div>
        ),
        id: columnName,
        render: (
          rowIndex: number,
          cellValue: typeof visitorsPerStoreData.tableArr[0][string],
          columnIndex: number
        ) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              fontWeight: rowIndex === lastElementIndex ? 'bold' : undefined,
            }}
          >
            <div style={{ minWidth: 0 }}>{cellValue.visitor}</div>
            <div
              style={{
                minWidth: 0,
                color:
                  typeof cellValue?.perc === 'number' && cellValue.perc < 0
                    ? 'red'
                    : 'green',
              }}
            >
              {cellValue.perc && `${cellValue.perc}%`}
            </div>
          </div>
        ),
        inData: true,
      })) || [];

    return [
      {
        label: (
          <div
            style={{
              margin: 'auto',
              textAlign: 'center',
            }}
          >
            <div>
              <TranslatedText defaultText={'Month'} />
            </div>
            <div>
              <TranslatedText defaultText={'Store'} />
            </div>
          </div>
        ),
        id: 'name',
        render: (rowIndex: number, cellValue: string, columnIndex: number) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontWeight: rowIndex === lastElementIndex ? 'bold' : undefined,
            }}
          >
            {cellValue}
          </div>
        ),
        inData: true,
      },
      ...arr,
      {
        label: (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'space-between',
            }}
          >
            <div style={{ margin: 'auto' }}>
              <TranslatedText defaultText={'Total'} />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
              }}
            >
              <div style={{ minWidth: 0 }}>Visitors</div>
              <div style={{ minWidth: 0 }}>%Change</div>
            </div>
          </div>
        ),
        id: 'total',
        render: (rowIndex: number, cellValue: string, columnIndex: number) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              fontWeight: rowIndex === lastElementIndex ? 'bold' : undefined,
            }}
          >
            <div style={{ minWidth: 0 }}>{cellValue}</div>
            <div
              style={{
                minWidth: 0,
              }}
            >
              {''}
            </div>
          </div>
        ),
        inData: true,
      },
    ];
  }, [visitorsPerStoreData]);

  return (
    <Paper style={{ padding: 18 }} elevation={0} variant="outlined">
      <div>
        <CardLabelTitle
          title={
            <TranslatedText defaultText="Visitors and % Change per store" />
          }
        />
        <Datatable
          // paging={paging}
          data={visitorsPerStoreData?.tableArr || []}
          // sorting={{
          //   sortField: sortField.current,
          //   order: sortType.current,
          // }}
          tableCellMainRowProps={(columnIndex) => ({
            style: {
              backgroundColor: '#E3EDFF',
              // justifyContent: 'center',
              // textAlign: 'center',
              // margin: 0,
              padding: '0.4rem',
            },
          })}
          height={'50vh'}
          tableCellProps={(columnIndex, rowIndex) => ({
            style: {
              backgroundColor:
                rowIndex % 2 === 0 ? undefined : 'rgba(133, 137, 143, .05)',
            },
          })}
          loading={false}
          columns={Columns || []}
        />
      </div>
    </Paper>
  );
};

export default VisitorsPercChangeTable;
