import NativeChart from 'chart.js';

NativeChart.Chart.pluginService.register({
  afterDatasetDraw: function (chart) {
    if (
      chart.options.plugins &&
      chart.options.plugins['centerText'] &&
      typeof chart.options.plugins['centerText'].display !== 'undefined' &&
      chart.options.plugins['centerText'].display
    ) {
      drawTotals(chart);
    }
  },
});

// function drawTitle(chart: any) {
//   var width = chart.chart.width,
//     height = chart.chart.height,
//     ctx = chart.chart.ctx;

//   ctx.restore();
//   var fontSize = (height / 114).toFixed(2);
//   ctx.font = 'bold ' + fontSize + 'em Roboto';
//   ctx.textBaseline = 'middle';
//   ctx.fillStyle = chart.options.plugins['centerTitle'].color ?? 'black';

//   var text = chart.options.plugins['centerTitle'].formatText(chart.data),
//     textX = Math.round((width - ctx.measureText(text).width) / 2),
//     textY = height / 2;

//   ctx.fillText(text, textX, textY);
//   ctx.save();
// }

function drawTotals(chart: any) {
  var width = chart.chart.width,
    height = chart.chart.height,
    ctx = chart.chart.ctx;

  ctx.restore();
  var fontSize = (height / 114).toFixed(2);
  ctx.font = 'bold ' + fontSize + 'em Roboto';
  ctx.textBaseline = 'middle';
  ctx.fillStyle = chart.options.plugins['centerText'].color ?? 'black';

  var text = chart.options.plugins['centerText'].formatText(chart.data),
    textX = Math.round((width - ctx.measureText(text).width) / 2),
    textY = height / 2;

  ctx.fillText(text, textX, textY);
  ctx.save();
}
