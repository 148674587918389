import React, { ReactElement } from 'react';
import {
  MenuItem,
  TextField,
  StandardTextFieldProps,
  InputAdornment,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash';
import { Control, Controller } from 'react-hook-form';
import { makeStyles, useTheme } from '@material-ui/core/styles';

interface Iprops extends StandardTextFieldProps {
  options: Array<any>;
  control: Control;
  rules: any;
  selectedKey: string;
  name: string;
  handleChange?: (data: string) => void;
  controllerExtras?: any;
  style?: React.CSSProperties;
}

export default function AutoCompleteSelect(props: Iprops) {
  const {
    control,
    rules,
    options,
    handleChange,
    selectedKey,
    style,
    id,
    controllerExtras = {},
    ...other
  } = props;

  return (
    <Controller
      render={(props) => (
        <Autocomplete
          {...props}
          options={options}
          getOptionLabel={(option) => `${option[selectedKey]}` || ''}
          renderOption={(option) => `${option[selectedKey]}` || ''}
          disabled={other.disabled}
          renderInput={(params) => (
            <TextField variant="outlined" {...params} {...other} />
          )}
          onChange={(__, data) => {
            // const result = _.get(data, selectedKey);
            handleChange && handleChange(data || '');
            props.onChange(data);
          }}
        />
      )}
      name={other.name}
      control={control}
      rules={rules}
      {...controllerExtras}
    />
  );
}
