import React, { ReactElement } from 'react';
import { CustomChart } from 'components/molecules/custom_chart';
import * as LocalConfiguration from './configuration';
import TranslatedText from 'components/atoms/translated_text';
import { ChartTypesEnum } from 'components/molecules/custom_chart/configuration';
import * as StorePerformanceSelectors from 'redux/reducers/storePerformance_reducer/selectors';
import CardLabelTitle from 'components/app_common/card_label_title';

export default function DailyVisitorsOfWeekGraph() {
  const data = StorePerformanceSelectors.useGetCurrentVsPreviousWeekChartData();

  console.log('Data:', data);
  return (
    <CustomChart
      title={
        <CardLabelTitle
          title={
            <TranslatedText defaultText="Visitors Current Vs Previous Week" />
          }
        />
      }
      layoutProps={{
        variant: 'outlined',
      }}
      data={LocalConfiguration.DummyDataset(
        data?.listCurrent || [],
        data?.persList || []
      )}
      labels={data?.dates || []}
      options={LocalConfiguration.DummyChartOptions}
      chartType={ChartTypesEnum.COLUMN}
    />
  );
}
