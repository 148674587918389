import { colors, Theme } from '@material-ui/core';
import * as RandomUtils from 'utils/randomize';

export const DummyLabels = [
  '1 Monday',
  '2 Tuesday',
  '3 Wednesday',
  '4 Thursday',
];

export const DummyDataset = (
  data = RandomUtils.generateRandomSequence(1, 25, DummyLabels.length)
) => [
  {
    label: '# of Votes',
    data: data,
    format: {
      label: '# of Votes',
      backgroundColor: ['#1387FB', '#13239D', '#E56C37', '#9E018F'],
      borderColor: ['#1387FB', '#13239D', '#E56C37', '#9E018F'],
      borderWidth: 1,
    },
  },
];

export const DummyChartOptions = (theme: Theme) : any => ({
  legend: {
    display: true,
    // align: 'start',
    // labels: {
    //   usePointStyle: true,
    // },
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
  maintainAspectRatio: false,
  // responsive: true,
  // scales: {
  //   xAxes: [
  //     {
  //       barThickness: 20,
  //       maxBarThickness: 50,
  //       barPercentage: 0.5,
  //     }
  //   ],
  //   yAxes: [],
  // },
  tooltips: {
    enabled: true,
  },
});

//   xAxes: [
//     {
//       barThickness: 12,
//       maxBarThickness: 10,
//       barPercentage: 0.5,
//       categoryPercentage: 0.5,
//       ticks: {
//         fontColor: theme.palette.text.secondary,
//       },
//       gridLines: {
//         display: false,
//         drawBorder: false,
//       },
//     },
//   ],
