import { Box } from '@material-ui/core';
import TranslatedText from 'components/atoms/translated_text';
import { AppTextField } from 'components/molecules/form_controls';
import GridLayout from 'components/molecules/grid_layout';
import useQuery from 'custom_hooks/useQuery';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import StoreActions from 'redux/reducers/stores_reducer/actions';
import * as Configuration from '../../configuration';

interface IFormData {
  groupName: string;
}

const defaultValues: IFormData = {
  groupName: '',
};

const { GroupListDefaultFiltering } = Configuration;

const FilteringBox = () => {
  const {
    setValue,
    watch,
    handleSubmit,
    errors,
    control,
    getValues,
  } = useForm<IFormData>({
    defaultValues,
  });

  const dispatch = useDispatch();

  return (
    <Box display="flex" marginBottom="1rem" marginTop="1rem">
      <GridLayout
        justify="flex-start"
        elements={[
          {
            id: 'groupName',
            size: 2,
            element: (
              <AppTextField
                variant="outlined"
                margin="none"
                size="small"
                fullWidth
                name="groupName"
                error={'groupName' in errors}
                label={<TranslatedText defaultText={'Group Name'} />}
                handleChange={(value) => {
                  dispatch(
                    StoreActions.fetchGroupsAction({
                      name: value,
                      page: GroupListDefaultFiltering.DefaultPage,
                      pageSize: GroupListDefaultFiltering.DefaultPageSize,
                      order: GroupListDefaultFiltering.DefaultOrder,
                      sortField: GroupListDefaultFiltering.DefaultSortField,
                    })
                  );
                }}
                id="groupName"
                control={control}
                rules={{}}
              />
            ),
          },
        ]}
      />
    </Box>
  );
};

export default React.memo(FilteringBox);
