import React from 'react';
import Menu from '@material-ui/core/Menu';
import { LanguageConfiguration } from './configuration';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import { useDispatch } from 'react-redux';
import { LanguagesEnum } from '../../../config/languages';
import * as LanguageSelectors from '../../../redux/reducers/gl_translations_reducer/selectors';
import { Colors } from '../../../styles';
import FormattedMenu from 'components/atoms/formatted_menu';
import { Box, MenuItem, MenuList, Paper } from '@material-ui/core';

interface IProps {
  isLanMenuOpen: boolean;
  menuLanId: string;
  lanAnchorEl: HTMLElement | null;
  handleLanMenuClose: () => void;
}

export default function LanguageMenu({
  isLanMenuOpen,
  menuLanId,
  lanAnchorEl,
  handleLanMenuClose,
}: IProps) {
  const dispatch = useDispatch();
  const languageID: LanguagesEnum = LanguageSelectors.useSelectLanguageID();

  // const
  return (
    <FormattedMenu
      anchorEl={lanAnchorEl}
      id={menuLanId}
      open={isLanMenuOpen}
      onClose={handleLanMenuClose}
    >
      {LanguageConfiguration.map((props, index: number) => (
        <MenuItem
          style={{
            marginLeft: props.submenu ? 5 : 0,
            color:
              languageID === props.id ? Colors.APP_COLOR_HIGHLIGHT() : 'black',
          }}
          key={index}
          onClick={() => {
            props.action(dispatch);
            handleLanMenuClose();
          }}
        >
          {props.icon && <ListItemIcon>{props.icon}</ListItemIcon>}
          <Typography variant="inherit">{props.label}</Typography>
        </MenuItem>
      ))}
    </FormattedMenu>
  );
}
