import { matchPath } from 'react-router';
import reactLogger from 'utils/logger';

export const pathsIsTheSame = (
  path: string,
  paramsPath: string
): boolean | undefined => {
  let result = matchPath(path, {
    path: paramsPath,
    exact: true,
    strict: false,
  })?.isExact;
  return result;
};

/**
 * Sample Input: Array<str> -> [{varkey1: 'varvalue1'}, {varkey2: 'varvalue2'}, {varkey3: undefined}]
 * Sample Output: str -> '?varvalue1=varvalue1&varkey2=varvalue2'
 */

interface IVarToStringParams {
  variablesArray: {
    key: string;
    value?: string;
  }[];
}

export const varToStringParams = (data: IVarToStringParams): string => {
  let { variablesArray } = data;
  let str = '';
  if (variablesArray.length === 1) {
    const { key, value } = variablesArray[0];
    return value ? `?${key}=${value}` : '';
  }
  let first = true;
  for (let i = 0; i < variablesArray.length; i++) {
    const { key, value } = variablesArray[i];
    if (value) {
      let temp = `${key}=${value}`;
      if (!first) {
        // not first
        temp = `&${temp}`;
      }
      str += temp;
      first = false;
    }
  }
  if (str) {
    str = '?' + str;
  }
  return str;
};
