import { makeStyles } from '@material-ui/core/styles';
import Configuration from '../../configuration';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${Configuration.backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  containerBackground: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(15, 46, 105, .85)',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 3),
    width: '100%',
    alignSelf: 'center',
    [theme.breakpoints.up('sm')]: {
      width: '70%',
    },
  },
  img_logo: {
    marginBottom: 13,
    height: 50,
    width: 'auto',
  },
}));

export default useStyles;
