import React from 'react';
import Datatable from 'components/molecules/datatable';
import * as Colors from 'styles/colors';
import { Box, Chip, useTheme } from '@material-ui/core';
import TranslatedText from 'components/atoms/translated_text';
import { useDispatch } from 'react-redux';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import { IModalParams as IAddEditAlertModalParams } from '../modal_add_edit_alert';
import { IUserDataWithDetailsData } from 'services/api/user';
import { AlertsListDefaultFiltering, TabType } from '../../configuration';
import SelectButton from 'components/molecules/select_button';
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
import { IAlertsListData } from 'services/api/alert';
import { useHistory } from 'react-router';
import NavigationConfig from 'navigation/config';
import useQuery from 'custom_hooks/useQuery';
import StoreActions from 'redux/reducers/stores_reducer/actions';
import * as StoresSelectors from 'redux/reducers/stores_reducer/selectors';
import { AlertFieldValueType } from 'config/alerts';

const AlertsDatatable = () => {
  const sortField = React.useRef<string>(
    AlertsListDefaultFiltering.DefaultSortField
  );
  const sortType = React.useRef<'asc' | 'desc'>(
    AlertsListDefaultFiltering.DefaultOrder
  );

  const theme = useTheme();
  const result = StoresSelectors.useAlertsList();
  const dispatch = useDispatch();
  const query = useQuery();
  const storeid = query.get('storeid') || '';

  const paging = {
    page: result.data?.paging.page || 0,
    pageSize: result.data?.paging.pageSize || 0,
    total: result.data?.paging.total || 0,
  };

  return (
    <Box>
      {/* <FilteringBox /> */}
      <Datatable
        paging={paging}
        data={result.data?.items || []}
        sorting={{
          sortField: sortField.current,
          order: sortType.current,
        }}
        height={'70vh'}
        disablePaper={false}
        paperProps={{
          style: {
            borderRadius: 2,
            //   backgroundColor: Colors.APP_COLOR_PRIMARY(),
            overflow: 'hidden',
          },
          // square: false,
          variant: 'outlined',
        }}
        tableCellProps={() => ({
          style: {
            //   paddingTop: 0,
            //   paddingBottom: 0,
            borderLeft: 'none',
            borderRight: 'none',
          },
        })}
        tableCellMainRowProps={() => ({
          style: {
            paddingTop: '1rem',
            paddingBottom: '1rem',
            //   backgroundColor: Colors.APP_COLOR_DARK({
            //     darkMode: theme.palette.type === 'dark',
            //   }),
            //   color: 'white',
          },
        })}
        loading={false}
        columns={TableColumns}
        changePageHandle={(selectedPage, pageSize, order, _sortField) => {
          // 'asc' | 'desc'
          sortType.current = order === 'desc' ? 'asc' : 'desc';
          sortField.current = _sortField;
          dispatch(
            StoreActions.fetchAlertsListAction({
              data: {
                page: selectedPage,
                pageSize: pageSize,
                sortField: _sortField,
                order,
                storeid,
              },
              onFinish: () => {},
            })
          );
        }}
      />
    </Box>
  );
};

export const TableColumns = [
  {
    label: 'AlertId',
    id: '_id',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Field Name',
    id: 'fieldName',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Field Value',
    id: 'fieldValue',
    align: 'left',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: IAlertsListData['items']
    ) => (
      <React.Fragment>
        {cellValue +
          `${
            data[rowIndex].fieldValueType === AlertFieldValueType.Persentage
              ? '%'
              : ''
          }`}
      </React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Store',
    id: 'store.uniqueId',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Condition',
    id: 'condition',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: '',
    id: 'actions',
    align: 'left',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: IAlertsListData['items']
    ) => <TableActions data={data} rowIndex={rowIndex} />,
    inData: false,
  },
];

const TableActions = React.memo(
  ({
    rowIndex,
    data,
  }: {
    rowIndex: number;
    data: IAlertsListData['items'];
  }) => {
    const dispatch = useDispatch();

    return (
      <div>
        <SelectButton
          items={[
            {
              action: () => {
                dispatch(
                  ModalActions.openModalAction({
                    modalID: ModalIDs.MODAL_ADD_EDIT_ALERT,
                    params: {
                      storeid: data[rowIndex].store._id,
                      alertid: data[rowIndex]._id,
                      mode: 'edit',
                      defaultValues: {
                        fieldName: data[rowIndex].fieldName,
                        fieldValue: data[rowIndex].fieldValue,
                        condition: data[rowIndex].condition,
                        users: data[rowIndex].users,
                        types: data[rowIndex].types,
                        fieldValueType: data[rowIndex].fieldValueType,
                      },
                    } as IAddEditAlertModalParams,
                  })
                );
              },
              id: 'editCamera',
              children: <TranslatedText defaultText="Edit Alert" />,
            },
            {
              action: () => {
                dispatch(
                  StoreActions.removeAlertAction({
                    onFinish: () => {
                      dispatch(
                        StoreActions.fetchAlertsListAction({
                          data: {
                            page: AlertsListDefaultFiltering.DefaultPage,
                            pageSize:
                              AlertsListDefaultFiltering.DefaultPageSize,
                            sortField:
                              AlertsListDefaultFiltering.DefaultSortField,
                            order: AlertsListDefaultFiltering.DefaultOrder,
                          },
                          onFinish: () => {},
                        })
                      );
                    },
                    data: {
                      alertid: data[rowIndex]._id,
                    },
                  })
                );
              },
              id: 'remove',
              children: <TranslatedText defaultText="Remove" />,
            },
          ]}
          // action?: (open: boolean) => void;
          buttonProps={{
            variant: 'outlined',
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
          >
            <MoreHorizOutlinedIcon />
          </Box>
        </SelectButton>
      </div>
    );
  }
);

export default React.memo(AlertsDatatable);
