import {
  IAction,
  IActionMethods,
  StateStatus,
  KEEP_THE_SAME,
} from '../../utils/common';
import * as Types from './types';
import * as StoresService from 'services/api/stores';
import * as FilesService from 'services/api/files';
import * as UserService from 'services/api/user';
import * as GroupService from 'services/api/group';
import { Dispatch } from 'redux';
import reactLogger from 'utils/logger';
import * as RegisterService from 'services/api/register';
import { Roles } from 'config/authRoles';
import { InOutDataType } from 'config/store';
import * as AlertsService from 'services/api/alert';
import _ from 'lodash';
import { IFetchGroupInput } from 'services/api/group';

/** Fetch Stores  */
class FetchStores implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_STORES,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_STORES,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_STORES,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(input?: StoresService.IFetchStoresInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await StoresService.fetchStores(input);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        reactLogger.error('FetchStores Error:', error.message);
        dispatch(this.onFailed());
      }
    };
  }
}

/** Add Store  */
interface IAddStore {
  storeName: string;
  storeUniqueName: string;
  imgSrc: string;
  pairedUsers: Array<string>;
  initialin: number;
  group: string;
  boxesNumber: number;
  cappacity: number;
  timezone: string;
  onFinish: () => void;
}

class AddStore implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.ADD_STORE,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.ADD_STORE,
      status: StateStatus.Success,
      data: {},
    };
  }

  onFailed(): IAction {
    return {
      type: Types.ADD_STORE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IAddStore): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        const uploadImageResponse = await FilesService.uploadFile({
          file: payload.imgSrc,
        });
        const fileid = uploadImageResponse.data.id;
        await StoresService.addStore({
          imageName: fileid,
          uniqueId: payload.storeUniqueName,
          name: payload.storeName,
          pairedUsers: payload.pairedUsers,
          initialin: payload.initialin,
          cappacity: payload.cappacity,
          boxesNumber: payload.boxesNumber,
          group: payload.group,
          timezone: payload.timezone,
        });
        dispatch(this.onSuccess());
      } catch (error) {
        reactLogger.error('AddStore Error:', error.message);
        dispatch(this.onFailed());
      } finally {
        payload.onFinish();
      }
    };
  }
}

/** Edit Store  */
interface IEditStore {
  storeName: string;
  storeUniqueName: string;
  group: string;
  imgSrc?: string;
  storeid: string;
  pairedUsers: string[];
  initialin: number;
  boxesNumber: number;
  cappacity: number;
  onFinish: () => void;
}

class EditStore implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.EDIT_STORE,
      status: StateStatus.Pending,
      data: {},
    };
  }

  onSuccess(): IAction {
    return {
      type: Types.EDIT_STORE,
      status: StateStatus.Success,
      data: {},
    };
  }

  onFailed(): IAction {
    return {
      type: Types.EDIT_STORE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IEditStore): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let fileid;
        if (payload.imgSrc) {
          const uploadImageResponse = await FilesService.uploadFile({
            file: payload.imgSrc,
          });
          fileid = uploadImageResponse.data.id;
        }
        await StoresService.editStore(
          {
            imageName: fileid,
            uniqueId: payload.storeUniqueName,
            name: payload.storeName,
            pairedUsers: payload.pairedUsers,
            initialin: payload.initialin,
            cappacity: payload.cappacity,
            boxesNumber: payload.boxesNumber,
            group: payload.group,
          },
          payload.storeid
        );
        dispatch(this.onSuccess());
      } catch (error) {
        reactLogger.error('EditStore Error:', error.message);
        dispatch(this.onFailed());
      } finally {
        payload.onFinish();
      }
    };
  }
}

/** Remove Store  */
interface IRemoveStore {
  storeid: string;
  onFinish: () => void;
}

class RemoveStore implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.REMOVE_STORE,
      status: StateStatus.Pending,
      data: {},
    };
  }

  onSuccess(): IAction {
    return {
      type: Types.REMOVE_STORE,
      status: StateStatus.Success,
      data: {},
    };
  }

  onFailed(): IAction {
    return {
      type: Types.REMOVE_STORE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IRemoveStore): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        await StoresService.removeStore(payload.storeid);
        dispatch(this.onSuccess());
      } catch (error) {
        reactLogger.error('RemoveStore Error:', error.message);
        dispatch(this.onFailed());
      } finally {
        payload.onFinish();
      }
    };
  }
}

/** Fetch Users  */
interface IFetchUsers {}

class FetchUsers implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_USERS,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }

  onSuccess(data: UserService.IUserData): IAction {
    return {
      type: Types.SET_USERS,
      status: StateStatus.Success,
      data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_USERS,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(data?: IFetchUsers): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        const result = await UserService.getUserList();
        dispatch(this.onSuccess(result.data));
      } catch (error) {
        reactLogger.error('FetchUsers Error:', error.message);
        dispatch(this.onFailed());
      }
    };
  }
}

/** Edit Store Cameras  */
interface IUpdateStoreCameras {
  storeid: string;
  cameraid: string;
  data: {
    initialImageName?: string;
    imageName: string;
    name: string;
    cameraUrl: string;
    cameratype: InOutDataType[];
  };
  onFinish: () => void;
}

class UpdateStoreCameras implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.EDIT_STORE_CAMERAS,
      status: StateStatus.Pending,
      data: null,
    };
  }

  onSuccess(): IAction {
    return {
      type: Types.EDIT_STORE_CAMERAS,
      status: StateStatus.Success,
      data: null,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.EDIT_STORE_CAMERAS,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(input: IUpdateStoreCameras): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());

        const camera = input.data;
        if (camera.imageName.length > 0) {
          const uploadImageResponse = await FilesService.uploadFile({
            file: camera.imageName[0],
          });
          const fileid = uploadImageResponse.data.id;
          camera.imageName = fileid;
        } else {
          camera.imageName = camera.initialImageName || '';
        }
        // data.cameras.push({
        //   imageName: camera.imageName,
        //   name: camera.name,
        //   cameraid: camera.cameraid,
        //   cameraUrl: camera.cameraUrl,
        //   cameratype: camera.cameratype,
        // });

        await StoresService.editStoreCamera(
          {
            name: camera.name,
            imageName: camera.imageName,
            cameratype: camera.cameratype,
          },
          input.storeid,
          input.cameraid
        );
        dispatch(this.onSuccess());
        input.onFinish();
      } catch (error) {
        reactLogger.error('UpdateStoreCameras Error:', error.message);
        dispatch(this.onFailed());
      }
    };
  }
}

/** Register User Action */
interface IRegisterInput {
  // history: any;
  onFinish: () => void;
  data: Omit<RegisterService.IRegisterUserInput, 'role'>;
}
class RegisterUser implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.ADD_USER,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.ADD_USER,
      status: StateStatus.Success,
      data: {},
    };
  }

  onFailed(): IAction {
    return {
      type: Types.ADD_USER,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(input: IRegisterInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { data, onFinish } = input;
        dispatch(this.onPending());
        await RegisterService.registerUser({
          ...data,
          role: Roles.User as string,
        });
        dispatch(this.onSuccess());
        onFinish();
      } catch (error) {
        console.log('RegisterUser Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Fetch Users With Details  */
// interface IFetchUsersWithDetails {}

class FetchUsersWithDetails implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_USERS_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }

  onSuccess(data: UserService.IUserDataWithDetailsData): IAction {
    return {
      type: Types.SET_USERS_LIST,
      status: StateStatus.Success,
      data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_USERS_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(data: UserService.IUserDetailsInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        const result = await UserService.getUserListDetails(data);
        dispatch(
          this.onSuccess(result.data as UserService.IUserDataWithDetailsData)
        );
      } catch (error) {
        reactLogger.error('FetchUsers Error:', error.message);
        dispatch(this.onFailed());
      }
    };
  }
}

/** Register User Action */
interface IEditUserInput {
  onFinish: () => void;
  data: UserService.IEditUserByIDInput;
}
class EditUser implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.EDIT_USER,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.EDIT_USER,
      status: StateStatus.Success,
      data: null,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.EDIT_USER,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(input: IEditUserInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { data, onFinish } = input;
        dispatch(this.onPending());
        await UserService.editUserByID(data);
        dispatch(this.onSuccess());
        onFinish();
      } catch (error) {
        console.log('EditUser Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Fetch Cameras Action */
interface IFetchCamerasListInput {
  onFinish: () => void;
  data: StoresService.ICamerasListInput;
}
class FetchCamerasList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_CAMERA_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: StoresService.ICamerasListData): IAction {
    return {
      type: Types.SET_CAMERA_LIST,
      status: StateStatus.Success,
      data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_CAMERA_LIST,
      status: StateStatus.Failed,
      data: KEEP_THE_SAME,
    };
  }

  action(input: IFetchCamerasListInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { data, onFinish } = input;
        dispatch(this.onPending());
        const result = await StoresService.getCamerasList(data);
        dispatch(this.onSuccess(result.data));
        onFinish();
      } catch (error) {
        console.log('FetchCamerasList Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Fetch Boxes Action */
interface IFetchBoxesListInput {
  onFinish: () => void;
  data: StoresService.IBoxesListInput;
}
class FetchBoxesList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_BOXES_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: StoresService.IBoxesListData): IAction {
    return {
      type: Types.SET_BOXES_LIST,
      status: StateStatus.Success,
      data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_BOXES_LIST,
      status: StateStatus.Failed,
      data: KEEP_THE_SAME,
    };
  }

  action(input: IFetchBoxesListInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { data, onFinish } = input;
        dispatch(this.onPending());
        const result = await StoresService.getBoxesList(data);
        dispatch(this.onSuccess(result.data));
        onFinish();
      } catch (error) {
        console.log('FetchBoxesList Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Remove Store Camera */
interface IRemoveStoreCameraInput {
  onFinish: () => void;
  storeid: string;
  cameraid: string;
}
class RemoveStoreCamera implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.REMOVE_CAMERA,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.REMOVE_CAMERA,
      status: StateStatus.Success,
      data: null,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.REMOVE_CAMERA,
      status: StateStatus.Failed,
      data: KEEP_THE_SAME,
    };
  }

  action(input: IRemoveStoreCameraInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { storeid, cameraid, onFinish } = input;
        dispatch(this.onPending());
        await StoresService.removeStoreCamera(storeid, cameraid);
        dispatch(this.onSuccess());
        onFinish();
      } catch (error) {
        console.log('RemoveStoreList Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Remove Store Box */
interface IRemoveStoreBoxInput {
  onFinish: () => void;
  storeid: string;
  boxid: string;
}
class RemoveStoreBox implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.REMOVE_CAMERA,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.REMOVE_CAMERA,
      status: StateStatus.Success,
      data: null,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.REMOVE_CAMERA,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(input: IRemoveStoreBoxInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { storeid, boxid, onFinish } = input;
        dispatch(this.onPending());
        await StoresService.removeStoreBox(storeid, boxid);
        dispatch(this.onSuccess());
        onFinish();
      } catch (error) {
        console.log('RemoveStoreBox Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Fetch Alerts Action */
interface IFetchAlertsListInput {
  onFinish: () => void;
  data: AlertsService.IAlertsListInput;
}
class FetchAlertsList implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_ALERTS_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: AlertsService.IAlertsListData): IAction {
    return {
      type: Types.SET_ALERTS_LIST,
      status: StateStatus.Success,
      data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_ALERTS_LIST,
      status: StateStatus.Failed,
      data: KEEP_THE_SAME,
    };
  }

  action(input: IFetchAlertsListInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { data, onFinish } = input;
        dispatch(this.onPending());
        const result = await AlertsService.getAlertsList(data);
        dispatch(this.onSuccess(result.data));
        onFinish();
      } catch (error) {
        console.log('FetchAlertsList Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** UpdateAlert */
interface IUpdateAlertInput {
  onFinish: () => void;
  data: AlertsService.IAddAlertInput | AlertsService.IEditAlertInput;
}
class UpdateAlert implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.UPDATE_ALERT,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.UPDATE_ALERT,
      status: StateStatus.Success,
      data: null,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.UPDATE_ALERT,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(input: IUpdateAlertInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { data, onFinish } = input;
        dispatch(this.onPending());
        if (_.get(data, 'alertid', null)) {
          await AlertsService.editAlert(data as AlertsService.IEditAlertInput);
        } else {
          await AlertsService.addAlert(data as AlertsService.IAddAlertInput);
        }
        dispatch(this.onSuccess());
        onFinish();
      } catch (error) {
        console.log('UpdateAlert Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** RemoveAlert */
interface IRemoveAlertInput {
  onFinish: () => void;
  data: AlertsService.IRemoveAlertInput;
}
class RemoveAlert implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.REMOVE_ALERT,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.REMOVE_ALERT,
      status: StateStatus.Success,
      data: null,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.REMOVE_ALERT,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(input: IRemoveAlertInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        const { data, onFinish } = input;
        dispatch(this.onPending());
        await AlertsService.removeAlert(data);
        dispatch(this.onSuccess());
        onFinish();
      } catch (error) {
        console.log('RemoveAlert Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Fetch Groups  */
class FetchGroups implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_GROUPS,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_GROUPS,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_GROUPS,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(input: GroupService.IFetchGroupInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await GroupService.fetchGroups(input);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        reactLogger.error('FetchGroups Error:', error.message);
        dispatch(this.onFailed());
      }
    };
  }
}

/** Add Group  */
interface IAddGroup extends Omit<GroupService.IAddGroupInput, ''> {
  onFinish: () => void;
}

class AddGroup implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.ADD_GROUP,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.ADD_GROUP,
      status: StateStatus.Success,
      data: {},
    };
  }

  onFailed(): IAction {
    return {
      type: Types.ADD_GROUP,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IAddGroup): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        const uploadImageResponse = await FilesService.uploadFile({
          file: payload.icon,
        });
        const fileid = uploadImageResponse.data.id;
        await GroupService.addGroup({
          ...payload,
          icon: fileid,
        });
        dispatch(this.onSuccess());
      } catch (error) {
        reactLogger.error('AddGroup Error:', error.message);
        dispatch(this.onFailed());
      } finally {
        payload.onFinish();
      }
    };
  }
}

/** Edit Group  */
interface IEditGroup extends Omit<GroupService.IAddGroupInput, ''> {
  onFinish: () => void;
  groupid: string;
}

class EditGroup implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.EDIT_GROUP,
      status: StateStatus.Pending,
      data: {},
    };
  }

  onSuccess(): IAction {
    return {
      type: Types.EDIT_GROUP,
      status: StateStatus.Success,
      data: {},
    };
  }

  onFailed(): IAction {
    return {
      type: Types.EDIT_GROUP,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IEditGroup): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let fileid;
        if (payload.icon) {
          const uploadImageResponse = await FilesService.uploadFile({
            file: payload.icon,
          });
          fileid = uploadImageResponse.data.id;
        }

        const { groupid, ...data } = payload;
        await GroupService.editGroup(
          {
            ...data,
            icon: fileid,
          },
          groupid
        );
        dispatch(this.onSuccess());
      } catch (error) {
        reactLogger.error('EditGroup Error:', error.message);
        dispatch(this.onFailed());
      } finally {
        payload.onFinish();
      }
    };
  }
}

/** Remove Group  */
interface IRemoveGroup {
  groupid: string;
  onFinish: () => void;
}

class RemoveGroup implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.REMOVE_GROUP,
      status: StateStatus.Pending,
      data: {},
    };
  }

  onSuccess(): IAction {
    return {
      type: Types.REMOVE_GROUP,
      status: StateStatus.Success,
      data: {},
    };
  }

  onFailed(): IAction {
    return {
      type: Types.REMOVE_GROUP,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IRemoveGroup): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        await GroupService.removeGroup(payload.groupid);
        dispatch(this.onSuccess());
      } catch (error) {
        reactLogger.error('RemoveGroup Error:', error.message);
        dispatch(this.onFailed());
      } finally {
        payload.onFinish();
      }
    };
  }
}

/** Fetch Groups Basic Info  */
class FetchGroupsBasicInfo implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_GROUPS_BASIC_INFO,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_GROUPS_BASIC_INFO,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_GROUPS_BASIC_INFO,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await GroupService.fetchGroups({
          page: 0,
          pageSize: Number.MAX_SAFE_INTEGER,
          basicInfo: 'true',
          order: 'desc',
          sortField: 'createdAt',
        });
        dispatch(this.onSuccess(response.data.items));
      } catch (error) {
        reactLogger.error('FetchGroupsBasicInfo Error:', error.message);
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  removeAlertAction: (payload: IRemoveAlertInput) =>
    new RemoveAlert().action(payload),
  removeStoreBoxAction: (payload: IRemoveStoreBoxInput) =>
    new RemoveStoreBox().action(payload),
  updateAlertAction: (payload: IUpdateAlertInput) =>
    new UpdateAlert().action(payload),
  removeStoreCameraAction: (payload: IRemoveStoreCameraInput) =>
    new RemoveStoreCamera().action(payload),
  editUserAction: (payload: IEditUserInput) => new EditUser().action(payload),
  updateStoreCamerasAction: (payload: IUpdateStoreCameras) =>
    new UpdateStoreCameras().action(payload),
  fetchUsersAction: () => new FetchUsers().action(),
  fetchStoresAction: (payload?: StoresService.IFetchStoresInput) =>
    new FetchStores().action(payload),
  addStoreAction: (payload: IAddStore) => new AddStore().action(payload),
  editStoreAction: (payload: IEditStore) => new EditStore().action(payload),
  removeStoreAction: (payload: IRemoveStore) =>
    new RemoveStore().action(payload),
  registerUserAction: (payload: IRegisterInput) =>
    new RegisterUser().action(payload),
  fetchUsersWithDetailsAction: (payload: UserService.IUserDetailsInput) =>
    new FetchUsersWithDetails().action(payload),
  fetchCamerasListAction: (payload: IFetchCamerasListInput) =>
    new FetchCamerasList().action(payload),
  fetchBoxesListAction: (payload: IFetchBoxesListInput) =>
    new FetchBoxesList().action(payload),
  fetchAlertsListAction: (payload: IFetchAlertsListInput) =>
    new FetchAlertsList().action(payload),
  fetchGroupsAction: (payload: IFetchGroupInput) =>
    new FetchGroups().action(payload),
  fetchGroupsBasicInfoAction: () => new FetchGroupsBasicInfo().action(),
  addGroupAction: (payload: IAddGroup) => new AddGroup().action(payload),
  removeGroupAction: (payload: IRemoveGroup) =>
    new RemoveGroup().action(payload),
  editGroupAction: (payload: IEditGroup) => new EditGroup().action(payload),
};
