// {{BASE_URL}}/api/schemeType
import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';
import { varToStringParams } from 'utils/path';
import {
  AlertFieldValueType,
  AlertTypeEnum,
  ConditionEnum,
} from 'config/alerts';

// getAlertsList
export interface IAlertsListDataOutput extends Types.ResponseOutput {
  data: IAlertsListData;
}

export interface IAlertsListInput {
  pageSize: number;
  page: number;
  order: 'desc' | 'asc';
  sortField: string;
  storeid?: string;
}

export interface IAlertsListData {
  items: Array<{
    _id: string;
    users: string[];
    fieldName: string;
    fieldValue: string;
    fieldValueType: AlertFieldValueType;
    store: {
      _id: string;
      uniqueId: string;
    };
    condition: string;
    types: string[];
  }>;
  paging: {
    pageSize: number;
    page: number;
    total: number;
  };
}

export const getAlertsList = async (
  options: IAlertsListInput
): Promise<IAlertsListDataOutput> => {
  const query = varToStringParams({
    variablesArray: [
      {
        value: options.page.toString(),
        key: 'page',
      },
      {
        value: options.pageSize.toString(),
        key: 'pageSize',
      },
      {
        value: options.sortField.toString(),
        key: 'sortField',
      },
      {
        value: options.order.toString(),
        key: 'order',
      },
      {
        value: options.storeid,
        key: 'storeid',
      },
    ],
  });

  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/alert${query}`
  );
  return data;
};

// addAlert
export interface IAddAlertDataOutput extends Types.ResponseOutput {
  data: null;
}

export interface IAddAlertInput {
  fieldName: string;
  fieldValue: string;
  store: string;
  users: string[];
  fieldValueType: AlertFieldValueType;
  condition: ConditionEnum;
  types: AlertTypeEnum[];
}

export const addAlert = async (
  payload: IAddAlertInput
): Promise<IAddAlertDataOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/alert`,
    {
      body: payload,
    }
  );
  return data;
};

// editAlert
export interface IEditAlertDataOutput extends Types.ResponseOutput {
  data: null;
}

export interface IEditAlertInput {
  fieldName: string;
  fieldValue: string;
  store: string;
  users: string[];
  fieldValueType: 'number' | 'persentage';
  condition: ConditionEnum;
  alertid: string;
  types: AlertTypeEnum[];
}

export const editAlert = async (
  payload: IEditAlertInput
): Promise<IEditAlertDataOutput> => {
  const { alertid, ...body } = payload;
  const { data }: any = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/alert/${alertid}`,
    {
      body,
    }
  );
  return data;
};

// removeAlert
export interface IRemoveAlertDataOutput extends Types.ResponseOutput {
  data: null;
}

export interface IRemoveAlertInput {
  alertid: string;
}

export const removeAlert = async (
  payload: IRemoveAlertInput
): Promise<IRemoveAlertDataOutput> => {
  const { alertid } = payload;
  const { data }: any = await request(
    'delete',
    `${Environment.API_BASE_URL}/api/alert/${alertid}`
  );
  return data;
};
