export enum StoreStatus {
  active = 'Active',
  inactive = 'Inactive',
}

export enum BoxStatus {
  active = 'Active',
  inactive = 'Inactive',
}

export enum CameraStatus {
  active = 'Active',
  inactive = 'Inactive',
}

export enum InOutDataType {
  Inout = 'inout',
  PeopleCounting = 'PeopleCounting',
  Heatmap = 'Heatmap',
}
