//  [REDUCER][TYPE]
import { typeCreatorV2 } from '../../utils/common';

export const SET_MODAL_VISIBILITY: string = typeCreatorV2(
  'ModalReducer',
  'SetModalVisibility'
);

export enum ModalIDs {
  TOAST = 1,
  MODAL_DASHBOARD_ADD_GRAPH = 2,
  MODAL_FIXED_ESTABLISHMENT = 3,
  MODAL_IDENTIFICATION_IN_OTHER_EU_COUNTRIES = 4,
  MODAL_PREVIOUS_USE_OF_SPECIAL_SCHEMES = 5,
  MODAL_ADD_STORE = 'MODAL_ADD_STORE',
  MODAL_REMOVE_STORE = 'MODAL_REMOVE_STORE',
  MODAL_EDIT_CAMERAS = 'MODAL_EDIT_CAMERAS',
  MODAL_ADD_USER = 'MODAL_ADD_USER',
  MODAL_ADD_EDIT_ALERT = 'MODAL_ADD_EDIT_ALERT',
  MODAL_BOX_CONFIG = 'MODAL_BOX_CONFIG',
  MODAL_ADD_NOTIFICATION = 'MODAL_ADD_NOTIFICATION',
  MODAL_VIEW_NOTIFICATION = 'MODAL_VIEW_NOTIFICATION',
  MODAL_ADD_EDIT_GROUP = 'MODAL_ADD_EDIT_GROUP',
}
