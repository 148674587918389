import { Box } from '@material-ui/core';
import TranslatedText from 'components/atoms/translated_text';
import { AppTextField } from 'components/molecules/form_controls';
import GridLayout from 'components/molecules/grid_layout';
import useQuery from 'custom_hooks/useQuery';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import NavigationConfig from 'navigation/config';

interface IFormData {
  email: string;
}

// const { IDefaultFiltering } = Configuration;

const defaultValues: IFormData = {
  email: '',
};

const FilteringBox = () => {
  const {
    setValue,
    reset,
    handleSubmit,
    errors,
    control,
    getValues,
  } = useForm<IFormData>({
    defaultValues,
  });

  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const type = query.get('type') || '';
  const email = query.get('email') || '';

  React.useEffect(() => {
    reset({ ...defaultValues, email });
  }, [email, reset]);

  return (
    <Box display="flex" marginBottom="1rem" marginTop="1rem">
      <GridLayout
        justify="flex-start"
        elements={[
          {
            id: 'email',
            size: 2,
            element: (
              <AppTextField
                variant="outlined"
                margin="none"
                size="small"
                fullWidth
                name="email"
                error={'email' in errors}
                label={<TranslatedText defaultText={'Email'} />}
                handleChange={(value) => {
                  history.push(
                    NavigationConfig.storePage({
                      type,
                      email: value,
                    }).path
                  );
                }}
                id="email"
                control={control}
                rules={{}}
              />
            ),
          },
        ]}
      />
    </Box>
  );
};

export default React.memo(FilteringBox);
