import { Box } from '@material-ui/core';
import TranslatedText from 'components/atoms/translated_text';
import { AppTextField } from 'components/molecules/form_controls';
import GridLayout from 'components/molecules/grid_layout';
import useQuery from 'custom_hooks/useQuery';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import StoreActions from 'redux/reducers/stores_reducer/actions';
import * as Configuration from '../../configuration';

interface IFormData {
  storeName: string;
  storeUniqueId: string;
}

const defaultValues: IFormData = {
  storeName: '',
  storeUniqueId: '',
};

const { StoreListDefaultFiltering } = Configuration;

const FilteringBox = () => {
  const {
    setValue,
    watch,
    handleSubmit,
    errors,
    control,
    getValues,
  } = useForm<IFormData>({
    defaultValues,
  });

  const dispatch = useDispatch();

  return (
    <Box display="flex" marginBottom="1rem" marginTop="1rem">
      <GridLayout
        justify="flex-start"
        elements={[
          {
            id: 'storeName',
            size: 2,
            element: (
              <AppTextField
                variant="outlined"
                margin="none"
                size="small"
                fullWidth
                name="storeName"
                error={'storeName' in errors}
                label={
                  <TranslatedText
                    defaultText={'Store Name'}
                    // textMap={Translations.form_field_confirm_password}
                  />
                }
                handleChange={(value) => {
                  dispatch(
                    StoreActions.fetchStoresAction({
                      storeid: getValues().storeUniqueId,
                      name: value,
                      page: StoreListDefaultFiltering.DefaultPage,
                      pageSize: StoreListDefaultFiltering.DefaultPageSize,
                      order: StoreListDefaultFiltering.DefaultOrder,
                      sortField: StoreListDefaultFiltering.DefaultSortField,
                    })
                  );
                }}
                id="storeName"
                control={control}
                rules={{}}
              />
            ),
          },
          {
            id: 'storeUniqueId',
            size: 2,
            element: (
              <AppTextField
                variant="outlined"
                margin="none"
                size="small"
                fullWidth
                name="storeUniqueId"
                error={'storeUniqueId' in errors}
                label={
                  <TranslatedText
                    defaultText={'Store Unique Id'}
                    // textMap={Translations.form_field_confirm_password}
                  />
                }
                handleChange={(value) => {
                  dispatch(
                    StoreActions.fetchStoresAction({
                      storeid: value,
                      name: getValues().storeName,
                      page: StoreListDefaultFiltering.DefaultPage,
                      pageSize: StoreListDefaultFiltering.DefaultPageSize,
                      order: StoreListDefaultFiltering.DefaultOrder,
                      sortField: StoreListDefaultFiltering.DefaultSortField,
                    })
                  );
                }}
                id="storeUniqueId"
                control={control}
                rules={{}}
              />
            ),
          },
        ]}
      />
    </Box>
  );
};

export default React.memo(FilteringBox);
