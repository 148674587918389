import React from 'react';
import BaseModal from 'components/molecules/base_modal';
import TranslatedText from 'components/atoms/translated_text';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  AppTextField,
  AppUploadPicker,
  AppMultipleAutoCompleteSelect,
  AppAutoCompleteSelect,
  AppSelect,
} from 'components/molecules/form_controls';
import { Button } from '@material-ui/core';
import StoreActions from 'redux/reducers/stores_reducer/actions';
import * as StoreSelectors from 'redux/reducers/stores_reducer/selectors';
import { StateStatus } from 'redux/utils/common';
import Environment from 'config/environment';
import NavigationConfig from 'navigation/config';
import { TabType } from 'scenes/stores_page/configuration';
import { useHistory } from 'react-router';
import * as ArrayUtils from 'utils/arrays';
import Timezones from 'config/timezones';

export interface IModalParams {
  mode: 'edit' | 'add';
  defaultValues?: IFormData & {
    storeUsers: string[];
    group: string;
    timezone: string;
  };
  storeid?: string;
}

const ModalAddStore = () => {
  const dispatch = useDispatch();
  // const formRef = React.useRef<any>();
  const {
    setValue,
    reset,
    register,
    control,
    watch,
    errors,
    handleSubmit,
    getValues,
    formState,
  } = useForm<IFormData>({
    defaultValues,
  });
  const history = useHistory();

  const onSubmit = (storeid?: string) =>
    handleSubmit((data: IFormData) => {
      if (storeid) {
        dispatch(
          StoreActions.editStoreAction({
            storeName: data.name,
            storeid: storeid,
            storeUniqueName: data.storeid as any,
            imgSrc: data.uploadedFile[0],
            pairedUsers: data.storeUsers?.map(({ _id }) => _id) || [],
            initialin: parseInt(data.initialin as any),
            cappacity: parseInt(data.cappacity as any),
            boxesNumber: parseInt(data.boxesNumber as any),
            group: data.group?._id as string,
            onFinish: () => {
              dispatch(
                ModalActions.closeModalAction({
                  modalID: ModalIDs.MODAL_ADD_STORE,
                })
              );
              dispatch(StoreActions.fetchStoresAction());
            },
          })
        );
      } else {
        dispatch(
          StoreActions.addStoreAction({
            storeName: data.name,
            storeUniqueName: data.storeid as any,
            imgSrc: data.uploadedFile[0],
            pairedUsers: data.storeUsers?.map(({ _id }) => _id) || [],
            initialin: parseInt(data.initialin as any),
            cappacity: parseInt(data.cappacity as any),
            boxesNumber: parseInt(data.boxesNumber as any),
            timezone: data.timezone?.id as string,
            group: data.group?._id as string,
            onFinish: () => {
              dispatch(
                ModalActions.closeModalAction({
                  modalID: ModalIDs.MODAL_ADD_STORE,
                })
              );
              history.push(
                NavigationConfig.storePage({
                  type: TabType.Stores,
                }).path
              );
              dispatch(StoreActions.fetchStoresAction());
            },
          })
        );
      }
    });

  return (
    <form>
      <BaseModal
        modalID={ModalIDs.MODAL_ADD_STORE}
        renderContent={(props: IModalParams) => (
          <ModalForm
            params={props}
            reset={reset}
            register={register}
            setValue={setValue}
            control={control}
            errors={errors}
          />
        )}
        modalProps={{ fullWidth: true }}
        renderHeader={(props: IModalParams) => (
          <TranslatedText
            defaultText={props.mode === 'add' ? 'Add Store' : 'Edit Store'}
          />
        )}
        renderFooter={(props: IModalParams) => (
          <React.Fragment>
            <Button
              variant="outlined"
              onClick={() =>
                dispatch(
                  ModalActions.closeModalAction({
                    modalID: ModalIDs.MODAL_ADD_STORE,
                  })
                )
              }
            >
              <TranslatedText defaultText={`Cancel`} />
            </Button>
            <SubmitWrapper
              mode={props.mode}
              onSubmit={onSubmit(props.storeid)}
            />
          </React.Fragment>
        )}
      />
    </form>
  );
};

interface ISubmitWrapper {
  onSubmit: () => void;
  mode: 'edit' | 'add';
}

const SubmitWrapper = ({ onSubmit, mode }: ISubmitWrapper) => {
  const status = StoreSelectors.useGetAddStoreStatus();
  return (
    <Button
      variant="outlined"
      color="primary"
      disabled={status === StateStatus.Pending}
      onClick={() => {
        onSubmit();
      }}
    >
      <TranslatedText defaultText={`Submit`} />
    </Button>
  );
};

interface IFormData {
  name: string;
  storeid: string;
  uploadedFile: string;
  storeUsers: ReturnType<typeof StoreSelectors.useGetUsersList>;
  initialin: number | null;
  cappacity: number | null;
  boxesNumber: number | null;
  timezone: { label: string; id: string } | null;
  group: { name: string; _id: string } | null;
}

const defaultValues: IFormData = {
  name: '',
  storeid: '',
  uploadedFile: '',
  storeUsers: [],
  initialin: null,
  cappacity: null,
  boxesNumber: null,
  timezone: null,
  group: null,
};

interface IModalFormProps {
  control: any;
  errors: ReturnType<typeof useForm>['errors'];
  register: ReturnType<typeof useForm>['register'];
  reset: ReturnType<typeof useForm>['reset'];
  params: IModalParams;
  setValue: ReturnType<typeof useForm>['setValue'];
}

const ModalForm = ({
  control,
  errors,
  register,
  params,
  reset,
  setValue,
}: IModalFormProps) => {
  const timezones = React.useMemo(
    () => Timezones.map((zone) => ({ id: zone, label: zone })),
    []
  );

  React.useEffect(() => {
    if (params.mode === 'edit' && params.defaultValues) {
      reset({
        ...defaultValues,
        name: params.defaultValues.name,
        storeid: params.defaultValues.storeid,
        initialin: params.defaultValues.initialin,
        cappacity: params.defaultValues.cappacity,
        boxesNumber: params.defaultValues.boxesNumber,
        timezone:
          timezones.find((el) => el.id === params?.defaultValues?.timezone) ??
          null,
      });
    } else {
      reset(defaultValues);
    }
  }, [params, reset]);

  return (
    <React.Fragment>
      <AppTextField
        variant="outlined"
        margin="normal"
        error={'storeid' in errors}
        helperText={errors.storeid && errors.storeid.message}
        rules={{ required: <RequiredField /> }}
        fullWidth
        id="storeid"
        InputLabelProps={{
          shrink: true,
        }}
        placeholder="Enter store id"
        label={<TranslatedText defaultText={'Store id'} />}
        control={control}
        autoComplete="storeid"
        name="storeid"
      />
      <AppTextField
        variant="outlined"
        margin="normal"
        error={'name' in errors}
        helperText={errors.name && errors.name.message}
        rules={{ required: <RequiredField /> }}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        placeholder="Enter store name"
        id="name"
        label={<TranslatedText defaultText={'Store Name'} />}
        control={control}
        autoComplete="name"
        name="name"
      />
      <AppUploadPicker
        control={control}
        register={register}
        // fileInitialValue={  params.defaultValues?.uploadedFile}
        fileInitialValue={
          params.defaultValues?.uploadedFile
            ? Environment.STATIC_URL + `/${params.defaultValues?.uploadedFile}`
            : ''
        }
        helperText={errors.name && errors.name.message}
        size="medium"
        error={'uploadedFile' in errors}
        rules={{ required: <RequiredField /> }}
        name={'uploadedFile'}
        label={<TranslatedText defaultText={'Upload Image'} />}
      />
      <AppTextField
        variant="outlined"
        margin="normal"
        error={'initialin' in errors}
        helperText={errors.initialin && errors.initialin.message}
        rules={{ required: <RequiredField /> }}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        placeholder="Store persons initial number"
        id="initialin"
        label={
          <TranslatedText defaultText={'Enter store persons initial number'} />
        }
        type="number"
        control={control}
        autoComplete="initialin"
        name="initialin"
      />
      <AppTextField
        variant="outlined"
        margin="normal"
        error={'cappacity' in errors}
        helperText={errors.cappacity && errors.cappacity.message}
        rules={{ required: <RequiredField /> }}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        type="number"
        placeholder="Store cappacity"
        id="cappacity"
        label={<TranslatedText defaultText={'Enter store cappacity'} />}
        control={control}
        autoComplete="cappacity"
        name="cappacity"
      />
      <AppTextField
        variant="outlined"
        margin="normal"
        error={'boxesNumber' in errors}
        helperText={errors.boxesNumber && errors.boxesNumber.message}
        rules={{ required: <RequiredField /> }}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        placeholder="Boxes Number"
        id="boxesNumber"
        label={<TranslatedText defaultText={'Boxes Number'} />}
        type="number"
        control={control}
        autoComplete="boxesNumber"
        name="boxesNumber"
      />
      <UsersDropdownWrapper
        userIds={params?.defaultValues?.storeUsers || []}
        setValue={setValue}
        errors={errors}
        control={control}
      />
      <AppAutoCompleteSelect
        margin="normal"
        fullWidth
        name={'timezone'}
        control={control}
        rules={{ required: <RequiredField /> }}
        autoComplete={'timezone'}
        InputLabelProps={{
          shrink: true,
        }}
        placeholder="Timezone"
        label={<TranslatedText defaultText={'Timezone'} />}
        // variant="outlined"
        options={timezones}
        selectedKey="id"
      />
      <GroupsDropdownWrapper
        setValue={setValue}
        errors={errors}
        control={control}
        group={params.defaultValues?.group}
      />
    </React.Fragment>
  );
};

interface IUserDropdown {
  control: any;
  errors: any;
  setValue: ReturnType<typeof useForm>['setValue'];
  userIds: string[];
}

// users dropdown
const UsersDropdownWrapper = React.memo(
  ({ control, errors, setValue, userIds }: IUserDropdown) => {
    const usersList = StoreSelectors.useGetUsersList();
    const userIdsMap = React.useMemo(
      () => ArrayUtils.arrayObjectsToObject(usersList || [], '_id'),
      [usersList]
    );

    React.useEffect(() => {
      const data = userIds.map((id) => userIdsMap[id]);
      const timer = setTimeout(() => {
        setValue('storeUsers', data);
      });
      return () => {
        clearTimeout(timer);
      };
    }, [usersList, setValue, userIds]);

    return (
      <AppMultipleAutoCompleteSelect
        multiple={true}
        id="storeUsers"
        control={control}
        InputLabelProps={{
          shrink: true,
        }}
        margin="normal"
        label={<TranslatedText defaultText={'Users'} />}
        name="storeUsers"
        error={'storeUsers' in errors}
        options={usersList || []}
        rules={{
          required: <RequiredField />,
        }}
        size="medium"
        selectedKey="email"
      />
    );
  }
);

interface IGroupsDropdown {
  control: any;
  errors: any;
  setValue: ReturnType<typeof useForm>['setValue'];
  group?: string;
}

// users dropdown
const GroupsDropdownWrapper = React.memo(
  ({ control, errors, setValue, group }: IGroupsDropdown) => {
    const { data } = StoreSelectors.useGroupBasicInfo();
    const groupsList = data;

    React.useEffect(() => {
      if (group && groupsList) {
        const index = groupsList?.findIndex((element) => element._id === group);
        const timer = setTimeout(() => {
          if (data) {
            setValue('group', groupsList[index]);
          }
        });
        return () => {
          clearTimeout(timer);
        };
      }
    }, [groupsList]);

    return (
      <AppAutoCompleteSelect
        id="group"
        control={control}
        InputLabelProps={{
          shrink: true,
        }}
        margin="normal"
        label={<TranslatedText defaultText={'Group'} />}
        name="group"
        error={'group' in errors}
        options={groupsList || []}
        rules={{
          required: <RequiredField />,
        }}
        size="medium"
        selectedKey="name"
      />
    );
  }
);

const RequiredField = React.memo(() => {
  return <TranslatedText defaultText={'Required Value'} />;
});

export default React.memo(ModalAddStore);
