import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from '../../utils/common';
import * as Types from './types';
import * as UserService from 'services/api/user';
import { Dispatch } from 'redux';
import reactLogger from 'utils/logger';
import * as StoreService from 'services/api/stores';

/** Fetch Stores  */
class FetchStores implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_STORES,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_STORES,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_STORES,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await UserService.getStores();
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        reactLogger.error('FetchStores Error:', error.message);
        dispatch(this.onFailed());
      }
    };
  }
}

/** Set Store  */
class SetSelectedStore implements IActionMethods {
  onPending(): IAction {
    throw new Error('Method not implemented');
  }
  onSuccess(storeid: string): IAction {
    return {
      type: Types.SET_SELECTED_STORE,
      status: StateStatus.Success,
      data: storeid,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_SELECTED_STORE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(storeid: string): IAction {
    try {
      return this.onSuccess(storeid);
    } catch (error) {
      reactLogger.error('SetSelectedStore Error:', error.message);
      return this.onFailed();
    }
  }
}

/** Fetch Store Cameras  */
class FetchStoreCameras implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_CAMERAS,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_CAMERAS,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_CAMERAS,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(storeid: string): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await StoreService.getCamerasList({
          pageSize: Number.MAX_SAFE_INTEGER,
          storeid,
          page: 0,
        });
        dispatch(this.onSuccess(response.data.items));
      } catch (error) {
        reactLogger.error('Fetch Store Cameras Error:', error.message);
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  fetchStoresAction: () => new FetchStores().action(),
  fetchStoreCamerasAction: (storeid: string) =>
    new FetchStoreCameras().action(storeid),
  setSelectedStoreAction: (storeid: string) =>
    new SetSelectedStore().action(storeid),
};
