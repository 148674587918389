import React from 'react';
import DashboardBuilder from 'components/compounds/dashboard_builder';
import * as Configuration from './configuration';
import * as VisitorsByDaySelectors from 'redux/reducers/visitorsByDay_reducer/selectors';

export default function VisitorsByDayGraphs() {
  const weekNumData = VisitorsByDaySelectors.useGetVisitorsWeekNum();
  const visitorsByDayData = VisitorsByDaySelectors.useGetVisitorsByDay();

  return (
    <React.Fragment>
      <DashboardBuilder
        configuration={Configuration.DashboardBuilderConfig({
          weekNumData: {
            labels: weekNumData?.labels || [],
            datasets: weekNumData?.datasets || [],
          },
          visitorsByDayData: {
            labels: visitorsByDayData?.hoursLabels || [],
            datasets: visitorsByDayData?.datasets || [],
          },
        })}
      />
    </React.Fragment>
  );
}
