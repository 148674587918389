// {{BASE_URL}}/api/schemeType
import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';
import { BoxStatus, StoreStatus, InOutDataType } from 'config/store';
import { varToStringParams } from 'utils/path';
import { NotificationTypes } from '../../../config/notification';

/** fetch notifications  */
export type INotificationData = {
  items: Array<{
    _id: string;
    read: boolean;
    trash: boolean;
    title: string;
    description: string;
    sourceid: string;
    receiverid: {
      _id: string;
      email: string;
    };
    createdAt: string;
    updatedAt: string;
    readBy: string;
    readDate: string;
  }>;
  paging: {
    pageSize: number;
    page: number;
    total: number;
  };
};

interface IFetchNotificationsOutput extends Types.ResponseOutput {
  data: INotificationData;
}

export interface IFetchNotificationsInput {
  page: number;
  pageSize: number;
  sortField: string;
  order: 'asc' | 'desc';
  userEmail?: string;
  userid?: string;
  notificationType?: NotificationTypes;
  dateStart?: string;
  dateEnd?: string;
}

export const fetchNotificationList = async (
  payload: IFetchNotificationsInput
): Promise<IFetchNotificationsOutput> => {
  const query = varToStringParams({
    variablesArray: [
      {
        value: payload.page.toString(),
        key: 'page',
      },
      {
        value: payload.pageSize.toString(),
        key: 'pageSize',
      },
      {
        value: payload.sortField,
        key: 'sortField',
      },
      {
        value: payload.userEmail,
        key: 'userEmail',
      },
      {
        value: payload.userid,
        key: 'userid',
      },
      {
        value: payload.notificationType,
        key: 'notificationType',
      },
      {
        value: payload.dateStart,
        key: 'dateStart',
      },
      {
        value: payload.dateEnd,
        key: 'dateEnd',
      },
    ],
  });

  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/notification/list${query}`
  );
  return data;
};

/** add store  */
interface IAddNotificationOutput extends Types.ResponseOutput {
  data: null;
}

export interface IAddNotificationInput {
  title: string;
  description: string;
  type: NotificationTypes;
  users: Array<string>;
}

export const addNotification = async (
  payload: IAddNotificationInput
): Promise<IAddNotificationOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/notification`,
    {
      body: payload,
      showToastMessage: true,
    }
  );
  return data;
};

/** edit notification  */
interface IEdiNotificationOutput extends Types.ResponseOutput {
  data: null;
}

export interface IEditNotificationInput {
  read: boolean;
}

export const editNotification = async (
  payload: IEditNotificationInput,
  notificationid: string
): Promise<IEdiNotificationOutput> => {
  const { data }: any = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/notification/${notificationid}`,
    {
      body: payload,
      showToastMessage: true,
    }
  );
  return data;
};
