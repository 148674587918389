import React from 'react';
import BaseModal from 'components/molecules/base_modal';
import TranslatedText from 'components/atoms/translated_text';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  AppTextField,
  AppUploadPicker,
} from 'components/molecules/form_controls';
import { Button, Paper, Typography } from '@material-ui/core';
import StoreActions from 'redux/reducers/stores_reducer/actions';
import * as StoreSelectors from 'redux/reducers/stores_reducer/selectors';
import { StateStatus } from 'redux/utils/common';

export interface IModalParams {
  storeid: string;
}

const ModalRemoveStore = () => {
  const dispatch = useDispatch();

  return (
    <BaseModal
      modalID={ModalIDs.MODAL_REMOVE_STORE}
      modalProps={{ fullWidth: true }}
      renderHeader={(props: IModalParams) => (
        <TranslatedText
          defaultText={'Are sure you want to remove the store?'}
        />
      )}
      renderFooter={(props: IModalParams) => (
        <React.Fragment>
          <Button
            variant="outlined"
            onClick={() =>
              dispatch(
                ModalActions.closeModalAction({
                  modalID: ModalIDs.MODAL_REMOVE_STORE,
                })
              )
            }
          >
            <TranslatedText defaultText={`Cancel`} />
          </Button>
          <SubmitWrapper storeid={props.storeid} />
        </React.Fragment>
      )}
    />
  );
};

const SubmitWrapper = ({ storeid }: any) => {
  const status = StoreSelectors.useGetRemoveStoreStatus();
  const dispatch = useDispatch();

  return (
    <Button
      variant="outlined"
      color="primary"
      disabled={status === StateStatus.Pending}
      onClick={() => {
        dispatch(
          StoreActions.removeStoreAction({
            storeid: storeid,
            onFinish: () => {
              dispatch(
                ModalActions.closeModalAction({
                  modalID: ModalIDs.MODAL_REMOVE_STORE,
                })
              );
              dispatch(StoreActions.fetchStoresAction());
            },
          })
        );
      }}
    >
      <TranslatedText defaultText={`Remove Store`} />
    </Button>
  );
};

export default React.memo(ModalRemoveStore);
