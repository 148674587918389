import React from 'react';
import { LanguagesEnum } from 'config/languages';
import { MonthsEnum } from 'config';
import { ChartTypesEnum } from 'components/molecules/custom_chart';
import {
  IGridConf,
  ComponentTypeEnum,
} from 'components/compounds/dashboard_builder/configuration';
import * as NumberFormattingUtils from 'utils/format_number';
import * as AnalyticsService from 'services/api/analytics';
import { Theme } from '@material-ui/core';
import { ChartOptions } from 'chart.js';
import { IFormDataOutput } from 'redux/reducers/storeComparison_reducer/selectors';

const OptionsGraphTypePie = (theme?: Theme): ChartOptions => ({
  legend: {
    display: true,
    align: 'center',
    position: 'top',
    labels: {
      usePointStyle: true,
    },
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
  maintainAspectRatio: false,
  tooltips: {
    enabled: true,
  },
});

const OptionsGraphType1 = (): ChartOptions => ({
  legend: {
    display: false,
  },
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      display: false,
    },
  },
  responsive: true,
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          max: 8,
          min: 0,
          beginAtZero: true,
          callback: function (label: any, index: any, labels: any) {
            switch (label) {
              case 1:
                return '7. Sunday';
              case 2:
                return '1. Monday';
              case 3:
                return '2. Tuesday';
              case 4:
                return '3. Wednesday';
              case 5:
                return '4. Thursday';
              case 6:
                return '5. Friday';
              case 7:
                return '6. Saturday';
              default:
                return ``;
            }
          },
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          autoSkip: true,
        },
      },
    ],
  },
});

interface IFormData {
  storeid: string | null;
  selectedMonth: string | null;
}

/**
 * Fix store ids
 */
export const DashboardBuilderConfig = (data: IFormData): IGridConf[] => [
  {
    width: 6,
    type: ComponentTypeEnum.statistic,
    props: {
      backgroundColor: '#F6F0CC',
      title: '',
      // `${visitorsNumber}`,
      description: `Visitors`,
      titleColor: '#0F2E69',
      descriptionColor: '#0F2E69',
    },
    fetch: async () => {
      let response = await AnalyticsService.getTotalVisitorsData({
        type: 'month',
        value: parseInt(data.selectedMonth as string),
        storeid: data?.storeid || '',
      });
      return {
        title: response.data.dataCurrent.visitorsNumber.toString(),
        description: 'Visitors',
      };
    },
  },
  {
    width: 6,
    type: ComponentTypeEnum.statistic,
    props: {
      backgroundColor: '#DAFFE7',
      title: `% change`,
      description: '',
      titleColor: '#0F2E69',
      descriptionColor: '#0F2E69',
    },
    fetch: async () => {
      let response = await AnalyticsService.getTotalVisitorsData({
        type: 'month',
        value: parseInt(data.selectedMonth as string),
        storeid: data.storeid || '',
      });
      return {
        title: `${response.data.increasingPersentage}`,
        description: `% change`,
      };
    },
  },
  {
    width: 6,
    type: ComponentTypeEnum.chart,
    props: {
      height: 280,
      data: [
        {
          data: [],
          label: '',
          format: {
            label: 'Hourly Visitors',
            fill: false,
            backgroundColor: 'rgba(52, 164, 235, .8)',
            pointBorderColor: '#0094E8',
            hoverBackgroundColor: '#0094E8',
            pointHoverBorderColor: '#0094E8',
          },
        },
      ],
      labels: [],
      chartType: ChartTypesEnum.BUBBLE,
      options: OptionsGraphType1(),
      title: 'People in Time Group per Month',
    },
    fetch: async () => {
      let response = await AnalyticsService.getVisitorsPickByHourPerWeekday({
        storeid: data?.storeid || '',
      });
      return {
        labels: [],
        data: [response.data],
      };
    },
  },
  {
    width: 6,
    type: ComponentTypeEnum.chart,
    props: {
      height: 280,
      data: [
        {
          data: [],
          // 20, 50, 100, 46
          label: '',
          format: {
            label: '',
            fill: false,
            backgroundColor: ['#1387FB', '#13239D', '#E56C37', '#6C007A'],
          },
        },
      ],
      labels: [],
      chartType: ChartTypesEnum.PIE,
      options: OptionsGraphTypePie(),
      title: '% Visitors By Time Group',
    },
    fetch: async () => {
      let response = await AnalyticsService.getVisitorsByTimeGroup({
        monthNumber: parseInt(data.selectedMonth as string),
        storeid: data?.storeid || '',
      });

      return {
        data: [Object.values(response.data)],
        labels: Object.keys(response.data),
      };
    },
  },
];
