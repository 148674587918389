import React from 'react';
import { ChartOptions } from 'chart.js';
import { CustomChart } from 'components/molecules/custom_chart';
import CardLabelTitle from 'components/app_common/card_label_title';
import { ChartTypesEnum } from 'components/molecules/custom_chart/configuration';
import TranslatedText from 'components/atoms/translated_text';
import * as VisitorDetailsSelectors from 'redux/reducers/visitorDetails_reducer/selectors';
import { generateRandomColor } from 'utils/randomize';

export default function VisitorsDetailGraph() {
  const data = VisitorDetailsSelectors.useVisitorDetailsData();
  const chartref = React.useRef<any>();
  const dataset = React.useMemo(
    () =>
      data.data?.scale.map((el, index) => {
        const color = generateRandomColor();
        return {
          data: el.data as any,
          label: el.label,
          format: {
            label: el.label,
            fill: false,
            borderColor: color,
            lineTension: 0.1, // make the lines without radius
            backgroundColor: color,
            pointBorderColor: color,
            pointBackgroundColor: color,
            pointHoverBackgroundColor: color,
            pointHoverBorderColor: color,
          },
        };
      }),
    [data]
  );

  return (
    <React.Fragment>
      <CustomChart
        title={
          <CardLabelTitle
            title={<TranslatedText defaultText="Store In and Out" />}
          />
        }
        height={'400px'}
        layoutProps={{
          variant: 'outlined',
        }}
        data={dataset ?? []}
        labels={data.data?.labels || []}
        options={options}
        chartType={ChartTypesEnum.LINE}
      />
    </React.Fragment>
  );
}

export const options: ChartOptions = {
  legend: {
    display: true,
    align: 'start',
    labels: {
      usePointStyle: true,
    },
  },
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      display: false,
    },
  },
  responsive: true,
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: true,
        },
      },
    ],
  },
  tooltips: {
    enabled: true,
  },
};
