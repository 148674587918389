import React from 'react';
import { withRouter } from 'react-router';
import useStyles from './styles';
import { Container, Grid, makeStyles } from '@material-ui/core';
import ProfileDetails from './components/profile_details';
import { useDispatch } from 'react-redux';
import UserProfileActions from '../../redux/reducers/userProfile_reducer/actions';
import reactLogger from 'utils/logger';
import { withReducer } from 'redux/withReducer';
import { ReducerKeys } from 'redux/config';
import ChangePassword from './components/change_password';

interface IProps {}

const ProfilePage = (props: IProps) => {
  reactLogger.renderPage('ProfilePage');
  const classes = useStyles();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(UserProfileActions.fetchUserProfileAction());
  }, [dispatch]);

  return (
    <Container maxWidth="lg">
      <Grid justify="center" spacing={3} container>
        <Grid item xl={6} lg={6} md={6} xs={12}>
          <ProfileDetails />
        </Grid>
        <Grid item xl={6} lg={6} md={6} xs={12}>
          <ChangePassword />
        </Grid>
      </Grid>
    </Container>
  );
};

export default withRouter(ProfilePage);
