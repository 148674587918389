import React, { ReactElement } from 'react';
import { Button, Paper, Typography } from '@material-ui/core';
import { Control, Controller } from 'react-hook-form';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import useStyles from './styles';
import { useForm } from 'react-hook-form';

interface Iprops {
  control: Control;
  rules: any;
  variant?: 'outlined' | 'contained';
  margin?: 'none' | 'dense' | 'normal';
  size?: 'small' | 'medium';
  name: string;
  label: string | React.ReactElement;
  register: ReturnType<typeof useForm>['register'];
  error?: boolean;
  helperText?: React.ReactElement;
  fullWidth?: boolean;
  fileInitialValue?: string;
}

const ERROR_COLOR = '#f44336';

export default function UploadPicker(props: Iprops) {
  const {
    control,
    rules,
    register,
    fullWidth,
    variant,
    label,
    name,
    helperText,
    error,
    fileInitialValue,
    size,
    margin,
    ...other
  } = props;
  const hiddenFileInput = React.useRef<any>(null);
  const initialValue = React.useRef<string | null>(fileInitialValue || null)
    .current;
  const classes = useStyles({ margin: margin === 'normal' });

  let _rules = rules;
  if (initialValue && _rules) {
    _rules = {
      ...rules,
      required: false,
    };
  }

  const [uploadedFile, setUploadedFile] = React.useState<any>({
    src: initialValue,
    isImage: initialValue && initialValue.match(/.(jpg|jpeg|png|gif)$/i),
    name: '',
  });

  const handleClick = (event: any) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event: any) => {
    const fileInput = event.target.files[0];
    setUploadedFile({
      src: URL.createObjectURL(fileInput),
      isImage: fileInput.name.match(/.(jpg|jpeg|png|gif)$/i),
      name: fileInput.name,
    });
  };

  return (
    <div className={classes.container}>
      <Button
        style={{
          padding: size === 'medium' ? '0.8rem' : '0.4rem',
          margin: 'auto',
          color: error ? ERROR_COLOR : undefined,
          borderColor: error ? ERROR_COLOR : undefined,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        variant={variant || 'outlined'}
        size="large"
        fullWidth={fullWidth || true}
        onClick={handleClick}
      >
        <BackupOutlinedIcon style={{ marginRight: '1rem' }} />
        <Typography component="h5">{label}</Typography>
        <input
          className="upload"
          type="file"
          name={name}
          ref={(data) => {
            (hiddenFileInput.current = data) && register(data, _rules);
          }}
          onChange={handleChange}
          style={{ display: 'none' }}
        />
      </Button>
      {helperText && (
        <Typography
          style={{
            color: error ? ERROR_COLOR : undefined,
            fontSize: 12,
            marginLeft: '1rem',
            paddingTop: 1,
          }}
        >
          {helperText}
        </Typography>
      )}
      {uploadedFile?.src && (
        <Paper
          variant="outlined"
          style={{
            padding: '1rem',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: '0.5rem',
            justifyContent: 'center',
          }}
        >
          <img
            src={
              uploadedFile.isImage
                ? uploadedFile.src
                : require('assets/images/icons/file.png')
            }
            style={{ width: 'auto', height: 150 }}
          />
          <Typography component="h5">{uploadedFile.name}</Typography>
        </Paper>
      )}
    </div>
  );
}
