import React from 'react';
import { AppSelect, AppDatePicker } from 'components/molecules/form_controls';
import { useForm } from 'react-hook-form';
import useStyles from './styles';
import { Grid, Typography } from '@material-ui/core';
import TranslatedText from 'components/atoms/translated_text';
import VisitorAnalyticsActions from 'redux/reducers/visitorAnalytics_reducer/actions';
import reactLogger from 'utils/logger';
import { useDispatch } from 'react-redux';
import * as StoreHeatmapCountSelectors from 'redux/reducers/storeHeatmapCount_reducer/selectors';
import StoreHeatmapCountActions from 'redux/reducers/storeHeatmapCount_reducer/actions';
import StoresCommonActions from 'redux/reducers/gl_app_common/actions';
import * as StoresCommonSelectors from 'redux/reducers/gl_app_common/selectors';
import { HeatmapTypeEnum, HeatmapTypes } from 'config/heatmapTypes';
import store from 'redux/store';

interface IFormData {
  selectedStore: string | null;
  storeCameras: string | null;
  dateStart: Date;
  dateEnd: Date;
  type: HeatmapTypeEnum | null;
}

export default function SelectForm() {
  reactLogger.renderComponent('SelectForm');
  const classes = useStyles();
  const { setValue, watch, handleSubmit, errors, control, getValues } =
    useForm<IFormData>({
      defaultValues: {
        selectedStore: null,
        storeCameras: null, //VisitorAnalyticsSelectors.getDateRangeType(),
        dateStart: new Date(),
        dateEnd: new Date(),
        type: store.getState().HeatmapCountReducer.heatmapType,
      },
    });
  // const formData
  const dispatch = useDispatch();

  React.useEffect(() => {
    handleDataChange(
      getValues().dateStart ? getValues().dateStart : new Date(),
      getValues().dateEnd ? getValues().dateEnd : new Date(),
      getValues().selectedStore,
      getValues().storeCameras,
      getValues().type
    );
  }, [getValues]);

  const handleDataChange = (
    dateStart: Date,
    dateEnd: Date,
    storeid: string | null,
    cameraid: string | null,
    type: HeatmapTypeEnum | null
  ) => {
    if (dateStart && dateEnd && storeid && cameraid && type) {
      dispatch(
        StoreHeatmapCountActions.fetchHeatmapCountAction({
          dateEnd: dateEnd.toISOString(),
          dateStart: dateStart.toISOString(),
          storeid: storeid,
          cameraid: cameraid,
          type,
        })
      );
    }
  };

  return (
    <form className={classes.form} noValidate>
      <Grid spacing={1} container justify={'space-between'}>
        <Grid
          alignItems="flex-end"
          justify="flex-start"
          spacing={1}
          xl={8}
          lg={8}
          md={8}
          sm={7}
          xs={12}
          item
          container
        >
          <Grid sm={3} xs={12} item>
            <div>
              <Typography>
                <TranslatedText defaultText={'Select Store'} />
              </Typography>
              <SelectStoresWrapper
                getValues={getValues}
                setValue={setValue}
                control={control}
              />
            </div>
          </Grid>
          <Grid sm={3} xs={12} item>
            <div>
              <Typography component={'h4'}>
                <TranslatedText defaultText={'Date From'} />
              </Typography>
              <AppDatePicker
                fullWidth
                margin="none"
                autoOk
                size="small"
                helperText={errors.dateStart && errors.dateStart.message}
                error={'dateStart' in errors}
                control={control}
                handleChange={(data) =>
                  data &&
                  handleDataChange(
                    data,
                    getValues().dateEnd,
                    getValues().selectedStore,
                    getValues().storeCameras,
                    getValues().type
                  )
                }
                rules={{}}
                name="dateStart"
              />
            </div>
          </Grid>
          <Grid sm={3} xs={12} item>
            <div>
              <Typography>
                <TranslatedText defaultText={'Date To'} />
              </Typography>
              <AppDatePicker
                fullWidth
                margin="none"
                autoOk
                helperText={errors.dateEnd && errors.dateEnd.message}
                error={'dateEnd' in errors}
                control={control}
                size="small"
                handleChange={(data) => {
                  if (data) {
                    handleDataChange(
                      getValues().dateStart,
                      data,
                      getValues().selectedStore,
                      getValues().storeCameras,
                      getValues().type
                    );
                  }
                }}
                rules={{}}
                name="dateEnd"
              />
            </div>
          </Grid>
          <Grid sm={3} xs={12} item>
            <div>
              <Typography>
                <TranslatedText defaultText={'Heatmap Type'} />
              </Typography>
              <AppSelect
                margin="none"
                fullWidth
                style={{ marginTop: 0, marginBottom: 0 }}
                name={'type'}
                control={control}
                rules={{}}
                size="small"
                handleChange={(data) => {
                  if (data) {
                    handleDataChange(
                      getValues().dateStart,
                      getValues().dateEnd,
                      getValues().selectedStore,
                      getValues().storeCameras,
                      data as HeatmapTypeEnum
                    );
                    dispatch(
                      StoreHeatmapCountActions.setHeatmapTypeAction(
                        data as HeatmapTypeEnum
                      )
                    );
                  }
                }}
                autoComplete={'type'}
                variant="outlined"
                options={[
                  {
                    label: 'Avg Time',
                    id: HeatmapTypeEnum.AvgTime,
                  },
                  {
                    label: 'Count',
                    id: HeatmapTypeEnum.Count,
                  },
                  {
                    label: 'Degree',
                    id: HeatmapTypeEnum.Degree,
                  },
                ]}
              />
            </div>
          </Grid>
        </Grid>

        <Grid xl={3} lg={3} md={4} sm={5} xs={12} item>
          <div>
            <Typography component={'h4'}>
              <TranslatedText defaultText={'Camera'} />
            </Typography>
            <SelectStoreCamerasWrapper
              getValues={getValues}
              control={control}
              setValue={setValue}
            />
          </div>
        </Grid>
      </Grid>
    </form>
  );
}

//   control: ReturnType<typeof useForm>['control'];

interface IPropsSelectStores {
  control: ReturnType<typeof useForm>['control'];
  setValue: ReturnType<typeof useForm>['setValue'];
  getValues: ReturnType<typeof useForm>['getValues'];
}

const SelectStoresWrapper = (props: IPropsSelectStores) => {
  const stores = StoresCommonSelectors.useGetStores();
  const selectedStore = StoresCommonSelectors.useGetSelectedStore();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (stores.length > 0) {
      const storeid = selectedStore || stores[0].uniqueId;
      // console.log('Store selected:', stores);
      props.setValue('selectedStore', storeid);
      handleStoreChange(storeid);
    }
  }, [stores, selectedStore]);

  const handleStoreChange = (value: string | null) => {
    if (value) {
      dispatch(StoreHeatmapCountActions.fetchStoreCamerasActions(value));
      dispatch(StoresCommonActions.setSelectedStoreAction(value));
    }
  };

  return (
    <AppSelect
      margin="none"
      fullWidth
      style={{ marginTop: 0, marginBottom: 0 }}
      name={'selectedStore'}
      control={props.control}
      rules={{}}
      size="small"
      handleChange={handleStoreChange}
      autoComplete={'selectedStore'}
      variant="outlined"
      options={stores.map(({ uniqueId, name }) => ({
        label: name,
        id: uniqueId,
      }))}
    />
  );
};

const SelectStoreCamerasWrapper = (props: IPropsSelectStores) => {
  const storeCameras = StoreHeatmapCountSelectors.useGetStoreCameras();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (storeCameras.length > 0) {
      const value = storeCameras[0].cameraId;
      // const value = storeCameras[0].cameraid;
      props.setValue('storeCameras', value);
      handleChange(value);
    }
  }, [storeCameras]);

  const handleChange = (value: string | null) => {
    if (
      value &&
      props.getValues().dateEnd &&
      props.getValues().dateStart &&
      props.getValues().selectedStore
    ) {
      dispatch(
        StoreHeatmapCountActions.fetchHeatmapCountAction({
          dateEnd: props.getValues().dateEnd.toISOString(),
          dateStart: props.getValues().dateStart.toISOString(),
          storeid: props.getValues().selectedStore,
          cameraid: value,
          type: props.getValues().type,
        })
      );
      dispatch(
        StoreHeatmapCountActions.setSelectedStoreDataAction(
          (storeCameras.filter(({ cameraId }) => cameraId === value) as any)[0]
        )
      );
    }
  };

  return (
    <AppSelect
      margin="none"
      fullWidth
      name={'storeCameras'}
      style={{ marginTop: 0, marginBottom: 0 }}
      control={props.control}
      rules={{}}
      handleChange={handleChange}
      size="small"
      autoComplete={'storeCameras'}
      variant="outlined"
      options={storeCameras.map(({ cameraId, name }) => ({
        id: cameraId,
        label: name || cameraId,
      }))}
    />
  );
};
