export enum Roles {
  Guest = 'Guest',
  User = 'Simple',
  Admin = 'Admin',
}

const AllUsers = [Roles.Guest, Roles.Admin, Roles.User];
// .map((key: any) =>
//   Number.parseInt(Roles[key])
// );
const RegisteredUsers = [Roles.Admin, Roles.User];

export default {
  ...Roles,
  All: AllUsers,
  Registered: RegisteredUsers,
};
