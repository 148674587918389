import React, { useEffect } from 'react';
import { AppSelect, AppDatePicker } from 'components/molecules/form_controls';
import { useForm } from 'react-hook-form';
import useStyles from './styles';
import { Container, Grid, Typography } from '@material-ui/core';
import TranslatedText from 'components/atoms/translated_text';
import reactLogger from 'utils/logger';
import { useDispatch } from 'react-redux';
import * as DatesUtils from 'utils/dates';
import VisitorsByDayActions from 'redux/reducers/visitorsByDay_reducer/actions';
import StoresCommonActions from 'redux/reducers/gl_app_common/actions';
import * as StoresCommonSelectors from 'redux/reducers/gl_app_common/selectors';

import {
  DaysRangeConfig,
  Regions,
  Months,
  MonthsEnum,
  RegionsEnum,
} from 'config';

interface IFormData {
  selectedStore: string | null;
  // selectedRegion: RegionsEnum;
  selectedMonth: MonthsEnum;
  selectedWeek: number | null;
}

export default function SelectForm() {
  reactLogger.renderComponent('SelectForm');
  const classes = useStyles();
  const { setValue, watch, handleSubmit, errors, reset, control, getValues } =
    useForm<IFormData>({
      defaultValues: {
        selectedStore: null,
        selectedWeek: null,
        selectedMonth: new Date().getMonth() as any,
        // selectedRegion: RegionsEnum.Paphos,
      },
    });
  const dispatch = useDispatch();

  const handleValueChange = async (
    type: 'store' | 'month' | 'week',
    value: any
  ) => {
    let data = {
      storeid: type === 'store' ? value : getValues().selectedStore,
      // region: type === 'region' ? value : getValues().selectedRegion,
      month: type === 'month' ? value : getValues().selectedMonth,
      weekNumber: type === 'week' ? value : getValues().selectedWeek,
    };
    if (!Object.values(data).includes(null)) {
      // console.log("Handle...", data);
      dispatch(VisitorsByDayActions.fetchVisitorsByDayAction(data));
      dispatch(VisitorsByDayActions.fetchVisitorsWeekNumAction(data));
      if (type === 'store') {
        dispatch(StoresCommonActions.setSelectedStoreAction(data.storeid));
      }
    }
  };

  return (
    <React.Fragment>
      <form className={classes.form} noValidate>
        <Grid spacing={0} container>
          <Grid spacing={1} sm={12} md={8} justify="flex-start" container item>
            <Grid sm={12} md={4} item>
              <div>
                <Typography component={'h4'}>
                  <TranslatedText defaultText={'Store'} />
                </Typography>
                <SelectStoresWrapper
                  handleChange={async (value) =>
                    await handleValueChange('store', value)
                  }
                  control={control}
                  reset={reset}
                  setValue={setValue}
                />
              </div>
            </Grid>
            <Grid sm={12} xs={12} md={4} item>
              <div>
                <Typography component={'h4'}>
                  <TranslatedText defaultText={'Month'} />
                </Typography>
                <AppSelect
                  margin="none"
                  fullWidth
                  name={'selectedMonth'}
                  style={{ marginTop: 0, marginBottom: 0 }}
                  control={control}
                  rules={{}}
                  // handleChange={(value) => handleValueChange('month', value)}
                  size="small"
                  autoComplete={'selectedMonth'}
                  variant="outlined"
                  options={Months}
                />
              </div>
            </Grid>
          </Grid>

          <Grid
            spacing={1}
            justify="flex-end"
            container
            xs={12}
            sm={12}
            md={4}
            item
          >
            <Grid xs={12} sm={12} md={8} item>
              <div>
                <Typography component={'h4'}>
                  <TranslatedText defaultText={'Week'} />
                </Typography>
                <SelectedWeekWrapper
                  control={control}
                  watch={watch}
                  // selectedMonth={selectedMonth}
                  reset={reset}
                  handleChange={(value) => handleValueChange('week', value)}
                  setValue={setValue}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}

interface IPropsSelectStores {
  control: any;
  setValue: any;
  reset: any;
  // handleChange: any;
  handleChange: (data: string) => void;
}

interface IPropsSelectWeek {
  control: any;
  reset: any;
  setValue: any;
  watch: any;
  handleChange: (data: string) => void;
}

const SelectedWeekWrapper = (props: IPropsSelectWeek) => {
  const selectedMonth = props.watch('selectedMonth');
  const weeks = DatesUtils.getWeeksOfMonth(
    selectedMonth,
    new Date().getFullYear()
  ).map((number, index) => ({
    label: number.toString(),
    id: (index + 1).toString(),
  }));

  React.useEffect(() => {
    if (weeks.length > 0) {
      props.setValue('selectedWeek', weeks[0].id);
      props.handleChange(weeks[0].id);
    }
  }, [weeks]);

  return (
    <AppSelect
      margin="none"
      fullWidth
      style={{ marginTop: 0, marginBottom: 0 }}
      name={'selectedWeek'}
      control={props.control}
      rules={{}}
      handleChange={props.handleChange}
      size="small"
      autoComplete={'selectedWeek'}
      variant="outlined"
      options={weeks}
    />
  );
};

const SelectStoresWrapper = (props: IPropsSelectStores) => {
  const stores = StoresCommonSelectors.useGetStores();
  const selectedStore = StoresCommonSelectors.useGetSelectedStore();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (stores.length > 0) {
      const storeid = selectedStore || stores[0].uniqueId;
      props.setValue('selectedStore', storeid);
      props.handleChange(storeid);
    }
  }, [stores, selectedStore]);

  return (
    <AppSelect
      margin="none"
      fullWidth
      style={{ marginTop: 0, marginBottom: 0 }}
      name={'selectedStore'}
      control={props.control}
      rules={{}}
      size="small"
      handleChange={props.handleChange}
      autoComplete={'selectedStore'}
      variant="outlined"
      options={stores.map(({ uniqueId, name }) => ({
        label: name,
        id: uniqueId,
      }))}
    />
  );
};

interface IFormWatcherProps {
  watch: any;
}
