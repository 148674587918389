import React from 'react';
import { CustomChart } from 'components/molecules/custom_chart';
import * as LocalConfiguration from './configuration';
import TranslatedText from 'components/atoms/translated_text';
import { ChartTypesEnum } from 'components/molecules/custom_chart/configuration';
// import * as ReduxSelectors from 'react-redux';
import CardLabelTitle from 'components/app_common/card_label_title';
import * as StorePerformanceSelectors from 'redux/reducers/storePerformance_reducer/selectors';

export default function PeekHourPerWeekGraph() {
  // const dispatch = ReduxSelectors.useDispatch();
  const graphData = StorePerformanceSelectors.useGetVisitorsPickByHourPerWeekday();

  return (
    <CustomChart
      // height={300}
      title={
        <CardLabelTitle
          title={<TranslatedText defaultText="Peak Hour Per WeekDay" />}
        />
        // <TranslatedText defaultText={'Peak Hour Per WeekDay'} />
      }
      layoutProps={{
        variant: 'outlined',
      }}
      data={LocalConfiguration.DummyDataset(graphData || [])}
      options={LocalConfiguration.DummyChartOptions}
      chartType={ChartTypesEnum.BUBBLE}
    />
  );
}

// import React from 'react';
// import { Bubble } from 'react-chartjs-2';

// const data = {
//   labels: ['January'],
//   datasets: [
//     {
//       label: 'My First dataset',
//       // fill: false,
//       // lineTension: 0.1,
//       backgroundColor: 'rgba(75,192,192,0.4)',
//       borderColor: 'rgba(75,192,192,1)',
//       // borderCapStyle: 'butt',
//       // borderDash: [],
//       // borderDashOffset: 0.0,
//       // borderJoinStyle: 'miter',
//       // pointBorderColor: 'rgba(75,192,192,1)',
//       // pointBackgroundColor: '#fff',
//       // pointBorderWidth: 1,
//       // pointHoverRadius: 5,
//       pointHoverBackgroundColor: 'rgba(75,192,192,1)',
//       pointHoverBorderColor: 'rgba(220,220,220,1)',
//       pointHoverBorderWidth: 2,
//       pointRadius: 1,
//       pointHitRadius: 10,
//       data: [{ x: 10, y: 20, r: 5 }],
//     },
//   ],
// };

// export default function ImportanceOfWeekDayGraph() {
//   return (
//     <div>
//       <h2>Bubble Example</h2>
//       <Bubble data={data} />
//     </div>
//   );
// }
