import React from 'react';
import Datatable from 'components/molecules/datatable';
import * as Colors from 'styles/colors';
import { Box, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TranslatedText from 'components/atoms/translated_text';
import { useDispatch } from 'react-redux';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import * as StoresSelectors from 'redux/reducers/stores_reducer/selectors';
import StoreActions from 'redux/reducers/stores_reducer/actions';
import * as DateUtils from 'utils/dates';
import Avatar from '@material-ui/core/Avatar';
import Environment from 'config/environment';
import { IColumnProps } from 'components/molecules/datatable/configuration';
import { withRoles } from 'navigation/middlewares';
import { IStoreData } from 'services/api/stores';
import ModalAddStore, { IModalParams } from '../modal_add_edit_store';
import { Roles } from 'config/authRoles';
import SelectButton from 'components/molecules/select_button';
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
import { useHistory } from 'react-router';
import NavigationConfig from 'navigation/config';
import { TabType } from 'scenes/stores_page/configuration';
import ModalAddEditAlert, {
  IModalParams as IAddEditAlertModalParams,
} from '../modal_add_edit_alert';
import { useSelectUserRole } from 'redux/reducers/gl_auth_reducer/selectors';
import FilteringBox from './FilteringBox';
import * as Configuration from '../../configuration';

const { StoreListDefaultFiltering } = Configuration;

const UsersDatatable = () => {
  const theme = useTheme();
  const result = StoresSelectors.useGetStores();
  const dispatch = useDispatch();
  const sortField = React.useRef<string>(
    StoreListDefaultFiltering.DefaultSortField
  );
  const sortType = React.useRef<'asc' | 'desc'>(
    StoreListDefaultFiltering.DefaultOrder
  );

  const paging = {
    page: result.data?.paging.page || 0,
    pageSize: result.data?.paging.pageSize || 0,
    total: result.data?.paging.total || 0,
  };

  return (
    <div>
      <FilteringBox />
      <Datatable
        paging={paging}
        data={result.data?.items || []}
        sorting={{
          sortField: sortField.current,
          order: sortType.current,
        }}
        height={'70vh'}
        disablePaper={false}
        paperProps={{
          style: {
            borderRadius: 2,
            //   backgroundColor: Colors.APP_COLOR_PRIMARY(),
            overflow: 'hidden',
          },
          // square: false,
          variant: 'outlined',
        }}
        tableCellProps={() => ({
          style: {
            //   paddingTop: 0,
            //   paddingBottom: 0,
            borderLeft: 'none',
            borderRight: 'none',
          },
        })}
        tableCellMainRowProps={() => ({
          style: {
            paddingTop: '1rem',
            paddingBottom: '1rem',
            //   backgroundColor: Colors.APP_COLOR_DARK({
            //     darkMode: theme.palette.type === 'dark',
            //   }),
            //   color: 'white',
          },
        })}
        loading={false}
        columns={TableColumns}
        changePageHandle={(selectedPage, pageSize, order, _sortField) => {
          // 'asc' | 'desc'
          sortType.current = order === 'desc' ? 'asc' : 'desc';
          sortField.current = _sortField;
          dispatch(
            StoreActions.fetchStoresAction({
              page: selectedPage,
              pageSize,
              sortField: _sortField,
              order,
            })
          );
        }}
      />
    </div>
  );
};

interface IData {
  name: string;
  position: string;
  office: string;
  age: number;
  date: string;
  salary: number;
}

export const TableColumns: IColumnProps[] = [
  {
    label: '',
    id: 'imageName',
    align: 'right',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>
        {
          <Avatar
            style={{ height: 50, width: 50, alignSelf: 'center' }}
            src={Environment.STATIC_URL + `/${cellValue}`}
          />
        }
      </React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'UniqueId',
    id: 'uniqueId',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Name',
    id: 'name',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Group',
    id: 'group.name',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Cappacity',
    id: 'cappacity',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'State',
    id: 'state',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Date Registed',
    id: 'registerDate',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{DateUtils.formatDate(cellValue)}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Updated At',
    id: 'updatedAt',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{DateUtils.formatDate(cellValue)}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: '',
    id: 'actions',
    align: 'center',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: IStoreData[]
    ) => <ActionsGroup data={data[rowIndex]} />,
    inData: false,
  },
];

const ActionsGroup = withRoles(
  ({ data }: { data: IStoreData }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const role = useSelectUserRole();

    return (
      <div>
        <SelectButton
          items={[
            ...(role === Roles.Admin
              ? [
                  {
                    action: () => {
                      dispatch(
                        ModalActions.openModalAction({
                          modalID: ModalIDs.MODAL_ADD_STORE,
                          params: {
                            mode: 'edit',
                            storeid: data._id,
                            defaultValues: {
                              name: data.name,
                              storeid: data.uniqueId,
                              storeUsers: data.pairedUsers,
                              uploadedFile: data.imageName,
                              initialin: data.initialin,
                              cappacity: data.cappacity,
                              boxesNumber: data.boxes.length,
                              timezone: data.timezone,
                              group: data?.group?._id,
                            },
                          } as IModalParams,
                        })
                      );
                    },
                    id: 'editStore',
                    children: <TranslatedText defaultText="Edit Store" />,
                  },
                ]
              : []),
            ...(role === Roles.Admin
              ? [
                  {
                    action: () => {
                      dispatch(
                        ModalActions.openModalAction({
                          modalID: ModalIDs.MODAL_ADD_EDIT_ALERT,
                          params: {
                            mode: 'add',
                            storeid: data._id,
                          } as IAddEditAlertModalParams,
                        })
                      );
                    },
                    id: 'addAlert',
                    children: <TranslatedText defaultText="Add Alert" />,
                  },
                ]
              : []),
            {
              action: () => {
                history.push(
                  NavigationConfig.storePage({
                    type: TabType.Boxes,
                    storeid: data.uniqueId,
                  }).path
                );
              },
              id: 'boxes',
              children: <TranslatedText defaultText="Show Boxes" />,
            },
            {
              action: () => {
                history.push(
                  NavigationConfig.storePage({
                    type: TabType.Cameras,
                    storeid: data.uniqueId,
                  }).path
                );
              },
              id: 'cameras',
              children: <TranslatedText defaultText="Show Cameras" />,
            },
            {
              action: () => {
                history.push(
                  NavigationConfig.storePage({
                    type: TabType.Alerts,
                    storeid: data.uniqueId,
                  }).path
                );
              },
              id: 'alerts',
              children: <TranslatedText defaultText="Show Alerts" />,
            },
            ...(role === Roles.Admin
              ? [
                  {
                    action: () => {
                      dispatch(
                        StoreActions.removeStoreAction({
                          storeid: data._id,
                          onFinish: () =>
                            dispatch(StoreActions.fetchStoresAction()),
                        })
                      );
                    },
                    id: 'remove',
                    children: <TranslatedText defaultText="Remove" />,
                  },
                ]
              : []),
          ]}
          // action?: (open: boolean) => void;
          buttonProps={{
            variant: 'outlined',
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
          >
            <MoreHorizOutlinedIcon />
          </Box>
        </SelectButton>
      </div>
    );
  },
  [Roles.Admin]
);

export const DummyData: IData[] = Array.from({ length: 100 }, (_, index) => ({
  name: 'name_' + index,
  position: 'name_' + index,
  office: 'name_' + index,
  age: index + 4,
  date: 'name_' + index,
  salary: index * 1002,
}));

export default React.memo(UsersDatatable);
