import React from 'react';
import Datatable from 'components/molecules/datatable';
import * as Colors from 'styles/colors';
import { Box, Chip, IconButton, Tooltip, useTheme } from '@material-ui/core';
import TranslatedText from 'components/atoms/translated_text';
import { useDispatch } from 'react-redux';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import { IModalParams as IViewNotificationModalParams } from '../modal_view_notification';
import { IUserDataWithDetailsData } from 'services/api/user';
import { NotificationListDefaultFiltering } from '../../configuration';
import SelectButton from 'components/molecules/select_button';
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
import { INotificationData } from 'services/api/notification';
import NavigationConfig from 'navigation/config';
import useQuery from 'custom_hooks/useQuery';
import NotificationActions from 'redux/reducers/notification_reducer/actions';
import * as NotificationSelectors from 'redux/reducers/notification_reducer/selectors';
import FilteringBox from './FilteringBox';
import * as DateUtils from 'utils/dates';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { NotificationTypes } from 'config/notification';

const AlertsDatatable = () => {
  const sortField = React.useRef<string>(
    NotificationListDefaultFiltering.DefaultSortField
  );
  const sortType = React.useRef<'asc' | 'desc'>(
    NotificationListDefaultFiltering.DefaultOrder
  );
  const theme = useTheme();
  const result = NotificationSelectors.useNotificationList();
  const dispatch = useDispatch();
  const query = useQuery();
  const email = query.get('email');
  const type = query.get('type');
  const dateStart = query.get('dateStart');
  const dateEnd = query.get('dateEnd');

  const paging = {
    page: result.data?.paging.page || 0,
    pageSize: result.data?.paging.pageSize || 0,
    total: result.data?.paging.total || 0,
  };

  return (
    <Box>
      <FilteringBox />
      <Datatable
        paging={paging}
        data={result.data?.items || []}
        sorting={{
          sortField: sortField.current,
          order: sortType.current,
        }}
        height={'70vh'}
        disablePaper={false}
        paperProps={{
          style: {
            borderRadius: 2,
            //   backgroundColor: Colors.APP_COLOR_PRIMARY(),
            overflow: 'hidden',
          },
          // square: false,
          variant: 'outlined',
        }}
        tableCellProps={() => ({
          style: {
            //   paddingTop: 0,
            //   paddingBottom: 0,
            borderLeft: 'none',
            borderRight: 'none',
          },
        })}
        tableCellMainRowProps={() => ({
          style: {
            paddingTop: '1rem',
            paddingBottom: '1rem',
            //   backgroundColor: Colors.APP_COLOR_DARK({
            //     darkMode: theme.palette.type === 'dark',
            //   }),
            //   color: 'white',
          },
        })}
        loading={false}
        columns={TableColumns}
        changePageHandle={(selectedPage, pageSize, order, _sortField) => {
          // 'asc' | 'desc'
          sortType.current = order === 'desc' ? 'asc' : 'desc';
          sortField.current = _sortField;
          dispatch(
            NotificationActions.fetchNotificationsAction({
              page: selectedPage,
              pageSize: pageSize,
              sortField: _sortField,
              order,
              userEmail: email as string,
              notificationType: type as NotificationTypes,
              dateEnd: dateEnd as string,
              dateStart: dateStart as string,
            })
          );
        }}
      />
    </Box>
  );
};

interface IData {
  name: string;
  position: string;
  office: string;
  age: number;
  date: string;
  salary: number;
}

export const TableColumns = [
  {
    label: 'Id',
    id: '_id',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Receiver',
    id: 'receiverid.email',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Title',
    id: 'title',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Created At',
    id: 'createdAt',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{DateUtils.formatDate(cellValue)}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Read Date',
    id: 'readDate',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>
        {cellValue && DateUtils.formatDate(cellValue)}
      </React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Read',
    id: 'read',
    align: 'left',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: INotificationData['items']
    ) => <Read read={cellValue} notificationid={data[rowIndex]._id} />,
    inData: true,
  },
  {
    label: 'Type',
    id: 'type',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: '',
    id: 'actions',
    align: 'left',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: INotificationData['items']
    ) => <TableActions data={data} rowIndex={rowIndex} />,
    inData: false,
  },
];

const Read = ({
  read,
  notificationid,
}: {
  read: boolean;
  notificationid: string;
}) => {
  const dispatch = useDispatch();
  return (
    <Tooltip title={read ? 'Click to unread' : 'Mark as read'}>
      <IconButton
        onClick={() =>
          dispatch(
            NotificationActions.readUnReadNotificationAction({
              read: !read,
              notificationid,
              onFinish: () => {
                dispatch(
                  NotificationActions.fetchNotificationsAction({
                    page: NotificationListDefaultFiltering.DefaultPage,
                    pageSize: NotificationListDefaultFiltering.DefaultPageSize,
                    sortField:
                      NotificationListDefaultFiltering.DefaultSortField,
                    order: NotificationListDefaultFiltering.DefaultOrder,
                  })
                );
              },
            })
          )
        }
      >
        {read === true ? <VisibilityIcon /> : <VisibilityOffIcon />}
      </IconButton>
    </Tooltip>
  );
};

const TableActions = React.memo(
  ({
    rowIndex,
    data,
  }: {
    rowIndex: number;
    data: INotificationData['items'];
  }) => {
    const dispatch = useDispatch();

    return (
      <div>
        <Tooltip title="Open">
          <IconButton
            onClick={() =>
              dispatch(
                ModalActions.openModalAction({
                  modalID: ModalIDs.MODAL_VIEW_NOTIFICATION,
                  params: {
                    defaultValues: {
                      description: data[rowIndex].description,
                      title: data[rowIndex].title,
                    },
                  } as IViewNotificationModalParams,
                })
              )
            }
          >
            <OpenInNewIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  }
);

export default React.memo(AlertsDatatable);
