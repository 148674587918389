import { IAction, IActionMethods, StateStatus } from '../../utils/common';
import * as Types from './types';
import * as UserService from 'services/api/user';
import * as AnalyticsService from 'services/api/analytics';
import { Dispatch } from 'redux';
import reactLogger from 'utils/logger';
import { DaysEnum, MonthsEnum, RegionsEnum } from 'config';

/** Fetch Visitors By Day  */

interface ISetDayInput {
  day: Date;
}

class SetDay implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_DAY,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: ISetDayInput): IAction {
    return {
      type: Types.SET_DAY,
      status: StateStatus.Success,
      data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_DAY,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(input: ISetDayInput): IAction {
    return this.onSuccess(input);
  }
}

export default {
  setDayAction: (input: ISetDayInput) => new SetDay().action(input),
};
