import * as Types from './types';
import * as RegisterService from '../../../services/api/register';
import { Dispatch } from 'redux';
import NavigationConfig from '../../../navigation/config';
import {
  IAction,
  IActionMethods,
  StateStatus,
} from '../../../redux/utils/common';
import * as UserService from '../../../services/api/user';
import { MonthsEnum } from 'config/months';
import reactLogger from 'utils/logger';

// /** Fetch Stores  */
// class FetchStores implements IActionMethods {
//   onPending(): IAction {
//     return {
//       type: Types.SET_STORES,
//       status: StateStatus.Pending,
//       data: {},
//     };
//   }
//   onSuccess(data: any): IAction {
//     return {
//       type: Types.SET_STORES,
//       status: StateStatus.Success,
//       data: data,
//     };
//   }

//   onFailed(): IAction {
//     return {
//       type: Types.SET_STORES,
//       status: StateStatus.Failed,
//       data: {},
//     };
//   }

//   action(): any {
//     return async (dispatch: Dispatch<any>) => {
//       try {
//         dispatch(this.onPending());
//         let response = await UserService.getStores();
//         dispatch(this.onSuccess(response.data));
//       } catch (error) {
//         reactLogger.error('FetchStores Error:', error.message);
//         // console.log('FetchStores Error:', error.message); // '<ClassName> Error: <error>'
//         dispatch(this.onFailed());
//       }
//     };
//   }
// }

/** Fetch Select Month  */
interface ISelectMonth {
  selectedMonth: MonthsEnum;
}
class SelectMonth implements IActionMethods {
  onPending(): IAction {
    throw new Error('Method not implemented.');
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_SELECTED_MONTH,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_SELECTED_MONTH,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(data: ISelectMonth): IAction {
    try {
      return this.onSuccess(data.selectedMonth);
    } catch (error) {
      reactLogger.error('SelectMonth Error:', error.message);
      return this.onFailed();
    }
  }
}

// /** Set Selected Store */
// interface ISetSelectedStoreInput {
//   storeid: string;
// }
// class SelectStore implements IActionMethods {
//   onPending(): IAction {
//     throw new Error('Not Implemented');
//   }
//   onSuccess(data: any): IAction {
//     return {
//       type: Types.SET_SELECTED_STORE,
//       status: StateStatus.Success,
//       data: data,
//     };
//   }

//   onFailed(): IAction {
//     return {
//       type: Types.SET_SELECTED_STORE,
//       status: StateStatus.Failed,
//       data: null,
//     };
//   }

//   action(data: ISetSelectedStoreInput): any {
//     try {
//       return this.onSuccess(data.storeid);
//     } catch (error) {
//       reactLogger.error('SelectStore Error:', error.message);
//       return this.onFailed();
//     }
//   }
// }

export default {
  // setSelectedStore: (data: ISetSelectedStoreInput) =>
  //   new SelectStore().action(data),
  selectMonthAction: (payload: ISelectMonth) =>
    new SelectMonth().action(payload),
  // fetchStores: () => new FetchStores().action(),
};
