import React, { ReactElement } from 'react';
import { CustomChart } from 'components/molecules/custom_chart';
import * as LocalConfiguration from './configuration';
import TranslatedText from 'components/atoms/translated_text';
import { ChartTypesEnum } from 'components/molecules/custom_chart/configuration';
import * as VisistorAnalyticsSelectors from 'redux/reducers/visitorAnalytics_reducer/selectors';
import CardLabelTitle from 'components/app_common/card_label_title';
import * as DateUtils from 'utils/dates';

export default function VisitorsPerHourGraph() {
  const data = VisistorAnalyticsSelectors.useGetVisitorListPerHour();

  return (
    <React.Fragment>
      {data && (
        <CustomChart
          height={'38vh'}
          title={
            <CardLabelTitle
              title={<TranslatedText defaultText="Visitors per Hour" />}
            />
          }
          layoutProps={{
            variant: 'outlined',
          }}
          data={LocalConfiguration.DummyDataset(data?.datasets || [])}
          labels={(data?.labels || []).map((hour) =>
            DateUtils.numberToHourFormat(
              hour
              //  - new Date().getTimezoneOffset() / 60
            )
          )}
          options={LocalConfiguration.DummyChartOptions}
          chartType={ChartTypesEnum.COLUMN}
        />
      )}
    </React.Fragment>
  );
}
