import { IAction, IActionMethods, StateStatus } from '../../utils/common';
import * as Types from './types';
import * as AuthenticationService from '../../../services/api/authenticate';
import { Dispatch } from 'redux';
import { showToastAction } from '../../../components/atoms/toast_message';
import { LanguagesEnum } from '../../../config/languages';
import NavigationConfig from '../../../navigation/config';

/** Forgot Password  */

interface IForgotPasswordInput {
  email: string;
  history: any;
}
class ForgotPassword implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.FORGOT_PASSOWRD,
      data: {
        status: StateStatus.Pending,
      },
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.FORGOT_PASSOWRD,
      data: {
        status: StateStatus.Success,
      },
    };
  }

  onFailed(): IAction {
    return {
      type: Types.FORGOT_PASSOWRD,
      data: {
        status: StateStatus.Failed,
      },
    };
  }

  action(data: IForgotPasswordInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        await AuthenticationService.forgotPassword({
          email: data.email,
        });
        dispatch(this.onSuccess());
        data.history.push(NavigationConfig.loginPage().path);
      } catch (error) {
        console.log('ForgotPassword Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

interface IResetPasswordInput {
  password: string;
  confirmPassword: string;
  token: string;
  history: any;
}

class ResetPassword implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.RESET_PASSWORD,
      data: {
        status: StateStatus.Pending,
      },
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.RESET_PASSWORD,
      data: {
        status: StateStatus.Success,
      },
    };
  }

  onFailed(): IAction {
    return {
      type: Types.RESET_PASSWORD,
      data: {
        status: StateStatus.Failed,
      },
    };
  }

  action(data: IResetPasswordInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        await AuthenticationService.resetPassword({
          password: data.password,
          confirm_password: data.confirmPassword,
          token: data.token,
        });
        dispatch(this.onSuccess());
        data.history.push(NavigationConfig.loginPage().path);
      } catch (error) {
        console.log('ResetPassword Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  forgotPasswordAction: (email: string, history: any) =>
    new ForgotPassword().action({ email, history }),
  resetPasswordAction: (
    password: string,
    confirmPassword: string,
    token: string,
    history: any
  ) =>
    new ResetPassword().action({ password, confirmPassword, token, history }),
};
