import { IAction, StateStatus } from '../../utils/common';
import * as Types from './types';

interface IState {
  changePasswordStatus: boolean | null;
}

const initialState: IState = {
  changePasswordStatus: null,
};

const ResetPasswordReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    // CHANGE_PASSOWRD
    case Types.CHANGE_PASSWORD:
      return {
        ...state,
        changePasswordStatus: action.data.status,
      };
    default:
      return {
        ...state,
      };
  }
};

export default ResetPasswordReducer;
