import { useSelector } from 'react-redux';
import { ReducerKeys } from '../../config';
import useShallowEqualSelector from 'custom_hooks/useShallowEqualsSelector';
import _ from 'lodash';
import { StoresDataType } from 'services/api/user';
import { IState } from './reducer';

/* selector: useGetStores */
export const useGetStores = (): StoresDataType => {
  const data = useSelector(
    (state: any) =>
      (state[ReducerKeys.APP_COMMON_REDUCER] as IState).stores.data
  );
  return Array.isArray(data) ? data : [];
};

/* selector: useGetStores */
export const useGetSelectedStore = () => {
  return useSelector(
    (state: any) =>
      (state[ReducerKeys.APP_COMMON_REDUCER] as IState).selectedStore
  );
};

/* selector: useGetStores */
export const useGetStoreCameras = () => {
  const data = useSelector(
    (state: any) =>
      (state[ReducerKeys.APP_COMMON_REDUCER] as IState).cameras.data
  );
  return Array.isArray(data) ? data : [];
};
