//  [REDUCER][TYPE]
import { typeCreatorV2 } from '../../utils/common';
// export const SET_STORES: string = typeCreatorV2(
//   'StoresHeatmapCountReducer',
//   'FetchStores'
// );
export const SET_STORE_CAMERAS: string = typeCreatorV2(
  'StoresHeatmapCountReducer',
  'FetchStoreCameras'
);
export const SET_HEATMAP_COUNT: string = typeCreatorV2(
  'StoresHeatmapCountReducer',
  'FetchHeatmapCount'
);
export const SET_SELECTED_STORE: string = typeCreatorV2(
  'StoresHeatmapCountReducer',
  'SetSelectedStore'
);
export const SET_HEATMAP_TYPE: string = typeCreatorV2(
  'StoresHeatmapCountReducer',
  'SetHeatmapType'
);
