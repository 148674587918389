import React from 'react';
import { ICustomChartProps } from 'components/molecules/custom_chart';
import CustomChartWrapper from './wrappers/CustomChartWrapper';
import StatisticCardLabel, {
  IStatisticsLabelProps,
} from 'components/app_common/statistics_card_label';
import { ApexChart, IApexChartProps } from 'components/molecules/custom_chart';
// import {
//   GeoJsonMap,
//   ILeafletMapProps,
//   geoJsonMapDefaultParser,
// } from 'components/molecules/leaflet_map';

import _ from 'lodash';
import request from 'utils/request';
import { Environment } from 'config';

export interface IChartsFetchData {
  labels: string[];
  data: number[][] | Array<any>;
}

export interface ILabelsFetchData {
  title: string;
  description: string;
}

export interface IApexChartsFetchData {
  labels: string[];
  data: Array<any>;
}

// export interface IGeoJsonFetchData {
//   data: any;
// }

export interface IGridConf {
  width: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  widthMed?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  type: ComponentTypeEnum;
  fetch:
    | null
    | string
    | (() => Promise<
        IChartsFetchData | ILabelsFetchData | IApexChartsFetchData | null
      >);
  props: ICustomChartProps | IStatisticsLabelProps | IApexChartProps;
}

export enum ComponentTypeEnum {
  statistic,
  chart,
  apexChart,
}

// interface ChartParserConfig {
//   randomColorsEachElement: boolean;
// }

interface IComponentMap {
  [ComponentTypeEnum.chart]: {
    component: React.FC<ICustomChartProps>;
    parser: (
      props: ICustomChartProps,
      apiData: IChartsFetchData
      // chartsconfig?: ChartParserConfig
    ) => ICustomChartProps;
  };
  [ComponentTypeEnum.statistic]: {
    component: React.FC<IStatisticsLabelProps>;
    parser: (
      props: IStatisticsLabelProps,
      apiData: ILabelsFetchData
    ) => IStatisticsLabelProps;
  };
  [ComponentTypeEnum.apexChart]: {
    component: React.FC<IApexChartProps>;
    parser: (
      props: IApexChartProps,
      apiData: IApexChartsFetchData
    ) => IApexChartProps;
  };
}

export const ComponentsMap: IComponentMap = {
  [ComponentTypeEnum.chart]: {
    component: CustomChartWrapper,
    parser: (props, apiData) => {
      for (let index in props.data) {
        props.data[index].data = apiData?.data[index] || props.data[index].data;
      }
      props.labels = apiData?.labels || props.labels;
      return props;
    },
  },
  [ComponentTypeEnum.statistic]: {
    component: StatisticCardLabel,
    parser: (props, apiData) => {
      props.description = apiData?.description || props.description;
      props.title = apiData?.title || props.title;
      return props;
    },
  },
  [ComponentTypeEnum.apexChart]: {
    component: ApexChart,
    parser: (props, apiData) => {
      props.series = apiData?.data || props.series || [];
      // // solve issue https://github.com/apexcharts/react-apexcharts/issues/46
      props.options = {
        ...props.options,
        xaxis: {
          ...(props.options?.xaxis || {}),
          categories: apiData?.labels || props.options?.xaxis?.categories || [],
        },
      };
      return props;
    },
  },
};

export const fetchData = async (path: string) => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}${path}`
  );
  let output = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output.data;
};
