import React from 'react';
import { Menu, MenuProps } from '@material-ui/core';

const FormattedMenu = React.memo((props: MenuProps) => {
  return (
    <Menu
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      disableScrollLock
      getContentAnchorEl={null}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        square: true,
        elevation: 0,
        variant: 'outlined',
      }}
      {...props}
    />
  );
});

export default FormattedMenu;
