import TranslatedText from 'components/atoms/translated_text';
import { AppSelect } from 'components/molecules/form_controls';
import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import * as RandomUtils from 'utils/randomize';

interface IFormData {
  selectedWeek: string;
}

// options: {
//   chart: {
//     height: 350,
//     type: 'heatmap',
//   },
//   plotOptions: {
//     heatmap: {
//       shadeIntensity: 0.5,
//       radius: 0,
//       useFillColorAsStroke: true,
//       colorScale: {
//         ranges: [{
//             from: -30,
//             to: 5,
//             name: 'low',
//             color: '#00A100'
//           },
//           {
//             from: 6,
//             to: 20,
//             name: 'medium',
//             color: '#128FD9'
//           },
//           {
//             from: 21,
//             to: 45,
//             name: 'high',
//             color: '#FFB200'
//           },
//           {
//             from: 46,
//             to: 55,
//             name: 'extreme',
//             color: '#FF0000'
//           }
//         ]
//       }
//     }
//   },
//   dataLabels: {
//     enabled: false
//   },
//   stroke: {
//     width: 1
//   },
//   title: {
//     text: 'HeatMap Chart with Color Range'
//   },
// },


const WeekSelector = () => {
  const {
    setValue,
    watch,
    handleSubmit,
    errors,
    control,
    getValues,
  } = useForm<IFormData>({
    defaultValues: {
      selectedWeek: '1',
    },
  });
  return (
    <AppSelect
      margin="none"
      fullWidth
      name={'selectedWeek'}
      style={{ marginTop: 0, marginBottom: 0 }}
      control={control}
      rules={{}}
      size="small"
      autoComplete={'selectedWeek'}
      variant="outlined"
      options={[
        {
          id: '1',
          label: '10',
        },
        {
          id: '2',
          label: '11',
        },
        {
          id: '3',
          label: '12',
        },
        {
          id: '4',
          label: '13',
        },
        {
          id: '5',
          label: '14',
        },
        {
          id: '6',
          label: '15',
        },
      ]}
    />
  );
};

const DaysMap: any = {
  0: <TranslatedText defaultText="Monday" />,
  1: <TranslatedText defaultText="Tuesday" />,
  2: <TranslatedText defaultText="Wednesday" />,
  3: <TranslatedText defaultText="Thursday" />,
  4: <TranslatedText defaultText="Friday" />,
  5: <TranslatedText defaultText="Saturday" />,
  6: <TranslatedText defaultText="Sunday" />,
};

const generateColumns = (hourStart = 6, hourEnd = 22) => {
  const list = [
    {
      label: <WeekSelector />,
      id: 'day',
      inData: false,
      render: (rowIndex: number, cellValue: any, columnIndex: number) => (
        <React.Fragment>
          {`${rowIndex}- `}
          {DaysMap[rowIndex]}
        </React.Fragment>
      ),
    },
  ];
  for (let i = hourStart; i <= hourEnd; i++) {
    list.push({
      label: <React.Fragment>{`${i}`}</React.Fragment>,
      id: `${i}`,
      inData: true,
      render: (rowIndex: number, cellValue: any, columnIndex: number) => (
        <React.Fragment>{cellValue}</React.Fragment>
      ),
    });
  }
  return list;
};

export const TableColumns = generateColumns();

const generateData = () => {
  const columns = TableColumns;
  const rows = 7;
  let data = [];
  for (let i = 0; i < rows; i++) {
    let element: any = {};
    for (let column of columns) {
      element[column.id] = RandomUtils.generateRandomNumber(5, 600);
    }
    data.push(element);
  }
  return data;
};

export const TableDummyData: any[] = generateData();
