import React from 'react';
import { AppSelect, AppDatePicker } from 'components/molecules/form_controls';
import { useForm } from 'react-hook-form';
import useStyles from './styles';
import { Grid, Typography } from '@material-ui/core';
import TranslatedText from 'components/atoms/translated_text';
import StoreComparisonActions from 'redux/reducers/storeComparison_reducer/actions';
import reactLogger from 'utils/logger';
import { useDispatch } from 'react-redux';
import * as StoreComparisonSelectors from 'redux/reducers/storeComparison_reducer/selectors';
import { DaysRangeConfig, DaysRangeEnum, Months, MonthsEnum } from 'config';
import { formDefaultValues } from 'redux/reducers/storeComparison_reducer/selectors';
import StoresCommonActions from 'redux/reducers/gl_app_common/actions';
import * as StoresCommonSelectors from 'redux/reducers/gl_app_common/selectors';

interface IFormData {
  selectedStore1: string | null;
  selectedStore2: string | null;
  selectedDateRange: MonthsEnum | null;
}

const defaultValues: IFormData = {
  selectedStore1: formDefaultValues.store1,
  selectedStore2: formDefaultValues.store2,
  selectedDateRange: formDefaultValues.selectedMonth as any,
};

export default function SelectForm() {
  reactLogger.renderComponent('SelectForm');
  const classes = useStyles();
  const { setValue, watch, handleSubmit, errors, control, getValues } =
    useForm<IFormData>({
      mode: 'onChange',
      defaultValues: defaultValues,
    });
  const dispatch = useDispatch();

  const handleValueChange = (
    value: string | null,
    type: 'store1' | 'store2' | 'selectedMonth'
  ) => {
    if (value) {
      dispatch(StoreComparisonActions.setFormValuesAction({ [type]: value }));
      if (type === 'store1') {
        dispatch(StoresCommonActions.setSelectedStoreAction(value));
      }
    }
  };

  React.useEffect(() => {
    dispatch(
      StoreComparisonActions.setFormValuesAction({
        ...(defaultValues.selectedStore1
          ? { store1: defaultValues.selectedStore1 }
          : {}),
        ...(defaultValues.selectedStore1
          ? { store1: defaultValues.selectedStore1 }
          : {}),
        ...(defaultValues.selectedDateRange
          ? { selectedMonth: defaultValues.selectedDateRange as any }
          : {}),
      })
    );
  }, [dispatch]);

  return (
    <form className={classes.form} noValidate>
      <Grid spacing={1} container justify={'space-between'}>
        <Grid
          alignItems="flex-end"
          justify="flex-start"
          spacing={1}
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={12}
          item
          container
        >
          <Grid sm={5} xs={12} item>
            <div>
              <Typography>
                <TranslatedText defaultText={'Store 1'} />
              </Typography>
              <SelectStoresWrapper
                name="selectedStore1"
                setValue={setValue}
                handleChange={(value) => handleValueChange(value, 'store1')}
                control={control}
              />
            </div>
          </Grid>
          <Grid sm={5} xs={12} item>
            <div>
              <Typography>
                <TranslatedText defaultText={'Store 2'} />
              </Typography>
              <SelectStoresWrapper
                name="selectedStore2"
                setValue={setValue}
                handleChange={(value) => handleValueChange(value, 'store2')}
                control={control}
              />
            </div>
          </Grid>
        </Grid>

        <Grid xl={3} lg={3} md={4} sm={5} xs={12} item>
          <div>
            <Typography component={'h4'}>
              <TranslatedText defaultText={'Select Month'} />
            </Typography>
            <AppSelect
              margin="none"
              fullWidth
              name={'selectedDateRange'}
              style={{ marginTop: 0, marginBottom: 0 }}
              control={control}
              rules={{}}
              handleChange={(value) =>
                handleValueChange(value, 'selectedMonth')
              }
              size="small"
              autoComplete={'selectedDateRange'}
              variant="outlined"
              options={Months}
            />
          </div>
        </Grid>
      </Grid>
    </form>
  );
}

interface IPropsSelectStores {
  control: any;
  setValue: any;
  name: string;
  handleChange: (value: any) => void;
}

const SelectStoresWrapper = (props: IPropsSelectStores) => {
  const stores = StoresCommonSelectors.useGetStores();
  const selectedStore = StoresCommonSelectors.useGetSelectedStore();

  React.useEffect(() => {
    if (stores.length > 0) {
      const storeid =
        props.name === 'selectedStore1'
          ? selectedStore || stores[0].uniqueId
          : stores[0].uniqueId;
      props.setValue(props.name, storeid);
      props.handleChange(storeid);
    }
  }, [stores, selectedStore]);

  return (
    <AppSelect
      margin="none"
      fullWidth
      style={{ marginTop: 0, marginBottom: 0 }}
      name={props.name}
      control={props.control}
      handleChange={props.handleChange}
      rules={{}}
      size="small"
      autoComplete={props.name}
      variant="outlined"
      options={stores.map(({ uniqueId, name }) => ({
        label: name,
        id: uniqueId,
      }))}
    />
  );
};
