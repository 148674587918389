import { useSelector } from 'react-redux';
import { ReducerKeys } from '../../config';
import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import _ from 'lodash';
import { DaysRangeEnum } from 'config';
import { getState } from 'redux/store';
import { StoresDataType } from 'services/api/user';
import { IState } from './reducer';

// /* selector: useGetStores */
// interface IStoresOutput {
//   id: string;
//   code: string;
//   status: string;
// }

// export const useGetStores = (): StoresDataType => {
//   const data = useSelector(
//     (state: any) => state[ReducerKeys.VISITOR_ANALYTICS_REDUCER].stores.data
//   );
//   return Array.isArray(data) ? data : [];
// };

/* selector: useGetDateRangeType */
export const useGetDateRangeType = (): DaysRangeEnum => {
  return useSelector(
    (state: any) => state[ReducerKeys.VISITOR_ANALYTICS_REDUCER].daysRangeType
  );
};

/* getter: getDateRangeType */
export const getDateRangeType = (): DaysRangeEnum => {
  return getState(ReducerKeys.VISITOR_ANALYTICS_REDUCER).daysRangeType;
};

/* selector: useGetVisitorsPreviousDays */
interface IVisitorsPreviousDaysOutput {
  dataCurrent: {
    startDate: string;
    lastDay: string;
    visitorsNumber: number;
  };
  dataPrevious: {
    startDate: string;
    lastDay: string;
    visitorsNumber: number;
  };
  increasingPersentage: number;
}

export const useGetVisitorsPreviousDays = (): IVisitorsPreviousDaysOutput | null => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.VISITOR_ANALYTICS_REDUCER].visitorsLastDays.data
  );
  return data;
};

/* selector: useGetVisitorListByDate */
interface IVisitorListByDateOutput {
  labels: string[];
  data: number[];
}

export const useGetVisitorListByDate = (): IVisitorListByDateOutput | null => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.VISITOR_ANALYTICS_REDUCER].chart_visitorsByDate.data
  );
  return data;
};

/* selector: useGetVisitorListPerHour */
interface IVisitorListPerHourOutput {
  labels: number[];
  datasets: Array<{
    name: string;
    list: number[];
  }>;
}

export const useGetVisitorListPerHour = (): IVisitorListPerHourOutput | null => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.VISITOR_ANALYTICS_REDUCER].chart_visitorsPerHour.data
  );
  return data;
};

/* selector: useGetAverageHourlyVisitors  */
export const useGetAverageHourlyVisitors = (): number | null => {
  const data = useSelector(
    (state: any) =>
      state[ReducerKeys.VISITOR_ANALYTICS_REDUCER].averageHourlyVisitors.data
        ?.count
  );
  return data;
};

/* selector: useGetAvarageShppingTime */
export const useGetAvarageShppingTime = (): number | null => {
  const data = useSelector(
    (state: any) =>
      state[ReducerKeys.VISITOR_ANALYTICS_REDUCER].avarageShppingTime.data
        ?.count
  );
  return data;
};

/* selector: useGetVisitorsPerMonth */
interface IVisitorsPerMonthOutput {
  datasetVisitors: number[];
  datasetPerc: number[];
}

export const useGetVisitorsPerMonth = (): IVisitorsPerMonthOutput | null => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.VISITOR_ANALYTICS_REDUCER].chart_visitorsByMonth.data
  );
  return data;
};

// declare shallow equal selector: https://unpkg.com/browse/gm-react-hanger@2.0.0/useShallowEqualSelector.d.ts

/** useGetVisitorsPerStoreTableData */
export const useGetVisitorsPerStoreTableData = (): IState['visitorsPerStore']['data'] => {
  let result = useShallowEqualSelector(
    (state: any) =>
      (state[ReducerKeys.VISITOR_ANALYTICS_REDUCER] as IState).visitorsPerStore
        .data
  );
  return result;
};
