import { useSelector } from 'react-redux';
import { ReducerKeys } from '../../config';
import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { StateStatus } from '../../utils/common';
import { getState } from 'redux/store';
import { MonthsEnum } from 'config/months';
import _ from 'lodash';
import * as FormatNumberUtils from 'utils/format_number';
import { StoresDataType } from 'services/api/user';

// /* selector: useGetStores */

// export const useGetStores = (): StoresDataType => {
//   const data = useSelector(
//     (state: any) => state[ReducerKeys.STORE_PERFORMANCE_REDUCER].stores.data
//   );
//   return Array.isArray(data) ? data : [];
// };

/* selector: useGetStoreStatistics */
interface IStoresStatisticsOutput {
  visitors_last_thrd_days: number;
  perc_change_from_prev_thrd_days: number;
  total_visitors_curr_month: number;
  perc_same_per_last_month: number;
}

export const useGetStoreStatistics = (): IStoresStatisticsOutput | null => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.STORE_PERFORMANCE_REDUCER].storeStatistics.data
  );
  return data;
};

/* selector: useGetCurrentVsPreviousWeekChartData */
interface ICurrentVsPreviousWeekChartOutput {
  persList: number[];
  listCurrent: number[];
  dates: string[];
}

export const useGetCurrentVsPreviousWeekChartData = (): ICurrentVsPreviousWeekChartOutput | null => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.STORE_PERFORMANCE_REDUCER].chart_currentVsPreviousWeek
        .data
  );
  return data;
};

/* selector: useGetVisitorsCurrentMonth */
interface IVisitorsCurrentMonthOutput {
  dataCurrent: {
    startDate: string;
    lastDay: string;
    visitorsNumber: number;
  };
  dataPrevious: {
    startDate: string;
    lastDay: string;
    visitorsNumber: number;
  };
  increasingPersentage: number;
}

export const useGetVisitorsCurrentMonth = (): IVisitorsCurrentMonthOutput | null => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.STORE_PERFORMANCE_REDUCER].visitorsCurrentMonth.data
  );
  return data;
};

/* selector: useGetVisitorsPreviousDays */
interface IVisitorsPreviousDaysOutput {
  dataCurrent: {
    startDate: string;
    lastDay: string;
    visitorsNumber: number;
  };
  dataPrevious: {
    startDate: string;
    lastDay: string;
    visitorsNumber: number;
  };
  increasingPersentage: number;
}

export const useGetVisitorsPreviousDays = (): IVisitorsPreviousDaysOutput | null => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.STORE_PERFORMANCE_REDUCER].visitorsLastDays.data
  );
  return data;
};

/* getFunction : getGetCurrentMonth */
export const getGetCurrentMonth = (): MonthsEnum =>
  getState(ReducerKeys.STORE_PERFORMANCE_REDUCER).selectedMonth;

/* selector: useGetCurrentMonth */
export const useGetCurrentMonth = (): MonthsEnum => {
  const data = useSelector(
    (state: any) => state[ReducerKeys.STORE_PERFORMANCE_REDUCER].selectedMonth
  );
  return data;
};

/* selector: useGetVisitorsByDayOfWeekPerHour */
interface IGetVisitorsByDayOfWeekPerHourOutput {
  datasets: Array<number[]>;
  hours: string[];
  weeksNumberList: number[];
}

export const useGetVisitorsByDayOfWeekPerHour = (): IGetVisitorsByDayOfWeekPerHourOutput => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.STORE_PERFORMANCE_REDUCER]
        .chart_visitorsByDayOfWeekPerHour.data
  );
  let datasets = _.get(data, 'datasets', []);
  let hours = _.get(data, 'hours', []);
  let weeksNumberList = _.get(data, 'weeksNumberList', []);
  return { datasets, hours, weeksNumberList };
};

/* selector: useGetImportanceOfWeekDayOfSelectedMonth */
interface IImportanceOfWeekDayOfSelectedMonthOutput {
  datasets: Array<number[]>;
  weeksNumberList: number[];
}

export const useGetImportanceOfWeekDayOfSelectedMonth = (): IImportanceOfWeekDayOfSelectedMonthOutput => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.STORE_PERFORMANCE_REDUCER]
        .chart_importanceOfWeekDayOfSelectedMonth.data
  );
  let datasets = _.get(data, 'datasets', []);
  let weeksNumberList = _.get(data, 'weeksNumberList', []);
  return { datasets, weeksNumberList };
};

/* selector: useGetImportanceOfWeekDayOfSelectedMonth */
interface IVisitorsByTimeGroupOutput {
  labels: string[];
  data: number[];
}

export const useGetVisitorsByTimeGroup = (): IVisitorsByTimeGroupOutput => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.STORE_PERFORMANCE_REDUCER].chart_visitorsByTimeGroup
        .data
  );
  const _data = [
    _.get(data, '6-10', 0),
    _.get(data, '10-14', 0),
    _.get(data, '14-18', 0),
    _.get(data, '18-22', 0),
  ];
  const labels = [
    `06-10 (${FormatNumberUtils.formatNumberDigits(_data[0])}%)`,
    `10-14 (${FormatNumberUtils.formatNumberDigits(_data[1])}%)`,
    `14-18 (${FormatNumberUtils.formatNumberDigits(_data[2])}%)`,
    `18-22 (${FormatNumberUtils.formatNumberDigits(_data[3])}%)`,
  ];
  return { labels, data: _data };
};

/* selector: useGetvisitorsPickByHourPerWeekday */

interface IGetVisitorsPickByHourPerWeekdayOutput {
  x: number;
  y: string;
  r: number;
}

export const useGetVisitorsPickByHourPerWeekday = (): IGetVisitorsPickByHourPerWeekdayOutput[] => {
  const data = useShallowEqualSelector(
    (state: any) =>
      state[ReducerKeys.STORE_PERFORMANCE_REDUCER]
        .chart_visitorsPickByHourPerWeekday.data
  );
  return data || [];
};

// /* selector: useGetSelectedStore */
// export const useGetSelectedStore = (): string | null => {
//   const data = useSelector(
//     (state: any) => state[ReducerKeys.STORE_PERFORMANCE_REDUCER].selectedStore
//   );
//   return data;
// };

// export const getStoreSelectedCamera = () => {
//   const
// }
