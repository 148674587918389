import { colors, Theme } from '@material-ui/core';

export const DummyLabels = [
  '27/11/2020',
  '28/11/2020',
  '29/11/2020',
  '30/11/2020',
  '01/12/2020',
  '02/12/2020',
];

export const DummyDataset = (data: number[] = [11, -7, 12, 29, -3, 5, -3]) => [
  {
    // backgroundColor: colors.grey[200],
    data: data,
    label: 'Last year',
    format: {
      label: 'Visitors',
      fill: false,
      lineTension: 0.1, // make the lines without radius
      borderColor: '#5C80CC',
      backgroundColor: '#5C80CC',
    },
  },
];

export const DummyChartOptions = (theme: Theme): any => ({
  legend: {
    display: false,
    align: 'start',
    labels: {
      usePointStyle: true,
    },
  },
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      display: false,
    },
  },
  responsive: true,
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: true,
        },
      },
    ],
  },
  tooltips: {
    enabled: true,
  },
});
