import React from 'react';
import { LanguagesEnum } from 'config/languages';
import { MonthsEnum } from 'config';
import { ChartTypesEnum } from 'components/molecules/custom_chart';
import {
  IGridConf,
  ComponentTypeEnum,
} from 'components/compounds/dashboard_builder/configuration';
import * as NumberFormattingUtils from 'utils/format_number';
import * as AnalyticsService from 'services/api/analytics';
import { Theme } from '@material-ui/core';
import CardLabelTitle from 'components/app_common/card_label_title';
import * as RandomDataUtils from 'utils/randomize';
import { IVisitorsByDayOutput } from 'redux/reducers/visitorsByDay_reducer/selectors';

const OptionsGraphType1 = (): any => ({
  legend: {
    display: false,
    align: 'start',
    position: 'bottom',
    labels: {
      usePointStyle: true,
    },
  },
  maintainAspectRatio: false,
  tooltips: {
    enabled: true,
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
  responsive: true,
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          display: true,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          display: true,
          beginAtZero: true,
          // suggestedMax: max || 70000,
          callback: function (label: any, index: any, labels: any) {
            return `${NumberFormattingUtils.formatNumberWithCommas(label)}`;
          },
        },
      },
    ],
  },
});

const createDatasetElement = (
  data: number[],
  labels: string[],
  title: string
): IGridConf => ({
  width: 12,
  type: ComponentTypeEnum.chart,
  props: {
    titleLayoutProps: {
      style: {
        background: '#F2EFFA',
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 5,
        // fontSize: 10,
      },
    },
    layoutProps: {
      style: {
        boxShadow: 'none',
        background: 'transparent',
      },
    },
    contentLayoutProps: {
      style: {
        paddingBottom: 0,
      },
    },
    data: [
      {
        data: data,
        label: '',
        format: {
          label: '06-10',
          borderColor: '#1e769c',
          fill: 'start',
          backgroundColor: 'rgba(30, 118, 156, .5)',
          pointRadius: 0,
        },
      },
    ],
    labels: labels,
    chartType: ChartTypesEnum.LINE,
    options: OptionsGraphType1(),
    title: <CardLabelTitle style={{ fontSize: 13 }} title={title} />,
    height: 100,
  },
  fetch: null,
});

export const DashboardBuilderConfig = (
  data: IVisitorsByDayOutput
): IGridConf[] =>
  data.datasets.map((element) => {
    return createDatasetElement(element.data, data.hoursLabels, element.name);
  });
