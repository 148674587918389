import { useSelector } from 'react-redux';
import { ReducerKeys } from '../../config';
import useShallowEqualSelector from 'custom_hooks/useShallowEqualsSelector';
import _ from 'lodash';
import { IState } from './reducer';
import { useQuery } from 'react-query';
import * as OccupancyService from 'services/api/occupancy';
import addDays from 'date-fns/addDays';
import * as DateUtils from 'utils/dates';
import * as StoresCommonSelectors from 'redux/reducers/gl_app_common/selectors';
import * as OccupancySelectors from 'redux/reducers/occupancy_reducer/selectors';

export const useSelectedDay = () => {
  const data = useSelector(
    (state: any) => (state[ReducerKeys.OCCUPANCY_REDUCER] as IState).day
  );
  return data;
};

export const useSelectVisitorsHistoryPerHour = ():
  | OccupancyService.IFetchOccupancyHistoryPerHourOutput['data']
  | null => {
  const selectedStore = StoresCommonSelectors.useGetSelectedStore();
  const day = OccupancySelectors.useSelectedDay();

  const result = useQuery(['fetch-visitors-by-day', day, selectedStore], () => {
    return OccupancyService.fetchVisitorsByDayOfWeekPerHourList({
      date: day.toISOString(),
      stores: selectedStore ? [selectedStore] : [],
    });
  });

  return result?.data?.data &&
    Array.isArray(result.data.data) &&
    Array.isArray(result.data.data?.[0])
    ? result.data.data[0]
    : null;
};

export const useSelectOccupancyStatistic = ():
  | OccupancyService.ITotalVisiotrsCompareLastWeekOutput['data']
  | null => {
  const selectedStore = StoresCommonSelectors.useGetSelectedStore();

  const result = useQuery(['occupancyStatistic', selectedStore], () => {
    return OccupancyService.fetchTotalVisiotrsCompareLastWeek({
      store: selectedStore as string,
    });
  });

  return result.data?.data || null;
};
