import React, { ReactElement } from 'react';
import useStyles from './styles';
import Tabs, { TabsProps } from '@material-ui/core/Tabs';
import Tab, { TabProps } from '@material-ui/core/Tab';
import TabPanel from './components/tabs_panel';
import {
  makeStyles,
  withStyles,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import * as AppColors from 'styles/colors';

interface ITabsProps {
  elementsConfig: Array<ITabsElementsConfig>;
  tabsProps?: TabsProps;
  onChange: (value: number) => void;
  defaultSelection?: number;
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface ITabsElementsConfig {
  title: React.ReactElement | string;
  content: React.ReactElement;
  id: string | number;
  tabProps?: TabProps;
}

export default function TabsPanel(props: ITabsProps): ReactElement {
  const classes = useStyles();
  const [value, setValue] = React.useState(props.defaultSelection || 0);

  React.useEffect(() => {
    setValue(props.defaultSelection || 0);
  }, [props.defaultSelection, setValue]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    props.onChange(newValue);
  };

  return (
    <div className={classes.root}>
      <CustomTabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="off"
        style={{ marginBottom: '1rem' }}
        // scrollButtons="on"
        // aria-label="simple tabs example"
        {...props.tabsProps}
      >
        {props.elementsConfig.map(({ id, title, tabProps }, index) => (
          <CustomTab
            key={index}
            label={title}
            {...a11yProps(index)}
            {...tabProps}
          />
        ))}
      </CustomTabs>
      {props.elementsConfig.map(({ id, content }, index) => (
        <TabPanel key={index} value={value} index={index}>
          {content}
        </TabPanel>
      ))}
    </div>
  );
}

const CustomTabs = withStyles({
  // root: {
  //   borderBottom: '1px solid #e8e8e8',
  // },
  // indicator: {
  // },
})(Tabs);

const CustomTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      // minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      // marginRight: theme.spacing(4),
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:hover': {
        // color: AppColors.APP_COLOR_PRIMARY({
        //   darkMode: theme.palette.type === 'dark',
        // }),
        opacity: 1,
      },
      '&$selected': {
        // color: AppColors.APP_COLOR_PRIMARY({
        //   darkMode: theme.palette.type === 'dark',
        // }),

        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        // color: AppColors.APP_COLOR_PRIMARY({
        //   darkMode: theme.palette.type === 'dark',
        // }),
      },
    },
    selected: {},
  })
)((props: TabProps) => <Tab disableRipple {...props} />);
