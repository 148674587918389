// {{BASE_URL}}/api/schemeType
import * as Types from '../types';
import { Environment, MonthsEnum, RegionsEnum } from '../../../config';
import request from '../../../utils/request';
import _ from 'lodash';
import { TimeType, Metric, DateRangeType } from 'config/visitorsDetails';
import { varToStringParams } from 'utils/path';

/** getStorePerformanceStatistics  */
interface IStorePerformanceStatisticsOutput extends Types.ResponseOutput {
  data: {
    visitors_last_thrd_days: number;
    perc_change_from_prev_thrd_days: number;
    total_visitors_curr_month: number;
    perc_same_per_last_month: number;
  };
}
export const getStorePerformanceStatistics = async () => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/analytics/store-performance/statistics`
  );
  let output: IStorePerformanceStatisticsOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** getVisitorsCurrentVsPreviousWeekChartData  */
interface IVisitorsCurrentVsPreviousWeekChartData extends Types.ResponseOutput {
  data: {
    persList: number[];
    listCurrent: number[];
    dates: string[];
  };
}

interface IVisitorsCurrentVsPreviousWeekChartInput {
  storeid: string;
}
export const getVisitorsCurrentVsPreviousWeekChartData = async (
  input: IVisitorsCurrentVsPreviousWeekChartInput
) => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/analytics/total-visitors-for-each-day-comparison?storeid=${input.storeid}`
  );
  let output: IVisitorsCurrentVsPreviousWeekChartData = {
    message: data.message,
    data: {
      persList: _.get(data, 'data.persList', []),
      listCurrent: _.get(data, 'data.listCurrent', []),
      dates: _.get(data, 'data.dates', []),
    },
    status: data.status,
  };
  return output;
};

/** getTotalVisitorsData  */
interface ITotalVisitorsInput {
  type: 'month' | 'daysBefore';
  value: number;
  storeid: string;
}
interface ITotalVisitorsOutput extends Types.ResponseOutput {
  data: {
    dataCurrent: {
      startDate: string;
      lastDay: string;
      visitorsNumber: number;
    };
    dataPrevious: {
      startDate: string;
      lastDay: string;
      visitorsNumber: number;
    };
    increasingPersentage: number;
  };
}
export const getTotalVisitorsData = async (input: ITotalVisitorsInput) => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/analytics/total-visitors/${input.type}/${input.value}?storeid=${input.storeid}`
  );
  let output: ITotalVisitorsOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** getVisitorsByDayOfWeekperHour  */
interface IVisitorsByDayOfWeekperHourInput {
  monthNumber: number;
  storeid: string;
}
interface IVisitorsByDayOfWeekperHourOutput extends Types.ResponseOutput {
  data: {
    datasets: Array<number[]>;
    hours: string[];
    weeksNumberList: number[];
  };
}
export const getVisitorsByDayOfWeekperHour = async (
  input: IVisitorsByDayOfWeekperHourInput
) => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/analytics/visitors-per-hour/${input.monthNumber}?storeid=${input.storeid}`
  );
  let output: IVisitorsByDayOfWeekperHourOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** getImportanceOfWeekDayOfSelectedMonth  */
interface IImportanceOfWeekDayOfSelectedMonthInput {
  monthNumber: number;
  storeid: string;
}
interface IImportanceOfWeekDayOfSelectedMonthOutput
  extends Types.ResponseOutput {
  data: {
    datasets: Array<number[]>;
    weeksNumberList: number[];
  };
}
export const getImportanceOfWeekDayOfSelectedMonth = async (
  input: IImportanceOfWeekDayOfSelectedMonthInput
) => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/analytics/importance-of-weekday-of-selected-month/${input.monthNumber}?storeid=${input.storeid}`
  );
  let output: IImportanceOfWeekDayOfSelectedMonthOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** getVisitorsByTimeGroup  */
interface IVisitorsByTimeGroupInput {
  monthNumber: number;
  storeid: string;
}
interface IVisitorsByTimeGroupOutput extends Types.ResponseOutput {
  data: {
    '6-10': number;
    '10-14': number;
    '14-18': number;
    '18-22': number;
  };
}
export const getVisitorsByTimeGroup = async (
  input: IVisitorsByTimeGroupInput
) => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/analytics/visitors-by-timegroup/${input.monthNumber}?storeid=${input.storeid}`
  );
  let output: IVisitorsByTimeGroupOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** getVisitorsByTimeGroup  */
interface IVisitorsGroupbyHourInput {
  storeid: string;
}
interface IVisitorsGroupbyHourOutput extends Types.ResponseOutput {
  data: {
    x: number;
    y: string;
    z: number;
  }[];
}
export const getVisitorsPickByHourPerWeekday = async (
  input: IVisitorsGroupbyHourInput
) => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/analytics/pick-hour-per-weekday?storeid=${input.storeid}`
  );
  let output: IVisitorsGroupbyHourOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** getVisitorsListGroupByDate  */
interface IVisitorsListGroupByDateInput {
  type: 'daysBefore' | 'month';
  value: number;
  storeid: string;
}
interface IVisitorsListGroupByDateOutput extends Types.ResponseOutput {
  data: {
    labels: string[];
    data: number[];
  };
}
export const getVisitorsListGroupByDate = async (
  input: IVisitorsListGroupByDateInput
) => {
  const { type, value } = input;
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/analytics/total-visitors-list/${type}/${value}?storeid=${input.storeid}`
  );
  let output: IVisitorsListGroupByDateOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** getVisitorsPerHour  */
interface IVisitorsPerHourInput {
  dateStart: string;
  dateEnd: string;
}
interface IVisitorsPerHourOutput extends Types.ResponseOutput {
  data: {
    labels: string[];
    datasets: Array<{
      name: string;
      list: number[];
    }>;
  };
}
export const getVisitorsPerHour = async (input: IVisitorsPerHourInput) => {
  const { data }: any = await request(
    'get',
    `${
      Environment.API_BASE_URL
    }/api/analytics/visitors-per-hour-list?dateStart=${
      input.dateStart
    }&dateEnd=${input.dateEnd}&timezoneOffset=${new Date().getTimezoneOffset()}`
  );
  let output: IVisitorsPerHourOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** getVisitorsByMonth  */
interface IVisitorsByMonthInput {
  storeid: string;
}
interface IVisitorsByMonthOutput extends Types.ResponseOutput {
  data: {
    datasetVisitors: number[];
    datasetPerc: number[];
  };
}

export const getVisitorsByMonth = async (input: IVisitorsByMonthInput) => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/analytics/visitors-by-month-compare?storeid=${input.storeid}`
  );
  let output: IVisitorsByMonthOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** getAverageHourlyVisitorsPerDay  */
interface IAverageHourlyVisitorsPerDayInput {
  storeid: string;
}
interface IAverageHourlyVisitorsPerDayOutput extends Types.ResponseOutput {
  data: {
    count: number;
  };
}

export const getAverageHourlyVisitorsPerDay = async (
  input: IAverageHourlyVisitorsPerDayInput
) => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/analytics/average-hourly-visitors-per-day?storeid=${input.storeid}`
  );
  let output: IAverageHourlyVisitorsPerDayOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** getAverageShoppingTimeLastDays  */
interface IAverageShoppingTimeLastDaysInput {
  storeid: string;
}
interface IAverageShoppingTimeLastDaysOutput extends Types.ResponseOutput {
  data: {
    count: number;
  };
}

export const getAverageShoppingTimeLastDaysDay = async (
  input: IAverageShoppingTimeLastDaysInput
) => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/analytics/average-shopping-time-last-days?storeid=${input.storeid}`
  );
  let output: IAverageShoppingTimeLastDaysOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** getVisitorsByRegion  */
interface IVisitorsByRegionInput {
  month: MonthsEnum;
}
interface IVisitorsByRegionOutput extends Types.ResponseOutput {
  data: {
    labels: string[];
    dataset: number[];
    // id: string;
    // count: number;
  };
}

export const getVisitorsByRegion = async (input: IVisitorsByRegionInput) => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/analytics/visitors-by-region/${input.month}`
  );
  let output: IVisitorsByRegionOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** getPeopleInTimegroupPerMonth  */
interface IPeopleInTimegroupPerMonthInput {
  storeid: string;
}
interface IPeopleInTimegroupPerMonthOutput extends Types.ResponseOutput {
  data: {
    labels: string[];
    datasets: number[][];
  };
}

export const getPeopleInTimegroupPerMonth = async (
  input: IPeopleInTimegroupPerMonthInput
) => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/analytics/people-in-timegroup-per-month?storeid=${input.storeid}`
  );
  let output: IPeopleInTimegroupPerMonthOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** getVisitorsByDay  */
interface IVisitorsByDayInput {
  storeid: string;
  month: MonthsEnum;
  weekNumber: number;
}
interface IVisitorsByDayOutput extends Types.ResponseOutput {
  data: {
    hoursLabels: string[];
    daysLabels: string[];
    datasets: {
      data: number[];
      name: string;
    }[];
  };
}

export const getVisitorsByDay = async (input: IVisitorsByDayInput) => {
  const { storeid, month, weekNumber } = input;
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/analytics/visitors-by-day?storeid=${storeid}&month=${month}&weekNumber=${weekNumber}`
  );
  let output: IVisitorsByDayOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** getVisitorsByDayWeekNum  */
interface IVisitorsByDayWeekNumInput {
  storeid: string;
  month: MonthsEnum;
  weekNumber: number;
}
interface IVisitorsByDayWeekNumOutput extends Types.ResponseOutput {
  data: {
    labels: string[];
    datasets: number[][];
  };
}

export const getVisitorsByDayWeekNum = async (
  input: IVisitorsByDayWeekNumInput
) => {
  const { storeid, month, weekNumber } = input;
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/analytics/visitors-by-day-weekNum?storeid=${storeid}&month=${month}&weekNumber=${weekNumber}`
  );
  let output: IVisitorsByDayWeekNumOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** getStore1VsStore2PerDay  */
interface IStore1VsStore2PerDayInput {
  store1id: string;
  store2id: string;
  month: MonthsEnum;
}
interface IStore1VsStore2PerDayOutput extends Types.ResponseOutput {
  data: {
    labels: string[];
    store1: number[];
    store2: number[];
    weekends: number[];
  };
}

export const getStore1VsStore2PerDay = async (
  input: IStore1VsStore2PerDayInput
) => {
  const { store1id, store2id, month } = input;
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/analytics/store1-vs-store2-per-day/${month}?store1id=${store1id}&store2id=${store2id}`
  );
  let output: IStore1VsStore2PerDayOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** fetch visitors per store list  */
export type TVisitorsPerStoreListData = {
  tableArr: Array<{
    [key: string]: {
      visitor: number;
      perc: null | number;
    };
  }>;
  type: 'daily' | 'monthly';
  columnsOrder: string[];
};

interface IVisitorsPerStoreListOutput extends Types.ResponseOutput {
  data: TVisitorsPerStoreListData;
}

export const getVisitorsPerStoreListData = async (
  type: 'daily' | 'monthly'
): Promise<IVisitorsPerStoreListOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/analytics/visitors-table/${type}`
  );
  return data;
};

/** Visitors Scale */
export interface IVisitorDetailsScaleOutput extends Types.ResponseOutput {
  data: {
    total: number;
    labels: string[];
    scale: Array<{
      label: string;
      data: {
        x: string;
        y: number;
      };
    }>;
  };
}

interface IVisitorDetailsPayload {
  dateRangeType: DateRangeType;
  timeType: TimeType;
  cameraId?: string;
  storeId: string;
  metric: Metric[];
}

export const getDetailsScaleData = async (
  payload: IVisitorDetailsPayload
): Promise<IVisitorDetailsScaleOutput> => {
  const query = varToStringParams({
    variablesArray: [
      {
        value: payload.dateRangeType.toString(),
        key: 'dateRangeType',
      },
      {
        value: payload.timeType.toString(),
        key: 'timeType',
      },
      {
        value: payload.cameraId?.toString(),
        key: 'cameraId',
      },
      {
        value: payload.storeId.toString(),
        key: 'storeId',
      },
      {
        value: payload.metric.reduce(
          (previous, current) =>
            previous ? `${previous}, ${current}` : current,
          ''
        ), // comma seperated values
        key: 'metric',
      },
    ],
  });
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/analytics/visitors-details-scale${query}`
  );
  return data;
};

/** Daily total visitors */
export interface IVisitorsDailyOutput extends Types.ResponseOutput {
  data: {
    count: number;
  };
}

export const getVisitorsDailyData = async (
  storeid: string
): Promise<IVisitorsDailyOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/analytics/total-visitors-daily/${storeid}`
  );
  return data;
};
