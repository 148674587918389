import React, { useEffect } from 'react';
import { AppSelect, AppDatePicker } from 'components/molecules/form_controls';
import { useForm } from 'react-hook-form';
import useStyles from './styles';
import { Container, Grid, Typography } from '@material-ui/core';
import TranslatedText from 'components/atoms/translated_text';
import reactLogger from 'utils/logger';
import { useDispatch } from 'react-redux';
import * as DatesUtils from 'utils/dates';
import VisitorsDetailsActions from 'redux/reducers/visitorDetails_reducer/actions';
import StoresCommonActions from 'redux/reducers/gl_app_common/actions';
import * as StoresCommonSelectors from 'redux/reducers/gl_app_common/selectors';
import * as VisitorDetailsConfig from 'config/visitorsDetails';
import store from 'redux/store';

interface IFormData {
  selectedStore: string | null;
  selectedTimeType: VisitorDetailsConfig.TimeType;
  selectedMetric: VisitorDetailsConfig.Metric | 'all';
  selectedDateRangeType: VisitorDetailsConfig.DateRangeType;
  selectedCamera: 'all' | string;
}

export default function SelectForm() {
  reactLogger.renderComponent('SelectForm');
  const classes = useStyles();
  const { setValue, reset, control, getValues } = useForm<IFormData>({
    defaultValues: {
      selectedStore: null,
      selectedTimeType: store.getState().VisitorDetailsReducer.selectedTimeType,
      selectedMetric: store.getState().VisitorDetailsReducer.selectedMetric,
      selectedDateRangeType:
        store.getState().VisitorDetailsReducer.selectedDateRangeType,
      selectedCamera: store.getState().VisitorDetailsReducer.selectedCamera,
    },
  });
  const dispatch = useDispatch();

  const handleValueChange = async (
    type: 'storeType' | 'metricType' | 'dateRangeType' | 'cameraType',
    value: any
  ) => {
    if (type === 'storeType') {
      dispatch(StoresCommonActions.setSelectedStoreAction(value));
      dispatch(VisitorsDetailsActions.setCameraAction('all'));
      setValue('selectedCamera', 'all');
    } else if (type === 'metricType') {
      dispatch(VisitorsDetailsActions.setMetricAction(value));
    } else if (type === 'dateRangeType') {
      dispatch(VisitorsDetailsActions.setDateRangeTypeAction(value));
      const timeType =
        value === VisitorDetailsConfig.DateRangeType.Today
          ? VisitorDetailsConfig.TimeType.Hourly
          : VisitorDetailsConfig.TimeType.Daily;
      dispatch(VisitorsDetailsActions.setTimeTypeAction(timeType));
      setValue('selectedTimeType', timeType);
    } else if (type === 'cameraType') {
      dispatch(VisitorsDetailsActions.setCameraAction(value));
    }
  };

  return (
    <React.Fragment>
      <form className={classes.form} noValidate>
        <Grid spacing={0} container>
          <Grid spacing={1} sm={12} md={6} justify="flex-start" container item>
            <Grid sm={12} md={4} item>
              <div>
                <Typography component={'h4'}>
                  <TranslatedText defaultText={'Store'} />
                </Typography>
                <SelectStoresWrapper
                  handleChange={async (value) =>
                    await handleValueChange('storeType', value)
                  }
                  control={control}
                  reset={reset}
                  setValue={setValue}
                />
              </div>
            </Grid>
            <Grid sm={12} xs={12} md={4} item>
              <div>
                <Typography component={'h4'}>
                  <TranslatedText defaultText={'Camera'} />
                </Typography>
                <SelectCamerasWrapper
                  handleChange={async (value) =>
                    await handleValueChange('cameraType', value)
                  }
                  control={control}
                  reset={reset}
                  setValue={setValue}
                />
              </div>
            </Grid>
          </Grid>

          <Grid
            spacing={1}
            justify="flex-end"
            container
            xs={12}
            sm={12}
            md={6}
            item
          >
            <Grid sm={12} md={4} item>
              <div>
                <Typography component={'h4'}>
                  <TranslatedText defaultText={'Metric'} />
                </Typography>
                <AppSelect
                  margin="none"
                  fullWidth
                  name={'selectedMetric'}
                  style={{ marginTop: 0, marginBottom: 0 }}
                  control={control}
                  rules={{}}
                  handleChange={(value) =>
                    handleValueChange('metricType', value)
                  }
                  size="small"
                  autoComplete={'selectedMetric'}
                  variant="outlined"
                  options={[
                    {
                      label: 'In',
                      id: VisitorDetailsConfig.Metric.In,
                    },
                    {
                      label: 'Out',
                      id: VisitorDetailsConfig.Metric.Out,
                    },
                    {
                      label: 'All',
                      id: 'all',
                    },
                  ]}
                />
              </div>
            </Grid>
            <Grid sm={12} xs={12} md={4} item>
              <div>
                <Typography component={'h4'}>
                  <TranslatedText defaultText={'Date Range'} />
                </Typography>
                <AppSelect
                  margin="none"
                  fullWidth
                  name={'selectedDateRangeType'}
                  style={{ marginTop: 0, marginBottom: 0 }}
                  control={control}
                  rules={{}}
                  handleChange={async (value) =>
                    handleValueChange('dateRangeType', value)
                  }
                  size="small"
                  autoComplete={'selectedDateRangeType'}
                  variant="outlined"
                  options={[
                    {
                      label: 'Today',
                      id: VisitorDetailsConfig.DateRangeType.Today,
                    },
                    {
                      label: 'Last Week',
                      id: VisitorDetailsConfig.DateRangeType.LastWeek,
                    },
                    {
                      label: 'Last Month',
                      id: VisitorDetailsConfig.DateRangeType.LastMonth,
                    },
                    {
                      label: 'Last 30 days',
                      id: VisitorDetailsConfig.DateRangeType.Last30Days,
                    },
                  ]}
                />
              </div>
            </Grid>
            <Grid sm={12} xs={12} md={4} item>
              <div>
                <Typography component={'h4'}>
                  <TranslatedText defaultText={'Time Type'} />
                </Typography>
                <AppSelect
                  margin="none"
                  fullWidth
                  name={'selectedTimeType'}
                  style={{ marginTop: 0, marginBottom: 0 }}
                  control={control}
                  rules={{}}
                  disabled
                  // handleChange={(value) => handleValueChange('timeType', value)}
                  size="small"
                  autoComplete={'selectedTimeType'}
                  variant="outlined"
                  options={[
                    {
                      label: 'Daily',
                      id: VisitorDetailsConfig.TimeType.Daily,
                    },
                    {
                      label: 'Hourly',
                      id: VisitorDetailsConfig.TimeType.Hourly,
                    },
                  ]}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}

interface IPropsSelectWrapper {
  control: any;
  setValue: any;
  reset: any;
  handleChange: (data: string) => void;
}

const SelectStoresWrapper = (props: IPropsSelectWrapper) => {
  const stores = StoresCommonSelectors.useGetStores();
  const selectedStore = StoresCommonSelectors.useGetSelectedStore();

  React.useEffect(() => {
    if (stores.length > 0) {
      const storeid = selectedStore || stores[0].uniqueId;
      props.setValue('selectedStore', storeid);
      props.handleChange(storeid);
    }
  }, [stores, selectedStore]);

  return (
    <AppSelect
      margin="none"
      fullWidth
      style={{ marginTop: 0, marginBottom: 0 }}
      name={'selectedStore'}
      control={props.control}
      rules={{}}
      size="small"
      handleChange={props.handleChange}
      autoComplete={'selectedStore'}
      variant="outlined"
      options={stores.map(({ uniqueId, name }) => ({
        label: name,
        id: uniqueId,
      }))}
    />
  );
};

const SelectCamerasWrapper = (props: IPropsSelectWrapper) => {
  const cameras = StoresCommonSelectors.useGetStoreCameras();
  const options = React.useMemo(() => {
    return [
      {
        label: 'All',
        id: 'all',
      },
      ...(cameras.map(({ name, cameraId }) => ({
        label: name ?? cameraId,
        id: cameraId,
      })) ?? []),
    ];
  }, [cameras]);

  return (
    <AppSelect
      margin="none"
      fullWidth
      style={{ marginTop: 0, marginBottom: 0 }}
      name={'selectedCamera'}
      control={props.control}
      rules={{}}
      size="small"
      handleChange={props.handleChange}
      autoComplete={'selectedCamera'}
      variant="outlined"
      options={options}
    />
  );
};
