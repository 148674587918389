import { useSelector } from 'react-redux';
import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { ReducerKeys } from '../../config';
import { StateStatus } from '../../../redux/utils/common';

/* selector: useGetUserProfile */
interface IUserProfileData {
  email: string;
  firstName: string;
  surname: string;
  nationality: string;
  gender: string;
  birthDate: string;
  registeredDate: string;
  role: string;
}

interface IUserProfileOutput {
  status: StateStatus | null;
  data: IUserProfileData | null | undefined;
}

export const useGetUserProfile = (): IUserProfileOutput => {
  const { status, data } = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.USER_PROFILE_REDUCER].profileData
  );
  return { status, data };
};

/* selector: useGetCountries */

interface ICountriessData {
  code: string;
  code3: string;
  name: string;
  number: string;
}

interface ICountriesOutput {
  status: StateStatus;
  data: ICountriessData[] | null;
}

export const useGetCountries = (): ICountriesOutput => {
  const { status, data } = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.USER_PROFILE_REDUCER].countriesList
  );
  return { status, data };
};

/* selector: useGetEditUserStatus */

export const useGetEditUserStatus = (): StateStatus | null => {
  const status = useSelector(
    (state: any) => state[ReducerKeys.USER_PROFILE_REDUCER].editProfile.status
  );
  return status;
};

/* selector: useSelectChangePasswordStatus */
export const useSelectChangePasswordStatus = () => {
  return useSelector(
    (state: any) => state[ReducerKeys.USER_PROFILE_REDUCER].changePasswordStatus
  );
};
