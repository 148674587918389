// {{BASE_URL}}/api/schemeType
import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';
import { BoxStatus, StoreStatus, InOutDataType } from 'config/store';
import { varToStringParams } from 'utils/path';
import { type } from 'os';

/** fetch groups  */
export interface IGroupBasicData {
  _id: string;
  name: string;
}

export interface IGroupDetailData {
  name: string;
  icon: string;
  email: string;
  groupUsers: string[];
  createdAt?: string;
  _id: string;
  updatedAt?: string;
}

export type IGroupsData = {
  items: Array<IGroupDetailData | IGroupBasicData>;
  paging: {
    pageSize: number;
    total: number;
    page: number;
  };
};

interface IFetchGroupOutput extends Types.ResponseOutput {
  data: IGroupsData;
}

export interface IFetchGroupInput {
  name?: string;
  page: number;
  pageSize: number;
  order: 'asc' | 'desc';
  sortField: string;
  basicInfo?: 'true' | 'false';
}

export const fetchGroups = async (
  payload?: IFetchGroupInput
): Promise<IFetchGroupOutput> => {
  const query = varToStringParams({
    variablesArray: [
      {
        value: payload?.name,
        key: 'name',
      },
      {
        value: payload?.page.toString(),
        key: 'page',
      },
      {
        value: payload?.pageSize.toString(),
        key: 'pageSize',
      },
      {
        value: payload?.order,
        key: 'order',
      },
      {
        value: payload?.sortField,
        key: 'sortField',
      },
      {
        value: payload?.basicInfo,
        key: 'basicInfo',
      },
    ],
  });

  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/group/list${query}`
  );
  return data;
};

/** add group  */
interface IAddGroupOutput extends Types.ResponseOutput {
  data: {
    id: string;
  };
}

export interface IAddGroupInput {
  name: string;
  icon: string;
  email: string;
}

export const addGroup = async (
  payload: IAddGroupInput
): Promise<IAddGroupOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/group`,
    {
      body: payload,
      showToastMessage: true,
    }
  );
  return data;
};

/** edit group  */
interface IEditGroupOutput extends Types.ResponseOutput {
  data: {
    id: string;
  };
}

interface IEditGroupInput {
  name: string;
  icon?: string;
  email: string;
}

export const editGroup = async (
  payload: IEditGroupInput,
  groupid: string
): Promise<IEditGroupOutput> => {
  const { data }: any = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/group/${groupid}`,
    {
      body: payload,
      showToastMessage: true,
    }
  );
  return data;
};

/** remove group  */
interface IRemoveGroupOutput extends Types.ResponseOutput {
  data: {
    id: string;
  };
}

export const removeGroup = async (
  groupid: string
): Promise<IRemoveGroupOutput> => {
  const { data }: any = await request(
    'delete',
    `${Environment.API_BASE_URL}/api/group/${groupid}`
  );
  return data;
};
