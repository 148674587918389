import React, { ReactElement } from 'react';
import { CustomChart } from 'components/molecules/custom_chart';
import * as LocalConfiguration from './configuration';
import TranslatedText from 'components/atoms/translated_text';
import { ChartTypesEnum } from 'components/molecules/custom_chart/configuration';
import * as ReduxSelectors from 'react-redux';
import StorePerformanceActions from 'redux/reducers/storePerformance_reducer/actions';
import * as StorePerformanceSelectors from 'redux/reducers/storePerformance_reducer/selectors';
import { DaysEnum, Days } from 'config';
import { Box, Grid } from '@material-ui/core';
import { AppSelect } from 'components/molecules/form_controls';
import { useForm } from 'react-hook-form';
import { MonthsEnum } from 'config/months';
import CardLabelTitle from 'components/app_common/card_label_title';
import * as StoresCommonSelectors from 'redux/reducers/gl_app_common/selectors';

export default function CurrentVisitorsWeekCompareGraph() {
  const chartData = StorePerformanceSelectors.useGetVisitorsByDayOfWeekPerHour();
  let datasets = chartData.datasets;
  let hours = chartData.hours;
  let weeksNumberList = chartData.weeksNumberList;

  return (
    <CustomChart
      title={
        <CardLabelTitle
          title={
            <TranslatedText defaultText="Visitors by Day of Week per Hour" />
          }
        />
        // <TranslatedText defaultText={'Visitors by Day of Week per Hour'} />
      }
      onOverview={() => <DaySelectorWrapper />}
      layoutProps={{
        variant: 'outlined',
      }}
      data={LocalConfiguration.DummyDataset(
        {
          label:
            weeksNumberList.length >= 0 ? `Week ${weeksNumberList[0]}` : 'L1',
          data: datasets.length >= 0 ? datasets[0] : [],
        },
        {
          label:
            weeksNumberList.length >= 1 ? `Week ${weeksNumberList[1]}` : 'L2',
          data: datasets.length >= 1 ? datasets[1] : [],
        },
        {
          label:
            weeksNumberList.length >= 2 ? `Week ${weeksNumberList[2]}` : 'L3',
          data: datasets.length >= 2 ? datasets[2] : [],
        },
        {
          label:
            weeksNumberList.length >= 3 ? `Week ${weeksNumberList[3]}` : 'L4',
          data: datasets.length >= 3 ? datasets[3] : [],
        }
      )}
      labels={hours || []}
      options={LocalConfiguration.DummyChartOptions}
      chartType={ChartTypesEnum.LINE}
    />
  );
}

interface IFormData {
  day: DaysEnum;
}

const DaySelectorWrapper = () => {
  const dispatch = ReduxSelectors.useDispatch();
  const {
    setValue,
    watch,
    handleSubmit,
    errors,
    control,
    getValues,
  } = useForm<IFormData>({
    defaultValues: {
      day: new Date().getDay().toString() as DaysEnum,
    },
  });

  const selectedStore = StoresCommonSelectors.useGetSelectedStore();
  const selectedDay = watch('day');

  React.useEffect(() => {
    if (selectedDay && selectedStore) {
      dispatch(
        StorePerformanceActions.fetchVisitorsByDayOfWeekPerHourAction({
          selectedWeekDay: selectedDay,
          storeid: selectedStore,
        })
      );
    }
  }, [selectedDay, selectedStore]);

  const options = Days;
  return (
    <div style={{ marginTop: 8 }}>
      <AppSelect
        margin="none"
        fullWidth
        label={<TranslatedText defaultText={'Select Day'} />}
        name={'day'}
        size="small"
        required
        control={control}
        rules={{}}
        style={{ minWidth: 180 }}
        variant="outlined"
        options={options || []}
      />
    </div>
  );
};
