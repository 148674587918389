import { useSelector } from 'react-redux';
import { ReducerKeys } from '../../config';
import _ from 'lodash';
import { AuthRoles } from '../../../config';
import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { IState } from './reducer';

export const useSelectUserRole = () => {
  return useSelector(
    (state: any) => state[ReducerKeys.AUTH_REDUCER].role
    // _.get(state[ReducerKeys.AUTH_REDUCER], 'role', AuthRoles.Guest)
  );
};

export const useSelectUserData = () => {
  const { username, email } = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.AUTH_REDUCER].data
  );
  return { username, email };
};

export const useSelectAuthStatus = () => {
  const status = useSelector(
    (state: any) => state[ReducerKeys.AUTH_REDUCER].status
  );
  return status;
};

export const useSelectIsLogin = (): null | boolean => {
  const login = useSelector(
    (state: any) => state[ReducerKeys.AUTH_REDUCER].login
  );
  return login;
};

export const useToken = (): null | string => {
  const token = useSelector(
    (state: any) => state[ReducerKeys.AUTH_REDUCER].token
  );
  return token;
};

export const useRefreshToken = (): null | string => {
  const refreshToken = useSelector(
    (state: any) => state[ReducerKeys.AUTH_REDUCER].refreshToken
  );
  return refreshToken;
};

export const useGetMetricTypes = () => {
  const result = useSelector(
    (state: any) =>
      (state[ReducerKeys.AUTH_REDUCER] as IState).permissions?.camera
  );
  return result;
};

export const useGroup = (): IState['group'] => {
  const result = useShallowEqualSelector(
    (state: any) => (state[ReducerKeys.AUTH_REDUCER] as IState).group
  );
  return result;
};
