import { IAction, StateStatus } from '../../utils/common';
import * as Types from './types';
import { DaysRangeEnum, DaysRangeConfig } from 'config';
import { formDefaultValues } from './selectors';
import { StoresDataType } from 'services/api/user';

interface IState {
  // stores: {
  //   status: StateStatus | null | undefined;
  //   data: StoresDataType | null;
  // };
  formValues: {
    data: any;
  };
}

export const initialState: IState = {
  // stores: {
  //   status: null,
  //   data: null,
  // },
  formValues: {
    data: formDefaultValues,
  },
};

const StoreComparisonReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    // case Types.SET_STORES:
    //   return {
    //     ...state,
    //     stores: {
    //       ...state.stores,
    //       status: action.status,
    //       data: action.data,
    //     },
    //   };
    case Types.SET_FORM_DATA:
      return {
        ...state,
        formValues: {
          ...state.formValues,
          data: action.data,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default StoreComparisonReducer;
