import { IAction, StateStatus } from '../../utils/common';
import * as Types from './types';
import { DaysRangeEnum, DaysRangeConfig } from 'config';
import { StoresDataType } from 'services/api/user';

interface IState {
  visitorsByDay_graph: {
    status: StateStatus | null | undefined;
    data: any;
  };
  visitorsWeekNum_graph: {
    status: StateStatus | null | undefined;
    data: any;
  };
}

const initialState: IState = {
  visitorsByDay_graph: {
    status: null,
    data: null,
  },
  visitorsWeekNum_graph: {
    status: null,
    data: null,
  },
};

const VisitorsByDayReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_VISITORS_BY_DAY:
      return {
        ...state,
        visitorsByDay_graph: {
          ...state.visitorsByDay_graph,
          status: action.status,
          data: action.data,
        },
      };
    case Types.SET_VISITORS_WEEK_NUM:
      return {
        ...state,
        visitorsWeekNum_graph: {
          ...state.visitorsWeekNum_graph,
          status: action.status,
          data: action.data,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default VisitorsByDayReducer;
