import { colors, Theme } from '@material-ui/core';

export enum ChartTypesEnum {
  COLUMN,
  BAR,
  LINE,
  DOTS,
  PIE,
  BUBBLE,
  DOUGHNUT,
}

// export const ChartOptions = (theme: Theme) => ({
//   animation: false,
//   cornerRadius: 20,
//   layout: { padding: 0 },
//   legend: { display: false },
//   maintainAspectRatio: false,
//   responsive: true,
//   plugins: {
//     datalabels: {
//       display: false,
//     },
//   },
//   scales: {
//     xAxes: [
//       {
//         barThickness: 12,
//         maxBarThickness: 10,
//         barPercentage: 0.5,
//         categoryPercentage: 0.5,
//         ticks: {
//           fontColor: theme.palette.text.secondary,
//         },
//         gridLines: {
//           display: false,
//           drawBorder: false,
//         },
//       },
//     ],
//     yAxes: [
//       {
//         ticks: {
//           fontColor: theme.palette.text.secondary,
//           beginAtZero: true,
//           // min: 0,
//         },
//         gridLines: {
//           borderDash: [2],
//           borderDashOffset: [2],
//           color: theme.palette.divider,
//           drawBorder: false,
//           zeroLineBorderDash: [2],
//           zeroLineBorderDashOffset: [2],
//           zeroLineColor: theme.palette.divider,
//         },
//       },
//     ],
//   },
//   tooltips: {
//     backgroundColor: theme.palette.background.default,
//     bodyFontColor: theme.palette.text.secondary,
//     borderColor: theme.palette.divider,
//     borderWidth: 1,
//     enabled: true,
//     footerFontColor: theme.palette.text.secondary,
//     intersect: false,
//     mode: 'index',
//     titleFontColor: theme.palette.text.primary,
//   },
// });


// export const DummyLabels = [
//   '1 Aug',
//   '2 Aug',
//   '3 Aug',
//   '4 Aug',
//   '5 Aug',
//   '6 Aug',
// ];
// export const DummyDataset = [
//   {
//     // backgroundColor: colors.indigo[500],
//     data: [18, 5, 19, 27, 29, 19, 20],
//     label: 'This year',
//     format: {
//       label: 'My First dataset',
//       fill: false,
//       backgroundColor: 'rgba(75,192,192,0.4)',
//       pointBorderColor: 'rgba(75,192,192,1)',
//       pointBackgroundColor: '#fff',
//       pointBorderWidth: 1,
//       pointHoverRadius: 5,
//       pointHoverBackgroundColor: 'rgba(75,192,192,1)',
//       pointHoverBorderColor: 'rgba(220,220,220,1)',
//       pointHoverBorderWidth: 2,
//       pointRadius: 1,
//       pointHitRadius: 10,
//     },
//   },
//   {
//     // backgroundColor: colors.grey[200],
//     data: [11, 20, 12, 29, 30, 25, 13],
//     label: 'Last year',
//     format: {
//       label: 'Sales',
//       type: 'line',
//       fill: false,
//       borderColor: '#EC932F',
//       backgroundColor: '#EC932F',
//       pointBorderColor: '#EC932F',
//       pointBackgroundColor: '#EC932F',
//       pointHoverBackgroundColor: '#EC932F',
//       pointHoverBorderColor: '#EC932F',
//     },
//   },
// ];
