import React, { ReactElement } from 'react';
import {
  Bar,
  Line,
  HorizontalBar,
  Scatter,
  Pie,
  Bubble,
  Doughnut,
} from 'react-chartjs-2';
// import * as Test from 'chart.js';
import { Theme, useTheme } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import { ChartTypesEnum } from '../../configuration';
import TablePaginationActions from '../pagination_actions';
import { Box } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import useStyles from './styles';
import 'chartjs-plugin-datalabels';
import clsx from 'clsx';
import { ChartOptions as NativeChartOptions } from 'chart.js';
import { IChartLayoutProps } from '../chart_layout';
import ChartLayout from '../chart_layout';
import './plugins/centerText';

// https://www.chartjs.org/samples/master/
// https://github.com/reactchartjs/react-chartjs-2/tree/master/example/src/components

interface IScatterData {
  x: number;
  y: number;
}

interface IBubbleData {
  x: number | string;
  y: number | string;
  r: number | string;
}

interface IData {
  backgroundColor?: any;
  format?: any;
  data: number[] | IScatterData[] | IBubbleData[];
  label: string;
}

// layoutProps: CardProps;
// titleLayoutProps?: CardHeaderProps;
// contentLayoutProps?: CardContentProps;
// children: React.ReactElement;
// onOverview?: () => React.ReactElement;
// title?: any;
// divider?: boolean;
// FooterContent?: React.ReactElement;
// footerLayoutProps?: BoxProps;

export interface ICustomChartProps extends Omit<IChartLayoutProps, 'children'> {
  data: IData[];
  labels?: string[];
  options?: NativeChartOptions | ((theme: Theme) => NativeChartOptions);
  limitPerPage?: number;
  chartType: ChartTypesEnum;
  includePaging?: boolean;
  height?: number | string;
}

// https://blog.bitsrc.io/customizing-chart-js-in-react-2199fa81530a
const CustomChart = (props: ICustomChartProps) => {
  const {
    data,
    labels,
    limitPerPage = 500,
    chartType,
    includePaging = false,
    height = 240,
    divider,
    options,
    ...ChartLayoutProps
  } = props;

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(limitPerPage);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };
  const limitArray = (() => {
    let newArray: any = [];
    data.forEach(({ format = {}, ...element }: IData) => {
      // console.log('Forma:??', format);
      newArray.push({
        ...format,
        // backgroundColor: element.backgroundColor,
        data: includePaging
          ? element.data.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            )
          : element.data,
      });
    });
    return newArray;
  })();

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const theme = useTheme();
  return (
    <ChartLayout
      FooterContent={
        includePaging ? (
          <TablePagination
            rowsPerPageOptions={[10, 50, 100, 200, 300, 500]}
            component="div"
            count={(labels && labels.length) || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage={'Data per page'}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            ActionsComponent={TablePaginationActions}
          />
        ) : null
      }
      {...ChartLayoutProps}
    >
      <Box height={height} position="relative">
        <Chart
          type={chartType}
          options={
            typeof options === 'object'
              ? options
              : typeof options === 'function'
              ? options(theme)
              : {}
          }
          data={{
            datasets: limitArray,
            labels: labels,
          }}
        />
      </Box>
    </ChartLayout>
  );
};

interface IChartProps {
  type: any;
  data: any;
  options: any;
}

const Chart = ({ type, data, options }: IChartProps) => {
  return (
    <React.Fragment>
      {type === ChartTypesEnum.COLUMN ? (
        <Bar options={options} data={data} />
      ) : type === ChartTypesEnum.LINE ? (
        <Line options={options} data={data} />
      ) : type === ChartTypesEnum.BAR ? (
        <HorizontalBar options={options} data={data} />
      ) : type === ChartTypesEnum.DOTS ? (
        <Scatter options={options} data={data} />
      ) : type === ChartTypesEnum.PIE ? (
        <Pie options={options} data={data} />
      ) : type === ChartTypesEnum.BUBBLE ? (
        <Bubble options={options} data={data} />
      ) : type === ChartTypesEnum.DOUGHNUT ? (
        <Doughnut options={options} data={data} />
      ) : null}
    </React.Fragment>
  );
};

{
  /* <Card
{...layoutProps}
style={{ padding: 0, ...layoutProps.style }}
className={clsx(classes.chartContainer, layoutProps?.className)}
>
<CardHeader
  action={<React.Fragment>{onOverview && onOverview()}</React.Fragment>}
  title={title}
  {...titleLayoutProps}
  style={{
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 10,
    paddingBottom: 0,
    ...titleLayoutProps.style,
  }}
/>
{divider && <Divider />}
<CardContent
  {...contentLayoutProps}
  style={{ padding: 10, ...contentLayoutProps.style }}
  className={clsx(
    classes.contentContainer,
    contentLayoutProps?.className
  )}
>
  <Box height={height} position="relative">
    <Chart
      type={chartType}
      options={
        typeof options === 'object'
          ? options
          : typeof options === 'function'
          ? options(theme)
          : {}
      }
      data={{
        datasets: limitArray,
        labels: labels,
      }}
    />
  </Box>
</CardContent>
{divider && <Divider />}
<Box
  display="flex"
  justifyContent="space-between"
  alignItems="center"
  p={2}
  {...footerLayoutProps}
  style={{ padding: 0, ...footerLayoutProps.style }}
>
  {includePaging && (
    <TablePagination
      rowsPerPageOptions={[10, 50, 100, 200, 300, 500]}
      component="div"
      count={(labels && labels.length) || 0}
      rowsPerPage={rowsPerPage}
      page={page}
      labelRowsPerPage={'Data per page'}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      SelectProps={{
        inputProps: { 'aria-label': 'rows per page' },
        native: true,
      }}
      ActionsComponent={TablePaginationActions}
    />
  )}
</Box>
</Card> */
}

export default CustomChart;
