import React from 'react';
import LanguageIcon from '@material-ui/icons/Language';
import { Button, IconButton } from '@material-ui/core';
import LanguageMenu from '../language_selector';
import TranslatedText from '../../atoms/translated_text';
import { Translations } from './configuration';

export default function LanguageDropdown() {
  const handleLanguageMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setLanAnchorEl(event.currentTarget);
  };
  const [lanAnchorEl, setLanAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const menuLanId = 'primary-lan-account-menu';

  const handleLanMenuClose = () => {
    setLanAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color="primary"
        aria-label="system languages"
        aria-controls={menuLanId}
        aria-haspopup="true"
        onClick={handleLanguageMenuOpen}
      >
        <LanguageIcon />
        <span style={{ marginLeft: 5 }}>
          <TranslatedText textMap={Translations.language} />
        </span>
      </Button>
      <LanguageMenu
        handleLanMenuClose={handleLanMenuClose}
        isLanMenuOpen={Boolean(lanAnchorEl)}
        menuLanId={menuLanId}
        lanAnchorEl={lanAnchorEl}
      />
    </React.Fragment>
  );
}
