import React from 'react';
import DashboardBuilder from 'components/compounds/dashboard_builder';
import * as Configuration from './configuration';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import * as StoreComparisonSelectors from 'redux/reducers/storeComparison_reducer/selectors';

export function MiniStoreDashboard1() {
  const formData = StoreComparisonSelectors.useGetFormData();

  return (
    <Card variant="outlined">
      <CardHeader
        title={`Store 1: ${formData?.store1}`}
        style={{ paddingTop: 7, paddingBottom: 7 }}
      />
      <CardContent style={{ paddingTop: 0 }}>
        <DashboardBuilder
          configuration={Configuration.DashboardBuilderConfig({
            storeid: formData.store1,
            selectedMonth: formData.selectedMonth,
          })}
        />
      </CardContent>
    </Card>
  );
}

export function MiniStoreDashboard2() {
  const formData = StoreComparisonSelectors.useGetFormData();

  return (
    <Card variant="outlined">
      <CardHeader
        title={`Store 2: ${formData?.store2}`}
        style={{ paddingTop: 7, paddingBottom: 7 }}
      />
      <CardContent style={{ paddingTop: 0 }}>
        <DashboardBuilder
          configuration={Configuration.DashboardBuilderConfig({
            storeid: formData.store2,
            selectedMonth: formData.selectedMonth,
          })}
        />
      </CardContent>
    </Card>
  );
}
