import { colors, Theme } from '@material-ui/core';

export const DummyLabels = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

// export const CurrentWeekDataset = (data: number[]) => [
//   {
//     data: data,
//     label: 'Last year',
//     format: {
//       label: 'Sales',
//       type: 'line',
//       fill: false,
//       lineTension: 0.1, // make the lines without radius
//       borderColor: '#5C80CC',
//       backgroundColor: '#5C80CC',
//     },
//   },
// ]

// const colours = data.map((value) => value < 0 ? 'red' : 'green');

export const DummyDataset = (
  currentWeekData: number[] = [11, -7, 12, 29, -3, 5, -3],
  persentageData: number[] = [18, 5, 19, 27, 29, 19, 20]
) => [
  {
    // backgroundColor: colors.grey[200],
    data: currentWeekData,
    label: 'Last year',
    format: {
      label: 'Visitors',
      type: 'line',
      fill: false,
      lineTension: 0.1, // make the lines without radius
      borderColor: '#5C80CC',
      backgroundColor: '#5C80CC',
      datalabels: {
        display: true,
        align: 'end',
        anchor: 'end',
        backgroundColor: function (context: any) {
          return context.dataset.backgroundColor;
        },
        borderRadius: 4,
        color: 'white',
        font: {
          weight: 'bold',
        },
        formatter: function (value: any, context: any) {
          return `${value} (${persentageData[context.dataIndex]})%`;
        },
        padding: 6,
      },
    },
  },
  {
    data: persentageData,
    label: 'This year',
    format: {
      label: '%change',
      fill: false,
      borderColor: persentageData.map((value) =>
        value < 0 ? 'rgba(240, 181, 185, 1)' : 'rgba(120, 254, 201, 1)'
      ),
      backgroundColor: persentageData.map((value) =>
        value < 0 ? 'rgba(240, 181, 185, 1)' : 'rgba(120, 254, 201, 1)'
      ),
      pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
    },
  },
];

export const DummyChartOptions = (theme: Theme) : any => ({
  legend: {
    display: true,
    align: 'start',
    labels: {
      usePointStyle: true,
    },
  },
  maintainAspectRatio: false,
  // plugins: {
  //   beforeInit: function (chart: any, options: any) {
  //     chart.legend.afterFit = function () {
  //       // console.log(
  //       //   'Before afterFit: ' +
  //       //     JSON.stringify(chart.minSize) +
  //       //     ' ' +
  //       //     chart.height
  //       // );
  //       chart.height = chart.height + 50;
  //       // console.log(
  //       //   'After afterFit: ' +
  //       //     JSON.stringify(chart.minSize) +
  //       //     ' ' +
  //       //     chart.height
  //       // );
  //     };
  //   },
  //   datalabels: {
  //     align: 'end',
  //     anchor: 'end',
  //     color: function (context: any) {
  //       return context.dataset.backgroundColor;
  //     },
  //     font: function (context: any) {
  //       var w = context.chart.width;
  //       return {
  //         size: w < 512 ? 12 : 14,
  //         weight: 'bold',
  //         // marginTop: 15,
  //         // paddingTop: 10,
  //       };
  //     },
  //     // display: function (context: any) {
  //     //   if (context.datasetIndex === 4) {
  //     //     return 'Test';
  //     //   } else {
  //     //     return '-';
  //     //   }
  //     // },
  //     formatter: function (value: any, context: any) {
  //       // show label only in last dataset
  //       // console.log("Value:", value, "content:", context)
  //       // return context.datasetIndex;
  //       // let dataSize = context.chart.data.labels.length;
  //       // if (context.dataIndex === dataSize - 1) {
  //       //   return context.chart.data.labels[context.dataIndex];
  //       // } else {
  //       //   return null;
  //       // }
  //       // if (context.datasetIndex === 4) {
  //       //   return 'Test';
  //       // } else {
  //       //   return null;
  //       // }
  //       // return context.chart.data.labels[context.dataIndex];
  //       return value;
  //     },
  //   },
  // },
  // plugins: {
  //   datalabels: {
  //     display: true,
  //   },
  //   font: function (context: any) {
  //     var w = context.chart.width;
  //     return {
  //       size: w < 512 ? 12 : 14,
  //       weight: 'bold',
  //       // marginTop: 15,
  //       // paddingTop: 10,
  //     };
  //   },
  //   formatter: function (value: any, context: any) {
  //     // show label only in last dataset
  //     // console.log("Value:", value, "content:", context)
  //     // return context.datasetIndex;
  //     let dataSize = context.chart.data.labels.length;
  //     if (context.dataIndex === dataSize - 1) {
  //       return context.chart.data.labels[context.dataIndex];
  //     } else {
  //       return null;
  //     }
  //   },
  // },
  responsive: true,
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        gridLines: {
          display: true,
        },
      },
    ],
  },
  tooltips: {
    enabled: true,
  },
  plugins: {
    datalabels: {
      // hide datalabels for all datasets
      display: false,
    }, // datalabels: {
    //   backgroundColor: function(context: any) {
    //     return context.dataset.backgroundColor;
    //   },
    //   borderRadius: 4,
    //   color: 'white',
    //   font: {
    //     weight: 'bold'
    //   },
    //   formatter: Math.round,
    //   padding: 6
    // }
  },
  aspectRatio: 5 / 3,
  layout: {
    padding: {
      top: 32,
      right: 16,
      bottom: 16,
      left: 8,
    },
  },
});
