import { useSelector } from 'react-redux';
import { ReducerKeys } from '../../config';
import useShallowEqualSelector from 'custom_hooks/useShallowEqualsSelector';
import _ from 'lodash';
import * as AnalyticsService from 'services/api/analytics';
import { IState } from './reducer';
import { useQuery } from 'react-query';
import { IState as IAppCommonState } from '../gl_app_common/reducer';
import { Metric } from 'config/visitorsDetails';
import * as StoreService from 'services/api/stores';

export const useVisitorDetailsData = () => {
  // or with subscribe
  const dateRangeType = useSelector(
    (state: any) =>
      (state[ReducerKeys.VISITOR_DETAILS_REDUCER] as IState)
        .selectedDateRangeType
  );
  const store = useSelector(
    (state: any) =>
      (state[ReducerKeys.APP_COMMON_REDUCER] as IAppCommonState).selectedStore
  );
  const metric = useSelector(
    (state: any) =>
      (state[ReducerKeys.VISITOR_DETAILS_REDUCER] as IState).selectedMetric
  );
  const timeType = useSelector(
    (state: any) =>
      (state[ReducerKeys.VISITOR_DETAILS_REDUCER] as IState).selectedTimeType
  );
  const camera = useSelector(
    (state: any) =>
      (state[ReducerKeys.VISITOR_DETAILS_REDUCER] as IState).selectedCamera
  );
  const result = useQuery(
    ['fetch-visitors-details', dateRangeType, store, metric, timeType, camera],
    () => {
      if (store && camera && metric && dateRangeType) {
        return AnalyticsService.getDetailsScaleData({
          timeType,
          metric: metric === 'all' ? [Metric.In, Metric.Out] : [metric],
          dateRangeType,
          storeId: store,
          cameraId: camera === 'all' ? undefined : camera,
        });
      }
    },
    {
      select: (data) => {
        return data?.data;
      },
    }
  );

  return result;
};

export const useDailyTotalVisitors = () => {
  const store = useSelector(
    (state: any) =>
      (state[ReducerKeys.APP_COMMON_REDUCER] as IAppCommonState).selectedStore
  );
  const result = useQuery(
    ['daily-total-visitors', store],
    () => {
      if (store) {
        return StoreService.getStoreStatisticData(store);
        // AnalyticsService.getVisitorsDailyData(store);
      }
    },
    {
      select: (data) => {
        return data?.data;
      },
    }
  );

  return result;
};
