import React from 'react';
import { useHistory, withRouter } from 'react-router'; // import the react-router-dom components
import PageLayout from 'components/app_common/page_layout';
import DataList from 'components/molecules/data_list';
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Paper,
  useTheme,
} from '@material-ui/core';
import TranslatedText from 'components/atoms/translated_text';
import { withRoles } from 'navigation/middlewares';
import { Roles } from 'config/authRoles';
import Avatar from '@material-ui/core/Avatar';
import ModalAddStore, { IModalParams } from './components/modal_add_edit_store';
import ModalAddUser, {
  IModalParams as IAddUserModal,
} from './components/modal_add_user';
import ModalRemoveStore, {
  IModalParams as IRemoveStoreModal,
} from './components/modal_remove_store';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import { useDispatch } from 'react-redux';
import StoreActions from 'redux/reducers/stores_reducer/actions';
import * as StoreSelectors from 'redux/reducers/stores_reducer/selectors';
import Environment from 'config/environment';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import useStyles from './styles';
import Typography from '@material-ui/core/Typography';
import GridLayout from 'components/molecules/grid_layout';
import _ from 'lodash';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import GroupWorkOutlinedIcon from '@material-ui/icons/GroupWorkOutlined';
import StoreMallDirectoryOutlinedIcon from '@material-ui/icons/StoreMallDirectoryOutlined';
import ModalEditCameras, {
  IModalParams as ModalEditCamerasParams,
} from './components/modal_add_edit_cameras';
import { IStoreData } from 'services/api/stores';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import TabsPanel from 'components/molecules/tabs_panel';
import UsersDatatable from './components/users_datatable';
import * as Configuration from './configuration';
import * as AuthSelectors from 'redux/reducers/gl_auth_reducer/selectors';
import StoresDatatable from './components/stores_datatabe';
import BoxesDatatable from './components/boxes_datatable';
import CamerasDatatable from './components/cameras_datatable';
import AlertsDatatable from './components/alerts_datatable';
import ModalEditViewCamera from './components/modal_edit_view_camera';
import useQuery from 'custom_hooks/useQuery';
import NavigationConfig from 'navigation/config';
import { BoxStatus } from 'config/store';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import ModalAddEditAlert, {
  IModalParams as IAddEditAlertModalParams,
} from './components/modal_add_edit_alert';
import GroupDatatable from './components/groups_datatabe';
import ModalAddEditGroup, {
  IModalParams as IAddEditGroupModalParams,
} from './components/modal_add_edit_group';

const {
  UsersListDefaultFiltering,
  CamerasListDefaultFiltering,
  BoxesListDefaultFiltering,
  TabType,
  AlertsListDefaultFiltering,
  GroupListDefaultFiltering,
} = Configuration;

const StoresPage = () => {
  const dispatch = useDispatch();
  const role = AuthSelectors.useSelectUserRole();
  const query = useQuery();
  const type = query.get('type') || TabType.Stores;
  const status = query.get('status') || '';
  const storeid = query.get('storeid') || '';
  const boxid = query.get('boxid') || '';
  const email = query.get('email') || '';
  const groupName = query.get('groupName') || '';

  const history = useHistory();

  React.useEffect(() => {
    dispatch(StoreActions.fetchUsersAction());
    dispatch(StoreActions.fetchGroupsBasicInfoAction());
    if (type === TabType.Boxes) {
      dispatch(
        StoreActions.fetchBoxesListAction({
          data: {
            page: BoxesListDefaultFiltering.DefaultPage,
            pageSize: BoxesListDefaultFiltering.DefaultPageSize,
            sortField: BoxesListDefaultFiltering.DefaultSortField,
            order: BoxesListDefaultFiltering.DefaultOrder,
            status: status as BoxStatus,
            storeid,
            boxid,
          },
          onFinish: () => {},
        })
      );
    } else if (type === TabType.Cameras) {
      dispatch(
        StoreActions.fetchCamerasListAction({
          data: {
            page: CamerasListDefaultFiltering.DefaultPage,
            pageSize: CamerasListDefaultFiltering.DefaultPageSize,
            sortField: CamerasListDefaultFiltering.DefaultSortField,
            order: CamerasListDefaultFiltering.DefaultOrder,
            status: status as BoxStatus,
            storeid,
            boxid,
          },
          onFinish: () => {},
        })
      );
    } else if (type === TabType.Stores) {
      dispatch(
        StoreActions.fetchStoresAction({
          groupName: groupName || undefined,
        })
      );
    } else if (type === TabType.Users) {
      if (role === Roles.Admin) {
        dispatch(
          StoreActions.fetchUsersWithDetailsAction({
            page: UsersListDefaultFiltering.DefaultPage,
            pageSize: UsersListDefaultFiltering.DefaultPageSize,
            sortField: UsersListDefaultFiltering.DefaultSortField,
            order: UsersListDefaultFiltering.DefaultOrder,
            email: email || undefined,
            groupName: groupName || undefined,
          })
        );
      }
    } else if (type === TabType.Alerts) {
      dispatch(
        StoreActions.fetchAlertsListAction({
          data: {
            page: AlertsListDefaultFiltering.DefaultPage,
            pageSize: AlertsListDefaultFiltering.DefaultPageSize,
            sortField: AlertsListDefaultFiltering.DefaultSortField,
            order: AlertsListDefaultFiltering.DefaultOrder,
            storeid,
          },
          onFinish: () => {},
        })
      );
    } else if (type === TabType.Group) {
      dispatch(
        StoreActions.fetchGroupsAction({
          page: GroupListDefaultFiltering.DefaultPage,
          pageSize: GroupListDefaultFiltering.DefaultPageSize,
          sortField: GroupListDefaultFiltering.DefaultSortField,
          order: GroupListDefaultFiltering.DefaultOrder,
        })
      );
    }
  }, [dispatch, role, type, status, storeid, boxid, email]);

  const TabsConfig = React.useMemo(() => {
    const defaultTabs = [
      {
        title: 'Stores',
        content: <StoresDatatable />,
        id: TabType.Stores,
      },
      {
        title: 'Boxes',
        content: <BoxesDatatable />,
        id: TabType.Boxes,
      },
      {
        title: 'Cameras',
        content: <CamerasDatatable />,

        id: TabType.Cameras,
      },
    ];
    if (role === Roles.Admin) {
      defaultTabs.push({
        title: 'Alerts',
        content: <AlertsDatatable />,

        id: TabType.Alerts,
      });
    }
    if (role === Roles.Admin) {
      defaultTabs.push({
        title: 'Users',
        content: <UsersDatatable />,
        id: TabType.Users,
        // tabProps?: TabProps;
      });
    }
    if (role === Roles.Admin) {
      defaultTabs.unshift({
        title: 'Groups',
        content: <GroupDatatable />,
        id: TabType.Group,
      });
    }
    return defaultTabs;
  }, [role]);

  return (
    <PageLayout>
      <ModalAddUser />
      <ModalEditViewCamera />
      <ModalAddStore />
      <ModalRemoveStore />
      <ModalAddEditAlert />
      <ModalAddEditGroup />

      <Box style={{ marginTop: 10 }}>
        <ButtonGroup>
          <AddGroupBtn />
          <AddStoreBtn />
          <AddUserBtn />
        </ButtonGroup>
        <TabsPanel
          elementsConfig={TabsConfig}
          defaultSelection={TabsConfig.findIndex((el) => el.id === type) || 0}
          onChange={(index) => {
            history.push(
              NavigationConfig.storePage({ type: TabsConfig[index].id }).path
            );
          }}
        />
      </Box>
    </PageLayout>
  );
};

const AddStoreBtn = withRoles(() => {
  const dispatch = useDispatch();

  return (
    <ButtonGroup style={{ marginRight: '1rem' }}>
      <Button
        onClick={() =>
          dispatch(
            ModalActions.openModalAction({
              modalID: ModalIDs.MODAL_ADD_STORE,
              params: { mode: 'add' } as IModalParams,
            })
          )
        }
      >
        <StoreMallDirectoryOutlinedIcon />
        <Box marginRight="1rem" />
        <TranslatedText defaultText="Add Store" />
      </Button>
    </ButtonGroup>
  );
}, [Roles.Admin]);

const AddGroupBtn = withRoles(() => {
  const dispatch = useDispatch();

  return (
    <ButtonGroup style={{ marginRight: '1rem' }}>
      <Button
        onClick={() =>
          dispatch(
            ModalActions.openModalAction({
              modalID: ModalIDs.MODAL_ADD_EDIT_GROUP,
              params: { mode: 'add' } as IModalParams,
            })
          )
        }
      >
        <GroupWorkOutlinedIcon />
        <Box marginRight="1rem" />
        <TranslatedText defaultText="Add Group" />
      </Button>
    </ButtonGroup>
  );
}, [Roles.Admin]);

const AddUserBtn = withRoles(() => {
  const dispatch = useDispatch();

  return (
    <ButtonGroup style={{ marginRight: '1rem' }}>
      <Button
        onClick={() =>
          dispatch(
            ModalActions.openModalAction({
              modalID: ModalIDs.MODAL_ADD_USER,
              params: { mode: 'add' } as IModalParams,
            })
          )
        }
      >
        <PersonAddOutlinedIcon />
        <Box marginRight="1rem" />
        <TranslatedText defaultText="Add User" />
      </Button>
    </ButtonGroup>
  );
}, [Roles.Admin]);

export default withRouter(StoresPage);
