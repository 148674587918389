import { combineReducers } from 'redux';
import AuthReducer from './gl_auth_reducer/reducer';
import TranslationReducer from './gl_translations_reducer/reducer';
import NavigationReducer from './gl_navigation_reducer/reducer';
import ModalReducer from './gl_modal_reducer/reducer';
import ThemeReducer from './gl_theme_reducer/reducer';

import ResetPasswordReducer from './resetPassword_reducer/reducer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import ChangePasswordReducer from './changePassword_reducer/reducer';
import UserProfileReducer from './userProfile_reducer/reducer';
import StorePerformanceReducer from './storePerformance_reducer/reducer';
import VisitorAnalyticsReducer from './visitorAnalytics_reducer/reducer';
import TimeGroupReducer from './timeGroup_reducer/reducer';
import VisitorsByDayReducer from './visitorsByDay_reducer/reducer';
import StoreComparisonReducer from './storeComparison_reducer/reducer';
import StoresReducer from './stores_reducer/reducer';
import HeatmapCountReducer from './storeHeatmapCount_reducer/reducer';
import NotificationReducer from './notification_reducer/reducer';
import AppCommonReducer from './gl_app_common/reducer';
import OccupancyReducer from './occupancy_reducer/reducer';
import VisitorDetailsReducer from './visitorDetails_reducer/reducer';

import { LOGOUT_TYPE } from './gl_auth_reducer/types';
import { ReducerKeys } from '../config';
import _ from 'lodash';
import { persistor } from '../store';

const authPersistConfig = {
  key: ReducerKeys.AUTH_REDUCER,
  storage: storage,
  blacklist: ['status'],
};

const translationPersistConfig = {
  key: ReducerKeys.TRANSL_REDUCER,
  storage: storage,
};

const navigationPersistConfig = {
  key: ReducerKeys.NAVIGATION_REDUCER,
  storage: storage,
};

const appCommonPersistConfig = {
  key: ReducerKeys.APP_COMMON_REDUCER,
  storage: storage,
  blacklist: ['stores'],
};

export const asyncReducers = {
  // [ReducerKeys.STORE_PERFORMANCE_REDUCER]: StorePerformanceReducer,
  // [ReducerKeys.USER_PROFILE_REDUCER]: UserProfileReducer,
  // [ReducerKeys.RESET_PASSOWRD_REDUCER]: ResetPasswordReducer,
  // [ReducerKeys.REGISTER_REDUCER]: RegisterReducer,
  // [ReducerKeys.REGISTRATION_LIST_REDUCER]: RegistrationListReducer,
  // [ReducerKeys.CHANGE_PASSWORD_REDUCER]: ChangePasswordReducer,
};

export const staticReducers = {
  // global
  [ReducerKeys.AUTH_REDUCER]: persistReducer(authPersistConfig, AuthReducer),
  [ReducerKeys.TRANSL_REDUCER]: persistReducer(
    translationPersistConfig,
    TranslationReducer
  ),
  [ReducerKeys.NAVIGATION_REDUCER]: persistReducer(
    navigationPersistConfig,
    NavigationReducer
  ),
  [ReducerKeys.MODAL_REDUCER]: ModalReducer,
  [ReducerKeys.THEME_REDUCER]: ThemeReducer,

  [ReducerKeys.RESET_PASSOWRD_REDUCER]: ResetPasswordReducer,
  [ReducerKeys.CHANGE_PASSWORD_REDUCER]: ChangePasswordReducer,
  [ReducerKeys.USER_PROFILE_REDUCER]: UserProfileReducer,
  [ReducerKeys.STORE_PERFORMANCE_REDUCER]: StorePerformanceReducer,
  [ReducerKeys.VISITOR_ANALYTICS_REDUCER]: VisitorAnalyticsReducer,
  [ReducerKeys.TIMEGROUP_REDUCER]: TimeGroupReducer,
  [ReducerKeys.NOTIFICATION_REDUCER]: NotificationReducer,
  [ReducerKeys.VISITORS_BY_DAY_REDUCER]: VisitorsByDayReducer,
  [ReducerKeys.STORE_COMPARISON_REDUCER]: StoreComparisonReducer,
  [ReducerKeys.STORES_REDUCER]: StoresReducer,
  [ReducerKeys.OCCUPANCY_REDUCER]: OccupancyReducer,
  [ReducerKeys.HEATMAP_COUNT_REDUCER]: HeatmapCountReducer,
  [ReducerKeys.VISITOR_DETAILS_REDUCER]: VisitorDetailsReducer,
  [ReducerKeys.APP_COMMON_REDUCER]: persistReducer(
    appCommonPersistConfig,
    AppCommonReducer
  ),
};

// export default
function createReducer(asyncReducers = {}, store = {}) {
  return combineReducers({
    ...staticReducers,
    ...asyncReducers,
  });
}

const rootReducer =
  (asyncReducers = {}, store = {}) =>
  (state: any, action: any) => {
    if (action.type === LOGOUT_TYPE) {
      // for all keys defined in your persistConfig(s)
      console.log('LOGOUT USER !!!!!!!!!!');

      // window.location.reload();
      storage.removeItem('persist:root');
      localStorage.clear();

      return createReducer(asyncReducers, store)(undefined, action);
    }
    return createReducer(asyncReducers, store)(state, action);
  };

export default rootReducer;
