// export const DefaultPageSize = 25;
// export const DefaultPage = 0;
// export const DefaultSortField = 'registeredDate';
// export const DefaultOrder = 'desc';

interface IDefaultFiltering {
  DefaultPageSize: number;
  DefaultPage: number;
  DefaultSortField: string;
  DefaultOrder: 'desc' | 'asc';
}

export const NotificationListDefaultFiltering: IDefaultFiltering = {
  DefaultPageSize: 25,
  DefaultPage: 0,
  DefaultSortField: 'createdAt',
  DefaultOrder: 'desc',
};

export const CamerasListDefaultFiltering: IDefaultFiltering = {
  DefaultPageSize: 25,
  DefaultPage: 0,
  DefaultSortField: 'cameraId',
  DefaultOrder: 'desc',
};

export const BoxesListDefaultFiltering: IDefaultFiltering = {
  DefaultPageSize: 25,
  DefaultPage: 0,
  DefaultSortField: 'createdAt',
  DefaultOrder: 'desc',
};

export const AlertsListDefaultFiltering: IDefaultFiltering = {
  DefaultPageSize: 25,
  DefaultPage: 0,
  DefaultSortField: '',
  DefaultOrder: 'desc',
};

export enum TabType {
  Stores = 'stores',
  Boxes = 'boxes',
  Cameras = 'cameras',
  Users = 'users',
  Alerts = 'alerts',
}
