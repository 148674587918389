import { colors, Theme } from '@material-ui/core';
import * as RandomUtils from 'utils/randomize';

export const DummyLabels = ['6', '8', '10', '12', '14', '16'];

export const DummyDataset = (
  dataset1 = {
    label: 'DummyDataset1',
    data: RandomUtils.generateRandomSequence(1, 15, DummyLabels.length),
  },
  dataset2 = {
    label: 'DummyDataset2',
    data: RandomUtils.generateRandomSequence(1, 15, DummyLabels.length),
  },
  dataset3 = {
    label: 'DummyDataset3',
    data: RandomUtils.generateRandomSequence(1, 15, DummyLabels.length),
  },
  dataset4 = {
    label: 'DummyDataset4',
    data: RandomUtils.generateRandomSequence(1, 15, DummyLabels.length),
  }
) => [
  {
    data: dataset4.data,
    label: 'Label4',
    format: {
      label: dataset4.label,
      type: 'line',
      fill: false,
      borderColor: '#FF6100',
      lineTension: 0.1, // make the lines without radius
      backgroundColor: '#FF6100',
      pointBorderColor: '#FF6100',
      pointBackgroundColor: '#FF6100',
      pointHoverBackgroundColor: '#FF6100',
      pointHoverBorderColor: '#FF6100',
    },
    // #DF0494
  },
  {
    data: dataset3.data,
    label: 'Label3',
    format: {
      label: dataset3.label,
      type: 'line',
      fill: false,
      lineTension: 0.1, // make the lines without radius
      borderColor: '#DF0494',
      backgroundColor: '#DF0494',
      pointBorderColor: '#DF0494',
      pointBackgroundColor: '#DF0494',
      pointHoverBackgroundColor: '#DF0494',
      pointHoverBorderColor: '#DF0494',
    },
  },
  {
    data: dataset2.data,
    label: 'Label2',
    format: {
      label: dataset2.label,
      type: 'line',
      fill: false,
      lineTension: 0.1, // make the lines without radius
      borderColor: '#4668C2',
      backgroundColor: '#4668C2',
      pointBorderColor: '#4668C2',
      pointBackgroundColor: '#4668C2',
      pointHoverBackgroundColor: '#4668C2',
      pointHoverBorderColor: '#4668C2',
    },
  },
  {
    data: dataset1.data,
    label: 'Label1',
    format: {
      label: dataset1.label,
      type: 'line',
      fill: false,
      lineTension: 0.1, // make the lines without radius
      borderColor: '#9E018F',
      backgroundColor: '#9E018F',
      pointBorderColor: '#9E018F',
      pointBackgroundColor: '#9E018F',
      pointHoverBackgroundColor: '#9E018F',
      pointHoverBorderColor: '#9E018F',
    },
  },
];

export const DummyChartOptions = (theme: Theme) : any => ({
  legend: {
    display: true,
    align: 'start',
    labels: {
      usePointStyle: true,
      padding: 14,
    },
  },
  maintainAspectRatio: false,
  responsive: true,
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          autoSkip: false,
          // maxRotation: 90,
          // minRotation: 90,
        },
      },
    ],
    yAxes: [
      {
        // stacked: true,
        // ticks: {
        //   beginAtZero: true,
        // },
      },
    ],
  },
  tooltips: {
    enabled: true,
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
  // layout: {
  //   padding: {
  //     left: 50,
  //     right: 0,
  //     top: 0,
  //     bottom: 0,
  //   },
  // },
  // animation: true,
  // cornerRadius: 20,
  // layout: { padding: 0 },
  // legend: {
  //   display: true,
  //   align: 'start',
  //   labels: {
  //     usePointStyle: true,
  //   },
  // },
  // maintainAspectRatio: false,
  // responsive: true,
  // scales: {
  //   xAxes: [
  //     {
  //       barThickness: 12,
  //       maxBarThickness: 10,
  //       barPercentage: 0.5,
  //       categoryPercentage: 0.5,
  //       ticks: {
  //         fontColor: theme.palette.text.secondary,
  //       },
  //       gridLines: {
  //         display: false,
  //         drawBorder: false,
  //       },
  //     },
  //   ],
  //   yAxes: [
  //     {
  //       ticks: {
  //         fontColor: theme.palette.text.secondary,
  //         beginAtZero: true,
  //         // min: 0,
  //       },
  //       gridLines: {
  //         borderDash: [2],
  //         borderDashOffset: [2],
  //         color: theme.palette.divider,
  //         drawBorder: false,
  //         zeroLineBorderDash: [2],
  //         zeroLineBorderDashOffset: [2],
  //         zeroLineColor: theme.palette.divider,
  //       },
  //     },
  //   ],
  // },
  // tooltips: {
  //   backgroundColor: theme.palette.background.default,
  //   bodyFontColor: theme.palette.text.secondary,
  //   borderColor: theme.palette.divider,
  //   borderWidth: 1,
  //   enabled: true,
  //   footerFontColor: theme.palette.text.secondary,
  //   intersect: false,
  //   mode: 'index',
  //   titleFontColor: theme.palette.text.primary,
  // },
});
