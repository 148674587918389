//  [REDUCER][TYPE]
import { typeCreatorV2 } from '../../utils/common';

export const SET_NOTIFICATION_LIST: string = typeCreatorV2(
  'NotificationReducer',
  'SetNotificationList'
);

export const ADD_NOTIFICATION: string = typeCreatorV2(
  'NotificationReducer',
  'AddNotification'
);

export const READ_UNREAD_NOTIFICATION: string = typeCreatorV2(
  'NotificationReducer',
  'ReadUnreadNotification'
);

export const SET_USERS_LIST: string = typeCreatorV2(
  'NotificationReducer',
  'SetUsersListDetails'
);
