interface Environment {
  API_BASE_URL: string;
  APP_NAME: string;
  STATIC_URL: string;
  APP_LOGO: any;
}

const Prodution: Environment = {
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL || '',
  STATIC_URL: process.env.REACT_APP_API_BASE_URL + '/static',
  APP_NAME: 'CellockAI',
  APP_LOGO: require('assets/images/logos/logo-transparent.png'),
};

const Development: Environment = {
  API_BASE_URL:  'http://localhost:1337', //
  STATIC_URL: 'http://localhost:1337/static', //'http://192.168.2.15:1337/static',
  APP_NAME: 'CellockAI',
  APP_LOGO: require('assets/images/logos/logo-transparent.png'),
};

export default process.env.NODE_ENV === 'production' ? Prodution : Development;
