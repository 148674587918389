export enum ReducerKeys {
  AUTH_REDUCER = 'AuthReducer',
  TRANSL_REDUCER = 'TranslationReducer',
  NAVIGATION_REDUCER = 'NavigationReducer',
  MODAL_REDUCER = 'ModalReducer',
  RESET_PASSOWRD_REDUCER = 'ResetPasswordReducer',
  CHANGE_PASSWORD_REDUCER = 'ChangePasswordReducer',
  USER_PROFILE_REDUCER = 'UserProfileReducer',
  STORE_PERFORMANCE_REDUCER = 'StorePerformanceReducer',
  VISITOR_ANALYTICS_REDUCER = 'VisitorAnalyticsReducer',
  TIMEGROUP_REDUCER = 'TimeGroupReducer',
  VISITORS_BY_DAY_REDUCER = 'VisitorsByDayReducer',
  STORE_COMPARISON_REDUCER = 'StoreComparisonReducer',
  STORES_REDUCER = 'StoresReducer',
  HEATMAP_COUNT_REDUCER = 'HeatmapCountReducer',
  APP_COMMON_REDUCER = 'AppCommonReducer',
  THEME_REDUCER = 'ThemeReducer',
  NOTIFICATION_REDUCER = 'NotificationReducer',
  OCCUPANCY_REDUCER = 'OccupancyReducer',
  VISITOR_DETAILS_REDUCER = 'VisitorDetailsReducer',
}
