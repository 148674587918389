import GridLayout from 'components/molecules/grid_layout';
import React, { ReactElement } from 'react';
import SelectForm from '../select_form';
import reactLogger from 'utils/logger';

export default function GraphsLayout(): ReactElement {
  reactLogger.renderComponent('GraphsLayout');
  return (
    <React.Fragment>
      <GridLayout
        spacing={1}
        elements={[
          {
            size: 12,
            element: <SelectForm />,
            id: '1',
          },
        ]}
      />
    </React.Fragment>
  );
}
