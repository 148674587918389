import React, { ReactElement } from 'react';
import useStyles from './styles';
import MobileList from './components/mobile_list';
import { IProps, Order } from './configuration';
import AppTable from './components/table';

export default function Datatable(props: IProps): ReactElement {
  const classes = useStyles({});
  let page = props.paging?.page || 0;
  let rowsPerPage = props.paging?.pageSize || 10;
  const sortType = React.useRef<Order | undefined>(props.sorting?.order);
  const sortField = React.useRef<string | undefined>(props.sorting?.sortField);

  const handleChangePage = (event: unknown, newPage: number) => {
    if (props.changePageHandle) {
      const order = sortType.current || 'asc';
      props.changePageHandle(
        newPage,
        rowsPerPage,
        order,
        sortField.current || '',
        order
      );
    }
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string //keyof IData
  ) => {
    if (props.changePageHandle) {
      if (sortField.current !== property) {
        sortType.current = 'asc';
      } else {
        sortType.current = sortType.current === 'asc' ? 'desc' : 'asc';
      }

      sortField.current = property;
      props.changePageHandle(
        page,
        rowsPerPage,
        sortType.current,
        property,
        sortType.current || 'asc'
      );
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (props.changePageHandle && props.paging) {
      const order = sortType.current || 'asc';

      props.changePageHandle(
        props.paging.page,
        +event.target.value,
        order,
        props.sorting?.sortField || '',
        order
      );
    }
  };

  // keyof Data
  const createSortHandler =
    (property: string, inData: boolean) =>
    (event: React.MouseEvent<unknown>) => {
      if (inData) {
        handleRequestSort(event, property);
      }
    };

  return (
    <React.Fragment>
      <AppTable
        {...props}
        createSortHandler={createSortHandler}
        handleChangePage={handleChangePage}
        order={props.sorting?.order || 'asc'}
        rowsPerPage={rowsPerPage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        orderBy={props.sorting?.sortField || ''}
        page={page}
      />
      {/* hidden up to small */}
      <MobileList
        {...props}
        createSortHandler={createSortHandler}
        handleChangePage={handleChangePage}
        // order={order}
        rowsPerPage={rowsPerPage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        orderBy={props.sorting?.sortField || ''}
        page={page}
      />
    </React.Fragment>
  );
}
