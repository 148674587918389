import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: 150,
      height: ({ height }: any) => height,
      borderRadius: 8,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: '0.5rem 2rem 0.5rem 2rem',
    },
    title: {
      fontSize: '34px',
      fontWeight: 'bolder',
      lineHeight: 1.2,
    },
    description: {
      fontSize: '20px',
      fontWeight: 'bold',
    },
  })
);

export default useStyles;
