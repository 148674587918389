import { IAction, IActionMethods, StateStatus } from '../../utils/common';
import * as Types from './types';
import * as AuthenticationService from '../../../services/api/authenticate';
import { Dispatch } from 'redux';
import NavigationConfig from '../../../navigation/config';
import { AuthRoles } from '../../../config';
import { getState } from '../../store';
import { ReducerKeys } from '../../config';
import _ from 'lodash';
import { MetricTypes } from 'config/metricTypes';

/** Authenticate Action  */

interface IAutheticateInput {
  username: string;
  password: string;
  stayLogin: boolean;
}

interface IAutheticateViaTokenInput {
  token: string;
  refreshToken: string;
  stayLogin: boolean;
}

interface IAutheticateOutput {
  token: string;
  refreshToken: string;
  role: string;
  id: string;
  stayLogin: boolean;
  email: string;
  username: string;
  permissions: {
    camera: MetricTypes[];
  };
  group?: {
    name: string;
    icon: string;
  };
}

class Authenticate implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.LOGIN_TYPE,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(result: IAutheticateOutput): IAction {
    const data = {
      role: result.role,
      token: result.token,
      refreshToken: result.refreshToken,
      stayLogin: result.stayLogin,
      data: {
        username: result.username,
        email: result.email,
        id: result.id,
      },
      permissions: result.permissions,
      group: result.group,
    };

    return {
      type: Types.LOGIN_TYPE,
      status: StateStatus.Success,
      data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.LOGIN_TYPE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(data: IAutheticateInput | IAutheticateViaTokenInput): any {
    return async (dispatch: Dispatch<any>) => {
      let tempToken;
      let tempTokenRefresh;

      try {
        dispatch(this.onPending());
        if ('password' in data) {
          const result = await AuthenticationService.authenticateUser({
            username: data.username,
            password: data.password,
          });

          dispatch(
            this.onSuccess({
              token: result.access_token,
              role: result.user.role,
              id: result.user.uuid,
              stayLogin: data.stayLogin,
              refreshToken: result.access_token,
              email: _.get(result.user, 'data.email', 'Email Not Found'),
              username: _.get(result.user, 'data.username', ''),
              permissions: result.user.permissions,
              group: result.group,
            })
          );
        } else {
          let _input: IAutheticateViaTokenInput = data as any;

          tempToken = _input.token;
          tempTokenRefresh = _input.refreshToken;

          try {
            const result = await AuthenticationService.getUserData({
              token: _input.token,
              refreshToken: _input.refreshToken,
            });

            dispatch(
              this.onSuccess({
                token: _input.token,
                role: result.data.role,
                id: result.data.id,
                stayLogin: data.stayLogin,
                refreshToken: data.refreshToken,
                email: result.data.email,
                username: result.data.username,
                permissions: result.data.permissions,
              })
            );
          } catch (error) {
            dispatch(new Logout().action());
            alert(
              `Error...: ${tempToken} ___ Refresh: ${tempTokenRefresh} ${error.message}`
            );
            throw new Error();
          }
        }
      } catch (error) {
        console.log('Authenticate Error:', error.message); // '<ClassName> Error: <error>'
        // logout
        dispatch(this.onFailed());
      }
    };
  }
}

/** Logout Action  */

class Logout implements IActionMethods {
  onPending(result?: any): IAction {
    throw new Error('Method not implemented.');
  }
  onSuccess(): IAction {
    return {
      type: Types.LOGOUT_TYPE,
      status: StateStatus.Success,
      data: {},
    };
  }

  onFailed(): IAction {
    return {
      type: Types.LOGOUT_TYPE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(history?: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        // for webview
        (window as any)?.ReactNativeWebView &&
          (window as any).ReactNativeWebView.postMessage('LOGOUT');
        dispatch(this.onSuccess());
      } catch (error) {
        console.log('Logout Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      } finally {
        if (history) {
          history.push(NavigationConfig.loginPage().path);
        }
      }
    };
  }
}

/** Refresh Token Action  */

interface IRefreshTokenInput {
  token: string;
  refreshToken: string;
}

interface IRefreshTokenOutput {
  token: string;
  refreshToken: string;
}

class RefreshToken implements IActionMethods {
  onPending(result?: any): IAction {
    throw new Error('Method not implemented.');
  }
  onSuccess(data: IRefreshTokenOutput): IAction {
    throw new Error('Method not implemented.');
  }

  onFailed(): IAction {
    throw new Error('Method not implemented.');
  }

  action(data: IRefreshTokenInput): IAction {
    return new Authenticate().onSuccess({
      token: data.token,
      refreshToken: data.refreshToken,
      stayLogin: true,
      role: getState(ReducerKeys.AUTH_REDUCER).role,
      id: getState(ReducerKeys.AUTH_REDUCER).id,
      username: getState(ReducerKeys.AUTH_REDUCER).data.username,
      email: getState(ReducerKeys.AUTH_REDUCER).data.email,
      permissions: getState(ReducerKeys.AUTH_REDUCER).permissions,
      group: getState(ReducerKeys.AUTH_REDUCER).group,
    });
  }
}

export default {
  authenticateAction: (data: IAutheticateInput | IAutheticateViaTokenInput) =>
    new Authenticate().action(data),
  logoutAction: (history?: any) => new Logout().action(history),
  refreshTokenAction: (refreshToken: string, token: string) =>
    new RefreshToken().action({ refreshToken, token }),
};
