//  [REDUCER][TYPE]
import { typeCreatorV2 } from '../../utils/common';
export const ADD_STORE: string = typeCreatorV2('StoresReducer', 'AddStore');
export const SET_STORES: string = typeCreatorV2('StoresReducer', 'SetStores');
export const EDIT_STORE: string = typeCreatorV2('StoresReducer', 'EditStore');
export const REMOVE_STORE: string = typeCreatorV2(
  'StoresReducer',
  'RemoveStore'
);
export const SET_USERS: string = typeCreatorV2('StoresReducer', 'SetUsers');
export const EDIT_STORE_CAMERAS: string = typeCreatorV2(
  'StoresReducer',
  'EditStoreCameras'
);
export const ADD_USER: string = typeCreatorV2('StoresReducer', 'AddUser');
export const SET_USERS_LIST: string = typeCreatorV2(
  'StoresReducer',
  'SetUsersListDetails'
);

export const EDIT_USER: string = typeCreatorV2('StoresReducer', 'EditUser');

export const SET_CAMERA_LIST: string = typeCreatorV2(
  'StoresReducer',
  'SetCameraList'
);

export const SET_BOXES_LIST: string = typeCreatorV2(
  'StoresReducer',
  'SetBoxesList'
);

export const REMOVE_CAMERA: string = typeCreatorV2(
  'StoresReducer',
  'RemoveCamera'
);

export const REMOVE_BOX: string = typeCreatorV2('StoresReducer', 'RemoveBox');

export const SET_ALERTS_LIST: string = typeCreatorV2(
  'StoresReducer',
  'SetAlerts'
);

export const UPDATE_ALERT: string = typeCreatorV2(
  'StoresReducer',
  'UpdateAlert'
);

export const REMOVE_ALERT: string = typeCreatorV2('StoresReducer', 'Remove');

export const ADD_GROUP: string = typeCreatorV2('StoresReducer', 'AddGroup');
export const SET_GROUPS: string = typeCreatorV2('StoresReducer', 'SetGroups');
export const EDIT_GROUP: string = typeCreatorV2('StoresReducer', 'EditGroup');
export const REMOVE_GROUP: string = typeCreatorV2(
  'StoresReducer',
  'RemoveGroup'
);
export const SET_GROUPS_BASIC_INFO: string = typeCreatorV2(
  'StoresReducer',
  'SetGroupsBasicInfo'
);
