// {{BASE_URL}}/api/schemeType
import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';
import { varToStringParams } from 'utils/path';

/** getCountries  */
interface IGetCountriesOutput {
  code: string;
  code3: string;
  name: string;
  number: string;
}

export const getCountries = async (): Promise<Types.ResponseOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/users/nationality`
  );

  let _data: IGetCountriesOutput[] = data.data;
  let output: Types.ResponseOutput = {
    message: data.message,
    data: _data,
    status: data.status,
  };
  return output;
};

/** get user profile  */
interface IGetUserProfileOutput extends Types.ResponseOutput {
  data: {
    email: string;
    firstname: string;
    surname: string;
    nationality: string;
    gender: string;
    birthDate: string;
    registeredDate: string;
    role: string;
  };
}

export const getUserProfile = async (): Promise<IGetUserProfileOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/users/profile`
  );

  let output: IGetUserProfileOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** edit user profile  */
interface IEditUserProfileOutput extends Types.ResponseOutput {
  data: {
    email: string;
    firstName: string;
    surname: string;
    nationality: string;
    gender: string;
    birthDate: string;
    registeredDate: string;
    role: string;
  };
}

export interface IEditUserProfileInput {
  email: string;
  firstName: string;
  surname: string;
}

export const editUserProfile = async (
  payload: IEditUserProfileInput
): Promise<IEditUserProfileOutput> => {
  const { data }: any = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/users/profile`,
    {
      body: payload,
    }
  );

  let output: IEditUserProfileOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** getStores  */
interface IStoresData {
  uniqueId: string;
  name: string;
  imageName: string;
  _id: string;
}

export type StoresDataType = Array<IStoresData>;
interface IGetStoresOutput extends Types.ResponseOutput {
  data: StoresDataType;
}

export const getStores = async (): Promise<IGetStoresOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/store/user/list`
  );

  let output: Types.ResponseOutput = {
    message: data.message,
    data: data.data,
    status: data.status,
  };
  return output;
};

/** Get Users */
export type IUserData = Array<{
  _id: string;
  email: string;
}>;

export interface IGetUserOutput extends Types.ResponseOutput {
  data: IUserData;
}

interface IUserProfileInput {}

export const getUserList = async (
  input?: IUserProfileInput
): Promise<IGetUserOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/users/list`
  );
  return data;
};

/** User List Details  */
export interface IGetUserDetailsOutput extends Types.ResponseOutput {
  data: IUserDataWithDetailsData;
}

export type IUserDataWithDetailsData = {
  paging: {
    page: number;
    pageSize: number;
    total: number;
  };
  items: Array<{
    _id: string;
    email: string;
    firstName: string;
    surname: string;
    group: string;
  }>;
};

export interface IUserDetailsInput {
  pageSize: number;
  page: number;
  order: 'desc' | 'asc';
  sortField: string;
  email?: string;
  groupName?: string;
}

export const getUserListDetails = async (
  input: IUserDetailsInput
): Promise<IGetUserDetailsOutput> => {
  const query = varToStringParams({
    variablesArray: [
      {
        value: input?.pageSize.toString(),
        key: 'pageSize',
      },
      {
        value: input?.page.toString(),
        key: 'page',
      },
      {
        value: input?.order,
        key: 'order',
      },
      {
        value: input?.sortField,
        key: 'sortField',
      },
      {
        value: input?.email,
        key: 'email',
      },
      {
        value: input?.groupName,
        key: 'groupName',
      },
    ],
  });

  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/users/list-details${query}`
  );
  return data;
};

/** edit user by id  */
interface IEditUserByIDOutput extends Types.ResponseOutput {
  data: null;
}

export interface IEditUserByIDInput {
  email: string;
  firstName: string;
  surname: string;
  group: string;
  userid: string;
}

export const editUserByID = async (
  payload: IEditUserByIDInput
): Promise<IEditUserByIDOutput> => {
  const { userid, ...body } = payload;
  const { data }: any = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/users/${userid}`,
    {
      body,
      showToastMessage: true,
    }
  );
  return data;
};

// /** getPermissions  */
// export type IPermissionsData = {
//   cameraTypes: Array<string>;
// };

// interface IPermissionsOutput extends Types.ResponseOutput {
//   data: IPermissionsData;
// }

// export const fetchPermissions = async (): Promise<IPermissionsOutput> => {
//   const { data }: any = await request(
//     'get',
//     `${Environment.API_BASE_URL}/api/users/permissions`
//   );
//   return data;
// };
