import React, { ReactElement } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button, { ButtonProps } from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useForm } from 'react-hook-form';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useStyles from './styles';
import LockIcon from '@material-ui/icons/Lock';
import NavigationConfig from '../../../../navigation/config';
import { Link } from 'react-router-dom';
import TranslatedText from '../../../../components/atoms/translated_text';
import Configuration, { Translations } from '../../configuration';
import { useDispatch } from 'react-redux';
import Actions from '../../../../redux/reducers/gl_auth_reducer/actions';
import { IconButton, InputAdornment } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import * as AuthSelectors from '../../../../redux/reducers/gl_auth_reducer/selectors';
import { StateStatus } from '../../../../redux/utils/common';
import FormFeedback from '../../../../components/molecules/form_feedback';
import { AppMultipleSelect } from '../../../../components/molecules/form_controls';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function LoginForm(): ReactElement {
  const classes = useStyles();
  const { register, watch, errors, handleSubmit } = useForm();
  const dispatch = useDispatch();

  const errorSelector = (fieldName: string) => {
    if (fieldName in errors) {
      if (errors[fieldName].type === 'required') {
        return <TranslatedText textMap={Translations.form_error_required} />;
      }
    } else {
      return '';
    }
  };
  console.log('Render Form:');

  const onSubmit = (data: any) => {
    console.log('Data:', data);
    dispatch(
      Actions.authenticateAction({
        username: data.username,
        password: data.password,
        stayLogin: Boolean(data.rememberMe),
      })
    );
  };

  return (
    <Grid
      container
      component="main"
      onSubmit={handleSubmit(onSubmit)}
      className={classes.root}
    >
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        lg={8}
        xl={9}
        className={classes.image}
      >
        <div className={classes.containerBackground} />
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        lg={4}
        xl={3}
        component={Paper}
        elevation={6}
        square
      >
        <div className={classes.paper}>
          <img src={Configuration.loginIcon} className={classes.img_logo} />
          <Typography component="h1" variant="h5">
            <TranslatedText textMap={Translations.login_title} />
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label={
                <TranslatedText textMap={Translations.form_field_username} />
              }
              name="username"
              autoComplete="username"
              error={'username' in errors}
              helperText={errorSelector('username')}
              inputRef={register({ required: true })}
              autoFocus
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={
                <TranslatedText textMap={Translations.form_field_password} />
              }
              type="password"
              id="password"
              inputRef={register({ required: true })}
              error={'password' in errors}
              helperText={errorSelector('password')}
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
            />
            <WrongCrentetialsMessage />
            <Grid alignItems={'center'} container>
              <Grid item xs></Grid>
              <Grid item>
                <Link to={NavigationConfig.forgotPasswordPage().path}>
                  <TranslatedText
                    textMap={Translations.label_forgot_password}
                  />
                </Link>
              </Grid>
            </Grid>

            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <SubmitWrapper
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                <TranslatedText textMap={Translations.form_button_login} />
              </SubmitWrapper>
            </Box>
            <Box mt={3}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

interface IButtonProps extends ButtonProps {}

const SubmitWrapper = (props: IButtonProps) => {
  const authStatus = AuthSelectors.useSelectAuthStatus();
  console.log('Auth status:', authStatus);
  return <Button {...props} disabled={StateStatus.Pending === authStatus} />;
};

const WrongCrentetialsMessage = () => {
  const authStatus = AuthSelectors.useSelectAuthStatus();

  return (
    <React.Fragment>
      {authStatus === StateStatus.Failed && (
        <FormFeedback data={Translations.messgae_wrong_credentials} />
      )}
    </React.Fragment>
  );
};
