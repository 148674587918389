import React from 'react';
import Datatable from 'components/molecules/datatable';
import * as Colors from 'styles/colors';
import { useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TranslatedText from 'components/atoms/translated_text';
import { useDispatch } from 'react-redux';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import * as StoresSelectors from 'redux/reducers/stores_reducer/selectors';
import StoreActions from 'redux/reducers/stores_reducer/actions';
import { IModalParams as IAddUserModalParams } from '../modal_add_user';
import { IUserDataWithDetailsData } from 'services/api/user';
import FilteringBox from './FilteringBox';
import useQuery from 'custom_hooks/useQuery';
import * as DateUtils from 'utils/dates';

const UsersDatatable = () => {
  const sortField = React.useRef<string>(TableColumns[3].id);
  const sortType = React.useRef<'asc' | 'desc'>('asc');
  const theme = useTheme();
  const result = StoresSelectors.useGetUserListWithDetails();
  const dispatch = useDispatch();
  const query = useQuery();
  const email = query.get('email');

  const paging = {
    page: result.data?.paging.page || 0,
    pageSize: result.data?.paging.pageSize || 0,
    total: result.data?.paging.total || 0,
  };

  return (
    <div>
      <FilteringBox />
      <Datatable
        paging={paging}
        data={result.data?.items || []}
        sorting={{
          sortField: sortField.current,
          order: sortType.current,
        }}
        height={'70vh'}
        disablePaper={false}
        paperProps={{
          style: {
            borderRadius: 2,
            //   backgroundColor: Colors.APP_COLOR_PRIMARY(),
            overflow: 'hidden',
          },
          // square: false,
          variant: 'outlined',
        }}
        tableCellProps={() => ({
          style: {
            //   paddingTop: 0,
            //   paddingBottom: 0,
            borderLeft: 'none',
            borderRight: 'none',
          },
        })}
        tableCellMainRowProps={() => ({
          style: {
            paddingTop: '1rem',
            paddingBottom: '1rem',
          },
        })}
        loading={false}
        columns={TableColumns}
        changePageHandle={(selectedPage, pageSize, order, _sortField) => {
          // 'asc' | 'desc'
          sortType.current = order === 'desc' ? 'asc' : 'desc';
          sortField.current = _sortField;
          dispatch(
            StoreActions.fetchUsersWithDetailsAction({
              page: selectedPage,
              pageSize,
              sortField: _sortField,
              order,
              email: email || undefined,
            })
          );
        }}
      />
    </div>
  );
};

interface IData {
  name: string;
  position: string;
  office: string;
  age: number;
  date: string;
  salary: number;
}

export const TableColumns = [
  {
    label: 'Email',
    id: 'email',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Firstname',
    id: 'firstName',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Surname',
    id: 'surname',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Date Created',
    id: 'registeredDate',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{DateUtils.formatDate(cellValue)}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: '',
    id: 'actions',
    align: 'left',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: IUserDataWithDetailsData['items']
    ) => <TableActions data={data} rowIndex={rowIndex} />,
    inData: false,
  },
];

const TableActions = React.memo(
  ({
    rowIndex,
    data,
  }: {
    rowIndex: number;
    data: IUserDataWithDetailsData['items'];
  }) => {
    const dispatch = useDispatch();
    return (
      //  style={{ maxWidth: 120 }}
      <div>
        <Button
          onClick={() => {
            dispatch(
              ModalActions.openModalAction({
                modalID: ModalIDs.MODAL_ADD_USER,
                params: {
                  mode: 'edit',
                  defaultValues: {
                    firstName: data[rowIndex].firstName,
                    surname: data[rowIndex].surname,
                    email: data[rowIndex].email,
                    group: data[rowIndex].group,
                  },
                  userid: data[rowIndex]._id,
                } as IAddUserModalParams,
              })
            );
          }}
          variant="outlined"
          size="medium"
          // color="primary"
        >
          <TranslatedText defaultText="Edit" />
        </Button>
      </div>
    );
  }
);

export const DummyData: IData[] = Array.from({ length: 100 }, (_, index) => ({
  name: 'name_' + index,
  position: 'name_' + index,
  office: 'name_' + index,
  age: index + 4,
  date: 'name_' + index,
  salary: index * 1002,
}));

export default React.memo(UsersDatatable);
