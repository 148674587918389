import React from 'react';
import TranslatedText from 'components/atoms/translated_text';

export enum RegionsEnum {
  Larnaca = 'Larnaca',
  Limmasol = 'Limmasol',
  Paphos = 'Paphos',
  Famagusta = 'Famagusta',
  Nicosia = 'Nicosia',
}

export const Regions = [
  {
    id: RegionsEnum.Larnaca,
    label: <TranslatedText defaultText="Larnaca" />,
  },
  {
    id: RegionsEnum.Limmasol,
    label: <TranslatedText defaultText="Limmasol" />,
  },
  {
    id: RegionsEnum.Paphos,
    label: <TranslatedText defaultText="Paphos" />,
  },
  {
    id: RegionsEnum.Famagusta,
    label: <TranslatedText defaultText="Famagusta" />,
  },
  {
    id: RegionsEnum.Nicosia,
    label: <TranslatedText defaultText="Nicosia" />,
  },
];
