//  [REDUCER][TYPE]
import { IReduxType, typeCreatorV2 } from '../../utils/common';
import * as AnalyticsService from 'services/api/analytics';

// export const SET_STORES: string = typeCreatorV2(
//   'VisitorAnalyticsReducer',
//   'SetStores'
// );

export const SET_DATE_RANGE_TYPE: string = typeCreatorV2(
  'VisitorAnalyticsReducer',
  'SetDateRangeType'
);

export const SET_VISITORS_LAST_DAYS: string = typeCreatorV2(
  'VisitorAnalyticsReducer',
  'SetVisitorsLastDays'
);

export const SET_VISITORS_LAST_DAYS_LIST: string = typeCreatorV2(
  'VisitorAnalyticsReducer',
  'SetVisitorsLastDaysList'
);

export const SET_VISITORS_PER_HOUR_LIST: string = typeCreatorV2(
  'VisitorAnalyticsReducer',
  'SetVisitorsPerHourList'
);

export const SET_VISITORS_BY_MONTH: string = typeCreatorV2(
  'VisitorAnalyticsReducer',
  'SetVisitorsByMonth'
);

export const SET_AVERAGE_HOURLY_VISITORS_PER_DAY: string = typeCreatorV2(
  'VisitorAnalyticsReducer',
  'SetAverageHourlyVisitorsPerDay'
);

export const SET_AVERAGE_SHOPPING_TIME_IN_LAST_DAYS: string = typeCreatorV2(
  'VisitorAnalyticsReducer',
  'SetAverageShoppingTimeInLastDays'
);

export const SET_VISITORS_PER_STORE_LIST: string = typeCreatorV2(
  'VisitorAnalyticsReducer',
  'SetVisitorsPerStoreList'
);
