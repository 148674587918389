import React from 'react';
import * as Config from '../../config';
import LoginPage from '../../scenes/login_page';
import MainPage from '../../scenes/main_page';
import StorePerformancePage from '../../scenes/storePerformance_page';
import VisitorsByDayPage from '../../scenes/visitorsByDay_page';
import TimeGroupPage from '../../scenes/timeGroup_page';
import RootPage from '../../scenes/root_page';
import AppbarMenu from '../components/appbar';
import ForgotPasswordPage from '../../scenes/forgot_password';
import VisitorAnalyticsPage from '../../scenes/visitorAnalytics_page';
import ResetPasswordPage from '../../scenes/reset_password';
import ProfilePage from '../../scenes/profile_page';
import StoreComparisonPage from '../../scenes/storeComparison_page';
import OccupancyPage from '../../scenes/occupancy_page';
import StoreTreemap from '../../scenes/storeHeatmap_page';
import StoresPage from 'scenes/stores_page';
import MobileEntrypointPage from 'scenes/mobile_entrypoint_page';
import { MetricTypes } from 'config/metricTypes';
import * as PathUtils from 'utils/path';
import NotificationPage from '../../scenes/notifications_page';
import VisitorDetailsPage from '../../scenes/visitorDetails_page';

interface IPage {
  path: string;
  component: any;
  roles: Array<number | string>;
  metricTypes?: MetricTypes[];
}

interface INavigationConfig {
  rootPage: () => IPage;
  mainPage: () => IPage;
  loginPage: () => IPage;
  storeParformancePage: (params?: {
    store?: string | null;
    month?: string | null;
  }) => IPage;
  forgotPasswordPage: () => IPage;
  resetPasswordPage: (token?: string) => IPage;
  visitorAnalyticsPage: () => IPage;
  timeGroupPage: () => IPage;
  visitorsByDayPage: () => IPage;
  profilePage: () => IPage;
  storeComparisonPage: () => IPage;
  storePage: (params?: {
    type?: string;
    status?: string;
    storeid?: string;
    boxid?: string;
    email?: string;
    groupName?: string;
  }) => IPage;
  storeTreemapPage: () => IPage;
  occupancyPage: () => IPage;
  mobileEntrypointPage: () => IPage;
  notificationPage: (params?: {
    email?: string;
    dateStart?: string;
    dateEnd?: string;
    type?: string;
  }) => IPage;
  visitorDetailsPage: () => IPage;
}

interface IAppbarsConfig {
  primaryNav: INav;
  secondaryNav: INav;
}

interface INav {
  component: any;
  include: 'all' | string[] | null;
  exclude: 'all' | string[] | null;
  select: 'include' | 'exclude';
  // roles: Array<string>;
}

console.log('All users:', Config.AuthRoles.All);

const NavigationConfig: INavigationConfig = {
  rootPage: (params?: any): IPage => ({
    path: '*',
    component: RootPage,
    roles: Config.AuthRoles.All,
  }),
  mainPage: (params?: any) => ({
    path: `/`,
    component: MainPage,
    roles: Config.AuthRoles.All,
  }),
  storeTreemapPage: () => ({
    path: '/store-heatmap',
    component: StoreTreemap,
    roles: Config.AuthRoles.Registered,
    metricTypes: [MetricTypes.Heatmap],
  }),
  visitorDetailsPage: () => ({
    path: '/visitor-details',
    component: VisitorDetailsPage,
    roles: Config.AuthRoles.Registered,
    metricTypes: [MetricTypes.Inout],
  }),
  notificationPage: (params) => ({
    path: `/notifications${PathUtils.varToStringParams({
      variablesArray: [
        {
          value: params?.email,
          key: 'email',
        },
        {
          value: params?.type,
          key: 'type',
        },
        {
          value: params?.dateStart,
          key: 'dateStart',
        },
        {
          value: params?.dateEnd,
          key: 'dateEnd',
        },
      ],
    })}`,
    component: NotificationPage,
    roles: Config.AuthRoles.Registered,
  }),
  mobileEntrypointPage: () => ({
    path: '/mobile/entrypoint',
    component: MobileEntrypointPage,
    roles: Config.AuthRoles.All,
  }),
  storePage: (params) => ({
    path: `/store${PathUtils.varToStringParams({
      variablesArray: [
        {
          value: params?.groupName,
          key: 'groupName',
        },
        {
          value: params?.status,
          key: 'status',
        },
        {
          value: params?.type,
          key: 'type',
        },
        {
          value: params?.storeid,
          key: 'storeid',
        },
        {
          value: params?.boxid,
          key: 'boxid',
        },
        {
          value: params?.email,
          key: 'email',
        },
      ],
    })}`,
    component: StoresPage,
    roles: Config.AuthRoles.Registered,
  }),
  loginPage: () => ({
    path: '/login',
    component: LoginPage,
    roles: [Config.AuthRoles.Guest],
  }),
  storeParformancePage: (params) => ({
    path: `/store-performance`,
    component: StorePerformancePage,
    roles: Config.AuthRoles.Registered,
    metricTypes: [MetricTypes.Inout],
  }),
  forgotPasswordPage: () => ({
    path: '/forgot',
    component: ForgotPasswordPage,
    roles: Config.AuthRoles.All,
  }),
  resetPasswordPage: (token = ':token') => ({
    path: `/reset-password/${token}`,
    component: ResetPasswordPage,
    roles: Config.AuthRoles.All,
  }),
  visitorAnalyticsPage: () => ({
    path: '/visitor-analytics',
    component: VisitorAnalyticsPage,
    roles: Config.AuthRoles.Registered,
    metricTypes: [MetricTypes.Inout],
  }),
  timeGroupPage: () => ({
    path: '/time-group',
    component: TimeGroupPage,
    roles: Config.AuthRoles.Registered,
    metricTypes: [MetricTypes.Inout],
  }),
  visitorsByDayPage: () => ({
    path: `/visitors-by-day`,
    component: VisitorsByDayPage,
    roles: Config.AuthRoles.Registered,
    metricTypes: [MetricTypes.Inout],
  }),
  profilePage: () => ({
    path: `/profile`,
    component: ProfilePage,
    roles: Config.AuthRoles.Registered,
  }),
  storeComparisonPage: () => ({
    path: `/store-comparison`,
    component: StoreComparisonPage,
    roles: Config.AuthRoles.Registered,
    metricTypes: [MetricTypes.Inout],
  }),
  occupancyPage: () => ({
    path: `/occupancy`,
    component: OccupancyPage,
    roles: Config.AuthRoles.Registered,
    // metricTypes: [MetricTypes.PeopleCounting],
  }),
};
export const AppbarsConfig: IAppbarsConfig = {
  primaryNav: {
    component: AppbarMenu,
    include: null,
    exclude: [
      NavigationConfig.loginPage().path,
      NavigationConfig.forgotPasswordPage().path,
      NavigationConfig.resetPasswordPage().path,
    ],
    select: 'exclude',
  },
  secondaryNav: {
    component: AppbarMenu,
    include: [],
    exclude: 'all',
    select: 'exclude',
  },
};

export const Appbars: Array<INav> = Object.values(AppbarsConfig);
export default NavigationConfig;
