import React from 'react';
import BaseModal from 'components/molecules/base_modal';
import TranslatedText from 'components/atoms/translated_text';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { useDispatch } from 'react-redux';
import PrettyPrintJson from 'components/atoms/pretty_print_json';
import { Button, Paper, Typography } from '@material-ui/core';
import StoreActions from 'redux/reducers/stores_reducer/actions';
import * as StoreSelectors from 'redux/reducers/stores_reducer/selectors';
import { StateStatus } from 'redux/utils/common';

export interface IModalParams {
  boxid: string;
  config: object;
}

const ModalBoxConfig = () => {
  const dispatch = useDispatch();
  return (
    <BaseModal
      modalID={ModalIDs.MODAL_BOX_CONFIG}
      modalProps={{ fullWidth: true }}
      renderHeader={(props: IModalParams) => (
        <TranslatedText defaultText={`Box Config (${props.boxid})`} />
      )}
      renderContent={(props: IModalParams) => (
        <PrettyPrintJson data={props.config as JSON} />
      )}
      renderFooter={(props: IModalParams) => (
        <React.Fragment>
          <Button
            variant="outlined"
            onClick={() =>
              dispatch(
                ModalActions.closeModalAction({
                  modalID: ModalIDs.MODAL_BOX_CONFIG,
                })
              )
            }
          >
            <TranslatedText defaultText={`Close`} />
          </Button>
        </React.Fragment>
      )}
    />
  );
};

const SubmitWrapper = ({ storeid }: any) => {
  const status = StoreSelectors.useGetRemoveStoreStatus();
  const dispatch = useDispatch();

  return (
    <Button
      variant="outlined"
      color="primary"
      disabled={status === StateStatus.Pending}
      onClick={() => {
        dispatch(
          StoreActions.removeStoreAction({
            storeid: storeid,
            onFinish: () => {
              dispatch(
                ModalActions.closeModalAction({
                  modalID: ModalIDs.MODAL_REMOVE_STORE,
                })
              );
              dispatch(StoreActions.fetchStoresAction());
            },
          })
        );
      }}
    >
      <TranslatedText defaultText={`Remove Store`} />
    </Button>
  );
};

export default React.memo(ModalBoxConfig);
