import React from 'react';
import StatisticsCardLabel from 'components/app_common/statistics_card_label';
import * as VisitorDetailsSelectors from 'redux/reducers/visitorDetails_reducer/selectors';

export default function Statistics() {
  const result = VisitorDetailsSelectors.useVisitorDetailsData();
  const dailyVisitorsResult = VisitorDetailsSelectors.useDailyTotalVisitors();

  return (
    <div>
      <StatisticsCardLabel
        backgroundColor="rgba(240, 30, 2, 0.5)"
        title={`${result.data?.total ?? 0}`}
        titleColor="rgba(255, 255, 255, .95)"
        description={`Total Visitors`}
        descriptionColor="rgba(255, 255, 255, .8)"
        containerStyle={{ marginBottom: '1rem' }}
      />
 
    </div>
  );
}
