import { LanguagesEnum } from '../../config/languages';

const Configuration = {
  backgroundImage: require('../../assets/images/backgrounds/background_login_register.png'),
  loginIcon: require('../../assets/images/logos/app-logo.png'),
};

export const Translations = {
  login_title: {
    [LanguagesEnum.English]: 'Login',
    [LanguagesEnum.Greek]: 'Σύνδεση',
  },
  form_error_required: {
    [LanguagesEnum.English]: 'This is required',
    [LanguagesEnum.Greek]: 'Υποχρεωτικό πεδίο',
  },
  form_field_username: {
    [LanguagesEnum.English]: 'Username',
    [LanguagesEnum.Greek]: 'Username',
  },
  form_field_password: {
    [LanguagesEnum.English]: 'Password',
    [LanguagesEnum.Greek]: 'Κωδικός',
  },
  form_button_login: {
    [LanguagesEnum.English]: 'Login',
    [LanguagesEnum.Greek]: 'Σύνδεση',
  },
  label_forgot_password: {
    [LanguagesEnum.English]: 'Forgot Password',
    [LanguagesEnum.Greek]: 'Ξεχάσατε κωδικό;',
  },
  messgae_wrong_credentials: {
    [LanguagesEnum.English]: 'Wrong Credentials',
    [LanguagesEnum.Greek]: 'Λάθος στοιχεία χρήστη',
  },
};

export default Configuration;
