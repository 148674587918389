import React from 'react';
import { withRouter } from 'react-router'; // import the react-router-dom components
import useStyles from './styles';
import { useDispatch } from 'react-redux';
import reactLogger from 'utils/logger';
import PageLayout from 'components/app_common/page_layout';
import GraphsLayout from './components/graphs_layout';
import StoresCommonActions from 'redux/reducers/gl_app_common/actions';
import * as StoresCommonSelectors from 'redux/reducers/gl_app_common/selectors';

const VisitorsDetailsPage = () => {
  reactLogger.renderPage('VisitorsDetailsPage');
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedStore = StoresCommonSelectors.useGetSelectedStore();

  React.useEffect(() => {
    if (selectedStore) {
      dispatch(StoresCommonActions.fetchStoreCamerasAction(selectedStore));
    }
  }, [dispatch, selectedStore]);

  React.useEffect(() => {
    dispatch(StoresCommonActions.fetchStoresAction());
  }, [dispatch]);

  return (
    <PageLayout>
      <GraphsLayout />
    </PageLayout>
  );
};

export default withRouter(VisitorsDetailsPage);
