import { IAction, KEEP_THE_SAME, StateStatus } from '../../utils/common';
import * as Types from './types';
import {
  IStoresData,
  StoreCamerasData,
  IStoreCameraData,
} from 'services/api/stores';
import { StoresDataType } from 'services/api/user';
import { HeatmapTypeEnum } from 'config/heatmapTypes';

export interface IState {
  // stores: {
  //   status: StateStatus | null | undefined;
  //   data: StoresDataType | null;
  // };
  storeCameras: {
    status: StateStatus | null | undefined;
    data: StoreCamerasData | null;
  };
  heatmapCount: {
    status: StateStatus | null | undefined;
    data: IStoresData | null;
  };
  selectedStoreData: IStoreCameraData | null;
  heatmapType: HeatmapTypeEnum;
}

const initialState: IState = {
  // stores: {
  //   status: null,
  //   data: null,
  // },
  storeCameras: {
    status: null,
    data: null,
  },
  heatmapCount: {
    status: null,
    data: null,
  },
  selectedStoreData: null,
  heatmapType: HeatmapTypeEnum.Count,
};

const StoresHeatmapCountReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    // case Types.SET_STORES:
    //   return {
    //     ...state,
    //     stores: {
    //       ...state.stores,
    //       status: action.status,
    //       data: action.data === KEEP_THE_SAME ? state.stores.data : action.data,
    //     },
    //   };
    case Types.SET_HEATMAP_COUNT:
      return {
        ...state,
        heatmapCount: {
          ...state.heatmapCount,
          status: action.status,
          data: action.data,
        },
      };
    case Types.SET_STORE_CAMERAS:
      return {
        ...state,
        storeCameras: {
          ...state.storeCameras,
          status: action.status,
          data:
            action.data === KEEP_THE_SAME
              ? state.storeCameras.data
              : action.data,
        },
      };
    case Types.SET_SELECTED_STORE:
      return {
        ...state,
        selectedStoreData: action.data,
      };
    case Types.SET_HEATMAP_TYPE:
      return {
        ...state,
        heatmapType: action.data.heatmapType,
      };
    default:
      return {
        ...state,
      };
  }
};

export default StoresHeatmapCountReducer;
