import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    grow: {
      flexGrow: 1,
    },
    gridContainer: (props: any) => ({
      // marginTop: 10,
      // backgroundImage: `url(${props?.src})`,
      // backgroundRepeat: 'no-repeat',
      // backgroundSize: 'cover',
      // backgroundPosition: 'center',
      // width: props?.imgDimensions.width,
      // height: props?.imgDimensions.height,
      position: 'absolute',
      // height: '100%',
      // width: '100%',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    }),
    gridElement: {
      height: '100%',
      borderWidth: 0.5,
      borderColor: 'rgba(0, 0, 0, .3)',
      borderStyle: 'solid',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': {
        background: 'rgba(255, 255, 255, 0.9) !important',
      },
      fontWeight: 'bold',
    },
  })
);

export default useStyles;
