import { LanguagesEnum } from 'config/languages';

export default {
  signup_title: {
    [LanguagesEnum.English]: 'Create Account',
    [LanguagesEnum.Greek]: 'Δημιουργήστε λογαριασμό',
  },
  form_error_required: {
    [LanguagesEnum.English]: 'This is required',
    [LanguagesEnum.Greek]: 'Υποχρεωτικό πεδίο',
  },
  form_field_firstname: {
    [LanguagesEnum.English]: 'First Name',
    [LanguagesEnum.Greek]: 'Όνομα',
  },
  form_field_surname: {
    [LanguagesEnum.English]: 'Surname',
    [LanguagesEnum.Greek]: 'Επίθετο',
  },
  form_field_birthdate: {
    [LanguagesEnum.English]: 'Birth Date',
    [LanguagesEnum.Greek]: 'Ημέρα Γενεθλίων',
  },
  form_field_username: {
    [LanguagesEnum.English]: 'Username',
    [LanguagesEnum.Greek]: 'Username',
  },
  form_field_password: {
    [LanguagesEnum.English]: 'Password',
    [LanguagesEnum.Greek]: 'Κωδικός',
  },
  form_field_confirm_password: {
    [LanguagesEnum.English]: 'Confirm Password',
    [LanguagesEnum.Greek]: 'Επιβεβαίωση Κωδικού',
  },
  form_button_register: {
    [LanguagesEnum.English]: 'Signup',
    [LanguagesEnum.Greek]: 'Εγγραφή',
  },
  label_forgot_password: {
    [LanguagesEnum.English]: 'Forgot Password',
    [LanguagesEnum.Greek]: 'Ξεχάσατε κωδικό;',
  },
  form_email: {
    [LanguagesEnum.English]: 'Email Address',
    [LanguagesEnum.Greek]: 'Διεύθυνση Email',
  },
  form_confirm_email: {
    [LanguagesEnum.English]: 'Confirm Email Address',
    [LanguagesEnum.Greek]: 'Επιβεβαίωση Διεύθυνσης Email',
  },
  back_to_login: {
    [LanguagesEnum.English]: 'Back to login',
    [LanguagesEnum.Greek]: 'Επιστροφή στο Login',
  },
  already_have_an_account: {
    [LanguagesEnum.English]: 'Already have an account?',
    [LanguagesEnum.Greek]: 'Εχετε ήδη λογαριασμό;',
  },
  form_field_gender: {
    [LanguagesEnum.English]: 'Gender',
    [LanguagesEnum.Greek]: 'Φύλο',
  },
  form_field_nationality: {
    [LanguagesEnum.English]: 'Nationality',
    [LanguagesEnum.Greek]: 'Εθνικότητα',
  },
};
