import { IAction, IActionMethods, StateStatus } from '../../utils/common';
import * as Types from './types';
import * as AnalyticsService from 'services/api/analytics';
import * as UserService from 'services/api/user';
import { Dispatch } from 'redux';
import reactLogger from 'utils/logger';
import { DaysEnum } from 'config/days';
import { MonthsEnum } from 'config/months';

/** Fetch Visitors Current Vs Previous Week Chart Data  */
interface IVisitorsCurrentVsPreviousWeekChartInput {
  storeid: string;
}
class FetchVisitorsCurrentVsPreviousWeekChartData implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_VISITORS_CURRENT_VS_PREVIOUS_WEEK_DATA,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_VISITORS_CURRENT_VS_PREVIOUS_WEEK_DATA,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_VISITORS_CURRENT_VS_PREVIOUS_WEEK_DATA,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(input: IVisitorsCurrentVsPreviousWeekChartInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AnalyticsService.getVisitorsCurrentVsPreviousWeekChartData(
          { storeid: input.storeid }
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        reactLogger.error(
          'FetchVisitorsCurrentVsPReviousWeekChartData Error:',
          error.message
        );
        dispatch(this.onFailed());
      }
    };
  }
}

/** Fetch Visitor Data Last Days  */
interface IFetchVisitorsDataLastDaysInput {
  storeid: string;
}
class FetchVisitorsDataLastDays implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_VISITORS_LAST_DAYS,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_VISITORS_LAST_DAYS,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_VISITORS_LAST_DAYS,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(input: IFetchVisitorsDataLastDaysInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AnalyticsService.getTotalVisitorsData({
          type: 'daysBefore',
          value: 30,
          storeid: input.storeid,
        });
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        reactLogger.error('FetchVisitorsDataLastDays Error:', error.message);
        dispatch(this.onFailed());
      }
    };
  }
}

/** Fetch Visitor Data Current Month  */
interface IFetchVisitorsDataCurrentMonthInput {
  currentMonth: MonthsEnum;
  selectedStore: string;
}
class FetchVisitorsDataCurrentMonth implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_VISITORS_CURRENT_MONTH,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_VISITORS_CURRENT_MONTH,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_VISITORS_CURRENT_MONTH,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(data: IFetchVisitorsDataCurrentMonthInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AnalyticsService.getTotalVisitorsData({
          type: 'month',
          value: parseInt(data.currentMonth),
          storeid: data.selectedStore,
        });
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        reactLogger.error(
          'FetchVisitorsDataCurrentMonth Error:',
          error.message
        );
        dispatch(this.onFailed());
      }
    };
  }
}

/** Fetch Select Month  */
interface ISelectMonth {
  selectedMonth: MonthsEnum;
}
class SelectMonth implements IActionMethods {
  onPending(): IAction {
    throw new Error('Method not implemented.');
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_SELECTED_MONTH,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_SELECTED_MONTH,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(data: ISelectMonth): IAction {
    try {
      return this.onSuccess(data.selectedMonth);
    } catch (error) {
      reactLogger.error('SelectMonth Error:', error.message);
      return this.onFailed();
    }
  }
}

/** Fetch Visitors By Day Of Week Per Hour  */
interface IVisitorsByDayOfWeekPerHourInput {
  selectedWeekDay: DaysEnum;
  storeid: string;
}
class FetchVisitorsByDayOfWeekPerHour implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_VISITORS_BY_DAY_OF_WEEK_PER_HOUR,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_VISITORS_BY_DAY_OF_WEEK_PER_HOUR,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_VISITORS_BY_DAY_OF_WEEK_PER_HOUR,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(data: IVisitorsByDayOfWeekPerHourInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AnalyticsService.getVisitorsByDayOfWeekperHour({
          monthNumber: parseInt(data.selectedWeekDay),
          storeid: data.storeid,
        });
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        reactLogger.error(
          'FetchVisitorsByDayOfWeekPerHour Error:',
          error.message
        );
        dispatch(this.onFailed());
      }
    };
  }
}

/** Fetch Importance Of Week Day Of Selected Month  */
interface IImportanceOfWeekDayOfSelectedMonthInput {
  selectedMonth: MonthsEnum;
  storeid: string;
}
class FetchImportanceOfWeekDayOfSelectedMonth implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_IMPORTANCE_OF_WEEK_DAY_SELECTED_MONTH,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_IMPORTANCE_OF_WEEK_DAY_SELECTED_MONTH,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_IMPORTANCE_OF_WEEK_DAY_SELECTED_MONTH,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(data: IImportanceOfWeekDayOfSelectedMonthInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AnalyticsService.getImportanceOfWeekDayOfSelectedMonth(
          {
            monthNumber: parseInt(data.selectedMonth),
            storeid: data.storeid,
          }
        );
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        reactLogger.error(
          'FetchImportanceOfWeekDayOfSelectedMonth Error:',
          error.message
        );
        dispatch(this.onFailed());
      }
    };
  }
}

/** Fetch Visitors By Time Group */
interface IVisitorsByTimeGroupInput {
  selectedMonth: MonthsEnum;
  storeid: string;
}
class FetchVisitorsByTimeGroup implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_VISITORS_BY_TIMEGROUP,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_VISITORS_BY_TIMEGROUP,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_VISITORS_BY_TIMEGROUP,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(data: IVisitorsByTimeGroupInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AnalyticsService.getVisitorsByTimeGroup({
          monthNumber: parseInt(data.selectedMonth),
          storeid: data.storeid,
        });
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        reactLogger.error('FetchVisitorsByTimeGroup Error:', error.message);
        dispatch(this.onFailed());
      }
    };
  }
}

/** Fetch Visitors Group by Hour */
interface IVisitorsPickByHourPerWeekdayInput {
  storeid: string;
}
class FetchVisitorsPickByHourPerWeekday implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_VISITORS_PICK_BY_HOUR_PER_WEEKDAY,
      status: StateStatus.Pending,
      data: [],
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_VISITORS_PICK_BY_HOUR_PER_WEEKDAY,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_VISITORS_PICK_BY_HOUR_PER_WEEKDAY,
      status: StateStatus.Failed,
      data: [],
    };
  }

  action(data: IVisitorsPickByHourPerWeekdayInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AnalyticsService.getVisitorsPickByHourPerWeekday({
          storeid: data.storeid,
        });
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        reactLogger.error(
          'FetchVisitorsPickByHourPerWeekday Error:',
          error.message
        );
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  fetchVisitorsByDayOfWeekPerHourAction: (
    data: IVisitorsByDayOfWeekPerHourInput
  ) => new FetchVisitorsByDayOfWeekPerHour().action(data),
  setMonthAction: (data: ISelectMonth) => new SelectMonth().action(data),
  fetchVisitorsCurrentVsPreviousWeekChartDataAction: (
    payload: IVisitorsCurrentVsPreviousWeekChartInput
  ) => new FetchVisitorsCurrentVsPreviousWeekChartData().action(payload),
  fetchVisitorsDataLastDaysAction: (payload: IFetchVisitorsDataLastDaysInput) =>
    new FetchVisitorsDataLastDays().action(payload),
  fetchVisitorsDataCurrentMonthAction: (
    data: IFetchVisitorsDataCurrentMonthInput
  ) => new FetchVisitorsDataCurrentMonth().action(data),
  fetchImportanceOfWeekDayOfSelectedMonthAction: (
    data: IImportanceOfWeekDayOfSelectedMonthInput
  ) => new FetchImportanceOfWeekDayOfSelectedMonth().action(data),
  fetchVisitorsByTimeGroupAction: (data: IVisitorsByTimeGroupInput) =>
    new FetchVisitorsByTimeGroup().action(data),
  fetchVisitorsPickByHourPerWeekdayAction: (
    payload: IVisitorsPickByHourPerWeekdayInput
  ) => new FetchVisitorsPickByHourPerWeekday().action(payload),
};
