export enum ConditionEnum {
  LT = 'LT',
  LTE = 'LTE',
  GT = 'GT',
  GTE = 'GTE',
  EQ = 'EQ',
}

export enum AlertTypeEnum {
  PushNotification = 'PushNotification',
  Email = 'Email',
}

export enum AlertFieldValueType {
  Number = 'number',
  Persentage = 'persentage',
  Time = 'time',
}
