import React from 'react';
import BaseModal from 'components/molecules/base_modal';
import TranslatedText from 'components/atoms/translated_text';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  AppTextField,
  AppSelect,
  AppMultipleSelect,
  AppMultipleAutoCompleteSelect,
} from 'components/molecules/form_controls';
import { Button, InputAdornment, Theme } from '@material-ui/core';
import StoreActions from 'redux/reducers/stores_reducer/actions';
import * as StoreSelectors from 'redux/reducers/stores_reducer/selectors';
import { StateStatus } from 'redux/utils/common';
import Environment from 'config/environment';
import {
  AlertFieldValueType,
  AlertTypeEnum,
  ConditionEnum,
} from 'config/alerts';
import { AlertsListDefaultFiltering, TabType } from '../../configuration';
import { useHistory } from 'react-router';
import NavigationConfig from 'navigation/config';
import * as ArrayUtils from 'utils/arrays';
import _ from 'lodash';
import GridLayout from 'components/molecules/grid_layout';
import useStyles from './styles';
import { IEditAlertInput } from 'services/api/alert';

export interface IModalParams {
  mode: 'edit' | 'add';
  defaultValues?: IFormData & { users: string[] };
  storeid: string;
  alertid?: string;
}

const ModalAddEditAlert = () => {
  const dispatch = useDispatch();
  // const formRef = React.useRef<any>();
  const {
    setValue,
    reset,
    register,
    control,
    watch,
    errors,
    handleSubmit,
    getValues,
  } = useForm<IFormData>({
    defaultValues,
  });
  const history = useHistory();

  const onSubmit = (storeid: string, alertid?: string) =>
    handleSubmit((data: IFormData) => {
      dispatch(
        StoreActions.updateAlertAction({
          data: {
            fieldName: data.fieldName,
            fieldValue: data.fieldValue,
            users: data.users?.map((obj) => obj._id) || [],
            condition: data.condition as ConditionEnum,
            alertid: alertid as string,
            store: storeid,
            types: data.types,
            fieldValueType: data.fieldValueType,
          } as IEditAlertInput,
          onFinish: () => {
            dispatch(
              ModalActions.closeModalAction({
                modalID: ModalIDs.MODAL_ADD_EDIT_ALERT,
              })
            );
            history.push(
              NavigationConfig.storePage({
                type: TabType.Alerts,
              }).path
            );
            dispatch(
              StoreActions.fetchAlertsListAction({
                data: {
                  page: AlertsListDefaultFiltering.DefaultPage,
                  pageSize: AlertsListDefaultFiltering.DefaultPageSize,
                  sortField: AlertsListDefaultFiltering.DefaultSortField,
                  order: AlertsListDefaultFiltering.DefaultOrder,
                },
                onFinish: () => {},
              })
            );
          },
        })
      );
    });

  return (
    <form>
      <BaseModal
        modalID={ModalIDs.MODAL_ADD_EDIT_ALERT}
        renderContent={(props: IModalParams) => (
          <ModalForm
            params={props}
            reset={reset}
            setValue={setValue}
            register={register}
            control={control}
            watch={watch}
            errors={errors}
          />
        )}
        modalProps={{ fullWidth: true }}
        renderHeader={(props: IModalParams) => (
          <TranslatedText
            defaultText={props.mode === 'add' ? 'Add Alert' : 'Edit Alert'}
          />
        )}
        renderFooter={(props: IModalParams) => (
          <React.Fragment>
            <Button
              variant="outlined"
              onClick={() =>
                dispatch(
                  ModalActions.closeModalAction({
                    modalID: ModalIDs.MODAL_ADD_EDIT_ALERT,
                  })
                )
              }
            >
              <TranslatedText defaultText={`Cancel`} />
            </Button>
            <SubmitWrapper
              mode={props.mode}
              onSubmit={onSubmit(props.storeid, props.alertid)}
            />
          </React.Fragment>
        )}
      />
    </form>
  );
};

interface ISubmitWrapper {
  onSubmit: () => void;
  mode: 'edit' | 'add';
}

const SubmitWrapper = ({ onSubmit, mode }: ISubmitWrapper) => {
  const status = StoreSelectors.useGetAddStoreStatus();
  return (
    <Button
      variant="outlined"
      color="primary"
      disabled={status === StateStatus.Pending}
      onClick={() => {
        onSubmit();
      }}
    >
      <TranslatedText defaultText={`Submit`} />
    </Button>
  );
};

interface IFormData {
  fieldName: string;
  fieldValue: string;
  users: ReturnType<typeof StoreSelectors.useGetUsersList>;
  condition: ConditionEnum | '';
  types: AlertTypeEnum[] | null;
  fieldValueType: AlertFieldValueType;
}

const defaultValues: IFormData = {
  fieldName: '',
  fieldValue: '',
  condition: '',
  users: [],
  types: null,
  fieldValueType: AlertFieldValueType.Number,
};

interface IModalFormProps {
  control: any;
  errors: any;
  register: any;
  reset: any;
  setValue: ReturnType<typeof useForm>['setValue'];
  params: IModalParams;
  watch: ReturnType<typeof useForm>['watch'];
}

const ModalForm = ({
  control,
  errors,
  setValue,
  watch,
  params,
  reset,
}: IModalFormProps) => {
  const classes = useStyles();

  React.useEffect(() => {
    if (params.mode === 'edit' && params.defaultValues) {
      reset({
        ...defaultValues,
        fieldName: params.defaultValues.fieldName,
        fieldValue: params.defaultValues.fieldValue,
        condition: params.defaultValues.condition,
        types: params.defaultValues.types,
        fieldValueType: params.defaultValues.fieldValueType,
      });
    } else {
      reset(defaultValues);
    }
  }, [params, reset]);

  return (
    <React.Fragment>
      <GridLayout
        spacing={0}
        elements={[
          {
            element: (
              <FieldValueWrapper
                control={control}
                errors={errors}
                watch={watch}
              />
            ),
            id: 'fieldValue',
            size: 8,
          },
          {
            element: (
              <AppSelect
                fullWidth
                name={'fieldValueType'}
                control={control}
                margin="normal"
                rules={{ required: <RequiredField /> }}
                InputLabelProps={{
                  shrink: true,
                }}
                error={'fieldValueType' in errors}
                autoComplete={'fieldValueType'}
                label={<TranslatedText defaultText="Field Value Type" />}
                variant="outlined"
                placeholder="Enter Value Type"
                options={[
                  {
                    label: 'Number',
                    id: AlertFieldValueType.Number,
                  },
                  {
                    label: 'Persentage',
                    id: AlertFieldValueType.Persentage,
                  },
                  {
                    label: 'Time',
                    id: AlertFieldValueType.Time,
                  },
                ]}
              />
            ),
            id: 'fieldValueType',
            rowClassName: classes.textfield,
            size: 4,
          },
        ]}
      />

      {/* <AppSelect
        fullWidth
        name={'fieldName'}
        control={control}
        margin="normal"
        rules={{ required: <RequiredField /> }}
        InputLabelProps={{
          shrink: true,
        }}
        autoComplete={'fieldName'}
        label={<TranslatedText defaultText="Field Name" />}
        variant="outlined"
        placeholder="Enter field name"
        options={[
          {
            label: 'Initial In',
            id: 'initialin',
          },
          {
            label: 'Cappacity',
            id: 'cappacity',
          },
          {
            label: 'Occupancy In',
            id: 'occupancy.in',
          },
          {
            label: 'Occupancy Out',
            id: 'occupancy.out',
          },
          {
            label: 'Occupancy Current',
            id: 'occupancy.current',
          },
        ]}
      /> */}

      <FieldNameSelectorWrapper
        watch={watch}
        errors={errors}
        control={control}
      />

      <ConditionSelectorWrapper
        watch={watch}
        errors={errors}
        control={control}
      />
      {/* <AppSelect
        fullWidth
        name={'condition'}
        control={control}
        margin="normal"
        rules={{ required: <RequiredField /> }}
        InputLabelProps={{
          shrink: true,
        }}
        autoComplete={'condition'}
        label={<TranslatedText defaultText="Condition" />}
        variant="outlined"
        placeholder="Enter Condition"
        options={[
          {
            label: 'Greater Than (>)',
            id: ConditionEnum.GT,
          },
          {
            label: 'Less Than (<)',
            id: ConditionEnum.LT,
          },
          {
            label: 'Greater than equal (>=)',
            id: ConditionEnum.GTE,
          },
          {
            label: 'Less than equal (<=)',
            id: ConditionEnum.LTE,
          },
          {
            label: 'Equal (=)',
            id: ConditionEnum.EQ,
          },
        ]}
      /> */}
      <UsersDropdownWrapper
        userIds={params?.defaultValues?.users || []}
        setValue={setValue}
        errors={errors}
        control={control}
      />
      <AlertTypesDropdownWrapper
        setValue={setValue}
        errors={errors}
        control={control}
      />
    </React.Fragment>
  );
};

interface IUserDropdown {
  control: any;
  errors: any;
  setValue: ReturnType<typeof useForm>['setValue'];
  userIds: string[];
}

// users dropdown
const UsersDropdownWrapper = React.memo(
  ({ control, errors, setValue, userIds }: IUserDropdown) => {
    const usersList = StoreSelectors.useGetUsersList();
    const userIdsMap = React.useMemo(
      () =>
        ArrayUtils.arrayObjectsToObject(
          Array.isArray(usersList) ? usersList : [],
          '_id'
        ),
      [usersList]
    );

    React.useEffect(() => {
      console.log('REsult::....', usersList, userIds);
      // use the timeout to solve as initialization issue of AppMultipleAutoCompleteSelect default values
      const timer = setTimeout(() => {
        setValue(
          'users',
          userIds.map((id) => userIdsMap[id])
        );
      });
      return () => {
        clearTimeout(timer);
      };
    }, [usersList, setValue, userIds]);

    return (
      <AppMultipleAutoCompleteSelect
        multiple={true}
        id="users"
        control={control}
        margin="normal"
        label={<TranslatedText defaultText={'Users'} />}
        name="users"
        error={'users' in errors}
        options={usersList || []}
        rules={{
          required: <RequiredField />,
        }}
        size="medium"
        selectedKey="email"
      />
    );
  }
);

interface IFieldValueWrapperProps {
  watch: ReturnType<typeof useForm>['watch'];
  errors: ReturnType<typeof useForm>['errors'];
  control: ReturnType<typeof useForm>['control'];
}

const ConditionSelectorWrapper = ({
  watch,
  errors,
  control,
}: IFieldValueWrapperProps) => {
  const watchFieldValueType = watch('fieldValueType');

  const options = React.useMemo(() => {
    const _options = [
      {
        label: 'Greater Than (>)',
        id: ConditionEnum.GT,
      },
      {
        label: 'Less Than (<)',
        id: ConditionEnum.LT,
      },
      {
        label: 'Greater than equal (>=)',
        id: ConditionEnum.GTE,
      },
      {
        label: 'Less than equal (<=)',
        id: ConditionEnum.LTE,
      },
    ];
    if (watchFieldValueType === AlertFieldValueType.Time) {
      return _options;
    } else {
      return [
        ..._options,
        {
          label: 'Equal (=)',
          id: ConditionEnum.EQ,
        },
      ];
    }
  }, [watchFieldValueType]);

  return (
    <AppSelect
      fullWidth
      name={'condition'}
      control={control}
      margin="normal"
      error={'condition' in errors}
      rules={{ required: <RequiredField /> }}
      InputLabelProps={{
        shrink: true,
      }}
      helperText={errors.condition && errors.condition.message}
      autoComplete={'condition'}
      label={<TranslatedText defaultText="Condition" />}
      variant="outlined"
      placeholder="Enter Condition"
      options={options}
    />
  );
};

const FieldValueWrapper = ({
  watch,
  errors,
  control,
}: IFieldValueWrapperProps) => {
  const watchFieldValueType = watch('fieldValueType');

  return (
    <AppTextField
      variant="outlined"
      margin="normal"
      error={'fieldValue' in errors}
      helperText={errors.fieldValue && errors.fieldValue.message}
      rules={{ required: <RequiredField /> }}
      fullWidth
      id="fieldValue"
      placeholder="Enter field value"
      label={<TranslatedText defaultText={'Field Value'} />}
      control={control}
      InputProps={{
        endAdornment:
          watchFieldValueType === AlertFieldValueType.Persentage ? (
            <InputAdornment position="start">%</InputAdornment>
          ) : watchFieldValueType === AlertFieldValueType.Time ? (
            <InputAdornment position="start">Minutes</InputAdornment>
          ) : null,
      }}
      type={'number'}
      InputLabelProps={{
        shrink: true,
      }}
      autoComplete="fieldValue"
      name="fieldValue"
    />
  );
};

const FieldNameSelectorWrapper = ({
  watch,
  errors,
  control,
}: IFieldValueWrapperProps) => {
  const watchFieldValueType = watch('fieldValueType');

  const options = React.useMemo(() => {
    if (watchFieldValueType === AlertFieldValueType.Time) {
      return [
        {
          label: 'Updated Datetime',
          id: 'occupancy.updatedDatetime',
        },
      ];
    } else {
      return [
   
        {
          label: 'Customers  Inside',
          id: 'occupancy.in',
        },
        {
          label: 'Customers Out',
          id: 'occupancy.out',
        },
        {
          label: 'Current Occupancy',
          id: 'occupancy.current',
        },
      ];
    }
  }, [watchFieldValueType]);

  return (
    <AppSelect
      fullWidth
      name={'fieldName'}
      control={control}
      helperText={errors.fieldName && errors.fieldName.message}
      margin="normal"
      error={'fieldName' in errors}
      rules={{ required: <RequiredField /> }}
      InputLabelProps={{
        shrink: true,
      }}
      autoComplete={'fieldName'}
      label={<TranslatedText defaultText="Field Name" />}
      variant="outlined"
      placeholder="Enter field name"
      options={options}
    />
  );
};

interface IAlertTypesDropdown {
  control: any;
  errors: any;
  setValue: ReturnType<typeof useForm>['setValue'];
}

// users dropdown
const AlertTypesDropdownWrapper = React.memo(
  ({ control, errors, setValue }: IAlertTypesDropdown) => {
    const typesOptions = React.useMemo(
      () =>
        Object.values(AlertTypeEnum).map((data) => ({
          id: data,
          label: data,
        })),
      []
    );

    return (
      <AppMultipleSelect
        control={control}
        inputVariant="checkbox"
        variant="outlined"
        helperText={errors.types && errors.types.message}
        id={`types`}
        error={'types' in errors}
        name={`types`}
        rules={{ required: <RequiredField /> }}
        options={typesOptions}
        fullWidth
        margin="normal"
        label={<TranslatedText defaultText={'Alert Types'} />}
      />
    );
  }
);

const RequiredField = React.memo(() => {
  return <TranslatedText defaultText={'Required Value'} />;
});

export default React.memo(ModalAddEditAlert);
