import React from 'react';
import { LanguagesEnum } from 'config/languages';
import { MonthsEnum } from 'config';
import { ChartTypesEnum } from 'components/molecules/custom_chart';
import {
  IGridConf,
  ComponentTypeEnum,
} from 'components/compounds/dashboard_builder/configuration';
import * as NumberFormattingUtils from 'utils/format_number';
import * as AnalyticsService from 'services/api/analytics';
import { Theme } from '@material-ui/core';
import * as RandomUtils from 'utils/randomize';
import { IFormDataOutput } from 'redux/reducers/storeComparison_reducer/selectors';
import { ChartOptions } from 'chart.js';

const OptionsGraphType1 = (): ChartOptions => ({
  legend: {
    display: true,
    align: 'start',
    position: 'top',
    labels: {
      usePointStyle: true,
    },
  },
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      display: false,
    },
  },
  responsive: true,
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        // stacked: true,
        ticks: {
          display: true,
          // suggestedMax: max || 70000,
          callback: function (label: any, index: any, labels: any) {
            return `${NumberFormattingUtils.formatNumberWithCommas(label)} λ`;
          },
        },
      },
    ],
  },
});

interface IFormData extends IFormDataOutput {}

export const DashboardBuilderConfig = (data: IFormData): IGridConf[] => [
  {
    width: 12,
    type: ComponentTypeEnum.chart,
    props: {
      height: 350,
      data: [
        {
          data: [],
          label: '',
          format: {
            label: 'Store 1',
            fill: false,
            type: 'line',
            borderColor: '#0695FC',
            backgroundColor: '#0695FC',
          },
        },
        {
          data: [],
          label: '',
          format: {
            label: 'Store 2',
            fill: false,
            type: 'line',
            borderColor: '#4668C2',
            backgroundColor: '#4668C2',
          },
        },
        {
          data: [],
          label: '',
          format: {
            label: 'Weekends',
            fill: false,
            backgroundColor: 'rgba(238, 234, 248, 0.8)',
          },
        },
      ],
      labels: [],
      chartType: ChartTypesEnum.COLUMN,
      options: OptionsGraphType1(),
      title: 'Store 1 Vs Store 2 per Day',
    },
    fetch: async () => {
      if (!data.store1 || !data.store2) {
        return {
          data: [],
          labels: [],
        };
      }
      let response = await AnalyticsService.getStore1VsStore2PerDay({
        store1id: data.store1 || '',
        store2id: data.store2 || '',
        month: data.selectedMonth as any,
      });

      return {
        data: [
          response.data.store1,
          response.data.store2,
          response.data.weekends,
        ],
        labels: response.data.labels,
      };
    },
  },
];
