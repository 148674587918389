import React from 'react';
import BaseModal from 'components/molecules/base_modal';
import TranslatedText from 'components/atoms/translated_text';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { AppTextField } from 'components/molecules/form_controls';
import { Button } from '@material-ui/core';
import * as Configuration from '../../configuration';

export interface IModalParams {
  defaultValues: IFormData;
}
const { NotificationListDefaultFiltering } = Configuration;

const ModalAddNotification = () => {
  const dispatch = useDispatch();

  const {
    setValue,
    reset,
    register,
    control,
    watch,
    errors,
    handleSubmit,
    getValues,
  } = useForm<IFormData>({
    defaultValues,
  });

  return (
    <form>
      <BaseModal
        modalID={ModalIDs.MODAL_VIEW_NOTIFICATION}
        renderContent={(props: IModalParams) => (
          <ModalForm
            params={props}
            reset={reset}
            setValue={setValue}
            register={register}
            control={control}
            errors={errors}
          />
        )}
        modalProps={{ fullWidth: true }}
        renderHeader={(props: IModalParams) => (
          <TranslatedText defaultText={'View Notification'} />
        )}
        renderFooter={(props: IModalParams) => (
          <React.Fragment>
            <Button
              variant="outlined"
              onClick={() =>
                dispatch(
                  ModalActions.closeModalAction({
                    modalID: ModalIDs.MODAL_VIEW_NOTIFICATION,
                  })
                )
              }
            >
              <TranslatedText defaultText={`Close`} />
            </Button>
          </React.Fragment>
        )}
      />
    </form>
  );
};

interface IFormData {
  title: string;
  description: string;
}

const defaultValues: IFormData = {
  title: '',
  description: '',
};

interface IModalFormProps {
  control: any;
  errors: any;
  register: any;
  reset: any;
  setValue: ReturnType<typeof useForm>['setValue'];
  params: IModalParams;
}

const ModalForm = ({
  control,
  errors,
  setValue,
  params,
  reset,
}: IModalFormProps) => {
  React.useEffect(() => {
    reset({
      ...defaultValues,
      ...params.defaultValues,
    });
  }, [params, reset]);

  return (
    <React.Fragment>
      <AppTextField
        variant="outlined"
        margin="normal"
        contentEditable={false}
        error={'title' in errors}
        helperText={errors.title && errors.title.message}
        rules={{ required: <RequiredField /> }}
        fullWidth
        id="title"
        placeholder="Enter title"
        label={<TranslatedText defaultText={'Title'} />}
        control={control}
        InputLabelProps={{
          shrink: true,
        }}
        autoComplete="title"
        name="title"
      />
      <AppTextField
        variant="outlined"
        margin="normal"
        error={'description' in errors}
        helperText={errors.description && errors.description.message}
        rules={{ required: <RequiredField /> }}
        fullWidth
        contentEditable={false}
        id="description"
        placeholder="Enter description"
        label={<TranslatedText defaultText={'Description'} />}
        control={control}
        multiline
        rows={4}
        InputLabelProps={{
          shrink: true,
        }}
        autoComplete="description"
        name="description"
      />
    </React.Fragment>
  );
};

const RequiredField = React.memo(() => {
  return <TranslatedText defaultText={'Required Value'} />;
});

export default React.memo(ModalAddNotification);
