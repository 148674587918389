import React from 'react';
import { Grid } from '@material-ui/core';
import StatistcsCardLabel from 'components/app_common/statistics_card_label';
import TranslatedText from 'components/atoms/translated_text';
import * as VisitorAnalyticsSelectors from 'redux/reducers/visitorAnalytics_reducer/selectors';
import * as FormatNumbersUtils from 'utils/format_number';
import { useDispatch } from 'react-redux';

export default function StatisticCardsLayout() {
  const previousDaysResult = VisitorAnalyticsSelectors.useGetVisitorsPreviousDays();
  const hourlyVisitorsCount = VisitorAnalyticsSelectors.useGetAverageHourlyVisitors();
  const avgShoppingTime = VisitorAnalyticsSelectors.useGetAvarageShppingTime();

  return (
    <Grid container spacing={1} justify={'space-between'}>
      <Grid xl={3} lg={3} md={6} sm={6} xs={12} item>
        <StatistcsCardLabel
          descriptionColor={'#0F2E69'}
          titleColor={'#0F2E69'}
          backgroundColor={'#F6F0CC'}
          description={
            <TranslatedText defaultText={'Total Visitors in last 30 Days'} />
          }
          title={
            <TranslatedText
              defaultText={
                previousDaysResult?.dataCurrent?.visitorsNumber
                  ? `${FormatNumbersUtils.formatNumberWithCommas(
                      previousDaysResult?.dataCurrent?.visitorsNumber
                    )}`
                  : '-'
              }
            />
          }
        />
      </Grid>
      <Grid xl={3} lg={3} md={6} sm={6} xs={12} item>
        <StatistcsCardLabel
          descriptionColor={'#0F2E69'}
          titleColor={'#219653'}
          backgroundColor={'#DAFFE7'}
          description={
            <TranslatedText defaultText={'% Change from previous 30 Days'} />
          }
          title={
            <TranslatedText
              defaultText={`${
                FormatNumbersUtils.formatNumberDigits(
                  previousDaysResult?.increasingPersentage
                ) || '-'
              }%`}
            />
          }
        />
      </Grid>
      <Grid xl={3} lg={3} md={6} sm={6} xs={12} item>
        <StatistcsCardLabel
          descriptionColor={'#0F2E69'}
          titleColor={'#0F2E69'}
          backgroundColor={'#EEEAF8'}
          description={
            <TranslatedText
              defaultText={'Average Hourly Visitors Per Day in Last 30 Days'}
            />
          }
          title={
            <TranslatedText
              defaultText={
                typeof hourlyVisitorsCount === 'number'
                  ? FormatNumbersUtils.formatNumberWithCommas(
                      hourlyVisitorsCount || 0
                    )
                  : '-'
              }
            />
          }
        />
      </Grid>
      <Grid xl={3} lg={3} md={6} sm={6} xs={12} item>
        <StatistcsCardLabel
          descriptionColor={'#0F2E69'}
          titleColor={'#F12121'}
          backgroundColor={'#FCE2E2'}
          description={
            <TranslatedText
              defaultText={'Average Shopping Time in Last 30 Days (mins)'}
            />
          }
          title={
            <TranslatedText
              defaultText={
                typeof avgShoppingTime === 'number'
                  ? FormatNumbersUtils.formatNumberWithCommas(
                      avgShoppingTime || 0
                    )
                  : '-'
              }
            />
          }
        />
      </Grid>
    </Grid>
  );
}
