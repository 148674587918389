import React from 'react';
import PageLayout from 'components/app_common/page_layout';
import { withRouter } from 'react-router';
import SelectForm from './components/select_form';
import { useDispatch } from 'react-redux';
import StoresCommonActions from 'redux/reducers/gl_app_common/actions';
import * as Configuration from './configuration';
import DashboardBuilder from 'components/compounds/dashboard_builder';
import * as StoresCommonSelectors from 'redux/reducers/gl_app_common/selectors';
import GridLayout from 'components/molecules/grid_layout';
import OccupancyStatitic from './components/occupancy_statistic';
import StoreInOutChart from './components/store_in_out_chart';

const OccupancyPage = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(StoresCommonActions.fetchStoresAction());
  }, [dispatch]);

  return (
    <PageLayout>
      <SelectForm />
      <DashboardBuilderWrapper />
    </PageLayout>
  );
};

const DashboardBuilderWrapper = React.memo(() => {
  const storeid = StoresCommonSelectors.useGetSelectedStore();
  const [refresh, setRefresh] = React.useState<number>(0);
  const ref = React.useRef();

  // console.log('Render....DashboardBuilderWrapper');
  React.useEffect(() => {
    const minutes = 0.5;
    const timeout = setTimeout(() => {
      setRefresh(refresh + 1);
    }, 60 * 1000 * minutes);

    return () => {
      clearTimeout(timeout);
    };
  }, [setRefresh, refresh]);

  return (
    <React.Fragment>
      {storeid && (
        <React.Fragment>
          <GridLayout
            elements={[
              {
                element: (
                  <DashboardBuilder
                    spacing={2}
                    ref={ref}
                    configuration={Configuration.DashboardConfig1(storeid)}
                  />
                ),
                size: 5,
                id: 'occupancy1',
              },
              {
                element: <OccupancyStatitic refComponent={ref} />,
                size: 4,
                id: 'occupancy2',
              },
              {
                element: (
                  <DashboardBuilder
                    spacing={2}
                    configuration={Configuration.DashboardConfig(storeid)}
                  />
                ),
                size: 3,
                id: 'occupancy3',
              },
              {
                element: <StoreInOutChart />,
                size: 12,
                id: 'storeInOut',
              },
            ]}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
});

export default withRouter(OccupancyPage);
