import React, { ReactElement } from 'react';
import useStyles from './styles';
import List, { ListProps } from '@material-ui/core/List';
import { ListItem, ListItemTypeMap } from '@material-ui/core';

interface IProps extends ListProps {
  data: IDataElement[];
  idKey: string;
  renderElement: (_data: any, index: number) => ReactElement;
  listItemProps?: {
    style: React.CSSProperties;
    className: string;
  };
}

interface IDataElement {
  [key: string]: any;
}

export default function DataList(props: IProps): ReactElement {
  const { data, renderElement, idKey, listItemProps, ...listProps } = props;

  return (
    <List {...listProps}>
      {data.map((_data, index) => (
        <ListItem
          {...listItemProps}
          style={{ paddingLeft: 0, paddingRight: 0, ...listItemProps?.style }}
          key={_data[idKey] || index}
        >
          {renderElement(_data, index)}
        </ListItem>
      ))}
    </List>
  );
}
