import {
  IAction,
  IActionMethods,
  StateStatus,
  KEEP_THE_SAME,
} from '../../utils/common';
import * as Types from './types';
import * as StoresService from 'services/api/stores';
import { Dispatch } from 'redux';
import reactLogger from 'utils/logger';
import * as UserService from 'services/api/user';
import * as HeatmapCounts from 'services/api/store_heatmap_counts';
import { StoresDataType } from 'services/api/user';
import { HeatmapTypeEnum } from 'config/heatmapTypes';

// /** Fetch Stores  */
// class FetchStores implements IActionMethods {
//   onPending(): IAction {
//     return {
//       type: Types.SET_STORES,
//       status: StateStatus.Pending,
//       data: KEEP_THE_SAME,
//     };
//   }
//   onSuccess(data: any): IAction {
//     return {
//       type: Types.SET_STORES,
//       status: StateStatus.Success,
//       data: data,
//     };
//   }

//   onFailed(): IAction {
//     return {
//       type: Types.SET_STORES,
//       status: StateStatus.Failed,
//       data: KEEP_THE_SAME,
//     };
//   }

//   action(): any {
//     return async (dispatch: Dispatch<any>) => {
//       try {
//         dispatch(this.onPending());
//         let response = await UserService.getStores();
//         dispatch(this.onSuccess(response.data));
//       } catch (error) {
//         reactLogger.error('FetchStores Error:', error.message);
//         // console.log('FetchStores Error:', error.message); // '<ClassName> Error: <error>'
//         dispatch(this.onFailed());
//       }
//     };
//   }
// }

/** Fetch Store Cameras  */
class FetchStoreCameras implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_STORE_CAMERAS,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_STORE_CAMERAS,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_STORE_CAMERAS,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(storeid: string): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await StoresService.fetchStoreCameras(storeid);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        reactLogger.error('FetchStores Error:', error.message);
        dispatch(this.onFailed());
      }
    };
  }
}

/** Fetch Heatmap Count  */
class FetchHeatmapCount implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_HEATMAP_COUNT,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_HEATMAP_COUNT,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_HEATMAP_COUNT,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: HeatmapCounts.IHeatmapCountsInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await HeatmapCounts.fetchHeatmapCounts(payload);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        reactLogger.error('FetchHeatmapCount Error:', error.message);
        dispatch(this.onFailed());
      }
    };
  }
}

/** set selected store  */

class SetSelectedStoreData implements IActionMethods {
  onPending(): IAction {
    throw new Error('Method not implemented');
  }
  onSuccess(data: StoresDataType): IAction {
    return {
      type: Types.SET_SELECTED_STORE,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    throw new Error('Method not implemented');
  }

  action(data: StoresDataType): IAction {
    try {
      return this.onSuccess(data);
    } catch (error) {
      reactLogger.error('SetSelectedStore Error:', error.message);
      return this.onFailed();
    }
  }
}

/** set heatmap type */
class SetHeatmapType implements IActionMethods {
  onPending(): IAction {
    throw new Error('Method not implemented');
  }
  onSuccess(data: HeatmapTypeEnum): IAction {
    return {
      type: Types.SET_HEATMAP_TYPE,
      status: StateStatus.Success,
      data: {
        heatmapType: data,
      },
    };
  }

  onFailed(): IAction {
    throw new Error('Method not implemented');
  }

  action(data: HeatmapTypeEnum): IAction {
    return this.onSuccess(data);
  }
}

export default {
  setSelectedStoreDataAction: (payload: StoresDataType) =>
    new SetSelectedStoreData().action(payload),
  setHeatmapTypeAction: (payload: HeatmapTypeEnum) =>
    new SetHeatmapType().action(payload),
  // fetchStoresAction: () => new FetchStores().action(),
  fetchHeatmapCountAction: (payload: HeatmapCounts.IHeatmapCountsInput) =>
    new FetchHeatmapCount().action(payload),
  fetchStoreCamerasActions: (storeid: string) =>
    new FetchStoreCameras().action(storeid),
};
