import { useSelector } from 'react-redux';
import { ReducerKeys } from '../../config';

export const useSelectForgotPassworddStatus = () => {
  return useSelector(
    (state: any) =>
      state[ReducerKeys.RESET_PASSOWRD_REDUCER].forgotPasswordStatus
  );
};

export const useSelectResetPassword = () => {
  return useSelector(
    (state: any) =>
      state[ReducerKeys.RESET_PASSOWRD_REDUCER].resetPasswordStatus
  );
};
