import React from 'react';
import { LanguagesEnum } from 'config/languages';
import { MonthsEnum, RegionsEnum } from 'config';
import { ChartTypesEnum } from 'components/molecules/custom_chart';
import {
  IGridConf,
  ComponentTypeEnum,
} from 'components/compounds/dashboard_builder/configuration';
import * as NumberFormattingUtils from 'utils/format_number';
import * as AnalyticsService from 'services/api/analytics';
import { Theme } from '@material-ui/core';
import * as RandomDataUtils from 'utils/randomize';
import * as RandomUtils from 'utils/randomize';
import { ChartOptions } from 'chart.js';

const OptionsGraphTypePie = (theme?: Theme) => ({
  legend: {
    display: true,
    align: 'start',
    position: 'left',
    labels: {
      usePointStyle: true,
    },
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
  maintainAspectRatio: false,
  tooltips: {
    enabled: true,
  },
});

const OptionsGraphType1 = (): ChartOptions => ({
  legend: {
    display: true,
    align: 'start',
    position: 'top',
    labels: {
      usePointStyle: true,
    },
  },
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      display: false,
    },
  },
  responsive: true,
  scales: {
    xAxes: [
      {
        gridLines: {
          borderDash: [0, 1],
        },
        ticks: {
          display: true,
          beginAtZero: true,
          callback: function (label: any, index: any, labels: any) {
            return `${label}x`;
          },
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          borderDash: [0, 1],
        },
        ticks: {
          display: true,
          // suggestedMax: max || 70000,
          callback: function (label: any, index: any, labels: any) {
            return `${label}`;
          },
        },
      },
    ],
  },
});

interface IConfigProps {
  weekNumData: {
    datasets: number[][];
    labels: string[];
  };
  visitorsByDayData: {
    datasets: {
      data: number[];
      name: string;
    }[];
    labels: string[];
  };
}

//   plotOptions: {
//     heatmap: {
//       shadeIntensity: 0.5,
//       radius: 0,
//       useFillColorAsStroke: true,
//       colorScale: {
//         ranges: [{
//             from: -30,
//             to: 5,
//             name: 'low',
//             color: '#00A100'
//           },
//           {
//             from: 6,
//             to: 20,
//             name: 'medium',
//             color: '#128FD9'
//           },
//           {
//             from: 21,
//             to: 45,
//             name: 'high',
//             color: '#FFB200'
//           },
//           {
//             from: 46,
//             to: 55,
//             name: 'extreme',
//             color: '#FF0000'
//           }
//         ]
//       }
//     }
//   },

export const DashboardBuilderConfig = (data: IConfigProps): IGridConf[] => [
  {
    width: 12,
    type: ComponentTypeEnum.apexChart,
    props: {
      type: 'heatmap',
      height: 350,
      layoutProps: {
        variant: 'outlined',
      },
      series: data.visitorsByDayData.datasets,
      options: {
        chart: {
          toolbar: {
            show: false,
          },
          height: 350,
          type: 'heatmap',
        },
        xaxis: {
          tickPlacement: 'between',
          type: 'category',
          categories: data.visitorsByDayData.labels,
        },
        colors: ['#008FFB'],
        legend: {
          show: false,
        },
        plotOptions: {
          heatmap: {
            shadeIntensity: 0.5,
            radius: 3,
            colorScale: {
              ranges: [
                {
                  from: 0,
                  to: 0,
                  name: 'zero',
                  color: '#dce0e0',
                },
                {
                  from: 1,
                  to: 15,
                  name: 'low',
                  color: '#00A100',
                },
                {
                  from: 16,
                  to: 40,
                  name: 'medium',
                  color: '#128FD9',
                },
                {
                  from: 41,
                  to: 100,
                  name: 'high',
                  color: '#FFB200',
                },
                {
                  from: 101,
                  // to: 55,
                  name: 'extreme',
                  color: '#FF0000',
                },
              ],
            },
          },
        },
      },
    },
    fetch: null,
  },
  {
    width: 12,
    type: ComponentTypeEnum.chart,
    props: {
      height: 450,
      data: [
        {
          data: data.weekNumData.datasets[0],
          label: '',
          format: {
            label: '19',
            maxBarThickness: 50,
            fill: false,
            backgroundColor: '#0F2E69',
          },
        },
      ],
      labels: data.weekNumData.labels,
      chartType: ChartTypesEnum.BAR,
      options: OptionsGraphType1(),
      title: 'Visitors By Day WeekNum',
    },
    fetch: null,
  },
];
