import React from 'react';
import { AppSelect } from 'components/molecules/form_controls';
import { useForm } from 'react-hook-form';
import useStyles from './styles';
import { Grid, Typography } from '@material-ui/core';
import TranslatedText from 'components/atoms/translated_text';
import { Months } from 'config';
import * as StorePerformanceSelectors from 'redux/reducers/storePerformance_reducer/selectors';
import StorePerformanceActions from 'redux/reducers/storePerformance_reducer/actions';
import reactLogger from 'utils/logger';
import { useDispatch } from 'react-redux';
import { MonthsEnum } from 'config/months';
import StoresCommonActions from 'redux/reducers/gl_app_common/actions';
import * as StoresCommonSelectors from 'redux/reducers/gl_app_common/selectors';

interface IFormData {
  selectedStore: string | null;
  selectedMonth: string | null;
}

export default function SelectForm() {
  const classes = useStyles();
  const { setValue, watch, handleSubmit, errors, control, getValues } =
    useForm<IFormData>({
      // mode: 'onChange',
      defaultValues: {
        selectedStore: null,
        selectedMonth: StorePerformanceSelectors.getGetCurrentMonth() || null,
        // get Months[new Date().getMonth()].id
      },
    });
  reactLogger.renderComponent('Select Form');
  const selectedStoreWatch = watch('selectedStore');
  const selectedMonthWatch = watch('selectedMonth');

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (selectedStoreWatch && selectedMonthWatch) {
      dispatch(
        StorePerformanceActions.fetchVisitorsDataCurrentMonthAction({
          currentMonth: selectedMonthWatch as MonthsEnum,
          selectedStore: selectedStoreWatch,
        })
      );
      dispatch(
        StorePerformanceActions.fetchImportanceOfWeekDayOfSelectedMonthAction({
          selectedMonth: selectedMonthWatch as MonthsEnum,
          storeid: selectedStoreWatch,
        })
      );

      dispatch(
        StorePerformanceActions.fetchVisitorsByTimeGroupAction({
          selectedMonth: selectedMonthWatch as MonthsEnum,
          storeid: selectedStoreWatch,
        })
      );
    }
  }, [selectedStoreWatch, selectedMonthWatch]);

  React.useEffect(() => {
    if (selectedStoreWatch) {
      dispatch(
        StorePerformanceActions.fetchVisitorsDataLastDaysAction({
          storeid: selectedStoreWatch,
        })
      );
      dispatch(
        StorePerformanceActions.fetchVisitorsCurrentVsPreviousWeekChartDataAction(
          {
            storeid: selectedStoreWatch,
          }
        )
      );
      dispatch(
        StorePerformanceActions.fetchVisitorsPickByHourPerWeekdayAction({
          storeid: selectedStoreWatch,
        })
      );
      // dispatch(
      //   StorePerformanceActions.setSelectedStore({
      //     storeid: selectedStoreWatch,
      //   })
      // );
      dispatch(StoresCommonActions.setSelectedStoreAction(selectedStoreWatch));
    }
  }, [selectedStoreWatch]);

  const onSubmit = (_data: IFormData) => {
    console.log('Data:', _data);
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
      <Grid spacing={1} container justify={'space-between'}>
        <Grid xl={3} lg={3} md={4} sm={5} xs={12} item>
          <div>
            <Typography>
              <TranslatedText defaultText={'Select Store'} />
            </Typography>
            <SelectStoresWrapper setValue={setValue} control={control} />
          </div>
        </Grid>
        <Grid xl={3} lg={3} md={4} sm={5} xs={12} item>
          <div>
            <Typography component={'h4'}>
              <TranslatedText defaultText={'Select Month'} />
            </Typography>
            <AppSelect
              margin="none"
              fullWidth
              name={'selectedMonth'}
              style={{ marginTop: 0, marginBottom: 0 }}
              control={control}
              rules={{}}
              size="small"
              autoComplete={'selectedMonth'}
              variant="outlined"
              options={Months}
            />
          </div>
        </Grid>
      </Grid>
    </form>
  );
}

interface IPropsSelectStores {
  control: any;
  setValue: any;
}

const SelectStoresWrapper = (props: IPropsSelectStores) => {
  // const stores = StorePerformanceSelectors.useGetStores();
  const stores = StoresCommonSelectors.useGetStores();
  const selectedStore = StoresCommonSelectors.useGetSelectedStore();

  React.useEffect(() => {
    if (stores.length > 0) {
      props.setValue('selectedStore', selectedStore || stores[0].uniqueId);
    }
    // stores.length > 0 && props.setValue('selectedStore', stores[0].uniqueId);
  }, [stores, selectedStore]);

  return (
    <AppSelect
      margin="none"
      fullWidth
      style={{ marginTop: 0, marginBottom: 0 }}
      name={'selectedStore'}
      control={props.control}
      rules={{}}
      size="small"
      autoComplete={'selectedStore'}
      variant="outlined"
      options={stores.map(({ uniqueId, name }) => ({
        label: name,
        id: uniqueId,
      }))}
    />
  );
};
