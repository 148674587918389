import { Box, Button } from '@material-ui/core';
import TranslatedText from 'components/atoms/translated_text';
import {
  AppSelect,
  AppDatePicker,
  AppAutoCompleteSelect,
} from 'components/molecules/form_controls';
import GridLayout from 'components/molecules/grid_layout';
import { BoxStatus } from 'config/store';
import useQuery from 'custom_hooks/useQuery';
import NavigationConfig from 'navigation/config';
import React from 'react';
import { Control, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import * as NotificationSelectors from 'redux/reducers/notification_reducer/selectors';
import NotificationActions from 'redux/reducers/notification_reducer/actions';
import * as Configuration from '../../configuration';
import _ from 'lodash';
import { NotificationTypes } from 'config/notification';

const { NotificationListDefaultFiltering } = Configuration;

interface IFormData {
  dateStart: Date | null;
  dateEnd: Date | null;
  userEmail: string | null;
  type: string | null;
}

const defaultValues: IFormData = {
  dateStart: null,
  dateEnd: null,
  userEmail: null,
  type: null,
};

const FilteringBox = () => {
  const {
    setValue,
    watch,
    handleSubmit,
    errors,
    control,
    getValues,
  } = useForm<IFormData>({
    defaultValues,
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const handleValueChange = React.useCallback(
    (value: any, type?: 'dateStart' | 'dateEnd' | 'user' | 'type') => {
      history.push(
        NavigationConfig.notificationPage({
          email:
            type === 'user'
              ? _.get(value, 'email')
              : _.get(getValues().userEmail, 'email') || undefined,
          dateEnd:
            type === 'dateEnd'
              ? new Date(value).toISOString()
              : getValues().dateEnd?.toISOString() || undefined,

          dateStart:
            type === 'dateStart'
              ? new Date(value).toISOString()
              : getValues().dateStart?.toISOString() || undefined,
          type: type === 'type' ? value : getValues().type || undefined,
        }).path
      );
    },
    [getValues, dispatch]
  );

  return (
    <Box display="flex" marginBottom="1rem" marginTop="1rem">
      <GridLayout
        justify="flex-start"
        elements={[
          {
            id: 'addNotification',
            size: 2,
            element: (
              <Button
                onClick={() =>
                  dispatch(
                    ModalActions.openModalAction({
                      modalID: ModalIDs.MODAL_ADD_NOTIFICATION,
                      params: {},
                    })
                  )
                }
                color="primary"
                variant="contained"
                style={{ margin: 'auto' }}
                fullWidth
              >
                <TranslatedText defaultText="Send Notification" />
              </Button>
            ),
          },
          {
            id: 'userEmail',
            size: 2,
            element: (
              <UsersWrapperDropdown
                handleChange={(value) =>
                  handleValueChange(value as any, 'user')
                }
                control={control}
              />
            ),
          },
          {
            id: 'dateStart',
            size: 2,
            element: (
              <AppDatePicker
                fullWidth
                margin="none"
                size="small"
                label={<TranslatedText defaultText={'Start Date'} />}
                autoOk
                helperText={errors.dateStart && errors.dateStart.message}
                error={'dateStart' in errors}
                control={control}
                rules={{
                  required: false,
                }}
                handleChange={(value) =>
                  handleValueChange(value as any, 'dateStart')
                }
                name="dateStart"
              />
            ),
          },
          {
            id: 'dateEnd',
            size: 2,
            element: (
              <AppDatePicker
                fullWidth
                margin="none"
                size="small"
                label={<TranslatedText defaultText={'End Date'} />}
                autoOk
                helperText={errors.dateEnd && errors.dateEnd.message}
                error={'dateEnd' in errors}
                control={control}
                rules={{
                  required: false,
                }}
                name="dateEnd"
                handleChange={(value) =>
                  handleValueChange(value as any, 'dateEnd')
                }
              />
            ),
          },
          {
            id: 'type',
            size: 2,
            element: (
              <AppSelect
                fullWidth
                name={'type'}
                control={control}
                handleChange={(value) =>
                  handleValueChange(value as any, 'type')
                }
                margin="none"
                size="small"
                rules={{}}
                autoComplete={'type'}
                label={<TranslatedText defaultText="Notification Type" />}
                variant="outlined"
                options={[
                  {
                    label: 'Alert',
                    id: NotificationTypes.Alert,
                  },
                ]}
              />
            ),
          },
        ]}
      />
    </Box>
  );
};

interface IUsersWrapperDropdownProps {
  control: Control;
  handleChange: (value: any) => void;
}

const UsersWrapperDropdown = ({
  control,
  handleChange,
}: IUsersWrapperDropdownProps) => {
  const users = NotificationSelectors.useGetUsersList();

  return (
    <AppAutoCompleteSelect
      margin="none"
      fullWidth
      name={'userEmail'}
      control={control}
      rules={{}}
      handleChange={handleChange}
      size="small"
      autoComplete={'userEmail'}
      label={<TranslatedText defaultText="User email" />}
      options={users || []}
      selectedKey="email"
    />
  );
};
export default React.memo(FilteringBox);
