import React from 'react';
import { Typography, Paper, IconButton } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { useLocation } from 'react-router';
import FooterConfig, { FooterEnumSelector } from 'config/footerConfig';
import { matchPath } from 'react-router';
import useStyles from './styles';

interface IFooterProps {
  children: React.ReactNode;
}

const AppFooter = (props: IFooterProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {props.children}
      <FooterContent />
    </div>
  );
};

const FooterContent = React.memo(() => {
  const location = useLocation();
  const path = location.pathname;
  const arrayConfig = FooterConfig[FooterConfig.select];
  const classes = useStyles();

  let visible: boolean = false;

  const arrayMatch = (_paths: string[], _path: string): boolean => {
    for (let ___path of _paths) {
      if (
        matchPath(_path, {
          path: ___path,
          exact: true,
          strict: false,
        })?.isExact
      ) {
        return true;
      }
    }
    return false;
  };

  if (FooterConfig.select === FooterEnumSelector.excludePaths) {
    visible =
      arrayConfig !== 'all' &&
      Array.isArray(arrayConfig) &&
      !arrayMatch(arrayConfig, path);
  } else if (FooterConfig.select === FooterEnumSelector.includePaths) {
    visible =
      arrayConfig === 'all' ||
      (Array.isArray(arrayConfig) && arrayMatch(arrayConfig, path));
  }

  return (
    <React.Fragment>
      {visible && (
        <div className={classes.footerContent}>
          <Typography style={{ fontStyle: 'bold' }}>
            Copyright <span>&#169;</span> 2021. All Rights Reserved.
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
            }}
          >
            <img
              src={require('assets/images/logos/app-logo.png')}
              style={{ height: 30, marginBottom: 10 }}
            />
            <div style={{ display: 'flex' }}>
              <IconButton color="primary">
                <FacebookIcon color="primary" />
              </IconButton>
              <IconButton>
                <LinkedInIcon color="primary" />
              </IconButton>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
});

export default React.memo(AppFooter);
