import GridLayout from 'components/molecules/grid_layout';
import React, { ReactElement } from 'react';
import SelectForm from '../select_form';
import reactLogger from 'utils/logger';
import VisitorsByDayGraphs from '../statstics';
import VisitorsByDayWeekNumGraph from '../visitor_details_analytics';

const GraphsLayout = () => {
  reactLogger.renderComponent('GraphsLayout');

  // console.log('Data::-->', data);

  return (
    <React.Fragment>
      <GridLayout
        spacing={1}
        elements={[
          {
            size: 12,
            element: <SelectForm />,
            id: '1',
          },
          {
            size: 3,
            element: <LeftContainerWrapper />,
            id: '2',
          },
          {
            size: 9,
            element: <RightContainerWrapper />,
            id: '3',
          },
        ]}
      />
    </React.Fragment>
  );
};

const LeftContainerWrapper = () => <VisitorsByDayGraphs />;
const RightContainerWrapper = () => (
  <div>
    <VisitorsByDayWeekNumGraph />
  </div>
);

export default React.memo(GraphsLayout);
