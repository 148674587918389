import { ReactElement } from 'react';

export enum DateRangeEnum {
  Today = 'Today',
  Yesterday = 'Yesterday',
  LastWeek = 'LastWeek',
  LastMonth = 'LastMonth',
  LastSixMonths = 'LastSixMonths',
}

type IOptions = Array<{
  id: DateRangeEnum;
  label: string | ReactElement;
}>;

export const DateRangeSelect: IOptions = [
  {
    id: DateRangeEnum.Today,
    label: 'Today',
  },
  {
    id: DateRangeEnum.Yesterday,
    label: 'Yesterday',
  },
  {
    id: DateRangeEnum.LastWeek,
    label: 'LastWeek',
  },
  {
    id: DateRangeEnum.LastMonth,
    label: 'LastMonth',
  },
  {
    id: DateRangeEnum.LastSixMonths,
    label: 'Last 6 Months',
  },
];

export default DateRangeEnum;
