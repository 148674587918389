import { typeCreatorV2 } from '../../../redux/utils/common';

//  [REDUCER][TYPE]
// export const SET_STORES: string = typeCreatorV2(
//   'TimeGroupReducer',
//   'SetStores'
// );

export const SET_SELECTED_MONTH: string = typeCreatorV2(
  'TimeGroupReducer',
  'SetSelectedMonth'
);

// export const SET_SELECTED_STORE: string = typeCreatorV2(
//   'StorePerformanceReducer',
//   'SetSelectedStore'
// );
