//  [REDUCER][TYPE]
import { IReduxType, typeCreatorV2 } from '../../utils/common';

export const SET_TIME_TYPE: string = typeCreatorV2(
  'VisitorsDetailsReducer',
  'SetTime'
);

export const SET_METRIC: string = typeCreatorV2(
  'VisitorsDetailsReducer',
  'SetMetric'
);

export const SET_DATE_RANGE_TYPE: string = typeCreatorV2(
  'VisitorsDetailsReducer',
  'SetDateRange'
);

export const SET_CAMERA: string = typeCreatorV2(
  'VisitorsDetailsReducer',
  'SetCamera'
);
