import { useSelector } from 'react-redux';
import { ReducerKeys } from '../../config';
import useShallowEqualSelector from 'custom_hooks/useShallowEqualsSelector';
import _ from 'lodash';
import { StoresDataType } from 'services/api/user';

// /* selector: useGetStores */
// export const useGetStores = (): StoresDataType => {
//   const data = useSelector(
//     (state: any) => state[ReducerKeys.STORE_COMPARISON_REDUCER].stores.data
//   );
//   return Array.isArray(data) ? data : [];
// };

/* selector: useGetFormData */
export interface IFormDataOutput {
  store1: string | null;
  store2: string | null;
  selectedMonth: string | null;
}

export const useGetFormData = (): IFormDataOutput => {
  const data = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.STORE_COMPARISON_REDUCER].formValues.data
  );
  return data;
};

export const formDefaultValues = {
  store1: null,
  store2: null,
  selectedMonth: new Date().getMonth(),
};
