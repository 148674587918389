import React, { ReactElement } from 'react';
import { CustomChart } from 'components/molecules/custom_chart';
import * as LocalConfiguration from './configuration';
import TranslatedText from 'components/atoms/translated_text';
import { ChartTypesEnum } from 'components/molecules/custom_chart/configuration';
import * as StorePerformanceSelectors from 'redux/reducers/storePerformance_reducer/selectors';
import CardLabelTitle from 'components/app_common/card_label_title';

export default function VisitorsByTimeGroupGraph() {
  const {
    labels,
    data,
  } = StorePerformanceSelectors.useGetVisitorsByTimeGroup();

  return (
    <CustomChart
      title={
        <CardLabelTitle
          title={<TranslatedText defaultText="% Visitors By Time Group" />}
        />
      }
      layoutProps={{
        variant: 'outlined',
      }}
      labels={labels}
      data={LocalConfiguration.DummyDataset(data)}
      options={LocalConfiguration.DummyChartOptions}
      chartType={ChartTypesEnum.PIE}
    />
  );
}
