import { useSelector } from 'react-redux';
import { ReducerKeys } from '../../config';
import useShallowEqualSelector from 'custom_hooks/useShallowEqualsSelector';
import _ from 'lodash';
import { DaysRangeEnum } from 'config';
import { getState } from 'redux/store';
import { StoresDataType } from 'services/api/user';

// /* selector: useGetStores */

// export const useGetStores = (): StoresDataType => {
//   const data = useSelector(
//     (state: any) => state[ReducerKeys.VISITORS_BY_DAY_REDUCER].stores.data
//   );
//   return Array.isArray(data) ? data : [];
// };

/* selector: useGetVisitorsByDay */
export interface IVisitorsByDayOutput {
  hoursLabels: string[];
  datasets: {
    data: number[];
    name: string;
  }[];
}

export const useGetVisitorsByDay = (): IVisitorsByDayOutput | null => {
  const data = useSelector(
    (state: any) =>
      state[ReducerKeys.VISITORS_BY_DAY_REDUCER].visitorsByDay_graph.data
  );
  return data || null;
};

/* selector: useGetVisitorsWeekNum */
export interface IVisitorsWeekNumOutput {
  labels: string[];
  datasets: number[][];
}

export const useGetVisitorsWeekNum = (): IVisitorsWeekNumOutput | null => {
  const data = useSelector(
    (state: any) =>
      state[ReducerKeys.VISITORS_BY_DAY_REDUCER].visitorsWeekNum_graph.data
  );
  return data || null;
};
