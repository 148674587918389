//  [REDUCER][TYPE]
import { IReduxType, typeCreatorV2 } from '../../utils/common';
// export const SET_STORES: string = typeCreatorV2(
//   'VisitorsByDayReducer',
//   'SetStores'
// );

export const SET_VISITORS_BY_DAY: string = typeCreatorV2(
  'VisitorsByDayReducer',
  'SetVisitorsByDay'
);

export const SET_VISITORS_WEEK_NUM: string = typeCreatorV2(
  'VisitorsByDayReducer',
  'SetVisitorsWeekNum'
);
