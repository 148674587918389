import React from 'react';
import clsx from 'clsx';
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import useStyles from '../styles';
import { useDispatch } from 'react-redux';
import NavigationActions from '../../../../redux/reducers/gl_navigation_reducer/actions';
import LanguageMenu from '../../../../components/app_common/language_selector';
import { AccountCircle, ArrowBack } from '@material-ui/icons';
import LanguageIcon from '@material-ui/icons/Language';
import {
  Badge,
  InputBase,
  ListItemIcon,
  Menu,
  MenuItem,
  Button,
  Box,
  MenuProps,
  Hidden,
  Tooltip,
} from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { ProfileConfiguration } from '../configuration';
import { useHistory, useLocation } from 'react-router';
import MoreIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import { Environment } from '../../../../config';
import SelectButton from 'components/molecules/select_button';
import * as AuthSelectors from 'redux/reducers/gl_auth_reducer/selectors';
import * as NavigationSelectors from 'redux/reducers/gl_navigation_reducer/selectors';
import { getNavbarConfig } from 'navigation/config/Routes';
import * as Paths from 'utils/path';
import * as AppColors from 'styles/colors';
import Configuration from '../configuration';
import FormattedMenu from 'components/atoms/formatted_menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const menuLanId = 'primary-lan-account-menu';
const menuId = 'primary-search-account-menu';
const mobileMenuId = 'primary-search-account-menu-mobile';

const AppbarMenu = (props: any) => {
  console.log('Render AppbarMenu');

  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [lanAnchorEl, setLanAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isLanMenuOpen = Boolean(lanAnchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setLanAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleLanMenuClose = () => {
    setLanAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  // const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
  //   setMobileMoreAnchorEl(event.currentTarget);
  // };

  const renderMobileMenu = (
    <FormattedMenu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      // elevation={0}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleLanguageMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <LanguageIcon />
        </IconButton>
        <p>Language</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </FormattedMenu>
  );

  const renderLanguageMenu = (
    <LanguageMenu
      handleLanMenuClose={handleLanMenuClose}
      isLanMenuOpen={isLanMenuOpen}
      menuLanId={menuLanId}
      lanAnchorEl={lanAnchorEl}
    />
  );

  const renderProfileMenu = (
    <FormattedMenu
      anchorEl={anchorEl}
      id={menuId}
      keepMounted
      elevation={1}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {ProfileConfiguration.map((props, index: number) => (
        <MenuItem
          style={{ marginLeft: props.submenu ? 5 : 0 }}
          key={index}
          onClick={() => {
            props.action(dispatch, history);
            handleMenuClose();
          }}
        >
          {props.icon && <ListItemIcon>{props.icon}</ListItemIcon>}
          <Typography variant="inherit">{props.label}</Typography>
        </MenuItem>
      ))}
    </FormattedMenu>
  );

  return (
    <AppBar position="fixed" color="primary" className={clsx(classes.appBar)}>
      <Toolbar>
        <OpenSideBarIconButton />
        <AppLogo />

        {Configuration.search.enable && (
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
        )}
        <div className={classes.grow}>
          {!Configuration.sidebar.enable && <ButtonsGroup />}
        </div>
        <div>
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            style={{ marginLeft: 10 }}
            onClick={handleProfileMenuOpen}
            color="inherit"
          >
            <AccountCircle />
            <ExpandMoreIcon />
          </IconButton>
        </div>
      </Toolbar>
      {renderMobileMenu}
      {renderLanguageMenu}
      {renderProfileMenu}
    </AppBar>
  );
};

/**
 * Mobile Devices: Visible always
 * Desktop: Visible only when sidebar is enable
 *
 */
const OpenSideBarIconButton = React.memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const ButtonIcon = React.useMemo(
    () => (
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={() =>
          dispatch(
            NavigationActions.setSideMenuVisiblityAction(
              !NavigationSelectors.sideBarIsVisible()
            )
          )
        }
        edge="start"
        className={clsx(classes.menuButton)}
      >
        <MenuIcon />
      </IconButton>
    ),
    []
  );

  return (
    <React.Fragment>
      {/* visible in desktop */}
      {Configuration.sidebar.enable && <Hidden smDown>{ButtonIcon}</Hidden>}
      {/* visible in mobile device */}
      <Hidden mdUp>{ButtonIcon}</Hidden>
    </React.Fragment>
  );
});

const ButtonsGroup = React.memo(() => {
  const role = AuthSelectors.useSelectUserRole();
  const metricTypes = AuthSelectors.useGetMetricTypes();

  const config = getNavbarConfig(role, metricTypes || []);
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box className={classes.sectionDesktop}>
      {config.map(({ title, subItems, Icon, path, id }) => (
        <SelectButton
          key={id}
          buttonProps={{
            color: 'inherit',
            style: {
              marginRight: 15,
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontFamily: 'Roboto',
              lineHeight: '140.2%',
              textTransform: 'capitalize',
            },
            variant: 'text',
          }}
          action={() => path && history.push(path)}
          children={
            <TextWrapper
              enableHidden
              Iconstart={Icon}
              text={title}
              path={path}
            />
          }
          items={(subItems || []).map(({ title, path }, index) => ({
            id: index.toString(),
            action: () => history.push(path),
            children: <TextWrapper key={index} text={title} path={path} />,
          }))}
        />
      ))}
    </Box>
  );
});

const AppLogo = () => {
  const classes = useStyles();
  const groupData = AuthSelectors.useGroup();

  return (
    <div className={classes.titleContainer}>
      <img
        style={{ maxHeight: 30 }}
        src={
          groupData?.icon
            ? `${Environment.STATIC_URL}/${groupData.icon}`
            : Environment.APP_LOGO
        }
      />
    </div>
  );
};

interface ITextWrapperProps {
  text: any;
  Iconstart?: React.FC | undefined;
  IconEnd?: React.FC | undefined;
  path: string | null | undefined;
  enableHidden?: boolean;
}

const TextWrapper = React.memo((props: ITextWrapperProps) => {
  const classes = useStyles({ enableHidden: props.enableHidden });
  const location = useLocation();
  const currentPath = location.pathname;
  const isSelected = props.path
    ? Paths.pathsIsTheSame(currentPath, props.path)
    : false;

  return (
    <Tooltip title={props.text}>
      <Box
        style={{
          color: isSelected ? AppColors.APP_COLOR_HIGHLIGHT() : undefined,
          fontWeight: isSelected ? 'bold' : undefined,
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {props.Iconstart && <props.Iconstart />}
        <span className={classes.buttonText}>{props.text}</span>
        {props.IconEnd && <props.IconEnd />}
      </Box>
    </Tooltip>
  );
});

export default AppbarMenu;
