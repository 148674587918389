import { IAction, IActionMethods, StateStatus } from '../../utils/common';
import * as Types from './types';
import * as UserService from 'services/api/user';
import * as AnalyticsService from 'services/api/analytics';
import { Dispatch } from 'redux';
import reactLogger from 'utils/logger';
import { DateRangeType, TimeType, Metric } from 'config/visitorsDetails';

/** SetTimeType  */
class SetTimeType implements IActionMethods {
  onPending(): IAction {
    throw new Error('Method not implemented');
  }
  onSuccess(data: TimeType): IAction {
    return {
      type: Types.SET_TIME_TYPE,
      status: StateStatus.Success,
      data,
    };
  }

  onFailed(): IAction {
    throw new Error('Method not implemented');
  }

  action(data: TimeType): IAction {
    return this.onSuccess(data);
  }
}

/** Set Metric  */
class SetMetric implements IActionMethods {
  onPending(): IAction {
    throw new Error('Method not implemented');
  }
  onSuccess(data: Metric): IAction {
    return {
      type: Types.SET_METRIC,
      status: StateStatus.Success,
      data,
    };
  }

  onFailed(): IAction {
    throw new Error('Method not implemented');
  }

  action(data: Metric): IAction {
    return this.onSuccess(data);
  }
}

/** Set DateRangeType  */
class SetDateRangeType implements IActionMethods {
  onPending(): IAction {
    throw new Error('Method not implemented');
  }
  onSuccess(data: DateRangeType): IAction {
    return {
      type: Types.SET_DATE_RANGE_TYPE,
      status: StateStatus.Success,
      data,
    };
  }

  onFailed(): IAction {
    throw new Error('Method not implemented');
  }

  action(data: DateRangeType): IAction {
    return this.onSuccess(data);
  }
}

/** Set Camera  */
class SetCamera implements IActionMethods {
  onPending(): IAction {
    throw new Error('Method not implemented');
  }
  onSuccess(data: string): IAction {
    return {
      type: Types.SET_CAMERA,
      status: StateStatus.Success,
      data,
    };
  }

  onFailed(): IAction {
    throw new Error('Method not implemented');
  }

  action(data: string): IAction {
    return this.onSuccess(data);
  }
}

export default {
  setCameraAction: (input: string) => new SetCamera().action(input),
  setTimeTypeAction: (input: TimeType) => new SetTimeType().action(input),
  setMetricAction: (input: Metric) => new SetMetric().action(input),
  setDateRangeTypeAction: (input: DateRangeType) =>
    new SetDateRangeType().action(input),
};
