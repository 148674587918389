// {{BASE_URL}}/api/schemeType
import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';

/** upload file  */
interface IUploadSingleFileOutput extends Types.ResponseOutput {
  data: {
    fieldname: string;
    originalname: string;
    mimetype: string;
    id: string;
  };
}

interface IUploadSingleFileInput {
  file: string;
}

export const uploadFile = async (
  payload: IUploadSingleFileInput
): Promise<IUploadSingleFileOutput> => {
  const formData = new FormData();
  formData.append('myFile', payload.file);

  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/file/upload-single`,
    { body: formData }
  );
  return data;
};
