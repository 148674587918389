import { Theme } from '@material-ui/core/styles';

export const PRIMARY = '#1779ba';
export const SECONDARY = '#767676';
export const WHITE = '#FFFFFF';
export const BLACK = '#000000';

// GRAYSCALE
export const GRAY_LIGHT = '#e6e6e6';
export const GRAY_MEDIUM = '#cacaca';
export const GRAY_DARK = '#8a8a8a';

export const APP_COLOR_SECONDARY = (transparency = 1) =>
  `rgba(15, 46, 105, ${transparency})`;
export const APP_COLOR_PRIMARY = (transparency = 1) =>
  `rgba(15, 46, 105, ${transparency})`;
export const APP_COLOR_HIGHLIGHT = (transparency = 1) =>
  `rgba(3, 155, 229, ${transparency})`;
export const APP_COLOR_DARK = (transparency = 1) =>
  `rgba(45, 50, 62, ${transparency})`;
export const ERROR_COLOR = 'red';
export const TEXT_COLOR_PRIMARY = (transparency = 1) =>
  `rgba(224, 222, 222, ${transparency})`;
export const TEXT_COLOR_HIGHLIGHT = (transparency = 1) =>
  `rgba(0, 0, 0, ${transparency})`;
export const TEXT_COLOR_DARK = (transparency = 1) =>
  `rgba(255, 255, 255, ${transparency})`;

export const CHART_BACKGROUND_COLOR = (theme: Theme) =>
  theme.palette.type === 'dark' ? '#1E1E1E' : '#FFFFFF';
