import { AuthRoles } from '../../../config';
import { IAction, StateStatus } from '../../utils/common';
import * as Types from './types';
import { MetricTypes } from 'config/metricTypes';

interface IUserData {
  username: string | null;
  email: string | null;
  id: string | null;
}

interface PermissionsData {
  camera: MetricTypes[];
}

interface IGroupData {
  _id: string;
  name: string;
  icon: string;
}

export interface IState {
  login: boolean;
  status: string | null | undefined;
  role: number | string | null;
  token: string | null;
  refreshToken: string | null;
  stayLogin: boolean;
  data: IUserData;
  permissions: PermissionsData | null;
  group: IGroupData | null;
}

const initialState: IState = {
  login: false,
  status: null,
  role: AuthRoles.Guest,
  token: null,
  refreshToken: null,
  stayLogin: false,
  data: {
    username: null,
    email: null,
    id: null,
  },
  permissions: null,
  group: null,
};

const AuthReducer = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    // AUTHENTICATE
    case Types.LOGIN_TYPE:
      return {
        ...state,
        login: action.status === StateStatus.Success,
        status: action.status,
        ...action.data,
      };
    case Types.LOGOUT_TYPE:
      return {
        ...state,
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default AuthReducer;
