import React from 'react';
import { useHistory, withRouter } from 'react-router'; // import the react-router-dom components
import PageLayout from 'components/app_common/page_layout';
import DataList from 'components/molecules/data_list';
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Paper,
  useTheme,
} from '@material-ui/core';
import TranslatedText from 'components/atoms/translated_text';
import { withRoles } from 'navigation/middlewares';
import { Roles } from 'config/authRoles';
import NotificationDatatable from './components/notification_datatable';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import { useDispatch } from 'react-redux';
import NotificationActions from 'redux/reducers/notification_reducer/actions';
import _ from 'lodash';
import * as Configuration from './configuration';
import ModalAddNotification from './components/modal_add_notification';
import ModalViewNotification from './components/modal_view_notification';
import useQuery from 'custom_hooks/useQuery';
import { NotificationTypes } from 'config/notification';

const { NotificationListDefaultFiltering } = Configuration;

const NotificationPage = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const email = query.get('email');
  const type = query.get('type');
  const dateStart = query.get('dateStart');
  const dateEnd = query.get('dateEnd');

  React.useEffect(() => {
    dispatch(
      NotificationActions.fetchNotificationsAction({
        page: NotificationListDefaultFiltering.DefaultPage,
        pageSize: NotificationListDefaultFiltering.DefaultPageSize,
        sortField: NotificationListDefaultFiltering.DefaultSortField,
        order: NotificationListDefaultFiltering.DefaultOrder,
        userEmail: email as string,
        notificationType: type as NotificationTypes,
        dateEnd: dateEnd as string,
        dateStart: dateStart as string,
      })
    );
    dispatch(NotificationActions.fetchUsersAction());
  }, [dispatch, email, type, dateStart, dateEnd]);

  return (
    <PageLayout>
      <ModalAddNotification />
      <ModalViewNotification />
      <NotificationDatatable />
    </PageLayout>
  );
};

export default withRouter(NotificationPage);
