import { Roles } from 'config/authRoles';
import * as ArrayUtils from 'utils/arrays';
import { MetricTypes } from 'config/metricTypes';

export const hasAccess = (
  requiredRoles: Array<string | number> | null,
  requiredMetricTypes: MetricTypes[] | null,
  cameraTypes: MetricTypes[] | null,
  role: Roles
) => {
  if (role === Roles.Admin) {
    return true;
  }

  let accessWithRole = false,
    accessWithMetricTypes = false;

  // console.log('Access with role1::', accessWithRole, requiredRoles, role);

  // access with roles
  if (Array.isArray(requiredRoles) && requiredRoles.includes(role)) {
    // return true;
    accessWithRole = true;
  }

  // access with metric types and roles
  if (!Array.isArray(requiredMetricTypes)) {
    accessWithMetricTypes = true;
  }

  if (
    ArrayUtils.arraysHasCommonElements(
      requiredMetricTypes || [],
      cameraTypes || []
    )
  ) {
    accessWithMetricTypes = true;
  }

  console.log('Access with role::', accessWithRole, requiredRoles, role);

  return accessWithRole && accessWithMetricTypes;
};
