import _ from 'lodash';

export const arraysHasCommonElements = (
  arr1: Array<string | number>,
  arr2: Array<string | number>
): boolean => {
  return arr1.some((item) => arr2.includes(item));
};

/**
 *
 * @param arr
 * @param key
 * @returns
 *
 *  convert:
 *  [
 *     { key : '11', value : '1100', $$hashKey : '00X' },
 *     { key : '22', value : '2200', $$hashKey : '018' }
 *  ];
 *
 *  to
 *
 *  {
 *    "11": "1100",
 *    "22": "2200"
 *  }
 *
 */
export const arrayObjectsToObject = (arr: Array<any>, key: string) => {
  return arr.reduce(
    (obj, item) => Object.assign(obj, { [_.get(item, key)]: item }),
    {}
  );
};
