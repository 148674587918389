import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from '../../utils/common';
import * as Types from './types';
import * as UserService from 'services/api/user';
import * as AnalyticsService from 'services/api/analytics';
import { Dispatch } from 'redux';
import reactLogger from 'utils/logger';
import { DaysRangeEnum } from 'config';
import { AnyRecordWithTtl } from 'dns';

// /** Fetch Stores  */
// class FetchStores implements IActionMethods {
//   onPending(): IAction {
//     return {
//       type: Types.SET_STORES,
//       status: StateStatus.Pending,
//       data: {},
//     };
//   }
//   onSuccess(data: any): IAction {
//     return {
//       type: Types.SET_STORES,
//       status: StateStatus.Success,
//       data: data,
//     };
//   }

//   onFailed(): IAction {
//     return {
//       type: Types.SET_STORES,
//       status: StateStatus.Failed,
//       data: {},
//     };
//   }

//   action(): any {
//     return async (dispatch: Dispatch<any>) => {
//       try {
//         dispatch(this.onPending());
//         let response = await UserService.getStores();
//         dispatch(this.onSuccess(response.data));
//       } catch (error) {
//         reactLogger.error('FetchStores Error:', error.message);
//         // console.log('FetchStores Error:', error.message); // '<ClassName> Error: <error>'
//         dispatch(this.onFailed());
//       }
//     };
//   }
// }

/** Set Date Range Type */
class SetDateRangeType implements IActionMethods {
  onPending(): IAction {
    throw new Error('Method not implemented');
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_DATE_RANGE_TYPE,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_DATE_RANGE_TYPE,
      data: null,
    };
  }

  action(dateRangeType: DaysRangeEnum): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onSuccess(dateRangeType));
      } catch (error) {
        reactLogger.error('SetDateRangeType Error:', error.message);
        dispatch(this.onFailed());
      }
    };
  }
}

/** Fetch Visitor Data Last Days  */
interface IVisitorsDataLastDays {
  selectedStore: string;
}

class FetchVisitorsDataLastDays implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_VISITORS_LAST_DAYS,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_VISITORS_LAST_DAYS,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_VISITORS_LAST_DAYS,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(input: IVisitorsDataLastDays): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AnalyticsService.getTotalVisitorsData({
          type: 'daysBefore',
          value: 30,
          storeid: input.selectedStore,
        });
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        reactLogger.error('FetchVisitorsDataLastDays Error:', error.message);
        dispatch(this.onFailed());
      }
    };
  }
}

/** Fetch Visitor List By Date  */
interface IFetchVisitorListInput {
  storeid: string;
}

class FetchVisitorListByDate implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_VISITORS_LAST_DAYS_LIST,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_VISITORS_LAST_DAYS_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_VISITORS_LAST_DAYS_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(input: IFetchVisitorListInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AnalyticsService.getVisitorsListGroupByDate({
          type: 'daysBefore',
          value: 30,
          storeid: input.storeid,
        });
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        reactLogger.error('FetchVisitorListByDate Error:', error.message);
        dispatch(this.onFailed());
      }
    };
  }
}

/** Fetch Visitor List Per Hour  */
interface IVisitorListPerHourInput {
  dateStart: string;
  dateEnd: string;
}

class FetchVisitorListPerHour implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_VISITORS_PER_HOUR_LIST,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_VISITORS_PER_HOUR_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_VISITORS_PER_HOUR_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(input: IVisitorListPerHourInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AnalyticsService.getVisitorsPerHour(input);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        reactLogger.error('FetchVisitorListByDate Error:', error.message);
        dispatch(this.onFailed());
      }
    };
  }
}

/** Fetch Visitor List Per Month  */
interface IVisitorsPerMonthInput {
  storeid: string;
}

class FetchVisitorsPerMonth implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_VISITORS_BY_MONTH,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_VISITORS_BY_MONTH,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_VISITORS_BY_MONTH,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(input: IVisitorsPerMonthInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AnalyticsService.getVisitorsByMonth(input);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        reactLogger.error('FetchVisitorsPerMonth Error:', error.message);
        dispatch(this.onFailed());
      }
    };
  }
}

/** Fetch Visitor List Per Month  */
interface IAverageShoppingTimeInLastDaysInput {
  storeid: string;
}

class FetchAverageShoppingTimeInLastDays implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_AVERAGE_SHOPPING_TIME_IN_LAST_DAYS,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_AVERAGE_SHOPPING_TIME_IN_LAST_DAYS,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_AVERAGE_SHOPPING_TIME_IN_LAST_DAYS,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(input: IAverageShoppingTimeInLastDaysInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AnalyticsService.getAverageShoppingTimeLastDaysDay(
          {
            storeid: input.storeid,
          }
        );
        console.log('DDDDDDATA:', response.data);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        reactLogger.error(
          'FetchAverageShoppingTimeInLastDays Error:',
          error.message
        );
        dispatch(this.onFailed());
      }
    };
  }
}

/** Fetch Average Hourly Visitors Per Day  */
interface IHourlyVisitorsPerDayInput {
  storeid: string;
}

class FetchHourlyVisitorsPerDay implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_AVERAGE_HOURLY_VISITORS_PER_DAY,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_AVERAGE_HOURLY_VISITORS_PER_DAY,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_AVERAGE_HOURLY_VISITORS_PER_DAY,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(input: IHourlyVisitorsPerDayInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AnalyticsService.getAverageHourlyVisitorsPerDay({
          storeid: input.storeid,
        });
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        reactLogger.error('FetchHourlyVisitorsPerDay Error:', error.message);
        dispatch(this.onFailed());
      }
    };
  }
}

/** Fetch Visitor Per store list  */
interface IVisitorPerStoreInput {
  type: 'monthly' | 'daily';
}

class FetchVisitorsPerStore implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_VISITORS_PER_STORE_LIST,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: AnalyticsService.TVisitorsPerStoreListData): IAction {
    return {
      type: Types.SET_VISITORS_PER_STORE_LIST,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_VISITORS_PER_STORE_LIST,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(input: IVisitorPerStoreInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AnalyticsService.getVisitorsPerStoreListData(
          input.type
        );
        dispatch(
          this.onSuccess({
            type: response.data.type,
            tableArr: response.data.tableArr,
            columnsOrder: response.data.columnsOrder,
          })
        );
      } catch (error) {
        reactLogger.error('FetchVisitorsPerStore Error:', error.message);
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  // fetchStoresAction: () => new FetchStores().action(),
  setDateRangeTypeAction: (data: DaysRangeEnum) =>
    new SetDateRangeType().action(data),
  fetchVisitorsDataLastDaysAction: (payload: IVisitorsDataLastDays) =>
    new FetchVisitorsDataLastDays().action(payload),
  fetchVisitorListByDateAction: (payload: IFetchVisitorListInput) =>
    new FetchVisitorListByDate().action(payload),
  fetchVisitorListPerHourAction: (data: IVisitorListPerHourInput) =>
    new FetchVisitorListPerHour().action(data),
  fetchVisitorsPerMonthAction: (data: IVisitorsPerMonthInput) =>
    new FetchVisitorsPerMonth().action(data),
  fetchAverageShoppingTimeInLastDaysAction: (
    data: IAverageShoppingTimeInLastDaysInput
  ) => new FetchAverageShoppingTimeInLastDays().action(data),
  fetchHourlyVisitorsPerDayAction: (data: IHourlyVisitorsPerDayInput) =>
    new FetchHourlyVisitorsPerDay().action(data),
  fetchVisitorsPerStoreAction: (payload: IVisitorPerStoreInput) =>
    new FetchVisitorsPerStore().action(payload),
};
