//  [REDUCER][TYPE]
import { IReduxType, typeCreatorV2 } from '../../utils/common';
export const SET_STORES: string = typeCreatorV2(
  'AppCommonReducer',
  'SetStores'
);
export const SET_SELECTED_STORE: string = typeCreatorV2(
  'AppCommonReducer',
  'SetSelectedStore'
);
export const SET_CAMERAS: string = typeCreatorV2(
  'AppCommonReducer',
  'SetCameras'
);
