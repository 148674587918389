import React from 'react';
import BaseModal from 'components/molecules/base_modal';
import TranslatedText from 'components/atoms/translated_text';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  AppTextField,
  AppUploadPicker,
  AppMultipleAutoCompleteSelect,
  AppSelect,
} from 'components/molecules/form_controls';
import { Button } from '@material-ui/core';
import StoreActions from 'redux/reducers/stores_reducer/actions';
import * as StoreSelectors from 'redux/reducers/stores_reducer/selectors';
import { StateStatus } from 'redux/utils/common';
import Environment from 'config/environment';
import NavigationConfig from 'navigation/config';
import { TabType, GroupListDefaultFiltering } from '../../configuration';
import { useHistory } from 'react-router';
import Timezones from 'config/timezones';

export interface IModalParams {
  mode: 'edit' | 'add';
  defaultValues?: IFormData;
  groupid?: string;
}

const ModalAddEditGroup = () => {
  const dispatch = useDispatch();

  const {
    setValue,
    reset,
    register,
    control,
    watch,
    errors,
    handleSubmit,
    getValues,
    formState,
  } = useForm<IFormData>({
    defaultValues,
  });
  const history = useHistory();

  const onSubmit = (groupid?: string) =>
    handleSubmit((data: IFormData) => {
      if (groupid) {
        dispatch(
          StoreActions.editGroupAction({
            name: data.name,
            icon: data.icon[0],
            email: data.email,
            groupid: groupid,
            onFinish: () => {
              dispatch(
                ModalActions.closeModalAction({
                  modalID: ModalIDs.MODAL_ADD_EDIT_GROUP,
                })
              );
              dispatch(
                StoreActions.fetchGroupsAction({
                  page: GroupListDefaultFiltering.DefaultPage,
                  pageSize: GroupListDefaultFiltering.DefaultPageSize,
                  sortField: GroupListDefaultFiltering.DefaultSortField,
                  order: GroupListDefaultFiltering.DefaultOrder,
                })
              );
            },
          })
        );
      } else {
        dispatch(
          StoreActions.addGroupAction({
            name: data.name,
            icon: data.icon[0],
            email: data.email,
            onFinish: () => {
              dispatch(
                ModalActions.closeModalAction({
                  modalID: ModalIDs.MODAL_ADD_EDIT_GROUP,
                })
              );
              history.push(
                NavigationConfig.storePage({
                  type: TabType.Group,
                }).path
              );
              dispatch(
                StoreActions.fetchGroupsAction({
                  page: GroupListDefaultFiltering.DefaultPage,
                  pageSize: GroupListDefaultFiltering.DefaultPageSize,
                  sortField: GroupListDefaultFiltering.DefaultSortField,
                  order: GroupListDefaultFiltering.DefaultOrder,
                })
              );
            },
          })
        );
      }
    });

  return (
    <form>
      <BaseModal
        modalID={ModalIDs.MODAL_ADD_EDIT_GROUP}
        renderContent={(props: IModalParams) => (
          <ModalForm
            params={props}
            reset={reset}
            register={register}
            setValue={setValue}
            control={control}
            errors={errors}
          />
        )}
        modalProps={{ fullWidth: true }}
        renderHeader={(props: IModalParams) => (
          <TranslatedText
            defaultText={props.mode === 'add' ? 'Add Group' : 'Edit Group'}
          />
        )}
        renderFooter={(props: IModalParams) => (
          <React.Fragment>
            <Button
              variant="outlined"
              onClick={() =>
                dispatch(
                  ModalActions.closeModalAction({
                    modalID: ModalIDs.MODAL_ADD_EDIT_GROUP,
                  })
                )
              }
            >
              <TranslatedText defaultText={`Cancel`} />
            </Button>
            <SubmitWrapper
              mode={props.mode}
              onSubmit={onSubmit(props.groupid)}
            />
          </React.Fragment>
        )}
      />
    </form>
  );
};

interface ISubmitWrapper {
  onSubmit: () => void;
  mode: 'edit' | 'add';
}

const SubmitWrapper = ({ onSubmit, mode }: ISubmitWrapper) => {
  const status = StoreSelectors.useGetAddStoreStatus();
  return (
    <Button
      variant="outlined"
      color="primary"
      disabled={status === StateStatus.Pending}
      onClick={() => {
        onSubmit();
      }}
    >
      <TranslatedText defaultText={`Submit`} />
    </Button>
  );
};

interface IFormData {
  name: string;
  icon: string;
  email: string;
}

const defaultValues: IFormData = {
  name: '',
  icon: '',
  email: '',
};

interface IModalFormProps {
  control: any;
  errors: ReturnType<typeof useForm>['errors'];
  register: ReturnType<typeof useForm>['register'];
  reset: ReturnType<typeof useForm>['reset'];
  params: IModalParams;
  setValue: ReturnType<typeof useForm>['setValue'];
}

const ModalForm = ({
  control,
  errors,
  register,
  params,
  reset,
  setValue,
}: IModalFormProps) => {
  const timezones = React.useMemo(
    () => Timezones.map((zone) => ({ id: zone, label: zone })),
    []
  );

  React.useEffect(() => {
    if (params.mode === 'edit' && params.defaultValues) {
      // console.log('DDATTA:', params.defaultValues.storeUsers);
      reset({
        ...defaultValues,
        name: params.defaultValues.name,
        email: params.defaultValues.email,
        // storeid: params.defaultValues.storeid,
        // initialin: params.defaultValues.initialin,
        // cappacity: params.defaultValues.cappacity,
        // boxesNumber: params.defaultValues.boxesNumber,
        // timezone: params.defaultValues.timezone,
      });
    } else {
      reset(defaultValues);
    }
  }, [params, reset]);

  return (
    <React.Fragment>
      <AppTextField
        variant="outlined"
        margin="normal"
        error={'name' in errors}
        helperText={errors.name && errors.name.message}
        rules={{ required: <RequiredField /> }}
        fullWidth
        id="name"
        InputLabelProps={{
          shrink: true,
        }}
        placeholder="Enter name"
        label={<TranslatedText defaultText={'Name'} />}
        control={control}
        autoComplete="name"
        name="name"
      />
      <AppUploadPicker
        control={control}
        register={register}
        // fileInitialValue={  params.defaultValues?.uploadedFile}
        fileInitialValue={
          params.defaultValues?.icon
            ? Environment.STATIC_URL + `/${params.defaultValues?.icon}`
            : ''
        }
        helperText={errors.name && errors.name.message}
        size="medium"
        error={'icon' in errors}
        rules={{ required: <RequiredField /> }}
        name={'icon'}
        label={<TranslatedText defaultText={'Upload Image'} />}
      />
      <AppTextField
        variant="outlined"
        margin="normal"
        error={'email' in errors}
        helperText={errors.name && errors.name.message}
        rules={{ required: <RequiredField /> }}
        fullWidth
        id="email"
        InputLabelProps={{
          shrink: true,
        }}
        type="email"
        placeholder="Enter email"
        label={<TranslatedText defaultText={'Email'} />}
        control={control}
        autoComplete="email"
        name="email"
      />
    </React.Fragment>
  );
};

const RequiredField = React.memo(() => {
  return <TranslatedText defaultText={'Required Value'} />;
});

export default React.memo(ModalAddEditGroup);
