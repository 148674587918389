import React from 'react';
import BaseModal from 'components/molecules/base_modal';
import TranslatedText from 'components/atoms/translated_text';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  AppTextField,
  AppAutoCompleteSelect,
  AppMultipleSelect,
} from 'components/molecules/form_controls';
import { Button } from '@material-ui/core';
import StoreActions from 'redux/reducers/stores_reducer/actions';
import * as StoreSelectors from 'redux/reducers/stores_reducer/selectors';
import { StateStatus } from 'redux/utils/common';
import Translations from '../../translations';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import * as Configuration from '../../configuration';
import NavigationConfig from 'navigation/config';
import { TabType } from 'scenes/stores_page/configuration';
import { useHistory } from 'react-router';

export interface IModalParams {
  mode: 'add' | 'edit';
  userid?: string;
  defaultValues?: IFormData & { group: string };
}

const ModalAddUser = () => {
  const dispatch = useDispatch();
  const {
    setValue,
    reset,
    register,
    control,
    watch,
    errors,
    handleSubmit,
    getValues,
    formState,
  } = useForm<IFormData>({
    defaultValues,
  });
  const history = useHistory();

  const onSubmit = (userid?: string) =>
    handleSubmit((data: IFormData) => {
      if (userid) {
        dispatch(
          StoreActions.editUserAction({
            data: {
              userid,
              ...data,
              group: data.group?._id as string,
            },
            onFinish: () => {
              dispatch(
                ModalActions.closeModalAction({
                  modalID: ModalIDs.MODAL_ADD_USER,
                })
              );
              dispatch(
                StoreActions.fetchUsersWithDetailsAction({
                  page: Configuration.DefaultPage,
                  pageSize: Configuration.DefaultPageSize,
                  sortField: Configuration.DefaultSortField,
                  order: Configuration.DefaultOrder,
                })
              );
            },
          })
        );
      } else {
        const { confirmPassword, ...body } = data;
        dispatch(
          StoreActions.registerUserAction({
            data: {
              ...body,
              group: data.group?._id as string,
            },
            onFinish: () => {
              dispatch(
                ModalActions.closeModalAction({
                  modalID: ModalIDs.MODAL_ADD_USER,
                })
              );
              history.push(
                NavigationConfig.storePage({
                  type: TabType.Users,
                }).path
              );
              dispatch(
                StoreActions.fetchUsersWithDetailsAction({
                  page: Configuration.DefaultPage,
                  pageSize: Configuration.DefaultPageSize,
                  sortField: Configuration.DefaultSortField,
                  order: Configuration.DefaultOrder,
                })
              );
            },
          })
        );
      }
    });

  return (
    <form>
      <BaseModal
        modalID={ModalIDs.MODAL_ADD_USER}
        renderContent={(props: IModalParams) => (
          <ModalForm
            params={props}
            reset={reset}
            register={register}
            setValue={setValue}
            control={control}
            errors={errors}
            getValues={getValues}
          />
        )}
        modalProps={{ fullWidth: true }}
        renderHeader={(props: IModalParams) => (
          <TranslatedText defaultText={'Add User'} />
        )}
        renderFooter={(props: IModalParams) => (
          <React.Fragment>
            <Button
              variant="outlined"
              onClick={() =>
                dispatch(
                  ModalActions.closeModalAction({
                    modalID: ModalIDs.MODAL_ADD_USER,
                  })
                )
              }
            >
              <TranslatedText defaultText={`Cancel`} />
            </Button>
            <SubmitWrapper
              mode={props.mode}
              onSubmit={onSubmit(props.userid)}
            />
          </React.Fragment>
        )}
      />
    </form>
  );
};

interface ISubmitWrapper {
  onSubmit: () => void;
  mode: 'edit' | 'add';
}

const SubmitWrapper = ({ onSubmit, mode }: ISubmitWrapper) => {
  const addStatus = StoreSelectors.useGetRegisterUserStatus();
  const editStatus = StoreSelectors.useGetEditUserStatus();

  let status = mode === 'add' ? addStatus : editStatus;

  return (
    <Button
      variant="outlined"
      color="primary"
      disabled={status === StateStatus.Pending}
      onClick={() => {
        onSubmit();
      }}
    >
      <TranslatedText defaultText={`Submit`} />
    </Button>
  );
};

interface IFormData {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  surname: string;
  group: { name: string; _id: string } | null;
}

const defaultValues: IFormData = {
  email: '',
  password: '',
  confirmPassword: '',
  firstName: '',
  surname: '',
  group: null,
};

interface IModalFormProps {
  control: ReturnType<typeof useForm>['control'];
  errors: ReturnType<typeof useForm>['errors'];
  register: ReturnType<typeof useForm>['register'];
  reset: ReturnType<typeof useForm>['reset'];
  setValue: ReturnType<typeof useForm>['setValue'];
  params: IModalParams;
  getValues: ReturnType<typeof useForm>['getValues'];
}

const ModalForm = ({
  control,
  errors,
  getValues,
  setValue,
  params,
  reset,
}: IModalFormProps) => {
  React.useEffect(() => {
    if (params.mode === 'edit') {
      reset({
        ...params.defaultValues,
      });
    }

    return () => {
      reset({ ...defaultValues });
    };
  }, [params, reset]);

  return (
    <React.Fragment>
      <AppTextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label={
          <TranslatedText
            defaultText={'Email Address'}
            textMap={Translations.form_email}
          />
        }
        name="email"
        autoComplete="email"
        control={control}
        error={'email' in errors}
        helperText={errors.email && errors.email.message}
        rules={{
          required: <RequiredField />,
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            message: 'Enter a valid e-mail address',
          },
        }}
        icon={{
          position: 'right',
          Component: <EmailOutlinedIcon />,
        }}
      />
      {params.mode === 'add' && (
        <AppTextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          control={control}
          error={'password' in errors}
          rules={{
            required: <RequiredField />,
            minLength: {
              value: 8,
              message: 'Password must have at least 8 characters',
            },
          }}
          label={
            <TranslatedText
              defaultText={'Password'}
              textMap={Translations.form_field_password}
            />
          }
          helperText={errors.password && errors.password.message}
          name="password"
          type="password"
          id="password"
          icon={{
            position: 'right',
            Component: <LockOutlinedIcon />,
          }}
        />
      )}
      {params.mode === 'add' && (
        <AppTextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="confirmPassword"
          error={'confirmPassword' in errors}
          label={
            <TranslatedText
              defaultText={'Confirm Password'}
              textMap={Translations.form_field_confirm_password}
            />
          }
          helperText={errors.confirmPassword && errors.confirmPassword.message}
          type="password"
          id="confirmPassword"
          control={control}
          rules={{
            required: <RequiredField />,
            validate: () =>
              getValues('password') === getValues('confirmPassword') ||
              'The passwords do not match',
          }}
          icon={{
            position: 'right',
            Component: <LockOutlinedIcon />,
          }}
        />
      )}
      <AppTextField
        variant="outlined"
        margin="normal"
        error={'firstName' in errors}
        helperText={errors.firstName && errors.firstName.message}
        rules={{
          required: <RequiredField />,
        }}
        required
        fullWidth
        id="firstName"
        control={control}
        label={
          <TranslatedText
            defaultText={'Firstname'}
            textMap={Translations.form_field_firstname}
          />
        }
        autoComplete="firstName"
        name="firstName"
      />

      <AppTextField
        variant="outlined"
        margin="normal"
        error={'surname' in errors}
        helperText={errors.surname && errors.surname.message}
        rules={{
          required: <RequiredField />,
        }}
        required
        fullWidth
        id="surname"
        label={
          <TranslatedText
            defaultText={'Surname'}
            textMap={Translations.form_field_surname}
          />
        }
        control={control}
        autoComplete="surname"
        name="surname"
      />
      <GroupsDropdownWrapper
        setValue={setValue}
        errors={errors}
        control={control}
        group={params.defaultValues?.group}
      />
    </React.Fragment>
  );
};

interface IGroupsDropdown {
  control: any;
  errors: any;
  setValue: ReturnType<typeof useForm>['setValue'];
  group?: string;
}

// users dropdown
const GroupsDropdownWrapper = React.memo(
  ({ control, errors, setValue, group }: IGroupsDropdown) => {
    const { data } = StoreSelectors.useGroupBasicInfo();
    const groupsList = data;

    React.useEffect(() => {
      if (group && groupsList) {
        const index = groupsList?.findIndex((element) => element._id === group);
        const timer = setTimeout(() => {
          if (data) {
            setValue('group', groupsList[index]);
          }
        });
        return () => {
          clearTimeout(timer);
        };
      }
    }, [groupsList]);

    return (
      <AppAutoCompleteSelect
        id="group"
        control={control}
        InputLabelProps={{
          shrink: true,
        }}
        margin="normal"
        label={<TranslatedText defaultText={'Group'} />}
        name="group"
        error={'group' in errors}
        options={groupsList || []}
        rules={{}}
        size="medium"
        selectedKey="name"
      />
    );
  }
);

const RequiredField = React.memo(() => {
  return <TranslatedText defaultText={'Required Value'} />;
});

export default React.memo(ModalAddUser);
