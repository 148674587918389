import React from 'react';
import { Redirect, useLocation, withRouter, matchPath } from 'react-router';
import {
  useSelectUserRole,
  useGetMetricTypes,
  useSelectAuthStatus,
} from '../../redux/reducers/gl_auth_reducer/selectors';
import NavigationConfig from '../config';
import { IRequireAuthProps, IWithnavbarProps } from './types';
import * as ThemeSelectors from 'redux/reducers/gl_theme_reducer/selectors';
import * as Permissions from 'utils/permissions';
import { StateStatus } from 'redux/utils/common';

export const RequireAuth = ({
  Component,
  roles,
  metricTypes: requiredMetricTypes,
}: IRequireAuthProps): any => {
  const userRole = useSelectUserRole();
  const metricTypes = useGetMetricTypes();

  console.log('Require auth..');

  return roles.includes(userRole) &&
    Permissions.hasAccess(
      roles,
      requiredMetricTypes ?? null,
      metricTypes ?? null,
      userRole
    ) ? (
    <Component role={userRole} />
  ) : (
    <Redirect to={NavigationConfig.mainPage().path} />
  );
};

export const withRoles = (
  Component: any,
  roles: Array<string | number>
): any => {
  const ComposedComponent = (props: any) => {
    const userRole = useSelectUserRole();
    return roles.includes(userRole) ? <Component {...props} /> : null;
  };

  return ComposedComponent;
};

export const WithNavbar = withRouter((props: IWithnavbarProps): any => {
  const location = useLocation();
  const { Component, select, include, exclude } = props;
  const accessFromMobileDev = ThemeSelectors.useIsMobileAppAccess();

  // const userRole = useSelectUserRole();
  const path = location.pathname;
  let visible: boolean = false;

  // let result = matchPath('/reset-password/teeeeeeeeeeeeqqwqst', {
  //   path: '/reset-password/:token',
  //   exact: true,
  //   strict: false,
  // })?.isExact;

  // console.log("RESSS:", result)

  const arrayMatch = (_paths: string[], _path: string): boolean => {
    // console.log("TEST::", matchPath(_paths[0], {
    //   path: _path,
    //   exact: true,
    //   strict: false
    // })?.isExact);

    for (let ___path of _paths) {
      if (
        matchPath(_path, {
          path: ___path,
          exact: true,
          strict: false,
        })?.isExact
      ) {
        return true;
      }
    }
    return false;
  };

  // if (!roles.includes(userRole)) {
  //   // return null;
  //   // visible =
  // }

  if (select === 'exclude') {
    visible =
      exclude !== 'all' && Array.isArray(exclude) && !arrayMatch(exclude, path);
  } else if (select === 'include') {
    visible =
      include === 'all' ||
      (Array.isArray(include) && arrayMatch(include, path));
  }

  return (
    <React.Fragment>
      <Component visible={accessFromMobileDev ? false : visible} />
    </React.Fragment>
  );
});
