import React, { ReactElement } from 'react';
import {
  Select as MuiSelect,
  MenuItem,
  TextField,
  StandardTextFieldProps,
  InputAdornment,
} from '@material-ui/core';
import { Control, Controller } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash';

type AutocompleteProps = React.ComponentProps<typeof Autocomplete>;

interface Iprops extends StandardTextFieldProps {
  options: Array<any>;
  control: Control;
  rules: any;
  selectedKey: string;
  name: string;
  handleChange?: (data: any) => void;
  controllerExtras?: any;
  style?: React.CSSProperties;
  multiple?: boolean;
}

export default function MultipleAutoCompleteSelect(props: Iprops) {
  const {
    control,
    rules,
    options,
    handleChange,
    selectedKey,
    style,
    id,
    name,
    controllerExtras = {},
    multiple,
    ...other
  } = props;

  return (
    <div>
      <Controller
        render={(props) => (
          <Autocomplete
            {...props}
            autoComplete={false}
            multiple={options && options.length > 1 && multiple}
            options={options}
            id={id}
            // filterOptions={(options, state) => {
            //   console.log('Options...', options, state);
            //   // options.filter((el) => _.get())
            //   return []
            //   return options;
            // }}
            filterSelectedOptions
            disableCloseOnSelect
            disabled={other.disabled}
            // getOptionSelected={(option, value) => {
            //   return option == value;
            // }}
            getOptionLabel={(option) => _.get(option, selectedKey) || ''}
            renderOption={(option, state) => {
              return _.get(option, selectedKey) || '';
            }}
            onChange={(_, result) => {
              handleChange && handleChange(result);
              props.onChange(result);
            }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" {...other} />
            )}
            // defaultValue={}
          />
        )}
        name={name}
        control={control}
        rules={rules}
        {...controllerExtras}
      />
    </div>
  );
}
