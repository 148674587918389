import { IAction, KEEP_THE_SAME, StateStatus } from '../../utils/common';
import * as Types from './types';
import { INotificationData } from 'services/api/notification';
import { IUserData } from 'services/api/user';

export interface IState {
  notificationList: {
    data: INotificationData | null;
    status: StateStatus | null;
  };
  readUnReadNotificationStatus: StateStatus | null;
  addNotificationStatus: StateStatus | null;
  userList: {
    data: IUserData | null;
    status: StateStatus | null;
  };
}

const initialState: IState = {
  notificationList: {
    data: null,
    status: null,
  },
  readUnReadNotificationStatus: null,
  addNotificationStatus: null,
  userList: {
    data: null,
    status: null,
  },
};

const NotificationReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_NOTIFICATION_LIST:
      return {
        ...state,
        notificationList: {
          ...state.notificationList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.notificationList.data
              : action.data,
        },
      };
    case Types.READ_UNREAD_NOTIFICATION:
      return {
        ...state,
        readUnReadNotificationStatus: action.status || null,
      };
    case Types.ADD_NOTIFICATION:
      return {
        ...state,
        addNotificationStatus: action.status || null,
      };
    case Types.SET_USERS_LIST:
      return {
        ...state,
        userList: {
          ...state.userList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.userList.data : action.data,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default NotificationReducer;
