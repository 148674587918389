import React from 'react';
import { Redirect, useHistory } from 'react-router';
import { withRouter } from 'react-router'; // import the react-router-dom components
import { useDispatch } from 'react-redux';
import AuthActions from 'redux/reducers/gl_auth_reducer/actions';
import * as AuthSelectors from 'redux/reducers/gl_auth_reducer/selectors';
import { Roles } from 'config/authRoles';
import TranslationActions from 'redux/reducers/gl_translations_reducer/actions';
import useQuery from 'custom_hooks/useQuery';
import ThemeActions from 'redux/reducers/gl_theme_reducer/actions';
import _ from 'lodash';

const MobileEntrypointPage = () => {
  const dispatch = useDispatch();
  const role = AuthSelectors.useSelectUserRole();
  const isLogin = AuthSelectors.useSelectIsLogin();
  const _token = AuthSelectors.useToken();
  const _refreshToken = AuthSelectors.useRefreshToken();

  const query = useQuery();
  const currentPath = query.get('path') || '';
  const token = query.get('token') || '';
  const refreshToken = query.get('refreshToken') || '';
  // const relogin = (query.get('relogin') || 'false') === 'true';
  const requireLogin =
    role === Roles.Guest || token !== _token || refreshToken !== _refreshToken;

  const _isLogin =
    token === _token && refreshToken === _refreshToken ? isLogin : false;

  React.useEffect(() => {
    // alert(`Token:: ${token}`);
    if (requireLogin) {
      // dispatch(AuthActions.logoutAction());
      // localStorage.clear();
      dispatch(
        AuthActions.authenticateAction({
          token,
          refreshToken,
          stayLogin: true,
        })
      );
    }
  }, [token, refreshToken, requireLogin]);

  React.useEffect(() => {
    window.addEventListener('message', (message) => {
      console.log('Get messages from webview...:', message);
      // alert('Get messages from webview...:');

      try {
        const data = message.data;

        // if ('lan' in data) {
        //   dispatch(TranslationActions.changeLanguageAction(data.lan as any));
        // }

        if (_.get(data, 'logout')) {
          dispatch(AuthActions.logoutAction());
        }

        // ... add more events..
      } catch (ex) {
        alert('Error..' + ex.message);
      }
    });
    // dome is ready
    (window as any)?.ReactNativeWebView &&
      (window as any).ReactNativeWebView.postMessage('READY');
  }, []);

  React.useLayoutEffect(() => {
    dispatch(ThemeActions.setAccessAction(true));
  }, [dispatch]);

  return (
    <React.Fragment>
      {!requireLogin && isLogin === false ? (
        <Redirect to={currentPath || ''} />
      ) : _isLogin === true ? (
        <Redirect to={currentPath || ''} />
      ) : (
        <div>Loading..</div>
      )}
    </React.Fragment>
  );
};

export default withRouter(MobileEntrypointPage);
