import { useSelector } from 'react-redux';
import { ReducerKeys } from '../../config';
import useShallowEqualSelector from 'custom_hooks/useShallowEqualsSelector';
import _ from 'lodash';
import { IStoresData } from 'services/api/stores';
import { StateStatus } from 'redux/utils/common';
import { IState } from './reducer';

/* selector: useGetStores */
export const useGetStores = (): IState['stores'] => {
  const { status, data } = useShallowEqualSelector(
    (state: any) => (state[ReducerKeys.STORES_REDUCER] as IState).stores
  );
  return { status, data };
};

/* selector: useGetAddStoreStatus */
export const useGetAddStoreStatus = (): StateStatus => {
  const status = useSelector(
    (state: any) => state[ReducerKeys.STORES_REDUCER].addStoreStatus
  );
  return status;
};

/* selector: useGetRemoveStoreStatus */
export const useGetRemoveStoreStatus = (): StateStatus => {
  const status = useSelector(
    (state: any) => state[ReducerKeys.STORES_REDUCER].removeStoreStatus
  );
  return status;
};

/* selector: useGetUsersList */
export const useGetUsersList = () => {
  const data = useSelector(
    (state: any) => (state[ReducerKeys.STORES_REDUCER] as IState).userList.data
  );
  return data;
};

/* selector: useGetUpdateCamerasStatus */
export const useGetUpdateCamerasStatus = () => {
  const result = useSelector(
    (state: any) =>
      (state[ReducerKeys.STORES_REDUCER] as IState).updateCamerasStatus
  );
  return result;
};

/* selector: useGetRegisterUserStatus */
export const useGetRegisterUserStatus = () => {
  const result = useSelector(
    (state: any) =>
      (state[ReducerKeys.STORES_REDUCER] as IState).registerUserStatus
  );
  return result;
};

/* selector: useGetUserListWithDetails */
export const useGetUserListWithDetails = (): IState['userListDetails'] => {
  const result = useShallowEqualSelector(
    (state: any) =>
      (state[ReducerKeys.STORES_REDUCER] as IState).userListDetails
  );
  return result;
};

/* selector: useGetEditUserStatus */
export const useGetEditUserStatus = () => {
  const result = useSelector(
    (state: any) => (state[ReducerKeys.STORES_REDUCER] as IState).editUserStatus
  );
  return result;
};

/* selector: useBoxesList */
export const useBoxesList = (): IState['boxesList'] => {
  const result = useShallowEqualSelector(
    (state: any) => (state[ReducerKeys.STORES_REDUCER] as IState).boxesList
  );
  return result;
};

/* selector: useCamerasList */
export const useCamerasList = (): IState['camerasList'] => {
  const result = useShallowEqualSelector(
    (state: any) => (state[ReducerKeys.STORES_REDUCER] as IState).camerasList
  );
  return result;
};

/* selector: useAlertsList */
export const useAlertsList = (): IState['alertsList'] => {
  const result = useShallowEqualSelector(
    (state: any) => (state[ReducerKeys.STORES_REDUCER] as IState).alertsList
  );
  return result;
};

/* selector: useUpdateAlertStatus */
export const useUpdateAlertStatus = () => {
  const result = useSelector(
    (state: any) =>
      (state[ReducerKeys.STORES_REDUCER] as IState).updateAlertStatus
  );
  return result;
};

/* selector: useGroups */
export const useGroups = (): IState['groups'] => {
  const { status, data } = useShallowEqualSelector(
    (state: any) => (state[ReducerKeys.STORES_REDUCER] as IState).groups
  );
  return { status, data };
};

/* selector: useAddGroupStatus */
export const useAddGroupStatus = () => {
  const status = useSelector(
    (state: any) => (state[ReducerKeys.STORES_REDUCER] as IState).addGroupStatus
  );
  return status;
};

/* selector: useRemoveGroupStatus */
export const useRemoveGroupStatus = () => {
  const status = useSelector(
    (state: any) =>
      (state[ReducerKeys.STORES_REDUCER] as IState).removeGroupStatus
  );
  return status;
};

/* selector: useEditGroupStatus */
export const useEditGroupStatus = () => {
  const status = useSelector(
    (state: any) =>
      (state[ReducerKeys.STORES_REDUCER] as IState).updateGroupStatus
  );
  return status;
};

/* selector: useGetStores */
export const useGroupBasicInfo = (): IState['groupsBasicInfo'] => {
  const { status, data } = useShallowEqualSelector(
    (state: any) =>
      (state[ReducerKeys.STORES_REDUCER] as IState).groupsBasicInfo
  );
  return { status, data };
};
