import React from 'react';
import { withRouter } from 'react-router';
import PageLayout from 'components/app_common/page_layout';
import HeatMap from './components/heatmap';
import SelectForm from './components/select_form';
import { useDispatch } from 'react-redux';
import StoresCommonActions from 'redux/reducers/gl_app_common/actions';
import * as StoresCommonSelectors from 'redux/reducers/gl_app_common/selectors';
import { Paper } from '@material-ui/core';

const PlaceTreemap = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(StoresCommonActions.fetchStoresAction());
  }, [dispatch]);

  return (
    <PageLayout>
      <SelectForm />
      <Paper variant="outlined">
        <div style={{ margin: 'auto', minHeight: '40vh' }}>
          <HeatMap />
        </div>
      </Paper>
    </PageLayout>
  );
};

export default withRouter(PlaceTreemap);
