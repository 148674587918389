import { Box, Grid } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';
import * as RandomizeUtils from 'utils/randomize';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import * as StoreHeatmapSelectors from 'redux/reducers/storeHeatmapCount_reducer/selectors';
import * as Configuration from '../../configuration';
import Environment from 'config/environment';
import LoadingView from 'components/atoms/loading_view';
import { StateStatus } from 'redux/utils/common';
import * as StoreHeatmapCountSelectors from 'redux/reducers/storeHeatmapCount_reducer/selectors';
import { HeatmapTypeEnum } from 'config/heatmapTypes';

const useTooltipStyle = makeStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function CustomTooltip(props: TooltipProps) {
  const classes = useTooltipStyle();

  return <Tooltip {...classes} arrow {...props} />;
}

export default function HeatMap() {
  const classes = useStyles({});
  const [data, showData] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      showData(true);
    }, 500);
  }, []);

  if (!data) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          margin: '5rem',
          justifyContent: 'center',
        }}
      >
        <LoadingView />
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        background: 'rgba(179, 177, 173, .05)',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          position: 'relative',
          width: 'fit-content',
          height: 'fit-content',
          margin: '1rem',
        }}
      >
        <ImageWrapper />
        <LoadingViewWrapper />
        <FlexGrid />
      </div>
    </div>
  );
}

const LoadingViewWrapper = React.memo(() => {
  const result = StoreHeatmapSelectors.useGetHeatmapCounts();

  return (
    <React.Fragment>
      {result.status === StateStatus.Pending && (
        <LoadingView fullScreen style={{ color: 'white' }} />
      )}
    </React.Fragment>
  );
});

const ImageWrapper = React.memo(() => {
  const cameraData = StoreHeatmapSelectors.useGetSelectedCameraData();
  return (
    <img
      style={{ display: 'block' }}
      src={
        cameraData
          ? Environment.STATIC_URL + `/${cameraData.imageName}`
          : undefined
      }
    />
  );
});

interface IArrowProps {
  drgrees: number;
}

const Arrow = (props: IArrowProps) => {
  return (
    <div
      style={{
        border: 'solid black',
        borderWidth: '0 3px 3px 0',
        display: 'inline-block',
        padding: '3px',
        transform: `rotate(${-45 - props.drgrees}deg)`,
      }}
    />
  );
};

// http://localhost:1337/api/heatmapcounts/list?dateStart=2021-05-05T16:02:00.000Z&dateEnd=2021-05-11T16:02:24.914Z&cameraid=CAMERA_1&storeid=Camera1
const FlexGrid = () => {
  // const arr = new Array(size).fill({});
  const classes = useStyles({});
  const result = StoreHeatmapSelectors.useGetHeatmapCounts();
  const heatmapType = StoreHeatmapCountSelectors.useHeatmapType();
  const arr = result?.data?.items || [];

  return (
    <div
      className={classes.gridContainer}
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
      }}
    >
      {/* <LoadingView /> */}
      {arr.map((element, index) => {
        return (
          <div
            key={index}
            style={{
              display: 'flex',
              flex: 1,
              alignItems: 'stretch',
              justifyContent: 'stretch',
              flexDirection: 'column',
              alignContent: 'stretch',
            }}
          >
            {element.map((element, index) => {
              return (
                <CustomTooltip
                  key={index}
                  title={
                    heatmapType === HeatmapTypeEnum.Degree
                      ? element.avgValue
                      : element.value
                  }
                >
                  <div
                    style={{
                      background:
                        heatmapType !== HeatmapTypeEnum.Degree
                          ? Configuration.PersantageColorSelector(
                              element.persValue
                            )
                          : undefined,
                    }}
                    className={classes.gridElement}
                  >
                    {heatmapType === HeatmapTypeEnum.Degree && (
                      <Arrow drgrees={element.avgValue} />
                    )}
                  </div>
                </CustomTooltip>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
