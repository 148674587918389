import React from 'react';
import DashboardBuilder from 'components/compounds/dashboard_builder';
import * as Configuration from './configuration';
import * as StoreComparisonSelectors from 'redux/reducers/storeComparison_reducer/selectors';
import reactLogger from 'utils/logger';

export default function VisitorsByDayGraphs() {
  const formData = StoreComparisonSelectors.useGetFormData();
  reactLogger.renderComponent('VisitorsByDayGraphs');
  // console.log('Form Data:', formData);

  return (
    <DashboardBuilder
      configuration={Configuration.DashboardBuilderConfig(formData)}
    />
  );
}
