import React from 'react';
import { withRouter } from 'react-router'; // import the react-router-dom components
import useStyles from './styles';
import { useDispatch } from 'react-redux';
import reactLogger from 'utils/logger';
import GraphsLayout from './components/graphs_layout';
import PageLayout from 'components/app_common/page_layout';
import { withReducer } from 'redux/withReducer';
import { ReducerKeys } from 'redux/config';
import StoresCommonActions from 'redux/reducers/gl_app_common/actions';

// const GraphsLayout = React.lazy(() => import('./components/graphs_layout'));

const StoreParformancePage = (props: any) => {
  reactLogger.renderPage('StoreParformancePage');
  const classes = useStyles();
  const dispatch = useDispatch();

  React.useEffect(() => {
    // dispatch(StorePerformanceActions.fetchStoresAction());
    dispatch(StoresCommonActions.fetchStoresAction());
  }, [dispatch]);

  return (
    <PageLayout>
      <GraphsLayout />
    </PageLayout>
  );
};

const Page = withRouter(StoreParformancePage);
export default withReducer(ReducerKeys.STORE_PERFORMANCE_REDUCER)(Page);
