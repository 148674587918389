import React from 'react';
import Datatable from 'components/molecules/datatable';
import * as Colors from 'styles/colors';
import { Box, Chip, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TranslatedText from 'components/atoms/translated_text';
import { useDispatch } from 'react-redux';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import * as StoresSelectors from 'redux/reducers/stores_reducer/selectors';
import StoreActions from 'redux/reducers/stores_reducer/actions';
import { BoxesListDefaultFiltering, TabType } from '../../configuration';
import { BoxStatus } from 'config/store';
import SelectButton from 'components/molecules/select_button';
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
import { IBoxesListData } from 'services/api/stores';
import { useHistory } from 'react-router';
import NavigationConfig from 'navigation/config';
import useQuery from 'custom_hooks/useQuery';
import FilteringBox from './FilteringBox';
import { useSelectUserRole } from 'redux/reducers/gl_auth_reducer/selectors';
import { Roles } from 'config/authRoles';
import ModalBoxConfig, {
  IModalParams as IBoxConfigModalParams,
} from '../modal_box_config';

const BoxesDatatable = () => {
  const sortField = React.useRef<string>(
    BoxesListDefaultFiltering.DefaultSortField
  );
  const sortType = React.useRef<'asc' | 'desc'>(
    BoxesListDefaultFiltering.DefaultOrder
  );
  const theme = useTheme();
  const result = StoresSelectors.useBoxesList();
  const dispatch = useDispatch();
  const query = useQuery();
  const status = query.get('status') || '';
  const storeid = query.get('storeid') || '';
  const boxid = query.get('boxid') || '';

  const paging = {
    page: result.data?.paging.page || 0,
    pageSize: result.data?.paging.pageSize || 0,
    total: result.data?.paging.total || 0,
  };

  return (
    <Box>
      <ModalBoxConfig />
      <FilteringBox />
      <Datatable
        paging={paging}
        data={result.data?.items || []}
        sorting={{
          sortField: sortField.current,
          order: sortType.current,
        }}
        height={'70vh'}
        disablePaper={false}
        paperProps={{
          style: {
            borderRadius: 2,
            //   backgroundColor: Colors.APP_COLOR_PRIMARY(),
            overflow: 'hidden',
          },
          // square: false,
          variant: 'outlined',
        }}
        tableCellProps={() => ({
          style: {
            //   paddingTop: 0,
            //   paddingBottom: 0,
            borderLeft: 'none',
            borderRight: 'none',
          },
        })}
        tableCellMainRowProps={() => ({
          style: {
            paddingTop: '1rem',
            paddingBottom: '1rem',
            //   backgroundColor: Colors.APP_COLOR_DARK({
            //     darkMode: theme.palette.type === 'dark',
            //   }),
            //   color: 'white',
          },
        })}
        loading={false}
        columns={TableColumns}
        changePageHandle={(selectedPage, pageSize, order, _sortField) => {
          // 'asc' | 'desc'
          sortType.current = order === 'desc' ? 'asc' : 'desc';
          sortField.current = _sortField;
          dispatch(
            StoreActions.fetchBoxesListAction({
              data: {
                page: selectedPage,
                pageSize: pageSize,
                sortField: _sortField,
                order,
                status: status as BoxStatus,
                storeid,
                boxid,
              },
              onFinish: () => {},
            })
          );
        }}
      />
    </Box>
  );
};

interface IData {
  name: string;
  position: string;
  office: string;
  age: number;
  date: string;
  salary: number;
}

export const TableColumns = [
  {
    label: 'Store',
    id: 'storeid',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'BoxId',
    id: 'boxId',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Status',
    id: 'state',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>
        {<Chip label={cellValue || BoxStatus.inactive} variant="outlined" />}
      </React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Public Ip',
    id: 'publicIp',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Version',
    id: 'version',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Memory Usage',
    id: 'memoryUsage',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>
        {typeof cellValue === 'number' ? parseInt(cellValue.toString()) : 0}
        {'%'}
      </React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'CPU Usage',
    id: 'cpuUsage',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>
        {typeof cellValue === 'number' ? parseInt(cellValue.toString()) : 0}
        {'%'}
      </React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Updated At',
    id: 'updatedAt',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: '',
    id: 'actions',
    align: 'left',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: IBoxesListData['items']
    ) => <TableActions data={data} rowIndex={rowIndex} />,
    inData: false,
  },
];

const TableActions = React.memo(
  ({ rowIndex, data }: { rowIndex: number; data: IBoxesListData['items'] }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const role = useSelectUserRole();

    return (
      <div>
        <SelectButton
          items={[
            ...(role === Roles.Admin
              ? [
                  {
                    action: () => {
                      dispatch(
                        ModalActions.openModalAction({
                          modalID: ModalIDs.MODAL_BOX_CONFIG,
                          params: {
                            boxid: data[rowIndex].boxId,
                            config: data[rowIndex].config,
                          } as IBoxConfigModalParams,
                        })
                      );
                    },
                    id: 'config',
                    children: <TranslatedText defaultText="Box Config" />,
                  },
                ]
              : []),
            {
              action: () => {
                history.push(
                  NavigationConfig.storePage({
                    type: TabType.Cameras,
                    boxid: data[rowIndex].boxId,
                  }).path
                );
              },
              id: 'cameras',
              children: <TranslatedText defaultText="Show Cameras" />,
            },
            ...(role === Roles.Admin
              ? [
                  {
                    action: () => {
                      dispatch(
                        StoreActions.removeStoreBoxAction({
                          onFinish: () => {
                            dispatch(
                              StoreActions.fetchBoxesListAction({
                                data: {
                                  page: BoxesListDefaultFiltering.DefaultPage,
                                  pageSize:
                                    BoxesListDefaultFiltering.DefaultPageSize,
                                  sortField:
                                    BoxesListDefaultFiltering.DefaultSortField,
                                  order: BoxesListDefaultFiltering.DefaultOrder,
                                },
                                onFinish: () => {},
                              })
                            );
                          },
                          storeid: data[rowIndex].storeObjId,
                          boxid: data[rowIndex].boxId,
                        })
                      );
                    },
                    id: 'remove',
                    children: <TranslatedText defaultText="Remove" />,
                  },
                ]
              : []),
          ]}
          // action?: (open: boolean) => void;
          buttonProps={{
            variant: 'outlined',
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
          >
            <MoreHorizOutlinedIcon />
          </Box>
        </SelectButton>
      </div>
    );
  }
);

export const DummyData: IData[] = Array.from({ length: 100 }, (_, index) => ({
  name: 'name_' + index,
  position: 'name_' + index,
  office: 'name_' + index,
  age: index + 4,
  date: 'name_' + index,
  salary: index * 1002,
}));

export default React.memo(BoxesDatatable);
