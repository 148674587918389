import React from 'react';
import Datatable from 'components/molecules/datatable';
import * as WeekDatatableConfiguration from './configuration';
import HeatmapChart from 'components/molecules/custom_chart/components/apex_charts';
import * as RandomUtils from 'utils/randomize';
import * as DatesUtils from 'utils/dates';
import { MonthsEnum } from 'config';

// console.log("??????", DatesUtils.getWeeksOfMonth(MonthsEnum.December, 2021));

export default function WeekDatatable() {
  const list = WeekDatatableConfiguration.TableDummyData;
  return (
    <React.Fragment>
      {/* <Datatable
        // paging={paging}
        data={list}
        height={485}
        paperProps={{
          style: {
            padding: 8,
          },
        }}
        tableCellProps={(
          columnIndex: number,
          rowIndex: number,
          callValue: number | string
        ) => ({
          style: handleCellProps(
            columnIndex,
            rowIndex,
            parseInt(`${callValue}`)
          ),
        })}
        loading={false}
        columns={WeekDatatableConfiguration.TableColumns}
      /> */}
    </React.Fragment>
  );
}

const handleCellProps = (
  columnIndex: number,
  rowIndex: number,
  callValue: number
): React.CSSProperties => {
  if (columnIndex === 0) {
    return {
      background: rowIndex % 2 === 0 ? '#F2EFFA' : undefined,
      marginLeft: 15,
    };
  } else {
    let background;
    if (callValue > 300) {
      background = '#5767DD';
    } else if (callValue > 200) {
      background = '#6B9BF5';
    } else if (callValue > 100) {
      background = '#97A3FF';
    } else if (callValue > 50) {
      background = '#BAC2FF';
    } else {
      background = undefined;
    }
    return {
      background,
    };
  }
};
