import { Container, Paper } from '@material-ui/core';
import React from 'react';
import { useHistory, useParams, withRouter } from 'react-router'; // import the react-router-dom components
import { Link } from 'react-router-dom';
import NavigationConfig from '../../navigation/config';
import ResetPasswordForm from './components/reset_password_form';
import useStyles from './styles';
import clsx from 'clsx';
import LanguageDropdown from '../../components/app_common/language_dropdown';
import TranslatedText from '../../components/atoms/translated_text';
import { Translations } from './configuration';
import { withReducer } from 'redux/withReducer';
import { ReducerKeys } from 'redux/config';

interface IProps {}

const ResetPassword: React.FC<IProps> = (props) => {
  console.log('Render ForgotPassword');
  const classes = useStyles();
  const { token } = useParams<any>();

  return (
    <React.Fragment>
      <div className={clsx(classes.root)}>
        <div className={classes.containerBackground}>
          <Paper elevation={7} className={clsx(classes.cardView)}>
            <ResetPasswordForm token={token} />
            <div className={classes.layoutForgot}>
              <Link
                className="font-medium"
                to={NavigationConfig.loginPage().path}
              >
                <TranslatedText textMap={Translations.back_to_login} />
              </Link>
            </div>
          </Paper>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ResetPassword);
