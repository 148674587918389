import React from 'react';
import useStyles from './styles';
import useSize from 'custom_hooks/useSize';
import * as OccupancySelectors from 'redux/reducers/occupancy_reducer/selectors';
import { Typography } from '@material-ui/core';

interface OccupancyStatisticProps {
  refComponent: any;
}

const OccupancyStatistic = ({ refComponent }: OccupancyStatisticProps) => {
  const classes = useStyles();
  const size = useSize(refComponent);
  const data = OccupancySelectors.useSelectOccupancyStatistic();

  return (
    <div style={{ height: size?.height }} className={classes.root}>
      <div className={classes.content}>
        <div style={{ display: 'flex', alignItems: 'start' }}>
          <div>
            <span style={{ fontSize: '5rem', padding: 5 }}>
              {data?.totalVisitors}
            </span>
            <span style={{ fontSize: '2rem', padding: 5 }}>{'Visitors'}</span>
          </div>
        </div>
        <Typography
          style={{ fontSize: '1rem', padding: 5 }}
        >{`${data?.persDiff}% vs last week`}</Typography>
      </div>
    </div>
  );
};

export default React.memo(OccupancyStatistic);
