import React from 'react';
import BaseModal from 'components/molecules/base_modal';
import TranslatedText from 'components/atoms/translated_text';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  AppTextField,
  AppSelect,
  AppMultipleSelect,
  AppMultipleAutoCompleteSelect,
} from 'components/molecules/form_controls';
import { Button } from '@material-ui/core';
import NotificationActions from 'redux/reducers/notification_reducer/actions';
import * as NotificationSelectors from 'redux/reducers/notification_reducer/selectors';
import { StateStatus } from 'redux/utils/common';
import Environment from 'config/environment';
import { ConditionEnum } from 'config/alerts';
import * as Configuration from '../../configuration';
import { useHistory } from 'react-router';
import NavigationConfig from 'navigation/config';
import * as ArrayUtils from 'utils/arrays';
import { NotificationTypes } from 'config/notification';

export interface IModalParams {}
const { NotificationListDefaultFiltering } = Configuration;

const ModalAddNotification = () => {
  const dispatch = useDispatch();

  const {
    setValue,
    reset,
    register,
    control,
    watch,
    errors,
    handleSubmit,
    getValues,
  } = useForm<IFormData>({
    defaultValues,
  });

  const onSubmit = handleSubmit((data: IFormData) => {
    dispatch(
      NotificationActions.addNotificationAction({
        onFinish: () => {
          dispatch(
            NotificationActions.fetchNotificationsAction({
              page: NotificationListDefaultFiltering.DefaultPage,
              pageSize: NotificationListDefaultFiltering.DefaultPageSize,
              sortField: NotificationListDefaultFiltering.DefaultSortField,
              order: NotificationListDefaultFiltering.DefaultOrder,
            })
          );
          dispatch(
            ModalActions.closeModalAction({
              modalID: ModalIDs.MODAL_ADD_NOTIFICATION,
            })
          );
        },
        description: data.description,
        title: data.title,
        type: data.type as NotificationTypes,
        users: data.users?.map(({ _id }) => _id) || [],
      })
    );
  });

  return (
    <form>
      <BaseModal
        modalID={ModalIDs.MODAL_ADD_NOTIFICATION}
        renderContent={(props: IModalParams) => (
          <ModalForm
            params={props}
            reset={reset}
            setValue={setValue}
            register={register}
            control={control}
            errors={errors}
          />
        )}
        modalProps={{ fullWidth: true }}
        renderHeader={(props: IModalParams) => (
          <TranslatedText defaultText={'Add Notification'} />
        )}
        renderFooter={(props: IModalParams) => (
          <React.Fragment>
            <Button
              variant="outlined"
              onClick={() =>
                dispatch(
                  ModalActions.closeModalAction({
                    modalID: ModalIDs.MODAL_ADD_NOTIFICATION,
                  })
                )
              }
            >
              <TranslatedText defaultText={`Cancel`} />
            </Button>
            <SubmitWrapper onSubmit={onSubmit} />
          </React.Fragment>
        )}
      />
    </form>
  );
};

interface ISubmitWrapper {
  onSubmit: () => void;
}

const SubmitWrapper = ({ onSubmit }: ISubmitWrapper) => {
  const status = NotificationSelectors.useAddNotificationStatus();
  return (
    <Button
      variant="outlined"
      color="primary"
      disabled={status === StateStatus.Pending}
      onClick={() => {
        onSubmit();
      }}
    >
      <TranslatedText defaultText={`Submit`} />
    </Button>
  );
};

interface IFormData {
  title: string;
  description: string;
  users: ReturnType<typeof NotificationSelectors.useGetUsersList>;
  type: NotificationTypes | '';
}

const defaultValues: IFormData = {
  type: '',
  title: '',
  description: '',
  users: [],
};

interface IModalFormProps {
  control: any;
  errors: any;
  register: any;
  reset: any;
  setValue: ReturnType<typeof useForm>['setValue'];
  params: IModalParams;
}

const ModalForm = ({
  control,
  errors,
  setValue,
  params,
  reset,
}: IModalFormProps) => {
  React.useEffect(() => {
    reset(defaultValues);
  }, [params, reset]);

  return (
    <React.Fragment>
      <AppTextField
        variant="outlined"
        margin="normal"
        error={'title' in errors}
        helperText={errors.title && errors.title.message}
        rules={{ required: <RequiredField /> }}
        fullWidth
        id="title"
        placeholder="Enter title"
        label={<TranslatedText defaultText={'Title'} />}
        control={control}
        InputLabelProps={{
          shrink: true,
        }}
        autoComplete="title"
        name="title"
      />
      <AppTextField
        variant="outlined"
        margin="normal"
        error={'description' in errors}
        helperText={errors.description && errors.description.message}
        rules={{ required: <RequiredField /> }}
        fullWidth
        multiline
        rows={4}
        id="description"
        placeholder="Enter description"
        label={<TranslatedText defaultText={'Description'} />}
        control={control}
        InputLabelProps={{
          shrink: true,
        }}
        autoComplete="description"
        name="description"
      />
      <AppSelect
        fullWidth
        name={'type'}
        control={control}
        margin="normal"
        rules={{ required: <RequiredField /> }}
        InputLabelProps={{
          shrink: true,
        }}
        autoComplete={'type'}
        label={<TranslatedText defaultText="Notification Type" />}
        variant="outlined"
        placeholder="Enter type"
        options={[
          {
            label: 'Alert',
            id: NotificationTypes.Alert,
          },
        ]}
      />
      <UsersDropdownWrapper
        setValue={setValue}
        errors={errors}
        control={control}
      />
    </React.Fragment>
  );
};

interface IUserDropdown {
  control: any;
  errors: any;
  setValue: ReturnType<typeof useForm>['setValue'];
}

// users dropdown
const UsersDropdownWrapper = React.memo(
  ({ control, errors, setValue }: IUserDropdown) => {
    const usersList = NotificationSelectors.useGetUsersList();

    return (
      <AppMultipleAutoCompleteSelect
        multiple={true}
        id="users"
        control={control}
        margin="normal"
        label={<TranslatedText defaultText={'Users'} />}
        name="users"
        error={'users' in errors}
        options={usersList || []}
        rules={{
          required: <RequiredField />,
        }}
        size="medium"
        selectedKey="email"
      />
    );
  }
);

const RequiredField = React.memo(() => {
  return <TranslatedText defaultText={'Required Value'} />;
});

export default React.memo(ModalAddNotification);
