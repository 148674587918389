export enum DateRangeType {
  Today = 'Today',
  LastWeek = 'LastWeek',
  LastMonth = 'LastMonth',
  Last30Days = 'Last30Days',
}

export enum TimeType {
  Hourly = 'Hourly',
  Daily = 'Daily',
}

export enum Metric {
  In = 'In',
  Out = 'Out',
}
