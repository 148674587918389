import React from 'react';
import BaseModal from 'components/molecules/base_modal';
import TranslatedText from 'components/atoms/translated_text';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { useDispatch } from 'react-redux';
import {
  AppTextField,
  AppUploadPicker,
  AppMultipleSelect,
} from 'components/molecules/form_controls';
import { Button } from '@material-ui/core';
import StoreActions from 'redux/reducers/stores_reducer/actions';
import * as StoreSelectors from 'redux/reducers/stores_reducer/selectors';
import { StateStatus } from 'redux/utils/common';
import GridLayout from 'components/molecules/grid_layout';
import { useFieldArray, useForm } from 'react-hook-form';
import _ from 'lodash';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import Environment from 'config/environment';
import { MetricTypesArr } from 'config/metricTypes';
import { InOutDataType } from 'config/store';
import { CamerasListDefaultFiltering } from '../../configuration';

interface IFormData {
  name: string | null;
  cameraid: string | null;
  imageName: string[] | string | null;
  initialImage: string | null;
  cameratype: string[] | null;
  cameraUrl: string | null;
  publicIp: string | null;
  internalIp: string | null;
  version: string | null;
}

export interface IModalParams {
  mode: 'edit';
  defaultValues?: IFormData;
  storeid: string;
  cameraid: string;
}

const defaultValues: IFormData = {
  name: null,
  cameraid: null,
  imageName: null,
  initialImage: null,
  cameratype: null,
  cameraUrl: null,
  publicIp: null,
  internalIp: null,
  version: null,
};

const ModalEditCameras = () => {
  const dispatch = useDispatch();
  const formRef = React.useRef<any>();
  const {
    setValue,
    reset,
    register,
    control,
    watch,
    errors,
    handleSubmit,
    getValues,
    formState,
  } = useForm<IFormData>({
    defaultValues,
  });

  const onSubmit = (storeid: string, cameraid: string) =>
    handleSubmit((data: IFormData) => {
      console.log('Data:', data, getValues());
      dispatch(
        StoreActions.updateStoreCamerasAction({
          storeid,
          cameraid,
          data: {
            name: data.name as string,
            imageName: data.imageName as string,
            cameratype: data.cameratype as InOutDataType[],
            cameraUrl: data.cameraUrl as string,
          },
          onFinish: () => {
            dispatch(
              ModalActions.closeModalAction({
                modalID: ModalIDs.MODAL_EDIT_CAMERAS,
              })
            );
            dispatch(
              StoreActions.fetchCamerasListAction({
                data: {
                  page: CamerasListDefaultFiltering.DefaultPage,
                  pageSize: CamerasListDefaultFiltering.DefaultPageSize,
                  sortField: CamerasListDefaultFiltering.DefaultSortField,
                  order: CamerasListDefaultFiltering.DefaultOrder,
                },
                onFinish: () => {},
              })
            );
          },
        })
      );
    });

  return (
    <form>
      <BaseModal
        modalID={ModalIDs.MODAL_EDIT_CAMERAS}
        renderContent={(props: IModalParams) => (
          <ModalForm
            params={props}
            getValues={getValues}
            reset={reset}
            register={register}
            control={control}
            errors={errors}
          />
        )}
        modalProps={{ fullWidth: true }}
        renderHeader={(props: IModalParams) => (
          <TranslatedText
            defaultText={props.mode === 'edit' ? 'Cameras' : 'Cameras'}
          />
        )}
        renderFooter={(props: IModalParams) => (
          <React.Fragment>
            <Button
              variant="outlined"
              onClick={() =>
                dispatch(
                  ModalActions.closeModalAction({
                    modalID: ModalIDs.MODAL_EDIT_CAMERAS,
                  })
                )
              }
            >
              <TranslatedText defaultText={`Cancel`} />
            </Button>
            <SubmitWrapper
              mode={props.mode}
              onSubmit={onSubmit(props.storeid, props.cameraid)}
            />
          </React.Fragment>
        )}
      />
    </form>
  );
};

interface ISubmitWrapper {
  onSubmit: () => void;
  mode: 'edit';
}

const SubmitWrapper = ({ onSubmit, mode }: ISubmitWrapper) => {
  const status = StoreSelectors.useGetUpdateCamerasStatus();
  return (
    <Button
      variant="outlined"
      color="primary"
      disabled={status === StateStatus.Pending}
      onClick={() => {
        onSubmit();
      }}
    >
      <TranslatedText defaultText={`Submit`} />
    </Button>
  );
};

interface IModalFormProps {
  control: any;
  errors: any;
  register: any;
  reset: any;
  params: IModalParams;
  getValues: any;
}

const ModalForm = ({
  control,
  errors,
  register,
  params,
  reset,
  getValues,
}: IModalFormProps) => {
  React.useEffect(() => {
    if (params.mode === 'edit' && params.defaultValues) {
      console.log('Default Values:', params.defaultValues.cameratype);
      reset({
        ...defaultValues,
        ...params.defaultValues,
      });
    }
  }, [params, reset]);

  return (
    <React.Fragment>
      <React.Fragment>
        <AppTextField
          variant="outlined"
          rules={{}}
          id={`initialImage`}
          control={control}
          autoComplete={`initialImage`}
          name={`initialImage`}
          style={{ display: 'none' }}
        />
        <GridLayout
          justify={'flex-end'}
          elements={[
            {
              id: 'name',
              size: 6,
              element: (
                <AppTextField
                  variant="outlined"
                  margin="dense"
                  error={Boolean(_.get(errors, `name.message`, ''))}
                  helperText={_.get(errors, `name.message`, '')}
                  rules={{
                    required: <RequiredField />,
                  }}
                  fullWidth
                  size="small"
                  id={`name`}
                  control={control}
                  label={<TranslatedText defaultText={'Name'} />}
                  autoComplete={`name`}
                  name={`name`}
                  controllerExtras={{
                    defaultValue: params?.defaultValues?.name,
                  }}
                />
              ),
            },
            {
              id: 'cameraid',
              size: 6,
              element: (
                <AppTextField
                  variant="outlined"
                  margin="dense"
                  rules={{
                    required: <RequiredField />,
                  }}
                  error={Boolean(_.get(errors, `cameraid.message`, ''))}
                  helperText={_.get(errors, `cameraid.message`, '')}
                  fullWidth
                  size="small"
                  id={`cameraid`}
                  disabled
                  control={control}
                  label={<TranslatedText defaultText={'Cameraid'} />}
                  autoComplete={`cameraid`}
                  name={`cameraid`}
                  controllerExtras={{
                    defaultValue: params?.defaultValues?.cameraid,
                  }}
                />
              ),
            },
            {
              id: 'imageName',
              size: 12,
              rowStyle: { marginTop: 'auto', marginBottom: 'auto' },
              element: (
                <AppUploadPicker
                  control={control}
                  register={register}
                  margin="none"
                  error={Boolean(_.get(errors, `imageName.message`, ''))}
                  helperText={_.get(errors, `imageName.message`, '')}
                  fileInitialValue={
                    typeof params?.defaultValues?.initialImage === 'string'
                      ? Environment.STATIC_URL +
                        `/${params?.defaultValues.initialImage}`
                      : ''
                  }
                  rules={{}}
                  name={`imageName`}
                  label={<TranslatedText defaultText={'Camera Image'} />}
                />
              ),
            },
            {
              id: 'cameratype',
              size: 12,
              rowStyle: { marginTop: 'auto', marginBottom: 'auto' },
              element: (
                <AppMultipleSelect
                  control={control}
                  inputVariant="checkbox"
                  variant="outlined"
                  id={`cameratype`}
                  error={Boolean(_.get(errors, `cameratype.message`, ''))}
                  name={`cameratype`}
                  helperText={_.get(errors, `cameratype.message`, '')}
                  rules={{ required: <RequiredField /> }}
                  options={MetricTypesArr}
                  fullWidth
                  margin="none"
                  label={<TranslatedText defaultText={'Camera Types'} />}
                />
              ),
            },
            {
              id: 'cameraUrl',
              size: 12,
              rowStyle: { marginTop: 'auto', marginBottom: 'auto' },
              element: (
                <AppTextField
                  variant="outlined"
                  margin="dense"
                  rules={{
                    required: <RequiredField />,
                  }}
                  error={Boolean(_.get(errors, `cameraUrl.message`, ''))}
                  helperText={_.get(errors, `cameraUrl.message`, '')}
                  fullWidth
                  size="small"
                  disabled
                  id={`cameraUrl`}
                  control={control}
                  label={<TranslatedText defaultText={'Camera Url'} />}
                  autoComplete={`cameraUrl`}
                  name={`cameraUrl`}
                  controllerExtras={{
                    defaultValue: params?.defaultValues?.cameraUrl,
                  }}
                />
              ),
            },
            {
              id: 'publicIp',
              size: 12,
              rowStyle: { marginTop: 'auto', marginBottom: 'auto' },
              element: (
                <AppTextField
                  variant="outlined"
                  margin="dense"
                  error={Boolean(_.get(errors, `publicIp.message`, ''))}
                  helperText={_.get(errors, `publicIp.message`, '')}
                  fullWidth
                  rules={{}}
                  size="small"
                  disabled
                  id={`publicIp`}
                  control={control}
                  label={<TranslatedText defaultText={'Public Ip'} />}
                  autoComplete={`publicIp`}
                  name={`publicIp`}
                />
              ),
            },
            {
              id: 'internalIp',
              size: 12,
              rowStyle: { marginTop: 'auto', marginBottom: 'auto' },
              element: (
                <AppTextField
                  variant="outlined"
                  margin="dense"
                  rules={{}}
                  error={Boolean(_.get(errors, `internalIp.message`, ''))}
                  helperText={_.get(errors, `internalIp.message`, '')}
                  fullWidth
                  size="small"
                  disabled
                  id={`internalIp`}
                  control={control}
                  label={<TranslatedText defaultText={'Internal Ip'} />}
                  autoComplete={`internalIp`}
                  name={`internalIp`}
                />
              ),
            },
            {
              id: 'version',
              size: 12,
              rowStyle: { marginTop: 'auto', marginBottom: 'auto' },
              element: (
                <AppTextField
                  variant="outlined"
                  margin="dense"
                  rules={{}}
                  error={Boolean(_.get(errors, `version.message`, ''))}
                  helperText={_.get(errors, `version.message`, '')}
                  fullWidth
                  size="small"
                  disabled
                  id={`version`}
                  control={control}
                  label={<TranslatedText defaultText={'Version'} />}
                  autoComplete={`version`}
                  name={`version`}
                />
              ),
            },
          ]}
        />
      </React.Fragment>
    </React.Fragment>
  );
};

const RequiredField = React.memo(() => {
  return <TranslatedText defaultText={'Required Value'} />;
});

export default React.memo(ModalEditCameras);
