import React from 'react';
import ResizeObserver from 'resize-observer-polyfill';

interface ISizeResult {
  bottom: number;
  height: number;
  left: number;
  right: number;
  top: number;
  width: number;
  x: number;
  y: number;
}

export default function useSize(ref: any): ISizeResult | undefined {
  const [size, setSize] = React.useState<ISizeResult | undefined>();

  React.useEffect(() => {
    if (ref.current == null) return;
    const observer = new ResizeObserver(([entry]) =>
      setSize(entry.contentRect)
    );
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, []);

  return size;
}
