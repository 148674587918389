import React from 'react';
import { AppSelect, AppDatePicker } from 'components/molecules/form_controls';
import { useForm } from 'react-hook-form';
import useStyles from './styles';
import { Grid, Typography } from '@material-ui/core';
import TranslatedText from 'components/atoms/translated_text';
import VisitorAnalyticsActions from 'redux/reducers/visitorAnalytics_reducer/actions';
import reactLogger from 'utils/logger';
import { useDispatch } from 'react-redux';
import * as VisitorAnalyticsSelectors from 'redux/reducers/visitorAnalytics_reducer/selectors';
import { DaysRangeConfig, DaysRangeEnum } from 'config';
import LayoutElementWrapper from '../type_wrapper';
import * as StoresCommonSelectors from 'redux/reducers/gl_app_common/selectors';
import StoresCommonActions from 'redux/reducers/gl_app_common/actions';

interface IFormData {
  selectedStore: string | null;
  selectedDateRange: DaysRangeEnum | null;
  dateStart: Date;
  dateEnd: Date;
}

export default function SelectForm() {
  reactLogger.renderComponent('SelectForm');
  const classes = useStyles();
  const { setValue, watch, handleSubmit, errors, control, getValues } =
    useForm<IFormData>({
      defaultValues: {
        selectedStore: null,
        selectedDateRange: VisitorAnalyticsSelectors.getDateRangeType(),
        dateStart: new Date(),
        dateEnd: new Date(),
      },
    });
  const dispatch = useDispatch();

  React.useEffect(() => {
    handleDateChange(
      getValues().dateStart ? getValues().dateStart : new Date(),
      getValues().dateEnd ? getValues().dateEnd : new Date()
    );

    handleDateRangeChange(getValues().selectedDateRange);

    dispatch(
      VisitorAnalyticsActions.fetchVisitorListPerHourAction({
        dateEnd: getValues().dateEnd
          ? getValues().dateEnd.toISOString()
          : new Date().toISOString(),
        dateStart: getValues().dateStart
          ? getValues().dateStart.toISOString()
          : new Date().toISOString(),
      })
    );
  }, [getValues]);

  const handleDateRangeChange = (value: string | null) => {
    if (value) {
      dispatch(
        VisitorAnalyticsActions.setDateRangeTypeAction(value as DaysRangeEnum)
      );

      if (value === DaysRangeEnum.LastDays) {
        dispatch(
          VisitorAnalyticsActions.fetchVisitorsPerStoreAction({
            type: 'daily',
          })
        );
      } else if (value === DaysRangeEnum.Monthly) {
        dispatch(
          VisitorAnalyticsActions.fetchVisitorsPerStoreAction({
            type: 'monthly',
          })
        );
      }
    }
  };

  const handleDateChange = (dateStart: Date, dateEnd: Date) => {
    dispatch(
      VisitorAnalyticsActions.fetchVisitorListPerHourAction({
        dateEnd: dateEnd.toISOString(),
        dateStart: dateStart.toISOString(),
      })
    );
  };

  return (
    <form className={classes.form} noValidate>
      <Grid spacing={1} container justify={'space-between'}>
        <Grid
          alignItems="flex-end"
          justify="flex-start"
          spacing={1}
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={12}
          item
          container
        >
          <Grid sm={6} xs={12} item>
            <div>
              <Typography>
                <TranslatedText defaultText={'Select Store'} />
              </Typography>
              <SelectStoresWrapper setValue={setValue} control={control} />
            </div>
          </Grid>
          <LayoutElementWrapper
            Component={() => (
              <React.Fragment>
                <Grid sm={3} xs={12} item>
                  <div>
                    <Typography component={'h4'}>
                      <TranslatedText defaultText={'Date'} />
                    </Typography>
                    <AppDatePicker
                      fullWidth
                      margin="none"
                      autoOk
                      size="small"
                      helperText={errors.dateStart && errors.dateStart.message}
                      error={'dateStart' in errors}
                      control={control}
                      handleChange={(data) =>
                        data && handleDateChange(data, getValues().dateEnd)
                      }
                      rules={
                        {
                          // required: <RequiredField />,
                        }
                      }
                      name="dateStart"
                    />
                  </div>
                </Grid>
                <Grid sm={3} xs={12} item>
                  <AppDatePicker
                    fullWidth
                    margin="none"
                    autoOk
                    helperText={errors.dateEnd && errors.dateEnd.message}
                    error={'dateEnd' in errors}
                    control={control}
                    size="small"
                    handleChange={(data) =>
                      data && handleDateChange(data, getValues().dateStart)
                    }
                    rules={
                      {
                        // required: <RequiredField />,
                      }
                    }
                    name="dateEnd"
                  />
                </Grid>
              </React.Fragment>
            )}
            categoryTypes={[DaysRangeEnum.Hourly]}
          />
        </Grid>

        <Grid xl={3} lg={3} md={4} sm={5} xs={12} item>
          <div>
            <Typography component={'h4'}>
              <TranslatedText defaultText={'Days'} />
            </Typography>
            <AppSelect
              margin="none"
              fullWidth
              name={'selectedDateRange'}
              style={{ marginTop: 0, marginBottom: 0 }}
              control={control}
              rules={{}}
              handleChange={handleDateRangeChange}
              size="small"
              autoComplete={'selectedDateRange'}
              variant="outlined"
              options={DaysRangeConfig}
            />
          </div>
        </Grid>
      </Grid>
    </form>
  );
}

interface IPropsSelectStores {
  control: any;
  setValue: any;
}

const SelectStoresWrapper = (props: IPropsSelectStores) => {
  // const stores = VisitorAnalyticsSelectors.useGetStores();
  const stores = StoresCommonSelectors.useGetStores();
  const selectedStore = StoresCommonSelectors.useGetSelectedStore();

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (stores.length > 0) {
      const storeid = selectedStore || stores[0].uniqueId;
      props.setValue('selectedStore', storeid);
      handleStoreChange(storeid);
    }
  }, [stores, selectedStore]);

  const handleStoreChange = (value: string | null) => {
    if (value) {
      dispatch(
        VisitorAnalyticsActions.fetchVisitorsDataLastDaysAction({
          selectedStore: value,
        })
      );
      dispatch(
        VisitorAnalyticsActions.fetchHourlyVisitorsPerDayAction({
          storeid: value,
        })
      );
      dispatch(
        VisitorAnalyticsActions.fetchAverageShoppingTimeInLastDaysAction({
          storeid: value,
        })
      );
      dispatch(
        VisitorAnalyticsActions.fetchVisitorsPerMonthAction({
          storeid: value,
        })
      );
      dispatch(
        VisitorAnalyticsActions.fetchVisitorListByDateAction({
          storeid: value,
        })
      );
      dispatch(StoresCommonActions.setSelectedStoreAction(value));
    }
  };

  return (
    <AppSelect
      margin="none"
      fullWidth
      style={{ marginTop: 0, marginBottom: 0 }}
      name={'selectedStore'}
      control={props.control}
      rules={{}}
      size="small"
      handleChange={handleStoreChange}
      autoComplete={'selectedStore'}
      variant="outlined"
      options={stores.map(({ uniqueId, name }) => ({
        label: name,
        id: uniqueId,
      }))}
    />
  );
};
