import { IAction, KEEP_THE_SAME, StateStatus } from '../../utils/common';
import * as Types from './types';
import {
  IStoresData,
  ICamerasListData,
  IBoxesListData,
} from 'services/api/stores';
import { IUserData, IUserDataWithDetailsData } from 'services/api/user';
import { IAlertsListData } from 'services/api/alert';
import { IGroupBasicData, IGroupsData } from 'services/api/group';

export interface IState {
  stores: {
    status: StateStatus | null;
    data: IStoresData | null;
  };
  addStoreStatus: StateStatus | null;
  removeStoreStatus: StateStatus | null;
  userList: {
    data: IUserData | null;
    status: StateStatus | null;
  };
  userListDetails: {
    data: IUserDataWithDetailsData | null;
    status: StateStatus | null;
  };
  updateCamerasStatus: StateStatus | null;
  registerUserStatus: StateStatus | null;
  editUserStatus: StateStatus | null;
  boxesList: {
    data: IBoxesListData | null;
    status: StateStatus | null;
  };
  camerasList: {
    data: ICamerasListData | null;
    status: StateStatus | null;
  };
  alertsList: {
    data: IAlertsListData | null;
    status: StateStatus | null;
  };
  removeCameraStatus: StateStatus | null;
  removeBoxStatus: StateStatus | null;
  updateAlertStatus: StateStatus | null;
  removeAlertStatus: StateStatus | null;

  groups: {
    status: StateStatus | null;
    data: IGroupsData | null;
  };
  addGroupStatus: StateStatus | null;
  updateGroupStatus: StateStatus | null;
  removeGroupStatus: StateStatus | null;
  groupsBasicInfo: {
    status: StateStatus | null;
    data: IGroupBasicData[] | null;
  };
}

const initialState: IState = {
  stores: {
    status: null,
    data: null,
  },
  registerUserStatus: null,
  addStoreStatus: null,
  removeStoreStatus: null,
  userList: {
    data: null,
    status: null,
  },
  userListDetails: {
    data: null,
    status: null,
  },
  updateCamerasStatus: null,
  editUserStatus: null,
  boxesList: {
    data: null,
    status: null,
  },
  camerasList: {
    data: null,
    status: null,
  },
  alertsList: {
    data: null,
    status: null,
  },
  removeCameraStatus: null,
  removeBoxStatus: null,
  updateAlertStatus: null,
  removeAlertStatus: null,

  groups: {
    status: null,
    data: null,
  },
  addGroupStatus: null,
  updateGroupStatus: null,
  removeGroupStatus: null,
  groupsBasicInfo: {
    status: null,
    data: null,
  },
};

const StoresReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_STORES:
      return {
        ...state,
        stores: {
          ...state.stores,
          status: action.status || null,
          data: action.data === KEEP_THE_SAME ? state.stores.data : action.data,
        },
      };
    case Types.ADD_STORE:
      return {
        ...state,
        addStoreStatus: action.status || null,
      };
    case Types.REMOVE_STORE:
      return {
        ...state,
        removeStoreStatus: action.status || null,
      };
    case Types.SET_USERS:
      return {
        ...state,
        userList: {
          ...state.userList,
          status: action.status || null,
          data: action.data,
        },
      };
    case Types.SET_USERS_LIST:
      return {
        ...state,
        userListDetails: {
          ...state.userListDetails,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.userListDetails.data
              : action.data,
        },
      };
    case Types.EDIT_STORE_CAMERAS:
      return {
        ...state,
        updateCamerasStatus: action.status || null,
      };
    case Types.ADD_USER:
      return {
        ...state,
        registerUserStatus: action.status || null,
      };
    case Types.EDIT_USER:
      return {
        ...state,
        editUserStatus: action.status || null,
      };
    case Types.SET_CAMERA_LIST:
      return {
        ...state,
        camerasList: {
          ...state.camerasList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.camerasList.data
              : action.data,
        },
      };
    case Types.SET_BOXES_LIST:
      return {
        ...state,
        boxesList: {
          ...state.boxesList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.boxesList.data : action.data,
        },
      };
    case Types.REMOVE_CAMERA:
      return {
        ...state,
        removeCameraStatus: action.status || null,
      };
    case Types.REMOVE_STORE:
      return {
        ...state,
        removeStoreStatus: action.status || null,
      };
    case Types.SET_ALERTS_LIST:
      return {
        ...state,
        alertsList: {
          ...state.alertsList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.alertsList.data : action.data,
        },
      };
    case Types.UPDATE_ALERT:
      return {
        ...state,
        updateAlertStatus: action.status || null,
      };
    case Types.REMOVE_ALERT:
      return {
        ...state,
        removeAlertStatus: action.status || null,
      };

    case Types.SET_GROUPS:
      return {
        ...state,
        groups: {
          ...state.groups,
          status: action.status || null,
          data: action.data === KEEP_THE_SAME ? state.groups.data : action.data,
        },
      };
    case Types.ADD_GROUP:
      return {
        ...state,
        addGroupStatus: action.status || null,
      };
    case Types.REMOVE_GROUP:
      return {
        ...state,
        removeGroupStatus: action.status || null,
      };
    case Types.REMOVE_GROUP:
      return {
        ...state,
        removeGroupStatus: action.status || null,
      };
    case Types.EDIT_GROUP:
      return {
        ...state,
        updateGroupStatus: action.status || null,
      };
    case Types.SET_GROUPS_BASIC_INFO:
      return {
        ...state,
        groupsBasicInfo: {
          ...state.groupsBasicInfo,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME
              ? state.groupsBasicInfo.data
              : action.data,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default StoresReducer;
