import React, { ReactElement } from 'react';
import { CustomChart } from 'components/molecules/custom_chart';
import * as LocalConfiguration from './configuration';
import TranslatedText from 'components/atoms/translated_text';
import { ChartTypesEnum } from 'components/molecules/custom_chart/configuration';
import * as StorePerformanceSelectors from 'redux/reducers/storePerformance_reducer/selectors';
import CardLabelTitle from 'components/app_common/card_label_title';

export default function ImportanceOfWeekDayGraph() {
  const chartData = StorePerformanceSelectors.useGetImportanceOfWeekDayOfSelectedMonth();
  let datasets = chartData.datasets;
  let weeksNumberList = chartData.weeksNumberList;

  return (
    <CustomChart
      title={
        <CardLabelTitle
          title={
            <TranslatedText defaultText="Importance of WeekDay for selected Month" />
          }
        />
      }
      layoutProps={{
        variant: 'outlined',
      }}
      data={LocalConfiguration.DummyDataset(
        {
          label:
            weeksNumberList.length >= 3 ? `Week ${weeksNumberList[3]}` : 'L4',
          data: datasets.length >= 3 ? datasets[3] : [],
        },
        {
          label:
            weeksNumberList.length >= 2 ? `Week ${weeksNumberList[2]}` : 'L3',
          data: datasets.length >= 2 ? datasets[2] : [],
        },
        {
          label:
            weeksNumberList.length >= 1 ? `Week ${weeksNumberList[1]}` : 'L2',
          data: datasets.length >= 1 ? datasets[1] : [],
        },
        {
          label:
            weeksNumberList.length >= 0 ? `Week ${weeksNumberList[0]}` : 'L1',
          data: datasets.length >= 0 ? datasets[0] : [],
        }
      )}
      labels={LocalConfiguration.DummyLabels}
      options={LocalConfiguration.DummyChartOptions}
      chartType={ChartTypesEnum.COLUMN}
    />
  );
}
