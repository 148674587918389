import { useSelector } from 'react-redux';
import { ReducerKeys } from '../../config';
import useShallowEqualSelector from 'custom_hooks/useShallowEqualsSelector';
import _ from 'lodash';
import { IStoresData } from 'services/api/stores';
import { StateStatus } from 'redux/utils/common';
import { IState } from './reducer';

/* selector: useGetUsersList */
export const useNotificationList = (): IState['notificationList'] => {
  const data = useShallowEqualSelector(
    (state: any) =>
      (state[ReducerKeys.NOTIFICATION_REDUCER] as IState).notificationList
  );
  return data;
};

/* selector: useAddNotificationStatus */
export const useAddNotificationStatus = () => {
  const data = useSelector(
    (state: any) =>
      (state[ReducerKeys.NOTIFICATION_REDUCER] as IState).addNotificationStatus
  );
  return data;
};

/* selector: useGetUsersList */
export const useGetUsersList = () => {
  const data = useSelector(
    (state: any) =>
      (state[ReducerKeys.NOTIFICATION_REDUCER] as IState).userList.data
  );
  return data;
};
