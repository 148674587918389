//  [REDUCER][TYPE]
import { IReduxType, typeCreatorV2 } from '../../utils/common';
// export const SET_STORES: string = typeCreatorV2(
//   'StoreComparisonReducer',
//   'SetStores'
// );

export const SET_FORM_DATA: string = typeCreatorV2(
  'StoreComparisonReducer',
  'SetFormData'
);
