import React from 'react';
import TranslatedText from 'components/atoms/translated_text';

export enum DaysEnum {
  Sunday = '0',
  Monday = '1',
  Tuesday = '2',
  Wednesday = '3',
  Thursday = '4',
  Friday = '5',
  Saturday = '6',
}

export const Days = [
  {
    id: DaysEnum.Sunday,
    label: 'Sunday',
  },
  {
    id: DaysEnum.Monday,
    label: 'Monday',
  },
  {
    id: DaysEnum.Tuesday,
    label: 'Tuesday',
  },
  {
    id: DaysEnum.Wednesday,
    label: 'Wednesday',
  },
  {
    id: DaysEnum.Thursday,
    label: 'Thursday',
  },
  {
    id: DaysEnum.Friday,
    label: 'Friday',
  },
  {
    id: DaysEnum.Saturday,
    label: 'Saturday',
  },
];

export enum DaysRangeEnum {
  LastDays = 'last_days',
  Monthly = 'monthly',
  Hourly = 'hourly',
  Daily = 'daily',
}

export const DaysRangeConfig = [
  {
    label: <TranslatedText defaultText="Last 30 Days" />,
    id: DaysRangeEnum.LastDays,
  },
  {
    label: <TranslatedText defaultText="Monthly" />,
    id: DaysRangeEnum.Monthly,
  },
  {
    label: <TranslatedText defaultText="Hourly" />,
    id: DaysRangeEnum.Hourly,
  },
];

export const StoreHeatmapRangeConfig = [
  {
    label: <TranslatedText defaultText="Hourly" />,
    id: DaysRangeEnum.Hourly,
  },
  {
    label: <TranslatedText defaultText="Daily" />,
    id: DaysRangeEnum.Daily,
  },
  {
    label: <TranslatedText defaultText="Monthly" />,
    id: DaysRangeEnum.Monthly,
  },
];
