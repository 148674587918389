import NavigationConfig from 'navigation/config';

export enum FooterEnumSelector {
  includePaths,
  excludePaths,
}

interface IConfig {
  select: FooterEnumSelector;
  [FooterEnumSelector.excludePaths]: string[] | 'all';
  [FooterEnumSelector.includePaths]: string[] | 'all';
}

const Config: IConfig = {
  [FooterEnumSelector.excludePaths]: [
    NavigationConfig.loginPage().path,
    NavigationConfig.forgotPasswordPage().path,
  ],
  [FooterEnumSelector.includePaths]: [],
  select: FooterEnumSelector.excludePaths,
};

export default Config;
