import { Box, Container, Paper } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router'; // import the react-router-dom components
import { Link } from 'react-router-dom';
import NavigationConfig from '../../navigation/config';
import ForgotForm from './components/forgot_password_form';
import useStyles from './styles';
import clsx from 'clsx';
import LanguageDropdown from '../../components/app_common/language_dropdown';
import TranslatedText from '../../components/atoms/translated_text';
import { Translations } from './configuration';
import { withReducer } from 'redux/withReducer';
import { ReducerKeys } from 'redux/config';

interface IProps {}

const ForgotPassword: React.FC<IProps> = (props) => {
  console.log('Render ForgotPassword');
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.languageButton}>
        <LanguageDropdown />
      </div>
      <div className={clsx(classes.root)}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={classes.layoutContainer}
        >
          <Paper elevation={7} className={clsx(classes.cardView)}>
            <ForgotForm />
            <div className={classes.layoutForgot}>
              <Link
                className="font-medium"
                to={NavigationConfig.loginPage().path}
              >
                <TranslatedText textMap={Translations.back_to_login} />
              </Link>
            </div>
          </Paper>
        </Box>
      </div>
    </React.Fragment>
  );
};

// const Page = withRouter(ForgotPassword);
// export default withReducer(ReducerKeys.RESET_PASSOWRD_REDUCER)(Page);

export default withRouter(ForgotPassword);
