import { Box } from '@material-ui/core';
import TranslatedText from 'components/atoms/translated_text';
import { AppSelect, AppTextField } from 'components/molecules/form_controls';
import GridLayout from 'components/molecules/grid_layout';
import { BoxStatus, CameraStatus } from 'config/store';
import useQuery from 'custom_hooks/useQuery';
import NavigationConfig from 'navigation/config';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { TabType } from 'scenes/stores_page/configuration';

interface IFormData {
  storeUniqueId: string;
  boxid: string;
  status: BoxStatus | null;
}

const defaultValues: IFormData = {
  status: null,
  storeUniqueId: '',
  boxid: '',
};

const FilteringBox = () => {
  const {
    setValue,
    reset,
    handleSubmit,
    errors,
    control,
    getValues,
  } = useForm<IFormData>({
    defaultValues,
  });

  const history = useHistory();
  const query = useQuery();
  const storeid = query.get('storeid') || '';
  const boxid = query.get('boxid') || '';
  const type = query.get('type') || '';
  const status = query.get('status') || '';

  React.useEffect(() => {
    reset({
      ...defaultValues,
      storeUniqueId: storeid,
      boxid: boxid,
      status: status as BoxStatus,
    });
  }, [reset, storeid, boxid, status]);

  return (
    <Box display="flex" marginBottom="1rem" marginTop="1rem">
      <GridLayout
        justify="flex-start"
        elements={[
          {
            id: 'status',
            size: 2,
            element: (
              <AppSelect
                margin="none"
                fullWidth
                name={'status'}
                style={{ marginTop: 0, marginBottom: 0 }}
                control={control}
                rules={{}}
                handleChange={(data) => {
                  history.push(
                    NavigationConfig.storePage({
                      storeid,
                      boxid,
                      status: data,
                      type,
                    }).path
                  );
                }}
                size="small"
                autoComplete={'status'}
                label={<TranslatedText defaultText="Status" />}
                variant="outlined"
                options={[
                  {
                    label: 'Active',
                    id: CameraStatus.active,
                  },
                  {
                    label: 'Inactive',
                    id: CameraStatus.inactive,
                  },
                ]}
              />
            ),
          },
          {
            id: 'storeUniqueId',
            size: 2,
            element: (
              <AppTextField
                variant="outlined"
                margin="none"
                size="small"
                fullWidth
                name="storeUniqueId"
                error={'storeUniqueId' in errors}
                label={<TranslatedText defaultText={'Store Unique Id'} />}
                handleChange={(value) => {
                  history.push(
                    NavigationConfig.storePage({
                      storeid: value,
                      boxid,
                      status,
                      type,
                    }).path
                  );
                }}
                id="storeUniqueId"
                control={control}
                rules={{}}
              />
            ),
          },
          {
            id: 'boxid',
            size: 2,
            element: (
              <AppTextField
                variant="outlined"
                margin="none"
                size="small"
                fullWidth
                name="boxid"
                error={'boxid' in errors}
                label={<TranslatedText defaultText={'Box Id'} />}
                handleChange={(value) => {
                  history.push(
                    NavigationConfig.storePage({
                      storeid,
                      boxid: value,
                      status,
                      type,
                    }).path
                  );
                }}
                id="boxid"
                control={control}
                rules={{}}
              />
            ),
          },
        ]}
      />
    </Box>
  );
};

export default React.memo(FilteringBox);
