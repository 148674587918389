export enum MetricTypes {
  Inout = 'inout',
  PeopleCounting = 'PeopleCounting',
  Heatmap = 'Heatmap',
}

export const MetricTypesArr = [
  {
    label: 'Inout',
    id: MetricTypes.Inout,
  },
  {
    label: 'PeopleCounting',
    id: MetricTypes.PeopleCounting,
  },
  {
    label: 'Heatmap',
    id: MetricTypes.Heatmap,
  },
];
