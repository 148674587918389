import { colors, Theme } from '@material-ui/core';

export const DummyLabels = [
  '27/11/2020',
  '28/11/2020',
  '29/11/2020',
  '30/11/2020',
  '01/12/2020',
  '02/12/2020',
];

// const colours = data.map((value) => value < 0 ? 'red' : 'green');

export const DummyDataset = (
  currentWeekData: number[] = [11, -7, 12, 29, -3, 5, -3],
  persentageData: number[] = [18, 5, 19, 27, 29, 19, 20]
) => [
  {
    // backgroundColor: colors.grey[200],
    data: currentWeekData,
    label: 'Last year',
    format: {
      label: 'Visitors',
      type: 'line',
      fill: false,
      yAxisID: 'B',

      lineTension: 0.1, // make the lines without radius
      borderColor: '#5C80CC',
      backgroundColor: '#5C80CC',
    },
  },
  {
    data: persentageData,
    label: 'This year',
    format: {
      label: '%SDLW',
      fill: false,
      yAxisID: 'A',

      borderColor: persentageData.map((value) =>
        value < 0 ? 'rgba(255, 120, 152, 0.9)' : 'rgba(65, 254, 116, 0.9)'
      ),
      backgroundColor: persentageData.map((value) =>
        value < 0 ? 'rgba(255, 120, 152, 0.9)' : 'rgba(65, 254, 116, 0.9)'
      ),
      pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
    },
  },
];

export const DummyChartOptions = (theme: Theme): any => ({
  legend: {
    display: true,
    align: 'center',
    labels: {
      usePointStyle: true,
    },
  },
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      display: false,
    },
  },
  responsive: true,
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          max: 110,
          min: -110,
          callback: function (label: any, index: any, labels: any) {
            if (label > 100 || label < -100) {
              return '';
            }
            return `${label}%`;
          },
        },
        gridLines: {
          display: false,
        },
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right',
      },
    ],
    // yAxes: [
    //   {
    //     gridLines: {
    //       display: true,
    //     },
    //   },
    // ],
  },
  tooltips: {
    enabled: true,
  },
  // animation: false,
  // cornerRadius: 20,
  // layout: { padding: 0 },
  // legend: {
  //   display: true,
  //   align: 'start',
  //   labels: {
  //     // position: 'bottom',
  //     // fontColor: '#333',
  //     usePointStyle: true,
  //     // padding: 50,
  //   },
  // },
  // maintainAspectRatio: false,
  // responsive: true,
  // scales: {
  //   xAxes: [
  //     {
  //       barThickness: 30,
  //       maxBarThickness: 100,
  //       barPercentage: 0.6,
  //       categoryPercentage: 0.5,
  //       ticks: {
  //         display: true,
  //         fontColor: theme.palette.text.secondary,
  //       },
  //       gridLines: {
  //         display: false,
  //         drawBorder: false,
  //       },
  //       // scaleLabel: {
  //       //   display: true
  //       // },
  //     },
  //   ],
  //   yAxes: [
  //     {
  //       ticks: {
  //         fontColor: theme.palette.text.secondary,
  //         beginAtZero: true,
  //         // min: 0,
  //       },
  //       gridLines: {
  //         borderDash: [2],
  //         borderDashOffset: [2],
  //         color: theme.palette.divider,
  //         drawBorder: false,
  //         zeroLineBorderDash: [2],
  //         zeroLineBorderDashOffset: [2],
  //         zeroLineColor: theme.palette.divider,
  //       },
  //     },
  //   ],
  // },
  // tooltips: {
  //   backgroundColor: theme.palette.background.default,
  //   bodyFontColor: theme.palette.text.secondary,
  //   borderColor: theme.palette.divider,
  //   borderWidth: 1,
  //   enabled: true,
  //   footerFontColor: theme.palette.text.secondary,
  //   intersect: false,
  //   mode: 'index',
  //   titleFontColor: theme.palette.text.primary,
  // },
});
