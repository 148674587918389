import { IAction, StateStatus } from '../../utils/common';
import * as Types from './types';
import { DaysRangeEnum, DaysRangeConfig, DaysEnum } from 'config';
import { StoresDataType } from 'services/api/user';

export interface IState {
  day: Date;
}

const initialState: IState = {
  day: new Date(),
};

const VisitorsByDayReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_DAY:
      return {
        ...state,
        day: action.data.day,
      };
    default:
      return {
        ...state,
      };
  }
};

export default VisitorsByDayReducer;
