// {{BASE_URL}}/api/schemeType
import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';
import { HeatmapTypeEnum } from 'config/heatmapTypes';

/** fetch stores  */
export interface IHeatmapCountsInput {
  dateStart: string;
  dateEnd: string;
  cameraid: string;
  storeid: string;
  type: HeatmapTypeEnum;
}

export type IHeatmapCountsData = {
  items: Array<{
    x: number;
    y: number;
    value: number;
    avgValue: number;
    persValue: number;
  }>[];
  paging: {
    total: number;
  };
};

interface IFetchHeatmapCountsOutput extends Types.ResponseOutput {
  data: IHeatmapCountsData;
}

export const fetchHeatmapCounts = async (
  payload: IHeatmapCountsInput
): Promise<IFetchHeatmapCountsOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/heatmapcounts/list?dateStart=${payload.dateStart}&dateEnd=${payload.dateEnd}&cameraid=${payload.cameraid}&storeid=${payload.storeid}&type=${payload.type}`
  );
  return data;
};
