import { IAction, StateStatus } from '../../utils/common';
import * as Types from './types';
import { DaysRangeEnum, DaysRangeConfig } from 'config';
import { StoresDataType } from 'services/api/user';
import { TimeType, Metric, DateRangeType } from 'config/visitorsDetails';

export interface IState {
  selectedTimeType: TimeType;
  selectedMetric: Metric | 'all';
  selectedDateRangeType: DateRangeType;
  selectedCamera: string | 'all';
}

export const initialState: IState = {
  selectedTimeType: TimeType.Hourly,
  selectedMetric: Metric.In,
  selectedDateRangeType: DateRangeType.Today,
  selectedCamera: 'all',
};

const VisitorsDetailsReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_CAMERA:
      return {
        ...state,
        selectedCamera: action.data,
      };
    case Types.SET_DATE_RANGE_TYPE:
      return {
        ...state,
        selectedDateRangeType: action.data,
      };
    case Types.SET_METRIC:
      return {
        ...state,
        selectedMetric: action.data,
      };
    case Types.SET_TIME_TYPE:
      return {
        ...state,
        selectedTimeType: action.data,
      };
    default:
      return {
        ...state,
      };
  }
};

export default VisitorsDetailsReducer;
