import React from 'react';
import Datatable from 'components/molecules/datatable';
import * as Colors from 'styles/colors';
import { Avatar, Box, Chip, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TranslatedText from 'components/atoms/translated_text';
import { useDispatch } from 'react-redux';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import * as StoresSelectors from 'redux/reducers/stores_reducer/selectors';
import StoreActions from 'redux/reducers/stores_reducer/actions';
import { IModalParams as IAddUserModalParams } from '../modal_add_user';
import { ICamerasListData } from 'services/api/stores';
import { CamerasListDefaultFiltering } from '../../configuration';
import SelectButton from 'components/molecules/select_button';
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
import { IModalParams as ModalEditViewCameraParams } from '../../components/modal_edit_view_camera';
import { BoxStatus, CameraStatus } from 'config/store';
import useQuery from 'custom_hooks/useQuery';
import FilteringBox from './FilteringBox';
import { useSelectUserRole } from 'redux/reducers/gl_auth_reducer/selectors';
import { Roles } from 'config/authRoles';
import Environment from 'config/environment';

const CamerasDatatable = () => {
  const sortField = React.useRef<string>(
    CamerasListDefaultFiltering.DefaultSortField
  );
  const sortType = React.useRef<'asc' | 'desc'>(
    CamerasListDefaultFiltering.DefaultOrder
  );
  const theme = useTheme();
  const result = StoresSelectors.useCamerasList();
  const dispatch = useDispatch();
  const query = useQuery();
  const status = query.get('status') || '';
  const storeid = query.get('storeid') || '';
  const boxid = query.get('boxid') || '';

  const paging = {
    page: result.data?.paging.page || 0,
    pageSize: result.data?.paging.pageSize || 0,
    total: result.data?.paging.total || 0,
  };

  return (
    <Box>
      <FilteringBox />
      <Datatable
        paging={paging}
        data={result.data?.items || []}
        sorting={{
          sortField: sortField.current,
          order: sortType.current,
        }}
        height={'70vh'}
        disablePaper={false}
        paperProps={{
          style: {
            borderRadius: 2,
            //   backgroundColor: Colors.APP_COLOR_PRIMARY(),
            overflow: 'hidden',
          },
          // square: false,
          variant: 'outlined',
        }}
        tableCellProps={() => ({
          style: {
            //   paddingTop: 0,
            //   paddingBottom: 0,
            borderLeft: 'none',
            borderRight: 'none',
          },
        })}
        tableCellMainRowProps={() => ({
          style: {
            paddingTop: '1rem',
            paddingBottom: '1rem',
            //   backgroundColor: Colors.APP_COLOR_DARK({
            //     darkMode: theme.palette.type === 'dark',
            //   }),
            //   color: 'white',
          },
        })}
        loading={false}
        columns={TableColumns}
        changePageHandle={(selectedPage, pageSize, order, _sortField) => {
          // 'asc' | 'desc'
          sortType.current = order === 'desc' ? 'asc' : 'desc';
          sortField.current = _sortField;
          console.log('RTESS:::');
          dispatch(
            StoreActions.fetchCamerasListAction({
              data: {
                page: selectedPage,
                pageSize: pageSize,
                sortField: _sortField,
                order,
                status: status as BoxStatus,
                storeid,
                boxid,
              },
              onFinish: () => {},
            })
          );
        }}
      />
    </Box>
  );
};

interface IData {
  name: string;
  position: string;
  office: string;
  age: number;
  date: string;
  salary: number;
}

export const TableColumns = [
  {
    label: '',
    id: 'imageName',
    align: 'right',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>
        {
          <Avatar
            style={{ height: 50, width: 50, alignSelf: 'center' }}
            src={Environment.STATIC_URL + `/${cellValue}`}
          />
        }
      </React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'CameraId',
    id: 'cameraId',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Boxid',
    id: 'boxId',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Store',
    id: 'storeid',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Type',
    id: 'cameratype',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>
        {Array.isArray(cellValue)
          ? cellValue.map((el, index) => (
              <Chip
                style={{ marginRight: index < cellValue.length - 1 ? 5 : 0 }}
                key={index}
                label={el}
                variant="outlined"
              />
            ))
          : cellValue}
      </React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Name',
    id: 'name',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: 'Status',
    id: 'state',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>
        {<Chip label={cellValue || CameraStatus.inactive} variant="outlined" />}
      </React.Fragment>
    ),
    inData: true,
  },
  {
    label: '',
    id: 'actions',
    align: 'left',
    render: (
      rowIndex: number,
      cellValue: any,
      columnIndex: number,
      data: ICamerasListData['items']
    ) => <TableActions data={data} rowIndex={rowIndex} />,
    inData: false,
  },
];

const TableActions = React.memo(
  ({
    rowIndex,
    data,
  }: {
    rowIndex: number;
    data: ICamerasListData['items'];
  }) => {
    const dispatch = useDispatch();
    const role = useSelectUserRole();

    return (
      <div>
        <SelectButton
          items={[
            ...(role === Roles.Admin
              ? [
                  {
                    action: () => {
                      dispatch(
                        ModalActions.openModalAction({
                          modalID: ModalIDs.MODAL_EDIT_CAMERAS,
                          params: {
                            mode: 'edit',
                            storeid: data[rowIndex].storeObjId,
                            cameraid: data[rowIndex].cameraObjId,
                            defaultValues: {
                              cameraid: data[rowIndex].cameraId,
                              imageName: null,
                              initialImage: data[rowIndex].imageName,
                              cameratype: data[rowIndex].cameratype,
                              cameraUrl: data[rowIndex].cameraUrl,
                              name: data[rowIndex].name,
                              version: data[rowIndex].version,
                              publicIp: data[rowIndex].publicIp,
                              internalIp: data[rowIndex].internalIp,
                            },
                          } as ModalEditViewCameraParams,
                        })
                      );
                    },
                    id: 'editCamera',
                    children: <TranslatedText defaultText="Edit Camera" />,
                  },
                ]
              : []),
            ...(role === Roles.Admin
              ? [
                  {
                    action: () => {
                      dispatch(
                        StoreActions.removeStoreCameraAction({
                          onFinish: () => {
                            dispatch(
                              StoreActions.fetchCamerasListAction({
                                data: {
                                  page: CamerasListDefaultFiltering.DefaultPage,
                                  pageSize:
                                    CamerasListDefaultFiltering.DefaultPageSize,
                                  sortField:
                                    CamerasListDefaultFiltering.DefaultSortField,
                                  order:
                                    CamerasListDefaultFiltering.DefaultOrder,
                                },
                                onFinish: () => {},
                              })
                            );
                          },
                          storeid: data[rowIndex].storeObjId,
                          cameraid: data[rowIndex].cameraObjId,
                        })
                      );
                    },
                    id: 'remove',
                    children: <TranslatedText defaultText="Remove" />,
                  },
                ]
              : []),
          ]}
          // action?: (open: boolean) => void;
          buttonProps={{
            variant: 'outlined',
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
          >
            <MoreHorizOutlinedIcon />
          </Box>
        </SelectButton>
      </div>
    );
  }
);

export const DummyData: IData[] = Array.from({ length: 100 }, (_, index) => ({
  name: 'name_' + index,
  position: 'name_' + index,
  office: 'name_' + index,
  age: index + 4,
  date: 'name_' + index,
  salary: index * 1002,
}));

export default React.memo(CamerasDatatable);
