import React from 'react';
import { withRouter } from 'react-router'; // import the react-router-dom components
import useStyles from './styles';
import { useDispatch } from 'react-redux';
import reactLogger from 'utils/logger';
import GraphsLayout from './components/graphs_layout';
import PageLayout from 'components/app_common/page_layout';
// import TimeGroupAction from 'redux/reducers/timeGroup_reducer/actions';
import DashboardBuilder from 'components/compounds/dashboard_builder';
import * as Configuration from './configuration';
import * as TimeGroupSelectors from 'redux/reducers/timeGroup_reducer/selectors';
import StoresCommonActions from 'redux/reducers/gl_app_common/actions';
import * as StoresCommonSelectors from 'redux/reducers/gl_app_common/selectors';

const TimeGroupPage = (props: any) => {
  reactLogger.renderPage('TimeGroupPage');
  const classes = useStyles();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(StoresCommonActions.fetchStoresAction());
  }, [dispatch]);

  return (
    <PageLayout>
      <GraphsLayout />
      <DashboardBuilderWrapper />
    </PageLayout>
  );
};

const DashboardBuilderWrapper = () => {
  const selectedMonth = TimeGroupSelectors.useGetCurrentMonth();
  const selectedStore = StoresCommonSelectors.useGetSelectedStore();

  reactLogger.renderComponent('DashboardBuilderWrapper' + selectedStore);
  return (
    <React.Fragment>
      {selectedStore && selectedMonth && (
        <DashboardBuilder
          configuration={Configuration.DashboardBuilderConfig(
            selectedMonth,
            selectedStore
          )}
        />
      )}
    </React.Fragment>
  );
};

export default withRouter(TimeGroupPage);
