// {{BASE_URL}}/api/schemeType
import * as Types from '../types';
import { DaysEnum, Environment } from '../../../config';
import request from '../../../utils/request';
import { varToStringParams } from 'utils/path';

/** fetch occupancy history per hour */
export interface IFetchOccupancyHistoryPerHourOutput
  extends Types.ResponseOutput {
  data: Array<{
    _id: string;
    count: number;
  }>;
}

export interface IVisitorsByDayOfWeekPerHourInput {
  stores: string[];
  date: string;
}

export const fetchVisitorsByDayOfWeekPerHourList = async (
  payload: IVisitorsByDayOfWeekPerHourInput
): Promise<IFetchOccupancyHistoryPerHourOutput> => {
  const query = varToStringParams({
    variablesArray: [
      {
        value: payload.date.toString(),
        key: 'date',
      },
      {
        value: payload.stores.reduce((id, current) => current + `,${id}`, ''),
        key: 'stores',
      },
    ],
  });

  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/occupancy-history/per-hour${query}`
  );
  return data;
};

/** fetch Total Visitors Compare Last Week */
export interface ITotalVisiotrsCompareLastWeekOutput
  extends Types.ResponseOutput {
  data: {
    totalVisitors: string;
    totalVisitorsLastWeek: string;
    persDiff: string;
  };
}

export interface ITotalVisiotrsCompareLastWeekInput {
  store: string;
}

export const fetchTotalVisiotrsCompareLastWeek = async (
  payload: ITotalVisiotrsCompareLastWeekInput
): Promise<ITotalVisiotrsCompareLastWeekOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/occupancy-history/total-visitors-compare-last-week/${payload.store}`
  );
  return data;
};
