import { IAction, KEEP_THE_SAME, StateStatus } from '../../utils/common';
import * as Types from './types';
import { DaysRangeEnum, DaysRangeConfig } from 'config';
import { StoresDataType } from 'services/api/user';
import * as AnalyticsService from 'services/api/analytics';

export interface IState {
  // stores: {
  //   status: StateStatus | null | undefined;
  //   data: StoresDataType | null;
  // };
  daysRangeType: DaysRangeEnum;
  visitorsLastDays: {
    status: StateStatus | null | undefined;
    data: any;
  };
  averageHourlyVisitors: {
    status: StateStatus | null | undefined;
    data: any;
  };
  avarageShppingTime: {
    status: StateStatus | null | undefined;
    data: any;
  };
  chart_visitorsByDate: {
    status: StateStatus | null | undefined;
    data: any;
  };
  chart_visitorsPerHour: {
    status: StateStatus | null | undefined;
    data: any;
  };
  chart_visitorsByMonth: {
    status: StateStatus | null | undefined;
    data: any;
  };
  visitorsPerStore: {
    status: StateStatus | null | undefined;
    data: AnalyticsService.TVisitorsPerStoreListData | null;
  };
}

const initialState: IState = {
  // stores: {
  //   status: null,
  //   data: null,
  // },
  daysRangeType: DaysRangeConfig[0].id,
  visitorsLastDays: {
    status: null,
    data: null,
  },
  chart_visitorsByDate: {
    status: null,
    data: null,
  },
  chart_visitorsPerHour: {
    status: null,
    data: null,
  },
  chart_visitorsByMonth: {
    status: null,
    data: null,
  },
  averageHourlyVisitors: {
    status: null,
    data: null,
  },
  avarageShppingTime: {
    status: null,
    data: null,
  },
  visitorsPerStore: {
    status: null,
    data: null,
  },
};

const VisitorAnalyticsReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    // case Types.SET_STORES:
    //   return {
    //     ...state,
    //     stores: {
    //       ...state.stores,
    //       status: action.status,
    //       data: action.data,
    //     },
    //   };
    case Types.SET_DATE_RANGE_TYPE:
      return {
        ...state,
        daysRangeType: action.data,
      };
    case Types.SET_VISITORS_LAST_DAYS:
      return {
        ...state,
        visitorsLastDays: {
          ...state.visitorsLastDays,
          status: action.status,
          data: action.data,
        },
      };
    case Types.SET_VISITORS_LAST_DAYS_LIST:
      return {
        ...state,
        chart_visitorsByDate: {
          ...state.chart_visitorsByDate,
          status: action.status,
          data:
            action.data === KEEP_THE_SAME
              ? state.chart_visitorsByDate.data
              : action.data,
        },
      };
    case Types.SET_VISITORS_PER_HOUR_LIST:
      return {
        ...state,
        chart_visitorsPerHour: {
          ...state.chart_visitorsPerHour,
          status: action.status,
          data: action.data,
        },
      };
    case Types.SET_VISITORS_BY_MONTH:
      return {
        ...state,
        chart_visitorsByMonth: {
          ...state.chart_visitorsByMonth,
          status: action.status,
          data:
            action.data === KEEP_THE_SAME
              ? state.chart_visitorsByMonth.data
              : action.data,
        },
      };
    case Types.SET_AVERAGE_HOURLY_VISITORS_PER_DAY:
      return {
        ...state,
        averageHourlyVisitors: {
          ...state.averageHourlyVisitors,
          status: action.status,
          data:
            action.data === KEEP_THE_SAME
              ? state.averageHourlyVisitors.data
              : action.data,
        },
      };
    case Types.SET_AVERAGE_SHOPPING_TIME_IN_LAST_DAYS:
      return {
        ...state,
        avarageShppingTime: {
          ...state.avarageShppingTime,
          status: action.status,
          data:
            action.data === KEEP_THE_SAME
              ? state.avarageShppingTime.data
              : action.data,
        },
      };
    case Types.SET_VISITORS_PER_STORE_LIST:
      return {
        ...state,
        visitorsPerStore: {
          ...state.visitorsPerStore,
          status: action.status,
          data: action.data,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default VisitorAnalyticsReducer;
