// {{BASE_URL}}/api/schemeType
import * as Types from '../types';
import { Environment } from '../../../config';
import request from '../../../utils/request';
import { BoxStatus, StoreStatus, InOutDataType } from 'config/store';
import { varToStringParams } from 'utils/path';

/** fetch stores  */
export type IStoreData = {
  registerDate: Date;
  uniqueId: string;
  name: string;
  group?: {
    _id: string;
    name: string;
  };
  imageName: string;
  _id: string;
  initialin: number;
  cappacity: number;
  pairedUsers: Array<string>;
  timezone: string;

  cameras: Array<{
    _id: string;
    imageName: string;
    name: string;
    cameraid: string;
    cameratype: string[];
    cameraUrl: string;
  }>;
  boxes: Array<{
    _id?: string;
    cameraIds: Array<{
      id: string;
      state: StoreStatus;
      cameraUrl: string;
    }>;
    createdAt?: Date;
    updatedAt?: Date;
    memoryUsage?: number;
    cpuUsage?: number;
    state?: StoreStatus;
    other?: object;
    config?: object;
    version?: string;
    internalIp?: string;
    publicIp?: string;
  }>;
};

export type IStoresData = {
  items: Array<IStoreData>;
  paging: {
    pageSize: number;
    total: number;
    page: number;
  };
};

interface IFetchStoresOutput extends Types.ResponseOutput {
  data: IStoresData;
}

export interface IFetchStoresInput {
  storeid?: string;
  name?: string;
  page?: number;
  pageSize?: number;
  order?: 'asc' | 'desc';
  sortField?: string;
  groupName?: string;
}

export const fetchStores = async (
  payload?: IFetchStoresInput
): Promise<IFetchStoresOutput> => {
  const query = varToStringParams({
    variablesArray: [
      {
        value: payload?.name,
        key: 'name',
      },
      {
        value: payload?.storeid,
        key: 'storeid',
      },
      {
        value: payload?.page?.toString(),
        key: 'page',
      },
      {
        value: payload?.pageSize?.toString(),
        key: 'pageSize',
      },
      {
        value: payload?.order,
        key: 'order',
      },
      {
        value: payload?.sortField,
        key: 'sortField',
      },
      {
        value: payload?.groupName,
        key: 'groupName',
      },
    ],
  });

  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/store/list${query}`
  );
  return data;
};

/** add store  */
interface IAddStoreOutput extends Types.ResponseOutput {
  data: {
    id: string;
  };
}

interface IAddStoreInput {
  uniqueId: string;
  imageName: string;
  name: string;
  pairedUsers: Array<string>;
  initialin: number;
  cappacity: number;
  group: string;
  boxesNumber: number;
  timezone: string;
}

export const addStore = async (
  payload: IAddStoreInput
): Promise<IAddStoreOutput> => {
  const { data }: any = await request(
    'post',
    `${Environment.API_BASE_URL}/api/store`,
    {
      body: payload,
      showToastMessage: true,
    }
  );
  return data;
};

/** edit store  */
interface IEditStoreOutput extends Types.ResponseOutput {
  data: {
    id: string;
  };
}

interface IEditStoreInput {
  uniqueId: string;
  imageName?: string;
  name: string;
  pairedUsers: string[];
  initialin: number;
  cappacity: number;
  boxesNumber: number;
  group: string;
}

export const editStore = async (
  payload: IEditStoreInput,
  storeid: string
): Promise<IEditStoreOutput> => {
  const { data }: any = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/store/${storeid}`,
    {
      body: payload,
      showToastMessage: true,
    }
  );
  return data;
};

/** remove store  */
interface IRemoveStoreOutput extends Types.ResponseOutput {
  data: {
    id: string;
  };
}

export const removeStore = async (
  storeid: string
): Promise<IRemoveStoreOutput> => {
  const { data }: any = await request(
    'delete',
    `${Environment.API_BASE_URL}/api/store/${storeid}`
  );
  return data;
};

/** fetch store cameras  */
export type IStoreCameraData = ICamerasListData['items'][0];

// {
//   cameraid: string;
//   name: string;
//   imageName: string;
//   _id: string;
// }

export type StoreCamerasData = Array<IStoreCameraData>;
interface IFetchStoreCamerasOutput extends Types.ResponseOutput {
  data: StoreCamerasData;
}

export const fetchStoreCameras = async (
  storeUniqueId: string
): Promise<IFetchStoreCamerasOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/store/${storeUniqueId}/cameras/list`
  );
  return data;
};

// getStoreStatisticData
export interface IStoreStatisticDataOutput extends Types.ResponseOutput {
  data: StoreStatisticData;
}

export interface StoreStatisticData {
  occupancy: {
    in: number;
    out: number;
    current: number;
  };
  initialin: number;
  cappacity: number;
  lastUpdated?: string;
}

export const getStoreStatisticData = async (
  storeid: string
): Promise<IStoreStatisticDataOutput> => {
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/store/${storeid}/statistic-data`
  );
  return data;
};

// getBoxesList
export interface IBoxesListDataOutput extends Types.ResponseOutput {
  data: IBoxesListData;
}

export interface IBoxesListInput {
  pageSize: number;
  page: number;
  order: 'desc' | 'asc';
  sortField: string;
  storeid?: string;
  status?: BoxStatus;
  boxid?: string;
}

export interface IBoxesListData {
  items: Array<{
    _id: string;
    state: BoxStatus;
    storeid: string;
    boxId: string;
    storeObjId: string;
    memoryUsage?: number;
    cpuUsage?: number;
    config?: object;
    updatedAt: string;
  }>;
  paging: {
    pageSize: number;
    page: number;
    total: number;
  };
}

export const getBoxesList = async (
  options: IBoxesListInput
): Promise<IBoxesListDataOutput> => {
  const query = varToStringParams({
    variablesArray: [
      {
        value: options.page.toString(),
        key: 'page',
      },
      {
        value: options.pageSize.toString(),
        key: 'pageSize',
      },
      {
        value: options.sortField.toString(),
        key: 'sortField',
      },
      {
        value: options.order.toString(),
        key: 'order',
      },
      {
        value: options.storeid,
        key: 'storeid',
      },
      {
        value: options.status,
        key: 'status',
      },
      {
        value: options.boxid,
        key: 'boxid',
      },
    ],
  });
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/store/boxes/list${query}`
  );
  return data;
};

// getCamerasList
export interface ICamerasListDataOutput extends Types.ResponseOutput {
  data: ICamerasListData;
}

export interface ICamerasListData {
  items: Array<{
    _id: string;
    state: BoxStatus;
    storeid: string;
    boxId: string;
    cameraId: string;
    publicIp?: string;
    version?: string;
    internalIp?: string;
    cameraUrl?: string;
    cameraObjId: string;
    storeObjId: string;
    name?: string;
    cameratype?: Array<InOutDataType>;
    imageName?: string;
  }>;
  paging: {
    pageSize: number;
    page: number;
    total: number;
  };
}

export interface ICamerasListInput {
  pageSize: number;
  page: number;
  order?: 'desc' | 'asc';
  sortField?: string;
  storeid?: string;
  boxid?: string;
  status?: BoxStatus;
}

export const getCamerasList = async (
  input: ICamerasListInput
): Promise<ICamerasListDataOutput> => {
  const query = varToStringParams({
    variablesArray: [
      {
        value: input.page.toString(),
        key: 'page',
      },
      {
        value: input.pageSize.toString(),
        key: 'pageSize',
      },
      {
        value: input.sortField,
        key: 'sortField',
      },
      {
        value: input.order,
        key: 'order',
      },
      {
        value: input.boxid,
        key: 'boxid',
      },
      {
        value: input.storeid,
        key: 'storeid',
      },
      {
        value: input.status,
        key: 'status',
      },
    ],
  });
  const { data }: any = await request(
    'get',
    `${Environment.API_BASE_URL}/api/store/cameras/list${query}`
  );
  return data;
};

/** Edit store camera */
export interface IEditStoreCameraOutput extends Types.ResponseOutput {
  data: null;
}

export interface IEditStoreCameraInput {
  name: string;
  imageName: string;
  cameratype: InOutDataType[];
}

export const editStoreCamera = async (
  input: IEditStoreCameraInput,
  storeid: string,
  cameraid: string
): Promise<IEditStoreCameraOutput> => {
  const { data }: any = await request(
    'patch',
    `${Environment.API_BASE_URL}/api/store/${storeid}/cameras/details/${cameraid}`,
    {
      body: input,
    }
  );
  return data;
};

/** Remove store camera */
export interface IRemoveStoreCameraOutput extends Types.ResponseOutput {
  data: null;
}

export const removeStoreCamera = async (
  storeid: string,
  cameraid: string
): Promise<IRemoveStoreCameraOutput> => {
  const { data }: any = await request(
    'delete',
    `${Environment.API_BASE_URL}/api/store/${storeid}/cameras/details/${cameraid}`
  );
  return data;
};

/** Remove store box */
export interface IRemoveStoreBoxOutput extends Types.ResponseOutput {
  data: null;
}

export const removeStoreBox = async (
  storeid: string,
  boxid: string
): Promise<IRemoveStoreBoxOutput> => {
  const { data }: any = await request(
    'delete',
    `${Environment.API_BASE_URL}/api/store/${storeid}/boxes/details/${boxid}`
  );
  return data;
};

// // getAlertsList
// export interface IAlertsListDataOutput extends Types.ResponseOutput {
//   data: IAlertsListData;
// }

// export interface IAlertsListInput {
//   pageSize: number;
//   page: number;
//   order: 'desc' | 'asc';
//   sortField: string;
//   storeid?: string;
// }

// export interface IAlertsListData {
//   items: Array<{
//     _id: string;
//     users: string[];
//     fieldName: string;
//     fieldValue: string;
//     store: string;
//     condition: string;
//   }>;
//   paging: {
//     pageSize: number;
//     page: number;
//     total: number;
//   };
// }

// export const getAlertsList = async (
//   options: IAlertsListInput
// ): Promise<IAlertsListDataOutput> => {
//   const query = varToStringParams({
//     variablesArray: [
//       {
//         value: options.page.toString(),
//         key: 'page',
//       },
//       {
//         value: options.pageSize.toString(),
//         key: 'pageSize',
//       },
//       {
//         value: options.sortField.toString(),
//         key: 'sortField',
//       },
//       {
//         value: options.order.toString(),
//         key: 'order',
//       },
//       {
//         value: options.storeid,
//         key: 'storeid',
//       },
//     ],
//   });
//   const { data }: any = await request(
//     'get',
//     `${Environment.API_BASE_URL}/api/alert${query}`
//   );
//   return data;
// };
