import { MonthsEnum } from 'config/months';
import { IAction, StateStatus } from '../../utils/common';
import * as Types from './types';
// import { StoresDataType } from 'services/api/user';

interface IState {
  // stores: {
  //   status: StateStatus | null | undefined;
  //   data: StoresDataType | null;
  // };
  visitorsLastDays: {
    status: StateStatus | null | undefined;
    data: any;
  };
  visitorsCurrentMonth: {
    status: StateStatus | null | undefined;
    data: any;
  };
  chart_visitorsByDayOfWeekPerHour: {
    status: StateStatus | null | undefined;
    data: any;
  };
  chart_currentVsPreviousWeek: {
    status: StateStatus | null | undefined;
    data: any;
  };
  chart_importanceOfWeekDayOfSelectedMonth: {
    status: StateStatus | null | undefined;
    data: any;
  };
  chart_visitorsByTimeGroup: {
    status: StateStatus | null | undefined;
    data: any;
  };
  chart_visitorsPickByHourPerWeekday: {
    status: StateStatus | null | undefined;
    data: any;
  };
  selectedMonth: MonthsEnum;
  // selectedStore: string | null;
}

const initialState: IState = {
  // stores: {
  //   status: null,
  //   data: null,
  // },
  chart_currentVsPreviousWeek: {
    status: null,
    data: null,
  },
  visitorsLastDays: {
    status: null,
    data: null,
  },
  visitorsCurrentMonth: {
    status: null,
    data: null,
  },
  chart_visitorsByDayOfWeekPerHour: {
    status: null,
    data: null,
  },
  chart_importanceOfWeekDayOfSelectedMonth: {
    status: null,
    data: null,
  },
  chart_visitorsByTimeGroup: {
    status: null,
    data: null,
  },
  chart_visitorsPickByHourPerWeekday: {
    status: null,
    data: null,
  },
  selectedMonth: new Date().getMonth().toString() as MonthsEnum,
  // selectedStore: null,
};

const StorePerformanceReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    // case Types.SET_STORES:
    //   return {
    //     ...state,
    //     stores: {
    //       ...state.stores,
    //       status: action.status,
    //       data: action.data,
    //     },
    //   };
    case Types.SET_VISITORS_CURRENT_VS_PREVIOUS_WEEK_DATA:
      return {
        ...state,
        chart_currentVsPreviousWeek: {
          ...state.chart_currentVsPreviousWeek,
          status: action.status,
          data: action.data,
        },
      };
    case Types.SET_VISITORS_CURRENT_MONTH:
      return {
        ...state,
        visitorsCurrentMonth: {
          ...state.visitorsCurrentMonth,
          status: action.status,
          data: action.data,
        },
      };
    case Types.SET_VISITORS_LAST_DAYS:
      return {
        ...state,
        visitorsLastDays: {
          ...state.visitorsLastDays,
          status: action.status,
          data: action.data,
        },
      };
    case Types.SET_SELECTED_MONTH:
      return {
        ...state,
        selectedMonth: action.data,
      };
    // case Types.SET_SELECTED_STORE:
    //   return {
    //     ...state,
    //     selectedStore: action.data,
    //   };
    case Types.SET_VISITORS_BY_DAY_OF_WEEK_PER_HOUR:
      return {
        ...state,
        chart_visitorsByDayOfWeekPerHour: {
          ...state.chart_visitorsByDayOfWeekPerHour,
          status: action.status,
          data: action.data,
        },
      };
    case Types.SET_IMPORTANCE_OF_WEEK_DAY_SELECTED_MONTH:
      return {
        ...state,
        chart_importanceOfWeekDayOfSelectedMonth: {
          ...state.chart_importanceOfWeekDayOfSelectedMonth,
          status: action.status,
          data: action.data,
        },
      };
    case Types.SET_VISITORS_BY_TIMEGROUP:
      return {
        ...state,
        chart_visitorsByTimeGroup: {
          ...state.chart_visitorsByTimeGroup,
          status: action.status,
          data: action.data,
        },
      };
    case Types.SET_VISITORS_PICK_BY_HOUR_PER_WEEKDAY:
      return {
        ...state,
        chart_visitorsPickByHourPerWeekday: {
          ...state.chart_visitorsPickByHourPerWeekday,
          status: action.status,
          data: action.data,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default StorePerformanceReducer;
