import React from 'react';
import { LanguagesEnum } from 'config/languages';
import { MonthsEnum } from 'config';
import { ChartTypesEnum } from 'components/molecules/custom_chart';
import {
  IGridConf,
  ComponentTypeEnum,
} from 'components/compounds/dashboard_builder/configuration';
import * as NumberFormattingUtils from 'utils/format_number';
import * as AnalyticsService from 'services/api/analytics';
import { Theme } from '@material-ui/core';

export const Translations = {
  add_modal_btn_submit: {
    [LanguagesEnum.English]: 'Submit',
    [LanguagesEnum.Greek]: 'Υποβολή',
  },
  add_modal_btn_cancel: {
    [LanguagesEnum.English]: 'Cancel',
    [LanguagesEnum.Greek]: 'Ακύρωση',
  },
  add_modal_title: {
    [LanguagesEnum.English]: 'Add Graph',
    [LanguagesEnum.Greek]: 'Προσθήκη γραφήματος',
  },
};

const OptionsGraphTypePie = (theme?: Theme): any => ({
  legend: {
    display: true,
    align: 'start',
    position: 'left',
    labels: {
      usePointStyle: true,
    },
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
  maintainAspectRatio: false,
  tooltips: {
    enabled: true,
  },
});

const OptionsGraphType1 = (): any => ({
  legend: {
    display: true,
    align: 'start',
    position: 'bottom',
    labels: {
      usePointStyle: true,
    },
  },
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      display: false,
    },
  },
  responsive: true,
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        // stacked: true,
        ticks: {
          display: true,
          // suggestedMax: max || 70000,
          callback: function (label: any, index: any, labels: any) {
            return `${NumberFormattingUtils.formatNumberWithCommas(label)} λ`;
          },
        },
      },
    ],
  },
});

// const GraphProps = {}

export const DashboardBuilderConfig = (
  selectedMonth: MonthsEnum, storeid: string
): IGridConf[] => [
  {
    width: 12,
    type: ComponentTypeEnum.chart,
    props: {
      height: '40vh',
      data: [
        {
          data: [],
          label: '',
          format: {
            label: '06-10',
            fill: false,
            borderColor: '#1e769c',
            lineTension: 0.1, // make the lines without radius
            backgroundColor: '#208DF0',
            pointBorderColor: '#1e769c',
            pointBackgroundColor: '#1e769c',
            pointHoverBackgroundColor: '#1e769c',
            pointHoverBorderColor: '#1e769c',
          },
        },
        {
          data: [],
          label: '',
          format: {
            label: '10-14',
            fill: false,
            borderColor: '#1e769c',
            lineTension: 0.1, // make the lines without radius
            backgroundColor: '#191E94',
            pointBorderColor: '#1e769c',
            pointBackgroundColor: '#1e769c',
            pointHoverBackgroundColor: '#1e769c',
            pointHoverBorderColor: '#1e769c',
          },
        },
        {
          data: [],
          label: '',
          format: {
            label: '14-18',
            fill: false,
            borderColor: '#1e769c',
            lineTension: 0.1, // make the lines without radius
            backgroundColor: '#E36D36',
            pointBorderColor: '#1e769c',
            pointBackgroundColor: '#1e769c',
            pointHoverBackgroundColor: '#1e769c',
            pointHoverBorderColor: '#1e769c',
          },
        },
        {
          data: [],
          label: '',
          format: {
            label: '18-22',
            fill: false,
            borderColor: '#1e769c',
            lineTension: 0.1, // make the lines without radius
            backgroundColor: '#6C007A',
            pointBorderColor: '#1e769c',
            pointBackgroundColor: '#1e769c',
            pointHoverBackgroundColor: '#1e769c',
            pointHoverBorderColor: '#1e769c',
          },
        },
      ],
      labels: [],
      chartType: ChartTypesEnum.COLUMN,
      options: OptionsGraphType1(),
      title: 'People in Time Group per Month',
    },
    fetch: async () => {
      let result = await AnalyticsService.getPeopleInTimegroupPerMonth({
        storeid: storeid,
      });
      return {
        labels: result.data.labels,
        data: [
          result.data.datasets[0],
          result.data.datasets[1],
          result.data.datasets[2],
          result.data.datasets[3],
        ],
      };
    },
  },
  {
    width: 6,
    type: ComponentTypeEnum.chart,
    props: {
      data: [
        {
          label: '# of Votes',
          data: [],
          format: {
            label: '# of Votes',
            backgroundColor: ['#1387FB', '#13239D', '#E56C37', '#9E018F'],
            borderColor: ['#1387FB', '#13239D', '#E56C37', '#9E018F'],
            borderWidth: 1,
          },
        },
      ],
      chartType: ChartTypesEnum.PIE,
      options: (theme: Theme) => OptionsGraphTypePie(theme),
      title: '% Visitors By Time Group',
    },
    fetch: async () => {
      let result = await AnalyticsService.getVisitorsByTimeGroup({
        monthNumber: parseInt(selectedMonth),
        storeid: storeid
      });
      return {
        labels: [
          `6-10 (${NumberFormattingUtils.formatNumberDigits(
            result.data['6-10']
          )}%)`,
          `10-14 (${NumberFormattingUtils.formatNumberDigits(
            result.data['10-14']
          )}%)`,
          `14-18 (${NumberFormattingUtils.formatNumberDigits(
            result.data['14-18']
          )}%)`,
          `18-22 (${NumberFormattingUtils.formatNumberDigits(
            result.data['18-22']
          )}%)`,
        ],
        data: [
          [
            result.data['6-10'],
            result.data['10-14'],
            result.data['14-18'],
            result.data['18-22'],
          ],
        ],
      };
    },
  },
  {
    width: 6,
    type: ComponentTypeEnum.chart,
    props: {
      data: [
        {
          label: 'Region',
          data: [],
          format: {
            label: 'Region',
            backgroundColor: ['#1387FB', '#13239D', '#E56C37', '#9E018F'],
            borderColor: ['#1387FB', '#13239D', '#E56C37', '#9E018F'],
            borderWidth: 1,
          },
        },
      ],
      chartType: ChartTypesEnum.PIE,
      options: OptionsGraphTypePie(),
      title: '% Visitors By Region',
    },
    fetch: async () => {
      let result = await AnalyticsService.getVisitorsByRegion({
        month: selectedMonth,
      });
      return {
        labels: result.data.labels,
        data: [result.data.dataset],
      };
    },
  },
];
