import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

//  style={{transform: [{rotateY: '180deg'}]}}

export const PersantageColorSelector = (persantage: number) => {
  if (persantage === 0) {
    return 'transaparent';
  } else if (persantage < 10) {
    return 'rgba(227, 223, 213, 0.2)';
  } else if (persantage < 20) {
    return 'rgba(237, 233, 180, 0.3)';
  } else if (persantage < 30) {
    return 'rgba(196, 237, 180, 0.4)';
  } else if (persantage < 40) {
    return 'rgba(180, 237, 230, 0.5)';
  } else if (persantage < 50) {
    return 'rgba(180, 184, 237, 0.6)';
  } else if (persantage < 60) {
    return 'rgba(65, 71, 145, 0.7)';
  } else if (persantage < 70) {
    return 'rgba(65, 47, 122, 0.8)';
  } else if (persantage < 80) {
    return 'rgba(33, 15, 61, 0.9)';
  } else if (persantage < 90) {
    return 'rgba(21, 13, 33, 0.9)';
  } else {
    return 'rgba(18, 18, 18, 0.9)';
  }
};

// export const DegreePersentageIconSelector = (persantage: number) => {
//   if (persantage === 0) {
//     return null;
//   } else if (persantage < 20) {
//     return <ArrowBackIcon />;
//   } else if (persantage < 50) {
//     return 'rgba(214, 211, 19, 0.4)';
//   } else {
//     return 'rgba(245, 53, 10, 0.4)';
//   }
// };
