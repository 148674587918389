import React from 'react';
import { Provider } from 'react-redux';
import store, { persistor } from './redux/store';
import { MainRoutes } from './navigation/config/Routes';
import ToastMessage from './components/atoms/toast_message';
import { PersistGate } from 'redux-persist/integration/react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { Colors } from 'styles';
import { QueryClientProvider, QueryClient } from 'react-query';

// Or Create your Own theme:
const theme = createTheme({
  palette: {
    // secondary: {
    //   main: 'rgba(1,1,1,1)',
    // },
    primary: {
      main: Colors.APP_COLOR_PRIMARY(),
    },
    // type: 'dark',
  },
});

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <MuiThemeProvider theme={theme}>
            <MainRoutes />
            <ToastMessage />
          </MuiThemeProvider>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
