import { IAction, IActionMethods, StateStatus } from '../../utils/common';
import * as Types from './types';
import * as UserService from 'services/api/user';
import * as AnalyticsService from 'services/api/analytics';
import { Dispatch } from 'redux';
import reactLogger from 'utils/logger';
import { DaysRangeEnum, MonthsEnum, RegionsEnum } from 'config';
import { getState } from 'redux/store';
import { ReducerKeys } from 'redux/config';

// /** Fetch Stores  */
// class FetchStores implements IActionMethods {
//   onPending(): IAction {
//     return {
//       type: Types.SET_STORES,
//       status: StateStatus.Pending,
//       data: {},
//     };
//   }
//   onSuccess(data: any): IAction {
//     return {
//       type: Types.SET_STORES,
//       status: StateStatus.Success,
//       data: data,
//     };
//   }

//   onFailed(): IAction {
//     return {
//       type: Types.SET_STORES,
//       status: StateStatus.Failed,
//       data: {},
//     };
//   }

//   action(): any {
//     return async (dispatch: Dispatch<any>) => {
//       try {
//         dispatch(this.onPending());
//         let response = await UserService.getStores();
//         dispatch(this.onSuccess(response.data));
//       } catch (error) {
//         reactLogger.error('FetchStores Error:', error.message);
//         dispatch(this.onFailed());
//       }
//     };
//   }
// }

/** SetFormValues  */
interface ISetValuesInput {
  store1?: string;
  store2?: string;
  selectedMonth?: string;
}

class SetFormValues implements IActionMethods {
  onPending(): IAction {
    throw new Error('Method Not Implement');
    // return {
    //   type: Types.SET_FORM_DATA,
    //   status: StateStatus.Pending,
    //   data: {},
    // };
  }
  onSuccess(data: ISetValuesInput): IAction {
    return {
      type: Types.SET_FORM_DATA,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_FORM_DATA,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(input: ISetValuesInput): IAction {
    try {
      const currentValues = getState(ReducerKeys.STORE_COMPARISON_REDUCER)
        .formValues.data;
      return this.onSuccess({
        ...currentValues,
        ...input,
      });
    } catch (error) {
      return this.onFailed();
    }
  }
}

export default {
  // fetchStoresAction: () => new FetchStores().action(),
  setFormValuesAction: (input: ISetValuesInput) =>
    new SetFormValues().action(input),
};
