import React from 'react';
import { AppSelect, AppDatePicker } from 'components/molecules/form_controls';
import { useForm } from 'react-hook-form';
import useStyles from './styles';
import { Grid, Typography } from '@material-ui/core';
import TranslatedText from 'components/atoms/translated_text';
import TimeGroupActions from 'redux/reducers/timeGroup_reducer/actions';
import reactLogger from 'utils/logger';
import { useDispatch } from 'react-redux';
import * as TimeGroupSelectors from 'redux/reducers/timeGroup_reducer/selectors';
import { DaysRangeConfig, DaysRangeEnum, Months, MonthsEnum } from 'config';
import StoresCommonActions from 'redux/reducers/gl_app_common/actions';
import * as StoresCommonSelectors from 'redux/reducers/gl_app_common/selectors';

interface IFormData {
  selectedStore: string | null;
  selectedDateRange: MonthsEnum | null;
}

export default function SelectForm() {
  reactLogger.renderComponent('SelectForm');
  const classes = useStyles();
  const { setValue, watch, handleSubmit, errors, control, getValues } =
    useForm<IFormData>({
      defaultValues: {
        selectedStore: null,
        selectedDateRange: TimeGroupSelectors.getGetCurrentMonth(),
      },
    });
  const dispatch = useDispatch();

  // React.useEffect(() => {
  //   dispatch(
  //     VisitorAnalyticsActions.fetchVisitorListPerHourAction({
  //       dateEnd: getValues().dateEnd
  //         ? getValues().dateEnd.toISOString()
  //         : new Date().toISOString(),
  //       dateStart: getValues().dateStart
  //         ? getValues().dateStart.toISOString()
  //         : new Date().toISOString(),
  //     })
  //   );
  // }, [getValues]);

  const handleDateRangeChange = (value: string | null) => {
    value &&
      dispatch(
        TimeGroupActions.selectMonthAction({ selectedMonth: value as any })
      );
  };

  // const handleDateChange = (dateStart: Date, dateEnd: Date) => {
  //   dispatch(
  //     VisitorAnalyticsActions.fetchVisitorListPerHourAction({
  //       dateEnd: dateEnd.toISOString(),
  //       dateStart: dateStart.toISOString(),
  //     })
  //   );
  // };

  return (
    <form className={classes.form} noValidate>
      <Grid spacing={1} container justify={'space-between'}>
        <Grid
          alignItems="flex-end"
          justify="flex-start"
          spacing={1}
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={12}
          item
          container
        >
          <Grid sm={6} xs={12} item>
            <div>
              <Typography>
                <TranslatedText defaultText={'Select Store'} />
              </Typography>
              <SelectStoresWrapper setValue={setValue} control={control} />
            </div>
          </Grid>
        </Grid>

        <Grid xl={3} lg={3} md={4} sm={5} xs={12} item>
          <div>
            <Typography component={'h4'}>
              <TranslatedText defaultText={'Date'} />
            </Typography>
            <AppSelect
              margin="none"
              fullWidth
              name={'selectedDateRange'}
              style={{ marginTop: 0, marginBottom: 0 }}
              control={control}
              rules={{}}
              handleChange={handleDateRangeChange}
              size="small"
              autoComplete={'selectedDateRange'}
              variant="outlined"
              options={Months}
            />
          </div>
        </Grid>
      </Grid>
    </form>
  );
}

interface IPropsSelectStores {
  control: any;
  setValue: any;
}

const SelectStoresWrapper = (props: IPropsSelectStores) => {
  const stores = StoresCommonSelectors.useGetStores();
  const selectedStore = StoresCommonSelectors.useGetSelectedStore();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (stores.length > 0) {
      const storeid = selectedStore || stores[0].uniqueId;
      props.setValue('selectedStore', storeid);
      handleChange(storeid);
    }
  }, [stores, selectedStore]);

  const handleChange = (value: any) => {
    dispatch(StoresCommonActions.setSelectedStoreAction(value));
  };

  return (
    <AppSelect
      margin="none"
      fullWidth
      style={{ marginTop: 0, marginBottom: 0 }}
      name={'selectedStore'}
      control={props.control}
      rules={{}}
      handleChange={handleChange}
      size="small"
      autoComplete={'selectedStore'}
      variant="outlined"
      options={stores.map(({ uniqueId, name }) => ({
        label: name,
        id: uniqueId,
      }))}
    />
  );
};
