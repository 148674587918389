import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      minHeight: '100vh',
    },
    footerContent: {
      minHeight: 100,
      background: 'white',
      marginTop: 15,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '2rem',
      flexWrap: 'wrap',
      overflow: 'auto',
    },
  })
);

export default useStyles;
