import {
  fade,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import { Colors } from '../../../styles';
import Configuration from './configuration';
import { SideBarWidth } from 'config';

const drawerWidth = SideBarWidth;

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      display: 'flex',
    },
    grow: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    appBar: {
      boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.05)',
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up('md')]: {
        zIndex: theme.zIndex.drawer + 1, //temp
      },
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
      },
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    content: {
      flexGrow: 1,
      // overflowX: 'revert',
      // overflowWrap: 'unset',
      overflow: 'auto',
      // padding: theme.spacing(2),

      [theme.breakpoints.up('md')]: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
      },
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        // backgroundColor:
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    title: {
      display: 'none',
      fontWeight: 'bold',
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },
    titleContainer: {
      // border: '1px solid #FFFFFF',
      // borderRadius: 5,
      // // display: 'flex',
      // boxSizing: 'border-box',
      // padding: '1px 10px 1px 10px',
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
          display: 'block',
        },
      },
    },
    avatar: {
      cursor: 'pointer',
      // width: 70,
      maxHeight: 70,
      maxWidth: '90%',
      // margin: 6,
      // backgroundColor: Configuration.sidebar.config?.backgroundColor6,
    },
    drawerListItemText: {
      color: Configuration.sidebar.config?.textColor1,
    },
    drawerListItemTextSelected: {
      color: Configuration.sidebar.config?.textColor1,
    },
    drawerSelectedItem: {
      width: '97%',
      backgroundColor: Configuration.sidebar.config?.backgroundColor1,
      '&:hover': {
        backgroundColor: Configuration.sidebar.config?.backgroundColor4,
      },
      borderBottomRightRadius: 50,
      borderTopRightRadius: 50,
      marginBottom: 2,
    },
    drawerUnselectedItem: {
      width: '97%',
      '&:hover': {
        backgroundColor: Configuration.sidebar.config?.backgroundColor4,
      },
      borderBottomRightRadius: 50,
      borderTopRightRadius: 50,
      marginBottom: 2,
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
      color: Configuration.sidebar.config?.textColor1,
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    subElement: {
      cursor: 'pointer',
      color: Configuration.sidebar.config?.textColor1,
      paddingLeft: 20,
      marginLeft: 5,
    },
    buttonText: (props: any) => ({
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down('md')]: {
        ...(props.enableHidden ? { display: 'none' } : undefined),
      },
    }),
  })
);

export default useStyles;
