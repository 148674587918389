import { useSelector } from 'react-redux';
import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { ReducerKeys } from '../../config';
import { StateStatus } from '../../../redux/utils/common';
import { MonthsEnum } from 'config';
import { getState } from 'redux/store';
import { StoresDataType } from 'services/api/user';

/* selector: useGetCurrentMonth */
export const useGetCurrentMonth = (): MonthsEnum => {
  const data = useSelector(
    (state: any) => state[ReducerKeys.TIMEGROUP_REDUCER].selectedMonth
  );
  return data;
};

// /* selector: useGetStores */
// interface IStoresOutput {
//   id: string;
//   code: string;
//   status: string;
// }

// export const useGetStores = (): StoresDataType => {
//   const data = useSelector(
//     (state: any) => state[ReducerKeys.TIMEGROUP_REDUCER].stores.data
//   );
//   return Array.isArray(data) ? data : [];
// };

/* getFunction : getGetCurrentMonth */
export const getGetCurrentMonth = (): MonthsEnum =>
  getState(ReducerKeys.TIMEGROUP_REDUCER).selectedMonth;

// /* selector: useGetSelectedStore */
// export const useGetSelectedStore = (): string | null => {
//   const data = useSelector(
//     (state: any) => state[ReducerKeys.TIMEGROUP_REDUCER].selectedStore
//   );
//   return data;
// };
