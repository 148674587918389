import React from 'react';
import { inspect } from 'util'; // or directly

interface IPrettyPrintJsonProps {
  data: JSON;
  spaces?: number;
  jsonData?: boolean;
}

export default function PrettyPrintJson({
  jsonData = true,
  ...props
}: IPrettyPrintJsonProps) {
  return (
    <div>
      <pre>
        {jsonData
          ? JSON.stringify(props.data || {}, null, props.spaces || 4)
          : inspect(props.data || {}, false, 2, true)}
      </pre>
    </div>
  );
}
