//  [REDUCER][TYPE]
import { typeCreatorV2 } from '../../utils/common';
export const FORGOT_PASSOWRD: string = typeCreatorV2(
  'AuthReducer',
  'ForgotPassword'
);

export const RESET_PASSWORD: string = typeCreatorV2(
  'AuthReducer',
  'ResetPassword'
);
