import _ from 'lodash';
import React from 'react';
import { Switch, Route } from 'react-router-dom'; // import the react-router-dom components
import NavigationConfig, { Appbars } from '.';
import { RequireAuth, WithNavbar } from '../middlewares';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import GroupWorkOutlinedIcon from '@material-ui/icons/GroupWorkOutlined';
import TimelineIcon from '@material-ui/icons/Timeline';
import SpeedOutlinedIcon from '@material-ui/icons/SpeedOutlined';
import CompareOutlinedIcon from '@material-ui/icons/CompareOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import { Roles } from '../../config/authRoles';
import AppFooter from 'components/app_common/app_footer';
import StoreIcon from '@material-ui/icons/Store';
import { MetricTypes } from 'config/metricTypes';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { AuthRoles } from 'config';
import * as Permissions from 'utils/permissions';

interface IProps {
  routes: Routes[];
}

interface Routes {
  exact: boolean;
  path: string;
  component: any;
  roles: Array<string | number>;
  metricTypes?: Array<MetricTypes>;
}

const StackNavigator = ({ routes }: IProps) => {
  // console.log("Stack naviator...")
  return (
    <Switch>
      {routes.map((route, i) => (
        <Route
          key={i}
          exact={route.exact}
          path={route.path}
          render={() => (
            <RequireAuth
              metricTypes={route.metricTypes}
              Component={route.component}
              roles={route.roles}
            />
          )}
        />
      ))}
    </Switch>
  );
};

export const MainRoutes = () => {
  return (
    <main>
      <Switch>
        {Appbars.map((route, i) => (
          <Route
            key={i}
            exact={false}
            path={'/'}
            render={() => (
              <WithNavbar
                include={route.include}
                exclude={route.exclude}
                select={route.select}
                Component={route.component}
              />
            )}
          />
        ))}
      </Switch>
    </main>
  );
};

/** CHANGE THIS TO PUT NEW ROUTES */
export const Routes = React.memo(() => {
  return (
    <main>
      <AppFooter>
        <StackNavigator
          routes={[
            { ...NavigationConfig.mobileEntrypointPage(), exact: true },
            { ...NavigationConfig.occupancyPage(), exact: true },
            { ...NavigationConfig.notificationPage(), exact: true },
            { ...NavigationConfig.visitorDetailsPage(), exact: true },
            { ...NavigationConfig.storeTreemapPage(), exact: true },
            { ...NavigationConfig.storePage(), exact: true },
            { ...NavigationConfig.profilePage(), exact: true },
            { ...NavigationConfig.storeParformancePage(), exact: true },
            { ...NavigationConfig.visitorAnalyticsPage(), exact: true },
            { ...NavigationConfig.loginPage(), exact: true },
            { ...NavigationConfig.mainPage(), exact: true },
            { ...NavigationConfig.forgotPasswordPage(), exact: true },
            { ...NavigationConfig.resetPasswordPage(), exact: true },
            { ...NavigationConfig.timeGroupPage(), exact: true },
            { ...NavigationConfig.visitorsByDayPage(), exact: true },
            { ...NavigationConfig.storeComparisonPage(), exact: true },
            { ...NavigationConfig.rootPage(), exact: true }, // always last
          ]}
        />
      </AppFooter>
    </main>
  );
});

interface ISubItems {
  path: string;
  title: string;
  component: React.FC;
  id: number;
  requiredRoles: Array<number | string>;
  metricTypes?: MetricTypes[];
}
interface ISidebarConfig {
  title: string;
  path: string | null;
  Icon: React.FC;
  id: number;
  component: React.FC | null;
  subItems?: ISubItems[];
  requiredRoles: Array<number | string> | null;
  metricTypes?: MetricTypes[];
}

export const SideBarConfig: ISidebarConfig[] = [
  {
    title: 'Occupancy',
    path: NavigationConfig.occupancyPage().path,
    requiredRoles: NavigationConfig.occupancyPage().roles,
    metricTypes: NavigationConfig.occupancyPage().metricTypes,
    Icon: () => <DonutLargeIcon />,
    id: 1,
    component: NavigationConfig.occupancyPage().component,
  },
  {
    title: 'Visitor Details',
    path: NavigationConfig.visitorDetailsPage().path,
    requiredRoles: NavigationConfig.visitorDetailsPage().roles,
    Icon: () => <TimelineIcon />,
    id: 2,
    component: NavigationConfig.visitorDetailsPage().component,
    metricTypes: NavigationConfig.visitorDetailsPage().metricTypes,

    // title: 'Occupancy',
    // path: NavigationConfig.occupancyPage().path,
    // requiredRoles: NavigationConfig.occupancyPage().roles,
    // metricTypes: NavigationConfig.occupancyPage().metricTypes,
    // Icon: () => <DonutLargeIcon />,
    // id: 1,
    // component: NavigationConfig.occupancyPage().component,
  },
  {
    title: 'Store Performance',
    path: NavigationConfig.storeParformancePage().path,
    Icon: () => <SpeedOutlinedIcon />,
    requiredRoles: NavigationConfig.storeParformancePage().roles,
    metricTypes: NavigationConfig.storeParformancePage().metricTypes,
    id: 3,
    component: NavigationConfig.storeParformancePage().component,
  },

  {
    title: 'Time Group',
    path: NavigationConfig.timeGroupPage().path,
    requiredRoles: NavigationConfig.timeGroupPage().roles,
    Icon: () => <GroupWorkOutlinedIcon />,
    id: 4,
    component: NavigationConfig.timeGroupPage().component,
    metricTypes: NavigationConfig.timeGroupPage().metricTypes,
  },
  {
    title: 'Visitors',
    id: 5,
    path: null,
    component: null,
    requiredRoles: AuthRoles.Registered,
    Icon: () => <GroupWorkOutlinedIcon />,
    subItems: [
      {
        title: 'Analytics',
        path: NavigationConfig.visitorAnalyticsPage().path,
        requiredRoles: NavigationConfig.visitorAnalyticsPage().roles,
        // Icon: () => <TimelineOutlinedIcon />,
        id: 3,
        component: NavigationConfig.visitorAnalyticsPage().component,
        metricTypes: NavigationConfig.visitorAnalyticsPage().metricTypes,
      },
      {
        title: 'Daily',
        path: NavigationConfig.visitorsByDayPage().path,
        requiredRoles: NavigationConfig.visitorsByDayPage().roles,
        // Icon: () => <PeopleAltOutlinedIcon />,
        id: 1,
        component: NavigationConfig.visitorsByDayPage().component,
        metricTypes: NavigationConfig.visitorsByDayPage().metricTypes,
      },
      // {
      //   title: 'Details',
      //   path: NavigationConfig.visitorDetailsPage().path,
      //   requiredRoles: NavigationConfig.visitorDetailsPage().roles,
      //   // Icon: () => <CompareOutlinedIcon />,
      //   id: 2,
      //   component: NavigationConfig.visitorDetailsPage().component,
      //   metricTypes: NavigationConfig.visitorDetailsPage().metricTypes,
      // },
    ],
  },
  {
    title: 'Store Comparison',
    path: NavigationConfig.storeComparisonPage().path,
    requiredRoles: NavigationConfig.storeComparisonPage().roles,
    Icon: () => <CompareOutlinedIcon />,
    id: 6,
    component: NavigationConfig.storeComparisonPage().component,
    metricTypes: NavigationConfig.storeComparisonPage().metricTypes,
  },
  {
    title: 'Stores',
    path: NavigationConfig.storePage().path,
    requiredRoles: NavigationConfig.storePage().roles,
    Icon: () => <StoreIcon />,
    id: 8,
    component: NavigationConfig.storePage().component,
    metricTypes: NavigationConfig.storePage().metricTypes,
  },
  {
    title: 'Store Heatmap',
    path: NavigationConfig.storeTreemapPage().path,
    requiredRoles: NavigationConfig.storeTreemapPage().roles,
    Icon: () => <ViewModuleIcon />,
    id: 9,
    component: NavigationConfig.storeTreemapPage().component,
    metricTypes: NavigationConfig.storeTreemapPage().metricTypes,
  },
  {
    title: 'Notifications',
    path: NavigationConfig.notificationPage().path,
    requiredRoles: NavigationConfig.notificationPage().roles,
    Icon: () => <NotificationsIcon />,
    id: 10,
    component: NavigationConfig.notificationPage().component,
    metricTypes: NavigationConfig.notificationPage().metricTypes,
  },
];

function filterSubElements(
  subElements: ISubItems[],
  role: Roles,
  cameraTypes: MetricTypes[]
): ISubItems[] {
  return (
    subElements.filter(({ requiredRoles, metricTypes }) =>
      Permissions.hasAccess(
        requiredRoles,
        metricTypes || null,
        cameraTypes,
        role
      )
    ) || []
  );
}

// const hasAccess = (
//   requiredRoles: Array<string | number> | null,
//   requiredMetricTypes: MetricTypes[] | null,
//   cameraTypes: MetricTypes[] | null,
//   role: Roles
// ) => {
//   if (role === Roles.Admin) {
//     return true;
//   }

//   return (
//     Array.isArray(requiredRoles) &&
//     requiredRoles.includes(role) &&
//     (ArrayUtils.arraysHasCommonElements(
//       requiredMetricTypes || [],
//       cameraTypes || []
//     ) ||
//       !Array.isArray(requiredMetricTypes) ||
//       !Array.isArray(cameraTypes))
//   );
// };

export const getNavbarConfig = (
  role: Roles,
  cameraTypes: MetricTypes[]
): ISidebarConfig[] => {
  return (
    role
      ? SideBarConfig.filter(({ requiredRoles, metricTypes }) =>
          Permissions.hasAccess(
            requiredRoles,
            metricTypes || null,
            cameraTypes,
            role
          )
        )
      : []
  ).map((element) => ({
    ...element,
    subItems: Array.isArray(element.subItems)
      ? filterSubElements(element.subItems, role, cameraTypes)
      : element.subItems,
  }));
};
