import * as Types from './types';
import * as RegisterService from '../../../services/api/register';
import { Dispatch } from 'redux';
import { LanguagesEnum } from '../../../config/languages';
import NavigationConfig from '../../../navigation/config';
import {
  IAction,
  IActionMethods,
  StateStatus,
} from '../../../redux/utils/common';
import * as UserServices from '../../../services/api/user';
import * as UserRegister from '../../../services/api/user';
import * as AuthenticationService from '../../../services/api/authenticate';

/** Fetch SchemeTypes  */

class FetchUserProfile implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_USER_PROFILE,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_USER_PROFILE,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_USER_PROFILE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await UserServices.getUserProfile();
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        console.log('FetchUserProfile Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Edit Profile */
interface IEditUserProfileInput extends UserRegister.IEditUserProfileInput {}
class EditUserProfile implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.EDIT_PROFILE,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.EDIT_PROFILE,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.EDIT_PROFILE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(payload: IEditUserProfileInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await UserRegister.editUserProfile(payload);
        dispatch(this.onSuccess(response.data));
        dispatch(new FetchUserProfile().action());
      } catch (error) {
        console.log('EditProfile Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

interface IChangePasswordInput {
  newpassword: string;
  confirmNewpassword: string;
  oldpassword: string;
  history: any;
}
class ChangePassword implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.CHANGE_PASSWORD,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.CHANGE_PASSWORD,
      status: StateStatus.Success,
      data: {},
    };
  }

  onFailed(): IAction {
    return {
      type: Types.CHANGE_PASSWORD,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(data: IChangePasswordInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        console.log('test:', data);
        await AuthenticationService.changePassword({
          newPassword: data.newpassword,
          confirmPassword: data.confirmNewpassword,
          oldPassword: data.oldpassword,
        });
        dispatch(this.onSuccess());
        data.history.push(NavigationConfig.mainPage().path);
      } catch (error) {
        console.log('ChangePassword Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  editUserProfileAction: (payload: IEditUserProfileInput) =>
    new EditUserProfile().action(payload),
  fetchUserProfileAction: () => new FetchUserProfile().action(),
  changePasswordAction: (payload: IChangePasswordInput) =>
    new ChangePassword().action(payload),
};
