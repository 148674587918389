import React from 'react';
import { withRouter } from 'react-router'; // import the react-router-dom components
import useStyles from './styles';
import { useDispatch } from 'react-redux';
import reactLogger from 'utils/logger';
import GraphsLayout from './components/graphs_layout';
import PageLayout from 'components/app_common/page_layout';
// import VisitorAnalyticsAction from 'redux/reducers/visitorAnalytics_reducer/actions';
import StoresCommonActions from 'redux/reducers/gl_app_common/actions';

const VisitorAnalyticsPage = (props: any) => {
  reactLogger.renderPage('VisitorAnalyticsPage');
  const classes = useStyles();
  const dispatch = useDispatch();

  React.useEffect(() => {
    // dipsatch(VisitorAnalyticsAction.fetchStoresAction());
    dispatch(StoresCommonActions.fetchStoresAction());
  }, [dispatch]);

  return (
    <PageLayout>
      <GraphsLayout />
    </PageLayout>
  );
};

export default withRouter(VisitorAnalyticsPage);
