import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: ({ margin }: any) => ({
    marginTop: margin ? '0.95rem' : '0.1rem',
    marginBottom: margin ? '0.95rem' : '0.1rem',
  }),
}));

export default useStyles;
