import React from 'react';
import { CustomChart } from 'components/molecules/custom_chart';
import * as LocalConfiguration from './configuration';
import TranslatedText from 'components/atoms/translated_text';
import { ChartTypesEnum } from 'components/molecules/custom_chart/configuration';
import * as VisitorAnalyticsSelectors from 'redux/reducers/visitorAnalytics_reducer/selectors';
import CardLabelTitle from 'components/app_common/card_label_title';

export default function VisitorsByMonth() {
  const data = VisitorAnalyticsSelectors.useGetVisitorsPerMonth();

  console.log('Data:', data);
  return (
    <CustomChart
      height={'38vh'}
      title={
        <CardLabelTitle
          title={<TranslatedText defaultText="Visitors By Month" />}
        />
      }
      layoutProps={{
        variant: 'outlined',
      }}
      data={LocalConfiguration.DummyDataset(
        data?.datasetVisitors || [],
        data?.datasetPerc || []
      )}
      labels={LocalConfiguration.DummyLabels}
      options={LocalConfiguration.DummyChartOptions}
      chartType={ChartTypesEnum.COLUMN}
    />
  );
}
