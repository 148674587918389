//  [REDUCER][TYPE]
import { IReduxType, typeCreatorV2 } from '../../utils/common';
// export const SET_STORES: string = typeCreatorV2(
//   'StorePerformanceReducer',
//   'SetStores'
// );

export const SET_VISITORS_CURRENT_VS_PREVIOUS_WEEK_DATA: string = typeCreatorV2(
  'StorePerformanceReducer',
  'SetVisitorsCurrentVsPreviousWeekData'
);

export const SET_VISITORS_LAST_DAYS: string = typeCreatorV2(
  'StorePerformanceReducer',
  'SetVisitorsLastDays'
);

export const SET_VISITORS_CURRENT_MONTH: string = typeCreatorV2(
  'StorePerformanceReducer',
  'SetVisitorsCurrentMonth'
);

export const SET_SELECTED_MONTH: string = typeCreatorV2(
  'StorePerformanceReducer',
  'SetSelectedMonth'
);

export const SET_VISITORS_BY_DAY_OF_WEEK_PER_HOUR: string = typeCreatorV2(
  'StorePerformanceReducer',
  'SetVisitorsByDayOfWeekPerHour'
);

export const SET_IMPORTANCE_OF_WEEK_DAY_SELECTED_MONTH: string = typeCreatorV2(
  'StorePerformanceReducer',
  'SetImportanceOfWeekDayOfSelectedMonth'
);

export const SET_VISITORS_BY_TIMEGROUP: string = typeCreatorV2(
  'StorePerformanceReducer',
  'SetVisitorsByTimeGroup'
);

export const SET_VISITORS_PICK_BY_HOUR_PER_WEEKDAY: string = typeCreatorV2(
  'StorePerformanceReducer',
  'SetVisitorsPickByHourPerWeekday'
);

// export const SET_SELECTED_STORE: string = typeCreatorV2(
//   'StorePerformanceReducer',
//   'SetSelectedStore'
// );
