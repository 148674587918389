import { useSelector } from 'react-redux';
import { ReducerKeys } from '../../config';
import useShallowEqualSelector from 'custom_hooks/useShallowEqualsSelector';
import _ from 'lodash';
import { StoresDataType } from 'services/api/user';
import { StateStatus } from 'redux/utils/common';
import { StoreCamerasData } from 'services/api/stores';
import { IHeatmapCountsData } from 'services/api/store_heatmap_counts';
import { IState } from './reducer';

// /* selector: useGetStores */
// export const useGetStores = (): StoresDataType => {
//   const data = useSelector(
//     (state: any) => state[ReducerKeys.HEATMAP_COUNT_REDUCER].stores.data
//   );
//   console.log('data?', data);
//   return Array.isArray(data) ? data : [];
// };

/* selector: useGetStoreCameras */
export const useGetStoreCameras = (): StoreCamerasData => {
  const data = useSelector(
    (state: any) => state[ReducerKeys.HEATMAP_COUNT_REDUCER].storeCameras.data
  );
  return Array.isArray(data) ? data : [];
};

/* selector: useGetRemoveStoreStatus */
interface IHeatmapCountsResult {
  status: StateStatus | null;
  data: IHeatmapCountsData;
}
export const useGetHeatmapCounts = (): IHeatmapCountsResult => {
  const { status, data } = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.HEATMAP_COUNT_REDUCER].heatmapCount
  );
  return { status, data };
};

/* selector: useGetSelectedCamera */
export const useGetSelectedCameraData = () => {
  const selectedStoreData = useSelector(
    (state: any) =>
      (state[ReducerKeys.HEATMAP_COUNT_REDUCER] as IState).selectedStoreData
  );

  return selectedStoreData;
};

/* selector: useHeatmapType */
export const useHeatmapType = () => {
  const type = useSelector(
    (state: any) =>
      (state[ReducerKeys.HEATMAP_COUNT_REDUCER] as IState).heatmapType
  );

  return type;
};
