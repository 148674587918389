import React, { ReactElement } from 'react';
import useStyles from './styles';

interface IProps {
  children: ReactElement[] | ReactElement;
  style?: React.CSSProperties;
}

export default function PageLayout(props: IProps) {
  const classes = useStyles();

  return <div className={classes.root} {...props} />;
}
