import React from 'react';
import DashboardBuilder from 'components/compounds/dashboard_builder';
import * as Configuration from './configuration';
import { Card, CardHeader, Typography } from '@material-ui/core';
import TranslatedText from 'components/atoms/translated_text';
import CardLabelTitle from 'components/app_common/card_label_title';
import * as VisitorsByDaySelectors from 'redux/reducers/visitorsByDay_reducer/selectors';

export default function VisitorsByDayGraphs() {
  const data = VisitorsByDaySelectors.useGetVisitorsByDay();

  return (
    <Card square variant="outlined">
      <CardHeader
        subheader={
          <Typography style={{ fontSize: 13 }} variant="h6">
            <TranslatedText defaultText={'Hour(0-24)'} />
          </Typography>
        }
        title={
          <CardLabelTitle
            title={<TranslatedText defaultText={'Visitors By Day'} />}
          />
        }
        style={{ paddingTop: 5, paddingBottom: 0 }}
      />
      {data && (
        <DashboardBuilder
          spacing={0}
          configuration={Configuration.DashboardBuilderConfig(data)}
        />
      )}
    </Card>
  );
}
