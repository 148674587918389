import React, { ReactElement } from 'react';
import { Button } from '@material-ui/core';
import TranslatedText from 'components/atoms/translated_text';

export const Translations = {};

const Configuration = {};

const MonthsMap = {
  '01': 'January',
  '02': 'February',
  '03': 'March',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'August',
  '09': 'September',
  '10': 'October',
  '11': 'November',
  '12': 'December',
};

const WeekDayMap = {
  '1': 'Sunday',
  '2': 'Monday',
  '3': 'Tuesday',
  '4': 'Wednesday',
  '5': 'Thursday',
  '6': 'Friday',
  '7': 'Saturday',
};

export const MAP_TABLE_COL_NAMES = {
  daily: WeekDayMap,
  monthly: MonthsMap,
};

interface IData {
  change: string;
  total: string;
  store: string;
  visitors: string;
}

export const DummyColumns = [
  {
    label: <TranslatedText defaultText="Store" />,
    id: 'store',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: <TranslatedText defaultText="Visitors" />,
    id: 'visitors',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: <TranslatedText defaultText="% Change" />,
    id: 'change',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
  {
    label: <TranslatedText defaultText="% on Total" />,
    id: 'total',
    // align: 'right',
    align: 'left',
    render: (rowIndex: number, cellValue: any, columnIndex: number) => (
      <React.Fragment>{cellValue}</React.Fragment>
    ),
    inData: true,
  },
];

export const DummyData: IData[] = [
  {
    change: '1csacscsaca11',
    total: 'ssvfvfvfvs',
    store: '1234',
    visitors: 'ddscccsd',
  },
  {
    change: '1csacscsaca11',
    total: 'ssvfvfvfvs',
    store: '1234',
    visitors: 'ddscccsd',
  },
  {
    change: '1csacscsaca11',
    total: 'ssvfvfvfvs',
    store: '1234',
    visitors: 'ddscccsd',
  },
  {
    change: '1csacscsaca11',
    total: 'ssvfvfvfvs',
    store: '1234',
    visitors: 'ddscccsd',
  },
  {
    change: '1csacscsaca11',
    total: 'ssvfvfvfvs',
    store: '1234',
    visitors: 'ddscccsd',
  },
  {
    change: '1csacscsaca11',
    total: 'ssvfvfvfvs',
    store: '1234',
    visitors: 'ddscccsd',
  },
  {
    change: '1csacscsaca11',
    total: 'ssvfvfvfvs',
    store: '1234',
    visitors: 'ddscccsd',
  },
  {
    change: '1csacscsaca11',
    total: 'ssvfvfvfvs',
    store: '1234',
    visitors: 'ddscccsd',
  },
  {
    change: '1csacscsaca11',
    total: 'ssvfvfvfvs',
    store: '1234',
    visitors: 'ddscccsd',
  },
  {
    change: '1csacscsaca11',
    total: 'ssvfvfvfvs',
    store: '1234',
    visitors: 'ddscccsd',
  },
];

export default Configuration;
