import React, { ReactElement } from 'react';
import { CustomChart } from 'components/molecules/custom_chart';
import * as LocalConfiguration from './configuration';
import TranslatedText from 'components/atoms/translated_text';
import { ChartTypesEnum } from 'components/molecules/custom_chart/configuration';
import * as VisitorAnalyticsSelectors from 'redux/reducers/visitorAnalytics_reducer/selectors';
import CardLabelTitle from 'components/app_common/card_label_title';
import reactLogger from 'utils/logger';

export default function TotalVisitorsLastDaysGraph() {
  const data = VisitorAnalyticsSelectors.useGetVisitorListByDate();
  reactLogger.renderComponent(
    'TotalVisitorsLastDaysGraph'
    // + JSON.stringify(data)
  );

  // console.log('Data:', data);
  return (
    <CustomChart
      height={'38vh'}
      title={
        <CardLabelTitle
          title={
            <TranslatedText defaultText="Total Visitors in Last 30 Days" />
          }
        />
      }
      layoutProps={{
        variant: 'outlined',
      }}
      // data={LocalConfiguration.DummyDataset(data?.data || [])}
      // labels={data?.labels || []}
      data={LocalConfiguration.DummyDataset(
        Array.isArray(data?.data) ? data?.data : []
      )}
      labels={data?.labels || []}
      options={LocalConfiguration.DummyChartOptions}
      chartType={ChartTypesEnum.LINE}
    />
  );
}
