import GridLayout from 'components/molecules/grid_layout';
import React, { ReactElement } from 'react';
import SelectForm from '../select_form';
import StatisticCardsLayout from '../statistic_cards_layout';
import reactLogger from 'utils/logger';
import TotalVisitorsLastDaysGraph from '../total_visitors_last_days_graph';
import VisitorsPercChangeTable from '../visitors_per_change_table';
import VisitorsByMonthGraph from '../visitors_by_month_graph';
import VisitorsPerHourGraph from '../visitors_per_hour_graph';
import { DaysRangeEnum } from 'config';
import LayoutElementWrapper from '../type_wrapper';

export default function GraphsLayout(): ReactElement {
  reactLogger.renderComponent('GraphsLayout');
  return (
    <React.Fragment>
      <GridLayout
        spacing={1}
        elements={[
          {
            size: 12,
            element: <SelectForm />,
            id: '1',
          },
          {
            size: 12,
            element: (
              <LayoutElementWrapper
                Component={StatisticCardsLayout}
                categoryTypes={[DaysRangeEnum.LastDays, DaysRangeEnum.Monthly]}
              />
            ),
            id: '2',
          },
          {
            size: 12,
            element: (
              <LayoutElementWrapper
                Component={TotalVisitorsLastDaysGraph}
                categoryTypes={[DaysRangeEnum.LastDays]}
              />
            ),

            // <TotalVisitorsLastDaysGraph />,
            id: '3',
          },
          {
            size: 12,
            element: (
              <LayoutElementWrapper
                Component={VisitorsByMonthGraph}
                categoryTypes={[DaysRangeEnum.Monthly]}
              />
            ),
            id: '4',
          },
          {
            size: 12,
            element: (
              <LayoutElementWrapper
                Component={VisitorsPerHourGraph}
                categoryTypes={[DaysRangeEnum.Hourly]}
              />
            ),
            id: '5',
          },
          {
            size: 12,
            element: (
              <LayoutElementWrapper
                Component={VisitorsPercChangeTable}
                categoryTypes={[DaysRangeEnum.Monthly, DaysRangeEnum.LastDays]}
              />
            ),
            id: '6',
          },
        ]}
      />
    </React.Fragment>
  );
}
