import { colors, Theme } from '@material-ui/core';
import * as RandomUtils from 'utils/randomize';
import { ChartOptions } from 'chart.js';

export const DummyLabels = [
  '1 Monday',
  '2 Tuesday',
  '3 Wednesday',
  '4 Thursday',
  '5 Friday',
  '6 Suturday',
  '7 Sunday',
];
const rand = () => Math.round(Math.random() * 20 - 10);

interface IGraphData {
  x: number | string;
  y: number | string;
  r: number | string;
}

let _data = [
  { x: rand(), y: rand(), r: rand() },
  { x: rand(), y: rand(), r: rand() },
  { x: rand(), y: rand(), r: rand() },
  { x: rand(), y: rand(), r: rand() },
  { x: rand(), y: rand(), r: rand() },
  { x: rand(), y: rand(), r: rand() },
  { x: rand(), y: rand(), r: rand() },
  { x: rand(), y: rand(), r: rand() },
  { x: rand(), y: rand(), r: rand() },
  { x: rand(), y: rand(), r: rand() },
  { x: rand(), y: rand(), r: rand() },
  { x: rand(), y: rand(), r: rand() },
  { x: rand(), y: rand(), r: rand() },
  { x: rand(), y: rand(), r: rand() },
];

export const DummyDataset = (data: IGraphData[] = _data) => [
  {
    // backgroundColor: colors.grey[200],
    data: data,
    label: '',
    format: {
      label: 'Hourly Visitors',
      fill: false,
      backgroundColor: 'rgba(52, 164, 235, .8)',
      pointBorderColor: '#0094E8',
      hoverBackgroundColor: '#0094E8',
      pointHoverBorderColor: '#0094E8',
    },
  },
];

export const DummyChartOptions = (theme: Theme): ChartOptions => ({
  legend: {
    display: false,
  },
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      display: false,
    },
  },
  responsive: true,
  scales: {
    xAxes: [
      {
        ticks: {
          max: 8,
          min: 0,
          beginAtZero: true,
          callback: function (label: any, index: any, labels: any) {
            switch (label) {
              case 1:
                return '7. Sunday';
              case 2:
                return '1. Monday';
              case 3:
                return '2. Tuesday';
              case 4:
                return '3. Wednesday';
              case 5:
                return '4. Thursday';
              case 6:
                return '5. Friday';
              case 7:
                return '6. Saturday';
              default:
                return ``;
            }
          },
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          autoSkip: true,
        },
      },
    ],
  },
  tooltips: {
    enabled: true,
  },
});
