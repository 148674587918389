import GridLayout from 'components/molecules/grid_layout';
import React, { ReactElement } from 'react';
import SelectForm from '../select_form';
import reactLogger from 'utils/logger';
import VisitorsByDayGraphs from '../visitors_by_day_graphs';
import VisitorsByDayWeekNumGraph from '../visitors_by_day_weeknum_graph';
import WeekDatatable from '../week_datatable';

export default function GraphsLayout(): ReactElement {
  reactLogger.renderComponent('GraphsLayout');
  return (
    <React.Fragment>
      <GridLayout
        spacing={1}
        elements={[
          {
            size: 12,
            element: <SelectForm />,
            id: '1',
          },
          {
            size: 5,
            element: <LeftContainerWrapper />,
            id: '2',
          },
          {
            size: 7,
            element: <RightContainerWrapper />,
            id: '3',
          },
        ]}
      />
    </React.Fragment>
  );
}

const LeftContainerWrapper = () => <VisitorsByDayGraphs />;
const RightContainerWrapper = () => (
  <div>
    <WeekDatatable />
    <VisitorsByDayWeekNumGraph />
  </div>
);
