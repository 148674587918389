import React from 'react';
import { AuthRoles, HideSideBar, Languages } from 'config';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Dispatch } from 'redux';
import Actions from '../../../redux/reducers/gl_auth_reducer/actions';
import { Colors } from '../../../styles';
import NavigationConfig from 'navigation/config';
import SettingsIcon from '@material-ui/icons/Settings';

// TDispatc
interface IMenuConfigurationElement {
  label: string;
  id: string;
  action: (props: Dispatch<any>, history: any) => void;
  submenu: boolean;
  roles: Array<number | string> | number | string;
  icon: any;
}

export const ProfileConfiguration: IMenuConfigurationElement[] = [
  {
    label: 'Settings',
    id: '1',
    action: (dispatch: Dispatch<any>, history) =>
      history.push(NavigationConfig.profilePage().path),
    submenu: false,
    roles: AuthRoles.Registered,
    icon: <SettingsIcon />,
  },
  {
    label: 'Logout',
    id: '4',
    action: (dispatch: Dispatch<any>, history) =>
      dispatch(Actions.logoutAction(history)),
    submenu: false,
    roles: AuthRoles.Registered,
    icon: <ExitToAppIcon />,
  },
];

interface IElementConfiguration {
  enable: boolean;
  config?: IConfig;
}

interface IConfiguration {
  sidebar: IElementConfiguration;
  search: IElementConfiguration;
  notifications: IElementConfiguration;
  profile: IElementConfiguration;
  language: IElementConfiguration;
  appBar: IElementConfiguration;
}

interface IConfig {
  name?: string;
  iconSrc?: any;
  backgroundColor1?: string;
  backgroundColor2?: string;
  backgroundColor3?: string;
  backgroundColor4?: string;
  backgroundColor5?: string;
  backgroundColor6?: string;
  textColor1?: string;
  textColor2?: string;
  textColor3?: string;
  textColor4?: string;
}

const Configuration: IConfiguration = {
  sidebar: {
    enable: !HideSideBar,
    config: {
      backgroundColor1: Colors.APP_COLOR_HIGHLIGHT(0.25),
      backgroundColor2: 'white',
      backgroundColor3: 'white',
      backgroundColor4: Colors.APP_COLOR_HIGHLIGHT(0.1),
      backgroundColor5: Colors.APP_COLOR_SECONDARY(),
      backgroundColor6: Colors.APP_COLOR_SECONDARY(),
      textColor1: 'black',
      textColor2: 'green',
      textColor3: 'white',
    },
  },
  appBar: {
    enable: true,
    config: {},
  },
  search: {
    enable: false,
    config: {},
  },
  notifications: {
    enable: true,
    config: {},
  },
  profile: {
    enable: true,
    config: {
      iconSrc: require('assets/images/logos/logo-transparent.png'),
    },
  },
  language: {
    enable: true,
  },
};

export default Configuration;
