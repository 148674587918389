import { IAction, IActionMethods, StateStatus } from '../../utils/common';
import * as Types from './types';
import * as UserService from 'services/api/user';
import * as AnalyticsService from 'services/api/analytics';
import { Dispatch } from 'redux';
import reactLogger from 'utils/logger';
import { DaysRangeEnum, MonthsEnum, RegionsEnum } from 'config';

/** Fetch Visitors By Day  */

interface IVisitorInput {
  storeid: string;
  // region: RegionsEnum;
  month: MonthsEnum;
  weekNumber: number;
}

class FetchVisitorsByDay implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_VISITORS_BY_DAY,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_VISITORS_BY_DAY,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_VISITORS_BY_DAY,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(input: IVisitorInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AnalyticsService.getVisitorsByDay(input);
        let { daysLabels, ...rest } = response.data;
        dispatch(this.onSuccess(rest));
      } catch (error) {
        reactLogger.error('FetchVisitorsByDay Error:', error.message);
        dispatch(this.onFailed());
      }
    };
  }
}

/** Fetch Visitors Week Num  */

interface IVisitorWeekNumInput {
  storeid: string;
  month: MonthsEnum;
  weekNumber: number;
}

class FetchVisitorsWeekNum implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_VISITORS_WEEK_NUM,
      status: StateStatus.Pending,
      data: null,
    };
  }
  onSuccess(data: any): IAction {
    return {
      type: Types.SET_VISITORS_WEEK_NUM,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_VISITORS_WEEK_NUM,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(input: IVisitorWeekNumInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());
        let response = await AnalyticsService.getVisitorsByDayWeekNum(input);
        dispatch(this.onSuccess(response.data));
      } catch (error) {
        reactLogger.error('FetchVisitorsWeekNum Error:', error.message);
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  // fetchStoresAction: () => new FetchStores().action(),
  fetchVisitorsByDayAction: (input: IVisitorInput) =>
    new FetchVisitorsByDay().action(input),
  fetchVisitorsWeekNumAction: (input: IVisitorWeekNumInput) =>
    new FetchVisitorsWeekNum().action(input),
};
