import { IAction, KEEP_THE_SAME, StateStatus } from '../../utils/common';
import * as Types from './types';
import { StoresDataType } from 'services/api/user';
import { ICamerasListData } from 'services/api/stores';

export interface IState {
  stores: {
    status: StateStatus | null;
    data: null | StoresDataType;
  };
  selectedStore: null | string;
  cameras: {
    status: StateStatus | null;
    data: null | ICamerasListData['items'];
  };
}

const initialState: IState = {
  stores: {
    status: null,
    data: null,
  },
  cameras: {
    status: null,
    data: null,
  },
  selectedStore: null,
};

const VisitorsByDayReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_STORES:
      return {
        ...state,
        stores: {
          ...state.stores,
          status: action.status || null,
          data: action.data === KEEP_THE_SAME ? state.stores.data : action.data,
        },
      };
    case Types.SET_SELECTED_STORE:
      return {
        ...state,
        selectedStore: action.data,
      };
    case Types.SET_CAMERAS:
      return {
        ...state,
        cameras: {
          ...state.cameras,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.cameras.data : action.data,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default VisitorsByDayReducer;
