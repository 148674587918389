import React from 'react';
import { CustomChart } from 'components/molecules/custom_chart';
import CardLabelTitle from 'components/app_common/card_label_title';
import { ChartTypesEnum } from 'components/molecules/custom_chart/configuration';
import { AppSelect, AppDatePicker } from 'components/molecules/form_controls';
import TranslatedText from 'components/atoms/translated_text';
import { useForm } from 'react-hook-form';
import { DaysEnum, Days } from 'config';
import { useDispatch } from 'react-redux';
import { ChartOptions } from 'chart.js';
import OccupancyActions from 'redux/reducers/occupancy_reducer/actions';
import * as OccupancySelectors from 'redux/reducers/occupancy_reducer/selectors';

const StoreInOutChart = () => {
  const data = OccupancySelectors.useSelectVisitorsHistoryPerHour();

  return (
    <CustomChart
      title={
        <CardLabelTitle
          title={
            <TranslatedText defaultText="Visitors by Day of Week per Hour" />
          }
        />
      }
      height="400px"
      onOverview={() => <DaySelectorWrapper />}
      layoutProps={{
        variant: 'outlined',
      }}
      data={[
        {
          data: data?.map((el) => el.count) || [],
          label: 'Dataset',
          format: {
            label: 'Store',
            fill: false,
            borderColor: '#DD2A42',
            lineTension: 0.1, // make the lines without radius
            backgroundColor: '#DD2A42',
            pointBorderColor: '#DD2A42',
            pointBackgroundColor: '#DD2A42',
            pointHoverBackgroundColor: '#DD2A42',
            pointHoverBorderColor: '#DD2A42',
          },
        },
      ]}
      labels={Array.isArray(data) ? data.map((el) => el._id) : []}
      options={StoreInOutOptionsChart}
      chartType={ChartTypesEnum.LINE}
    />
  );
};

interface IFormData {
  day: Date;
}

const DaySelectorWrapper = React.memo(() => {
  const dispatch = useDispatch();
  const { setValue, watch, handleSubmit, errors, control, getValues } =
    useForm<IFormData>({
      defaultValues: {
        day: new Date(),
      },
    });

  const selectedDay = watch('day');

  React.useEffect(() => {
    dispatch(OccupancyActions.setDayAction({ day: selectedDay }));
  }, [selectedDay]);

  const options = Days;
  return (
    <div style={{ marginTop: 8 }}>
      {/* <AppSelect
        margin="none"
        fullWidth
        label={<TranslatedText defaultText={'Select Day'} />}
        name={'day'}
        size="small"
        required
        control={control}
        rules={{}}
        style={{ minWidth: 180 }}
        variant="outlined"
        options={options || []}
      /> */}
      <AppDatePicker
        fullWidth
        margin="none"
        size="small"
        label={<TranslatedText defaultText={'Select Date'} />}
        autoOk
        helperText={errors.day && errors.day.message}
        error={'day' in errors}
        control={control}
        rules={{
          required: false,
        }}
        name={'day'}
      />
    </div>
  );
});

export const StoreInOutOptionsChart: ChartOptions = {
  legend: {
    display: false,
    align: 'start',
    labels: {
      usePointStyle: true,
    },
  },
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      display: false,
    },
  },
  responsive: true,
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: true,
        },
      },
    ],
  },
  tooltips: {
    enabled: true,
  },
};

export default React.memo(StoreInOutChart);
